import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { IBlogeCategory } from "./BlogCategoryController";
import { hasAnyModification } from "../../utils/AppFunctions";
import { Form, Formik } from "formik";
import ICFormikTextInput from "../../components/formik-input/ICFormikTextInput";
import ICFormikSwitch from "../../components/formik-input/ICFormikSwitch";
import ICButton from "../../core-component/ICButton";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import ICModal from "../../core-component/ICModal";
import {
  IBlogCategoryForm,
  blogCategoryValidationSchema,
  fetchBlogCategoryId,
  getBlogCategoryInitialValues,
  handleSubmitBlogCategory,
} from "./BlogCategoryFromController";

interface IBlogCategoryProps {
  handleClose: (status: boolean) => void;
  blogCategoryToEdit?: IBlogeCategory;
  readOnly?: boolean;
}

const BlogCategoryForm: React.FC<IBlogCategoryProps> = ({
  handleClose,
  blogCategoryToEdit,
  readOnly,
}) => {
  const { t } = UseTranslationHook();
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [blogCategoryFormInitialValues, setBlogCategoryFormInitialValues] =
    useState<IBlogCategoryForm>();
  const checkFormModification = (values: IBlogCategoryForm) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      blogCategoryFormInitialValues &&
      hasAnyModification(values, blogCategoryFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };
  useEffect(() => {
    if (blogCategoryToEdit) {
      fetchBlogCategoryId(
        blogCategoryToEdit.id,
        setBlogCategoryFormInitialValues,
        handleClose
      );
    } else {
      setBlogCategoryFormInitialValues(
        getBlogCategoryInitialValues(blogCategoryToEdit)
      );
    }
  }, []);
  return (
    <div>
      {blogCategoryFormInitialValues && (
        <Formik
          initialValues={blogCategoryFormInitialValues}
          validationSchema={() => blogCategoryValidationSchema(t)}
          enableReinitialize
          onSubmit={(values) =>
            handleSubmitBlogCategory(
              values,
              blogCategoryToEdit?.id,
              handleClose,
              t
            )
          }
        >
          {(formikPorps) => {
            const { handleSubmit, values } = formikPorps;
            return (
              <FormRightModalWrapper
                onClose={() => checkFormModification(values)}
                title={t("blog_category")}
              >
                <Form onSubmit={handleSubmit} className="flex flex-col h-full">
                  <div className="flex-1 overflow-y-auto pt-2.5">
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"category_title"}
                      placeholder={t("blog_category_title")}
                      disabled={readOnly}
                      autoFocus
                      required
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"category_meta_title"}
                      placeholder={t("blog_category_meta_title")}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"slug"}
                      placeholder={t("blog_slug")}
                      disabled={readOnly}
                      required
                    />
                    {values.id && (
                      <ICFormikSwitch
                        {...formikPorps}
                        containerClassName={
                          "flex items-center justify-between mt-4"
                        }
                        name={"is_active"}
                        placeholder={t("is_active")}
                        checkedValue={"1"}
                        unCheckedValue={"0"}
                        disabled={readOnly}
                        required
                      />
                    )}
                  </div>
                  {!readOnly && (
                    <ICButton className={"w-full"}>{t("submit")}</ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}
      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};

export default BlogCategoryForm;
