import { IAddService } from "../../data/AppInterface";
import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import {
  GET_ALL_SERVICE_TITLE,
  GET_BOOKING_CANCELLATION_BY_ID,
  POST_BOOKING_CANCELATION,
  UPDATE_BOOKING_CANCELATION,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import { IBookingCancellationCharges } from "./BookingCancellationViewController";
import * as Yup from "yup";

export interface IBookingCancellationForm
  extends Omit<
    IBookingCancellationCharges,
    "id" | "created_by" | "created_date" | "modified_by" | "modified_date"
  > {}

export const bookingCancellationValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    service_id: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("service_title")],
      ])
    ),

    cancellation_cutoff_hours: Yup.number()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("cancellation_cutoff_hours")],
        ])
      )
      .max(
        10000,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("cancellation_cutoff_hours")],
          ["max", "4"],
        ])
      )
      .min(
        0,
        prepareMessageFromParams(t("error_message_negative"), [
          ["fieldName", t("cancellation_cutoff_hours")],
        ])
      ),

    cancellation_refund_percentage: Yup.number()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("cancellation_refund_percentage")],
        ])
      )
      .max(
        100,
        prepareMessageFromParams(t("error_message_less_than_equal"), [
          ["fieldNameLess", t("cancellation_refund_percentage")],
          ["fieldNameGrater", "100"],
        ])
      )
      .min(
        1,
        prepareMessageFromParams(t("error_message_negative"), [
          ["fieldName", t("cancellation_refund_percentage")],
        ])
      ),
  });

export const getBookingCancellationInitialValues = (
  bookingCancellationToEdit: IBookingCancellationCharges | undefined,
  serviceId: number | undefined
): IBookingCancellationForm => {
  return {
    service_id: serviceId
      ? serviceId
      : bookingCancellationToEdit?.service_id || null,
    service_title: bookingCancellationToEdit?.service_title || "",
    cancellation_cutoff_hours:
      bookingCancellationToEdit?.cancellation_cutoff_hours || null,
    cancellation_refund_percentage:
      bookingCancellationToEdit?.cancellation_refund_percentage || null,
    is_active: bookingCancellationToEdit?.is_active || "1",
  };
};

export const fetchBookingCancellationById = async (
  id: number,
  setFaqsList: TReactSetState<IBookingCancellationForm | undefined>,
  handleClose: (status: boolean) => void
) => {
  try {
    const bookingCancellationId = await GET_BOOKING_CANCELLATION_BY_ID(id);
    if (
      bookingCancellationId &&
      bookingCancellationId.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      setFaqsList({
        service_id: bookingCancellationId.data.service_id,
        service_title: bookingCancellationId.data.service_title,
        cancellation_cutoff_hours:
          bookingCancellationId.data.cancellation_cutoff_hours,
        cancellation_refund_percentage:
          bookingCancellationId.data.cancellation_refund_percentage,
        is_active: bookingCancellationId.data.is_active,
      });
    } else {
      handleClose(false);
      SweetAlertError(
        bookingCancellationId.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(
      e?.bookingCancellationId?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const fetchServiceTitleApiCall = async (
  setServiceTitle: TReactSetState<IAddService[]>
) => {
  try {
    const serviceList = await GET_ALL_SERVICE_TITLE();
    if (serviceList && serviceList.code === DEFAULT_STATUS_CODE_SUCCESS) {
      if (serviceList.data) {
        setServiceTitle(serviceList.data.item);
      } else {
        SweetAlertError(serviceList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (e: any) {
    SweetAlertError(e?.serviceList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleSubmitBookingCancellation = async (
  values: IBookingCancellationForm,
  id: number | undefined,
  handleClose: (status: boolean) => void,
  t: TUseTranslationTfn
) => {
  try {
    let resultFaqs;
    if (!id) {
      resultFaqs = await POST_BOOKING_CANCELATION(values);
    } else {
      resultFaqs = await UPDATE_BOOKING_CANCELATION(values, id);
    }
    if (resultFaqs && resultFaqs.code === DEFAULT_STATUS_CODE_SUCCESS) {
      id
        ? SweetAlertSuccess(
            prepareMessageFromParams(t("update_data_successfully"), [
              ["updatedName", t("booking_cancellation")],
            ])
          )
        : SweetAlertSuccess(
            prepareMessageFromParams(t("add_data_successfully"), [
              ["addName", t("booking_cancellation")],
            ])
          );
      return handleClose(true);
    } else {
      SweetAlertError(resultFaqs?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    SweetAlertError(e?.resultFaqs?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
