import { serviceMaker } from "..";
import { IPagination, ISearch } from "../../data/AppInterface";
import { IPay } from "../../screens/payment-due/PaymentDueController";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";
import { encryptData } from "../../utils/AppFunctions";
interface IPaymentPayload {
  trainer_payout: IPay[];
}
export const GET_ALL_PAYMENT_DUE = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_payment_due.url +
      `?currentPage=${pagination.currentPage}&perPageRows=${
        pagination.perPageRows
      }&orderBy=${pagination.orderBy}${
        search.enterpriseSearch &&
        `&trainerName=${encodeURIComponent(
          search.enterpriseSearch ? search.enterpriseSearch : ""
        )}`
      }`,
    API_ENDPOINTS.get_all_payment_due.method
  );
};
export const GET_PAYMENT_DUE_SUMMARY = (
  pagination: IPagination,
  trainerId: number
) => {
  return serviceMaker(
    API_ENDPOINTS.get_payment_due_summary.url +
      `/${encryptData(trainerId.toString())}` +
      `?currentPage=${pagination.currentPage}&perPageRows=${pagination.perPageRows}&orderBy=${pagination.orderBy}&sortBy=${pagination.sortBy}
      `,
    API_ENDPOINTS.get_payment_due_summary.method
  );
};

export const TRAINER_PAYOUT = (payload: IPaymentPayload) => {
  return serviceMaker(
    API_ENDPOINTS.trainer_payout.url,
    API_ENDPOINTS.trainer_payout.method,
    { payload: encryptData(JSON.stringify(payload)) }
  );
};
