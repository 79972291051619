import React, { useState, useEffect } from "react";
import {
  IReviewLog,
  fetchReviewLogs,
  handleTotalCount,
  sectionFieldValues,
} from "./TrainerApplicationFormController";
import { ITrainerApplication } from "./TrainerApplicationController";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import ModalComponent from "../../core-component/ICModalComponent";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import { dateFormat, getLocalDate } from "../../utils/AppFunctions";

interface ITrainerApplicationFormProps {
  handleClose: (status: boolean) => void;
  trainerApplicationToEdit?: ITrainerApplication;
  readOnly?: boolean;
}
const TrainerApplicationTimeLineView: React.FC<
  ITrainerApplicationFormProps
> = ({ handleClose, trainerApplicationToEdit, readOnly }) => {
  const { t } = UseTranslationHook();
  const [showListModal, setShowListModal] = useState(false);
  const [timeLog, setTimeLog] = useState<IReviewLog>();
  const [reviewLogs, setReviewLogs] = useState<IReviewLog[]>([]);
  const [modalValue, setModalValue] = useState("");

  useEffect(() => {
    if (trainerApplicationToEdit?.id) {
      fetchReviewLogs(trainerApplicationToEdit.id, setReviewLogs);
    }
  }, []);

  useEffect(() => {
    if (timeLog?.section_review_status) {
      sectionFieldValues(timeLog?.section_review_status, setModalValue, t);
    }
  }, [timeLog]);

  const timeLineDateView = (reviewDate: string) => {
    const dateObj = getLocalDate(reviewDate);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");

    return (
      <div>
        <div>
          {year}-{month}-{day}
        </div>
        <div>
          {hours}:{minutes}:{seconds}
        </div>
      </div>
    );
  };

  const handleOpenListModal = (val: IReviewLog) => {
    setShowListModal(true);
    setTimeLog(val);
  };
  const handleCloseModal = () => {
    setShowListModal(false);
  };

  const timeLineComponent = (
    <>
      <div className={`  ${readOnly ? " ml-2.5" : ""}`}>
        {reviewLogs.length > 0 &&
          reviewLogs.map((val, index) => {
            return (
              <>
                <div
                  className={`grid grid-cols-15 p-2  ${
                    val.section_review_status !== null &&
                    val.section_review_status.length > 0
                      ? "cursor-pointer"
                      : ""
                  }`}
                  onClick={
                    val.section_review_status !== null &&
                    val.section_review_status.length > 0
                      ? () => handleOpenListModal(val)
                      : undefined
                  }
                  key={index}
                >
                  <div className="col-span-4">
                    <span className="">
                      {timeLineDateView(val.review_date)}
                    </span>
                  </div>

                  <div className=" col-span-1 relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-[13px] after:border-l-4 after:timeline-border-color after:border-dotted">
                    <div className="relative z-10 size-7 flex justify-center items-center">
                      <div className="size-2 rounded-full bg-skin-time-line-color"></div>
                    </div>
                  </div>

                  <div className="col-span-10  grow pt-0.5 pb-5">
                    <h3 className="inline-flex w-full flex-wrap gap-x-1.5 font-semibold ">
                      {val.review_status_label}
                    </h3>
                    <p className="mt-1 font-medium text-xs">
                      {t("by")}: {val.reviewed_by}
                    </p>
                    <p className="mt-1 text-sm text-skin-input-placeholder">
                      {val.review_comment}
                    </p>
                  </div>

                  <div
                    className={`col-span-1 font-semibold px-2 ${
                      val.section_review_status !== null &&
                      val.section_review_status.length > 0
                        ? "border trainer-form-accordion-border shadow-2xl rounded-full h-max w-max"
                        : ""
                    }`}
                  >
                    {val.section_review_status !== null &&
                      val.section_review_status.length > 0 &&
                      handleTotalCount(val.section_review_status)}
                  </div>
                </div>
              </>
            );
          })}
      </div>
      {showListModal && (
        <ModalComponent
          title={dateFormat(getLocalDate(timeLog?.review_date)) || ""}
          content={
            <div className="space-y-3 mt-3">
              <div className="flex space-x-1">
                <p>{t("application_review_by")}: </p>
                <p>{timeLog?.reviewed_by}</p>
              </div>
              <textarea
                className="h-52 w-full custom-text-area typo-input-value"
                value={modalValue}
                readOnly={true}
              />
            </div>
          }
          open={showListModal}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
  return readOnly ? (
    <FormRightModalWrapper
      containerClassName={"w-[40%]"}
      onClose={() => handleClose(false)}
      title={t("application_review_history")}
    >
      {timeLineComponent}
    </FormRightModalWrapper>
  ) : (
    timeLineComponent
  );
};

export default TrainerApplicationTimeLineView;
