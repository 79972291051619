import { ILibraryFolderPayload } from "../../data/AppInterface";
import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import {
  CREATE_FOLDER,
  GET_FOLDER_BY_ID,
  UPDATE_FOLDER_LIBRARY,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import * as Yup from "yup";

export interface ILibraryAddFolderForm
  extends Omit<
    ILibraryFolderPayload,
    "id" | "created_by" | "created_date" | "modified_by" | "modified_date"
  > {
  id: number | undefined;
}

export const libraryAddFolderValidationSchema = (
  t: TUseTranslationTfn,
  isPremium?: string
) =>
  Yup.object().shape({
    directory_name: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("title")],
        ])
      )
      .max(
        30,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("title")],
          ["max", "30"],
        ])
      ),
    description: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("description")],
        ])
      )
      .max(
        4000,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("title")],
          ["max", "4000"],
        ])
      ),
    ...(isPremium === undefined
      ? {
          course_price: Yup.number().when("is_premium", {
            is: "1",
            then: () =>
              Yup.number()
                .required(
                  prepareMessageFromParams(t("error_message_required"), [
                    ["fieldName", t("price")],
                  ])
                )
                .min(
                  1,
                  prepareMessageFromParams(t("error_message_negative"), [
                    ["fieldName", t("price")],
                  ])
                )
                .max(
                  9999999,
                  prepareMessageFromParams(t("error_message_less_than"), [
                    ["fieldNameLess", t("price")],
                    ["fieldNameGrater", "9999999"],
                  ])
                ),
          }),
        }
      : {}),

    course_discounted_price: Yup.number().when("is_premium", {
      is: "1",
      then: () =>
        Yup.number()
          .min(
            0,
            prepareMessageFromParams(t("error_message_negative"), [
              ["fieldName", t("discounted_price")],
            ])
          )
          .max(
            Yup.ref("course_price"),
            prepareMessageFromParams(t("error_message_less_than"), [
              ["fieldNameLess", t("discounted_price")],
              ["fieldNameGrater", "price"],
            ])
          ),
    }),
  });

export const getLibraryAddFolderInitialValues = (
  addFolderToEdit: ILibraryFolderPayload | undefined,
  libraryDirectory: number
): ILibraryAddFolderForm => {
  return {
    id: addFolderToEdit?.id,
    parent_directory_id:
      addFolderToEdit?.parent_directory_id || libraryDirectory,
    directory_name: addFolderToEdit?.directory_name || "",
    description: addFolderToEdit?.description || "",
    status: addFolderToEdit?.status || 1,
    duration: addFolderToEdit!?.duration,
    is_public: addFolderToEdit?.is_public || "0",
    is_premium: addFolderToEdit?.is_premium || "0",
    is_course_specific: addFolderToEdit?.is_course_specific || "0",
    course_price: addFolderToEdit?.course_price || undefined,
    cover_image_path: addFolderToEdit?.cover_image_path || "",
    course_discounted_price:
      addFolderToEdit?.course_discounted_price || undefined,
    is_active: addFolderToEdit?.is_active || "1",
  };
};

export const fetchLibraryAddFolderById = async (
  id: number,
  setLibraryAddFolderList: TReactSetState<ILibraryAddFolderForm | undefined>,
  handleClose: (status: boolean) => void
) => {
  try {
    const libraryAddFolderId = await GET_FOLDER_BY_ID(id);
    if (
      libraryAddFolderId &&
      libraryAddFolderId.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      setLibraryAddFolderList({
        id: libraryAddFolderId.data.id,
        parent_directory_id: libraryAddFolderId.data.parent_directory_id,
        directory_name: libraryAddFolderId.data.directory_name,
        description: libraryAddFolderId.data.description,
        status: libraryAddFolderId.data.status,
        duration: libraryAddFolderId.data.duration,
        is_public: libraryAddFolderId.data.is_public,
        is_premium: libraryAddFolderId.data.is_premium,
        is_course_specific: libraryAddFolderId.data.is_course_specific,
        course_price:
          libraryAddFolderId.data.course_price !== null
            ? libraryAddFolderId.data.course_price
            : undefined,
        course_discounted_price:
          libraryAddFolderId.data.course_discounted_price !== null
            ? libraryAddFolderId.data.course_discounted_price
            : undefined,
        is_active: libraryAddFolderId.data.is_active,
        cover_image_path: libraryAddFolderId.data.cover_image_path || "",
      });
    } else {
      handleClose(false);
      SweetAlertError(
        libraryAddFolderId.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(
      e?.libraryAddFolderId?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleSubmitLibraryAddFolder = async (
  values: ILibraryAddFolderForm,
  id: number | undefined,
  handleClose: (status: boolean) => void,
  libraryDirectory: number,
  t: TUseTranslationTfn
) => {
  try {
    let resultLibraryAddFolder;
    if (!id) {
      resultLibraryAddFolder = await CREATE_FOLDER(values);
    } else {
      resultLibraryAddFolder = await UPDATE_FOLDER_LIBRARY(values, id);
    }
    if (
      resultLibraryAddFolder &&
      resultLibraryAddFolder.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      id
        ? SweetAlertSuccess(resultLibraryAddFolder.message)
        : SweetAlertSuccess(resultLibraryAddFolder.message);
      return handleClose(true);
    } else {
      SweetAlertError(
        resultLibraryAddFolder?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    SweetAlertError(
      e?.resultLibraryAddFolder?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
