import React from "react";
import ReactDOM from 'react-dom/client';

import App from './App';
import { store } from './redux/Store';
import { Provider } from 'react-redux';
// it is use for env file
// require('dotenv').config({ path: '../environment/.env.production' });
import './style/Index.css';
import './style/Custom.css';
import './style/Style.css';
import './style/ThemeTypo.css';
import './i18n';
import ErrorBoundary from './core-component/error-boundary/ErrorBoundaryView';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>
);
