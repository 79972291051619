import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import { routeMaster } from "../../routes/RouteHome";
import { GET_ALL_PAYMENT_DUE, TRAINER_PAYOUT } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  encryptData,
  generatePaginationFromApiRes,
  prepareMessageFromParams,
  resetPaginationWithPpr,
  SweetAlertError,
  SweetAlertSuccess,
  toastError,
} from "../../utils/AppFunctions";
import Swal from "sweetalert2";

export interface IPaymentDue {
  trainer_id: number;
  trainer_name: string;
  from_date: string;
  to_date: string;
  no_of_bookings: number;
  no_of_sessions: number;
  total_amount: string;
  tds_amount: string;
  total_payable: string;
}

export interface IPay {
  trainer_id: number;
  amount: string;
}
interface IPaymentPayload {
  trainer_payout: IPay[];
}
export function getPaymentDueFields<T>(
  customTrainerView: (item: T) => JSX.Element,
  actionComponent: (item: T) => JSX.Element,
  customCheckBoxView: (item: T) => JSX.Element,
  checkBoxLabelComponent: JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("trainer"),
      key: "trainer_id",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      labelComponent: checkBoxLabelComponent,
      component: customCheckBoxView,
    },
    {
      name: t("trainer"),
      key: "trainer_name",
      sort: true,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-4",
      component: customTrainerView,
    },
    {
      name: t("from_date"),
      key: "from_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-3",
    },
    {
      name: t("to_date"),
      key: "to_date",
      sort: false,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-3",
    },
    {
      name: t("no_of_bookings"),
      key: "no_of_bookings",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("no_of_sessions"),
      key: "no_of_sessions",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("total_amount"),
      key: "total_amount",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-3",
    },
    {
      name: t("tds_amount"),
      key: "tds_amount",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-3",
    },
    {
      name: t("total_payable"),
      key: "total_payable",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-3",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
      component: actionComponent,
    },
  ];
}

export const getTotalAmount = (trainers: IPay[]): number => {
  return trainers.reduce((sum, trainer) => sum + parseFloat(trainer.amount), 0);
};

export const handleOnClickPay = (
  total: string,
  payItem: IPay[],
  fetchPaymentDue: (pagination: IPagination) => void,
  pagination: IPagination,
  t: TUseTranslationTfn
) => {
  Swal.fire({
    title: t("are_you_sure"),
    text: prepareMessageFromParams(t("payment_text_message"), [
      ["amount", t(total)],
    ]),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: t("confirm"),
    allowOutsideClick: false,
  }).then((result: any) => {
    if (result.isConfirmed) {
      let timerInterval: any;
      Swal.fire({
        title: t("payout_in_progress"),
        text: t("payout_warning_text"),
        icon: "warning",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup()?.querySelector("b");
          if (timer) {
            timerInterval = setInterval(() => {
              timer.textContent = `${Swal.getTimerLeft()}`;
            }, 100);
          }

          makePayout(payItem)
            .then(() => fetchPaymentDue(pagination))
            .finally(() => {
              clearInterval(timerInterval);
            });
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
    }
  });
};

const makePayout = (payItem: IPay[]) => {
  const payload = { trainer_payout: payItem };
  return makeTrainerPayment(payload);
};

export const fetchPaymentDueApiCall = async (
  setPaymentDueList: TReactSetState<IPaymentDue[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const paymentDueListResponse = await GET_ALL_PAYMENT_DUE(
      pagination,
      search
    );
    if (
      paymentDueListResponse &&
      paymentDueListResponse.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (
        paymentDueListResponse.data &&
        paymentDueListResponse.data.item.length > 0
      ) {
        setPaymentDueList(paymentDueListResponse.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(
            paymentDueListResponse.data.pagination
          ),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const paymentDueListResponse = await GET_ALL_PAYMENT_DUE(
          resetPagination,
          search
        );
        if (
          paymentDueListResponse &&
          paymentDueListResponse.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setPaymentDueList(paymentDueListResponse.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              paymentDueListResponse.data.pagination
            ),
          });
        } else {
          setPaymentDueList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(
        paymentDueListResponse.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    toastError(e?.response?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const makeTrainerPayment = async (payload: IPaymentPayload) => {
  try {
    const payment = await TRAINER_PAYOUT(payload);
    if (payment && payment.code === DEFAULT_STATUS_CODE_SUCCESS) {
      SweetAlertSuccess(payment.message);
      return true;
    } else {
      SweetAlertError(payment.message);
    }
  } catch (e: any) {
    toastError(e?.response?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    // after testing there is no error then remove this comment
    // throw e;
  }
};
