import React, { useState } from "react";
import PaginationFooter from "../components/page-layouts/PaginationFooter";
import { IPagination } from "../data/AppInterface";
import {
  arrowDownIcon,
  arrowUpIcon,
  sideIconLink,
} from "../assets/icons/SvgIconList";
import { ORDER_BY } from "../utils/AppConstants";
import {
  dateFormat,
  getLocalDate,
  getLocalTime,
  getTextContentOnly,
  timeFormat,
} from "../utils/AppFunctions";
import UseTranslationHook from "../hooks/UseTranslationHook";
import { PER_PAGE_ROWS } from "../config/AppConfig";

export const TABLE_FIELD_ALIGN = {
  center: 1,
  right: 2,
  left: 3,
};

export const TABLE_FIELD_TYPE = {
  text: 1,
  status: 2,
  switch: 3,
  date: 4,
  custom: 5,
  currency: 6,
  format: 7,
  time: 8,
};

type TTableFieldAlign =
  (typeof TABLE_FIELD_ALIGN)[keyof typeof TABLE_FIELD_ALIGN];

type TTableFieldType = (typeof TABLE_FIELD_TYPE)[keyof typeof TABLE_FIELD_TYPE];

export interface ITableFields<T> {
  name: string;
  key: string;
  sort: boolean;
  type: TTableFieldType;
  align: TTableFieldAlign;
  flex: string;
  checkedValue?: string | boolean | number;
  className?: string;
  handleCheck?: (item: T) => void;
  statusLabelPair?: Record<string | number, string>;
  statusColorClassPair?: Record<string | number, string>;
  labelComponent?: JSX.Element;
  component?: (item: T) => JSX.Element;
  limit?: number;
  currencyPrefix?: string;
  format?: (item: ITabelFieldViewProps<T>) => string | number | JSX.Element;
  drillDown?: boolean;
  rowIndex?: number;
  colIndex?: number;
  title?: string;
}

export interface ICustomTableProps<T> {
  fields: ITableFields<T>[];
  totalFlex: string;
  data: T[];
  action?: (item: T) => React.JSX.Element;
  pagination?: IPagination;
  handleChangePerPageRows?: (perPageRows: number) => void;
  handleChangePage?: (page: number) => void;
  handleSort: (field: ITableFields<T>) => void;
  isLoading?: boolean;
  isHover?: boolean;
  isRender?: boolean;
}

export interface ITabelFieldViewProps<T> {
  field: ITableFields<T>;
  data: any;
}

const getTextFieldAlignClass = (align: TTableFieldAlign) => {
  switch (align) {
    case TABLE_FIELD_ALIGN.center:
      return "text-center";
    case TABLE_FIELD_ALIGN.left:
      return "text-left";
    case TABLE_FIELD_ALIGN.right:
      return "text-right";
    default:
      return "text-center";
  }
};

const getTextFieldJustifyClass = (align: TTableFieldAlign) => {
  switch (align) {
    case TABLE_FIELD_ALIGN.center:
      return "justify-center";
    case TABLE_FIELD_ALIGN.left:
      return "justify-start";
    case TABLE_FIELD_ALIGN.right:
      return "justify-end";
    default:
      return "justify-center";
  }
};

const tableLabelView = <T extends object>(
  fields: ITableFields<T>[],
  handleSort: (field: ITableFields<T>) => void,
  sortBy: string,
  orderBy: string,
  totalFlex: string,
  isHover?: boolean
) => {
  return (
    <tr
      className={`bg-skin-table-base px-2 py-2 border lg:border border-skin-table ${totalFlex}`}
    >
      {fields.filter((isItem) => isItem.drillDown).length ? (
        <span className="w-4  ">&nbsp;</span>
      ) : (
        ""
      )}
      {fields
        .filter(
          (labelDrillDownItem) =>
            !labelDrillDownItem.drillDown &&
            (labelDrillDownItem.key !== "action" || isHover
              ? labelDrillDownItem.key !== "action"
              : labelDrillDownItem.key === "action")
        )
        .map(
          (
            { name, sort, align, className, key, flex, labelComponent },
            index
          ) => {
            return (
              <th
                scope="col"
                key={name + index + "t-header"}
                onClick={() => (sort ? handleSort(fields[index]) : {})}
                className={`item-center font-semibold ${flex} `}
              >
                {labelComponent ? (
                  labelComponent
                ) : (
                  <span
                    className={`${getTextFieldJustifyClass(align)} ${
                      className || ""
                    } flex items-center ${
                      sort === true ? "cursor-pointer" : "cursor-auto"
                    }`}
                  >
                    {name}
                    {sortBy === key ? (
                      orderBy === ORDER_BY.desc ? (
                        arrowDownIcon
                      ) : (
                        arrowUpIcon
                      )
                    ) : (
                      <span></span>
                    )}
                  </span>
                )}
              </th>
            );
          }
        )}
    </tr>
  );
};

const limitString = (inputString: string, maxCharacters?: number) => {
  if (maxCharacters) {
    if (
      inputString &&
      maxCharacters >= 0 &&
      inputString.length > maxCharacters
    ) {
      return inputString.substring(0, maxCharacters) + "...";
    } else {
      return inputString;
    }
  }
  return inputString;
};

const getTableFieldFromType = <T extends object>(
  fieldData: ITabelFieldViewProps<T>
) => {
  switch (fieldData.field.type) {
    case TABLE_FIELD_TYPE.text:
      return getTableFieldTextInput(fieldData);
    case TABLE_FIELD_TYPE.status:
      return getTableFieldStatus(fieldData);
    case TABLE_FIELD_TYPE.switch:
      return getTableFieldSwitch(fieldData);
    case TABLE_FIELD_TYPE.date:
      return getTableFieldDate(fieldData);
    case TABLE_FIELD_TYPE.custom:
      return getTableCustomField(fieldData);
    case TABLE_FIELD_TYPE.currency:
      return getTableFieldCurrency(fieldData);
    case TABLE_FIELD_TYPE.format:
      return getTableFieldFormat(fieldData);
    case TABLE_FIELD_TYPE.time:
      return getTableFieldTime(fieldData);
  }
};

const getTableFieldFormat = <T extends Object>({
  field,
  data,
}: ITabelFieldViewProps<T>) => (
  <span>{field.format ? field.format({ field, data }) : ""}</span>
);

const getTableFieldCurrency = <T extends Object>({
  field,
  data,
}: ITabelFieldViewProps<T>) => (
  <span className="break-words">
    {data[field.key] ? (field?.currencyPrefix || "") + data[field.key] : ""}
  </span>
);

const getTableFieldTextInput = <T extends Object>({
  field,
  data,
}: ITabelFieldViewProps<T>) => {
  const fieldValue = data[field.key];
  // Check if fieldValue is a string before processing
  const fieldValueAsString =
    typeof fieldValue === "string" || typeof fieldValue === "number"
      ? fieldValue.toString()
      : "";
  return (
    <span className="break-words">
      {limitString(getTextContentOnly(fieldValueAsString), field.limit)}
    </span>
  );
};

const getTableFieldSwitch = <T extends object>({
  field,
  data,
}: ITabelFieldViewProps<T>) => (
  <label className="relative inline-flex cursor-pointer">
    <input
      type="checkbox"
      name={field.name}
      className="sr-only peer"
      onChange={() => (field.handleCheck ? field.handleCheck(data) : {})}
      checked={data[field.key] === field.checkedValue}
    />
    <div className="w-9 h-5 bg-skin-switch-ict peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-skin-ict dark:peer-focus:ring-skin-dark-focus rounded-full peer dark:bg-skin-dark-ict peer-checked:after:translate-x-full peer-checked:after:border-skin-ict-after after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-skin-ict after:border-skin-ict-after after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-skin-ict-dark peer-checked:bg-skin-checked-ict"></div>
  </label>
);

const getTableFieldStatus = <T extends Object>({
  field,
  data,
}: ITabelFieldViewProps<T>) => (
  <span
    className={`py-1 px-3 rounded-full inline-block ${
      field.statusColorClassPair
        ? field.statusColorClassPair[data[field.key]]
        : "bg-purple-200"
    }`}
  >
    {field.statusLabelPair
      ? field.statusLabelPair[data[field.key]]
      : data[field.key]}
  </span>
);

const getTableCustomField = <T extends Object>({
  field,
  data,
}: ITabelFieldViewProps<T>) =>
  field.component ? field.component(data) : <></>;

const getTableFieldDate = <T extends Object>({
  field,
  data,
}: ITabelFieldViewProps<T>) => (
  <span>
    {data[field.key]
      ? dateFormat(getLocalDate(data[field.key]))
      : data[field.key]}
  </span>
);
const getTableFieldTime = <T extends Object>({
  field,
  data,
}: ITabelFieldViewProps<T>) => {
  const localTime = getLocalTime(data[field.key]);
  return <span>{localTime ? timeFormat(localTime) : data[field.key]}</span>;
};

const ICTable = <T extends object>({
  fields,
  data,
  pagination,
  handleChangePerPageRows,
  handleChangePage,
  handleSort,
  totalFlex,
  isLoading,
  isHover,
}: ICustomTableProps<T>) => {
  interface IColumnData {
    col: number;
    drillDownData: { newRow: number; field: ITableFields<T> | null }[];
  }

  const [rotation, setRotation] = useState(Array(fields.length).fill(0));
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [openDropdownIndices, setOpenDropdownIndices] = useState<number[]>([]);
  const [hoverId, SetHoverId] = useState<any>(null);
  const handleImageClick = (index: number) => {
    const newRotation = [...rotation];
    newRotation[index] = rotation[index] === 0 ? 90 : 0;
    setRotation(newRotation);

    const tableRow = document.getElementById(`data-row-${index}`);
    const rect: any = tableRow?.getBoundingClientRect();
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });

    if (openDropdownIndices.includes(index)) {
      setOpenDropdownIndices(openDropdownIndices.filter((i) => i !== index));
    } else {
      setOpenDropdownIndices([...openDropdownIndices, index]);
    }
  };
  const { t } = UseTranslationHook();

  const visibleFields = fields.filter((isVisible) => isVisible.drillDown);
  const doubleArray: IColumnData[] = [];
  let maxCol = 0;
  let maxRow = 0;

  for (let findMax = 0; findMax < visibleFields.length; findMax++) {
    const element = visibleFields[findMax];
    if (element.colIndex && maxCol < element.colIndex) {
      maxCol = element.colIndex;
    }
    if (element.rowIndex && maxRow < element.rowIndex) {
      maxRow = element.rowIndex;
    }
  }
  for (let addCol = 0; addCol < maxCol; addCol++) {
    const columnData: IColumnData = {
      col: addCol + 1,
      drillDownData: [],
    };
    for (let addRow = 0; addRow < maxRow; addRow++) {
      const findRow = visibleFields.find(
        (rowItem) =>
          rowItem.rowIndex === addRow + 1 && rowItem.colIndex === columnData.col
      );
      if (findRow) {
        columnData.drillDownData.push({ newRow: addRow + 1, field: findRow });
      } else {
        columnData.drillDownData.push({ newRow: addRow + 1, field: null });
      }
    }
    doubleArray.push(columnData);
  }

  return (
    <div id="tableToPrint">
      <div className="overflow-y-hidden overflow-x-scroll hide-scrollbar no-scrollbar ">
        <div className="custom-table">
          <table className="w-full">
            <thead className="typo-table-header">
              {tableLabelView<T>(
                fields,
                handleSort,
                pagination!?.sortBy,
                pagination!?.orderBy,
                totalFlex,
                isHover
              )}
            </thead>
            <tbody className="typo-table-value">
              {!isLoading &&
                data &&
                data.map((item, index) => (
                  <>
                    <tr
                      id={`data-row-${index}`}
                      key={index}
                      className={`px-2 py-1 border border-skin-table bg-skin-table-surface hover:bg-skin-table-hover rounded-sm ${totalFlex}  
                       ${(item as any).IsDeleted ? "isDelete" : ""}
                       ${(item as any).addressed_by ? "isDelete" : ""}
                      }
                      `}
                      onMouseEnter={() => SetHoverId(index)}
                      onMouseLeave={() => SetHoverId(null)}
                    >
                      {fields.filter((drillDownIcon) => drillDownIcon.drillDown)
                        .length ? (
                        <td scope="" data-label="" className="w-4 col-span-1">
                          <div
                            className="w-6 h-6"
                            onClick={() => handleImageClick(index)}
                            style={{
                              transform: `rotate(${rotation[index]}deg)`,
                              transition: "transform 0.5s ease-in-out",
                            }}
                          >
                            {sideIconLink}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {fields
                        .filter(
                          (visibleItem) =>
                            !visibleItem.drillDown &&
                            (visibleItem.key !== "action" || isHover
                              ? visibleItem.key !== "action"
                              : visibleItem.key === "action")
                        )
                        .map((field, mIndex) => (
                          <td
                            data-label={field.name}
                            key={index + field.name}
                            className={`${getTextFieldAlignClass(
                              field.align
                            )} ${field.className || ""} ${field.flex} `}
                            title={
                              field.title
                                ? `${String(
                                    (item as Record<string, any>)[field.key]
                                  )}`
                                : undefined
                            }
                          >
                            {isHover &&
                            isHover === true &&
                            hoverId === index &&
                            mIndex ===
                              fields.length - 1 - visibleFields.length - 1 ? (
                              fields
                                .filter((keyItem) => keyItem.key === "action")
                                .map((HItem) => (
                                  <td
                                    key={HItem.key}
                                    className="flex  justify-center items-center bg-gray-300 h-full w-full"
                                  >
                                    {HItem.component
                                      ? getTableCustomField({
                                          field: HItem,
                                          data: item,
                                        })
                                      : null}
                                  </td>
                                ))
                            ) : (
                              <>
                                {getTableFieldFromType({ field, data: item })}
                              </>
                            )}
                          </td>
                        ))}
                    </tr>

                    {openDropdownIndices.includes(index) && (
                      <tr className=" ">
                        <table className=" w-full">
                          <tr className="">
                            <td className="pr-1.5">&nbsp;</td>
                            {doubleArray.map((aItem) => (
                              <td className="max-w-[100px] h-full ">
                                <div id="Dropdowntable" className="mx-2  my-1">
                                  <table className=" ">
                                    <tbody className="border border-b-1 rounded">
                                      {aItem.drillDownData.map((bItem) => (
                                        <tr>
                                          <td data-label={bItem.field?.name}>
                                            {item &&
                                            bItem.field &&
                                            (item as any)[bItem.field.key] &&
                                            (item as any)[bItem.field.key]
                                              .toString()
                                              .toLocaleLowerCase() !==
                                              "null" ? (
                                              <>
                                                {bItem.field ? (
                                                  getTableFieldFromType({
                                                    field: bItem.field,
                                                    data: item,
                                                  })
                                                ) : (
                                                  <span>&nbsp;</span>
                                                )}
                                              </>
                                            ) : (
                                              <span>&nbsp;</span>
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            ))}
                          </tr>
                        </table>
                      </tr>
                    )}
                  </>
                ))}
              {isLoading &&
                [
                  ...Array(
                    pagination
                      ? pagination.totalItems === 0
                        ? pagination.perPageRows
                        : pagination.totalItems >
                          pagination.currentPage * pagination.perPageRows
                        ? pagination.perPageRows
                        : pagination.totalItems -
                            (pagination.currentPage - 1) *
                              pagination.perPageRows <
                          0
                        ? pagination.perPageRows
                        : pagination.totalItems -
                          (pagination.currentPage - 1) * pagination.perPageRows
                      : Number(PER_PAGE_ROWS)
                  ).fill("0"),
                ].map((_, index) => (
                  <tr
                    id={`data-row-${index}`}
                    key={index}
                    className={`px-2 py-1 border border-skin-table bg-skin-table-surface ${totalFlex}`}
                  >
                    {fields.map((field) => (
                      <td
                        data-label={field.name}
                        key={index + field.name}
                        className={`my-[6px] ${
                          field.align === TABLE_FIELD_ALIGN.center
                            ? "mx-[5px]"
                            : "mr-[10px]"
                        } ${getTextFieldAlignClass(field.align)} ${
                          field.className || ""
                        } ${field.flex}`}
                      >
                        <div
                          className={`table-value-skeleton ${
                            field.align === TABLE_FIELD_ALIGN.center
                              ? "mx-auto"
                              : ""
                          } w-full h-3`}
                          style={{
                            maxWidth:
                              200 *
                                ((Number(fields.length) *
                                  Number(field.flex.slice(-1))) /
                                  Number(totalFlex.slice(-1))) +
                              "px",
                          }}
                        ></div>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
          {!isLoading && data.length === 0 && (
            <div className="flex justify-center font-bold">
              {t("record_not_found")}
            </div>
          )}
        </div>
      </div>
      {pagination && (
        <PaginationFooter
          pagination={pagination}
          handleChangePerPageRows={handleChangePerPageRows}
          handleChangePage={handleChangePage}
        />
      )}
    </div>
  );
};

export default ICTable;
