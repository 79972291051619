import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_LOCAL_UNKNOWN_ERROR_OCCURRED,
} from "../../../../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  prepareMessageFromParams,
} from "../../../../../utils/AppFunctions";
import { NavigateFunction } from "react-router";
import {
  TReactSetState,
  TUseTranslationTfn,
} from "../../../../../data/AppType";
import * as Yup from "yup";
import { IAddService, IMediaItemImage } from "../../../../../data/AppInterface";
import {
  GET_SERVICE_BY_ID,
  UPDATE_BASIC_INFO_SERVICE,
} from "../../../../../services/Endpoints";
import { routeAdmin, routeMaster } from "../../../../../routes/RouteHome";

export interface IServiceParam {
  [key: string]: string;
}
export interface IAdditionalInfo {
  service_precautions: string[];
  service_pros: string[];
  service_params: IServiceParam;
  service_other_notes: string[];
  service_media?: [IMediaItemImage];
}
function convertToObjectWithKeyAndValue(obj: any[]): {
  key: string;
  value: any;
}[] {
  let paramList = [];
  for (const objParam of obj) {
    paramList.push({
      key: Object.keys(objParam)[0],
      value: Object.values(objParam)[0],
    });
  }
  return paramList;
}
export const additionalInfoValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    service_precautions: Yup.array().of(
      Yup.string().max(
        100,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("service_precautions")],
          ["max", "100"],
        ])
      )
    ),
    service_other_notes: Yup.array().of(
      Yup.string().max(
        100,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("service_other_notes")],
          ["max", "100"],
        ])
      )
    ),
    service_pros: Yup.array().of(
      Yup.string().max(
        100,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("service_pros")],
          ["max", "100"],
        ])
      )
    ),
    service_params: Yup.array().max(
      100,
      prepareMessageFromParams(t("error_message_max_length"), [
        ["fieldName", t("service_pros")],
        ["max", "100"],
      ])
    ),
  });
export const getServiceData = async (
  id: number,
  setGetService: TReactSetState<IAddService[]>
) => {
  if (id) {
    const result = await GET_SERVICE_BY_ID(id);
    const data = result.data;

    if (result.code === DEFAULT_STATUS_CODE_SUCCESS) {
      setGetService(data);
      return {
        service_precautions:
          data.service_precautions && data.service_precautions.length === 0
            ? ["", ""]
            : data.service_precautions,
        service_other_notes:
          data.service_other_notes && data.service_other_notes.length === 0
            ? ["", ""]
            : data.service_other_notes,
        service_pros:
          data.service_pros && data.service_pros.length === 0
            ? ["", ""]
            : data.service_pros,
        service_params:
          data.service_params && data.service_params.length === 0
            ? [{}, {}]
            : [...convertToObjectWithKeyAndValue(data.service_params)],
        service_media: data.app_media || undefined,
      };
    }
  } else {
    return {
      service_precautions: ["", ""],
      service_other_notes: ["", ""],
      service_pros: ["", ""],
      service_params: [{}, {}],
    };
  }
};

export const handleOnSubmitAdditionalInfo = async (
  value: IAddService,
  id: number,
  navigation: NavigateFunction,
  setCurrentIndex: TReactSetState<number>,
  buttonController: boolean,
  t: TUseTranslationTfn
) => {
  try {
    if (id) {
      value = {
        ...value,
        service_params: value.service_params?.filter((item: IServiceParam) => {
          const entries = Object.entries(item);
          // Check if the object is non-empty and all keys and values are defined and not empty strings
          return (
            entries.length > 0 &&
            entries.every(
              ([key, value]) =>
                key !== undefined &&
                value !== undefined &&
                key !== "" &&
                value !== ""
            )
          );
        }),
        service_precautions: value.service_precautions?.filter(
          (item) => item !== ""
        ),
        service_other_notes: value.service_other_notes?.filter(
          (item) => item !== ""
        ),
        service_pros: value.service_pros?.filter((item) => item !== ""),
      };
      const serviceData = await UPDATE_BASIC_INFO_SERVICE(value, id);
      if (serviceData.code === DEFAULT_STATUS_CODE_SUCCESS) {
        if (buttonController === true) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          navigation("/" + routeAdmin.admin + "/" + routeMaster.serviceTable);
        }
        SweetAlertSuccess(serviceData.message);
      } else {
        SweetAlertError(serviceData.message);
      }
    } else {
      SweetAlertError(t("service_not_found"));
    }
  } catch {
    SweetAlertError(MESSAGE_LOCAL_UNKNOWN_ERROR_OCCURRED);
  }
};
