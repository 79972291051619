import { serviceMaker } from "..";
import { IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

export const GET_ALL_PAYMENT_HISTORY = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_payout_history.url +
      `?currentPage=${pagination.currentPage}&perPageRows=${
        pagination.perPageRows
      }&orderBy=${pagination.orderBy}${
        search.enterpriseSearch &&
        `&trainerName=${encodeURIComponent(
          search.enterpriseSearch ? search.enterpriseSearch : ""
        )}`
      }${
        search.advanceSearch?.paymentStatus &&
        `&paymentStatus=${search.advanceSearch?.paymentStatus}`
      }${
        search.advanceSearch?.from_date &&
        `&fromDate=${search.advanceSearch?.from_date}`
      }${
        search.advanceSearch?.to_date &&
        `&toDate=${search.advanceSearch?.to_date}`
      }`,
    API_ENDPOINTS.get_all_payout_history.method
  );
};

export const GET_PAYMENT_HISTORY_BY_ID = (
  id: number,
  pagination: IPagination
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_payout_history.url +
      `/${id}?currentPage=${pagination.currentPage}&perPageRows=${pagination.perPageRows}&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_payout_history.method
  );
};
