import {
  TReactSetState,
  TTFaqsService,
  TUseTranslationTfn,
} from "../../../../../data/AppType";
import {
  ADD_SERVICE_FAQS,
  DELETE_SERVICE_FAQS,
  GET_ALL_SERVICE_FAQS,
  GET_FAQS_BY_SERVICE_ID,
  ServiceFaqsData,
} from "../../../../../services/faqs/FaqsServices";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  prepareMessageFromParams,
  toastError,
} from "../../../../../utils/AppFunctions";
import {
  ITabelFieldViewProps,
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../../../../core-component/ICTable";

export interface IServiceFaqs {
  faq_id: number;
  question: string;
}
export interface IServiceQuestionId {
  question_id: number;
}
export interface IGetServiceFaqs {
  service_faqs_id: number;
  faq_id: number;
  question: string;
  answer: string;
  reference_links: [];
  tags: [];
  is_common_for_service: TTFaqsService;
}
export function getServiceFaqsFields<T>(
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("question"),
      key: "question",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
    },
    {
      name: t("answer"),
      key: "answer",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-4",
      limit: 50,
      title: "answer",
    },
    {
      name: t("is_common_for_service"),
      key: "is_common_for_service",
      sort: false,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      flex: "col-span-2",
      format: ({ field, data }: ITabelFieldViewProps<T>) =>
        data[field.key] === "1" ? "Yes" : "No",
    },
    {
      name: t("tags"),
      key: "tags",
      sort: false,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      format: ({ field, data }: ITabelFieldViewProps<T>) =>
        data[field.key].join(" , ") ? data[field.key].join(" , ") : [],
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}

export const fetchFaqsByServiceId = async (
  id: number,
  setFaqsList: TReactSetState<IServiceFaqs[]>
) => {
  try {
    const faqsServiceList = await GET_FAQS_BY_SERVICE_ID(id);
    if (
      faqsServiceList &&
      faqsServiceList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      setFaqsList(faqsServiceList.data);
    } else {
      SweetAlertError(faqsServiceList.message);
    }
  } catch (err: any) {
    SweetAlertError(
      err.faqsServiceList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const addFaqsByServiceId = async (
  values: ServiceFaqsData,
  t: TUseTranslationTfn
) => {
  try {
    const addFaqService = await ADD_SERVICE_FAQS(values);
    if (addFaqService && addFaqService.code === DEFAULT_STATUS_CODE_SUCCESS) {
      SweetAlertSuccess(
        prepareMessageFromParams(t("add_data_successfully"), [
          ["addName", t("faqs")],
        ])
      );
    } else {
      SweetAlertError(addFaqService.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (err: any) {
    SweetAlertError(
      err.addFaqService.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const getFaqsByService = async (
  id: number,
  setFaqsList: TReactSetState<IGetServiceFaqs[]>
) => {
  try {
    const faqsServiceList = await GET_ALL_SERVICE_FAQS(id);
    if (
      faqsServiceList &&
      faqsServiceList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      setFaqsList(faqsServiceList.data);
    } else {
      SweetAlertError(faqsServiceList.message);
    }
  } catch (err: any) {
    SweetAlertError(
      err.faqsServiceList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleDeleteServiceFaqs = async (
  id: number,
  t: TUseTranslationTfn
) => {
  try {
    const serviceFaqsDelete = await DELETE_SERVICE_FAQS(id);
    if (
      serviceFaqsDelete &&
      serviceFaqsDelete.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(serviceFaqsDelete.message);
      return true;
    } else {
      SweetAlertError(
        serviceFaqsDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
    return false;
  } catch (e: any) {
    SweetAlertError(
      e?.serviceFaqsDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};
