import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  IBlog,
  fetchBlogCategoryApiCall,
  getBlogFields,
  handleDeleteBlog,
} from "./BlogController";
import UsePaginationHook from "../../hooks/UsePaginationHook";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import { IPagination } from "../../data/AppInterface";
import { editIcon, eyeIcon, restoreIcon } from "../../assets/icons/SvgIconList";
import { ORDER_BY } from "../../utils/AppConstants";
import ICTable from "../../core-component/ICTable";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import BlogsFormView from "./BlogFormView";
import ICSweetAlertModal from "../../core-component/ICSweetAlertModal";

const BlogView = () => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [readOnlyForm, setReadOnlyForm] = useState(false);
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [blogList, setBlogList] = useState<IBlog[]>([]);
  const [blogToEdit, setBlogToEdit] = useState<IBlog>();
  const [deleteAll, setDeleteAll] = useState(false);
  const { pagination, setPagination } = UsePaginationHook({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });
  useEffect(() => {
    fetchBlog(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, deleteAll]);

  const fetchBlog = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchBlogCategoryApiCall(
        setBlogList,
        setPagination,
        payloadPagination,
        {
          enterpriseSearch,
          deleteAll,
        }
      );
      setIsLoading(false);
    }, 500);
  };
  const handleCloseForm = (status: boolean) => {
    setShowForm(false);
    setReadOnlyForm(false);
    if (blogToEdit) {
      setBlogToEdit(undefined);
    } else {
      setEnterpriseSearch("");
    }
    if (status) {
      fetchBlog(pagination);
    }
  };

  const handleDeleteCfm = async (id: number) => {
    const data = await handleDeleteBlog(id, t);
    if (data) {
      fetchBlog(pagination);
    }
  };
  const customActionView = (item: IBlog) => {
    return (
      <div>
        {item.deleted === "1" ? (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              title={t("restore")}
              onClick={() => handleDeleteCfm(item.id)}
            >
              {restoreIcon}
            </div>
          </div>
        ) : (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              title={t("view_tooltip_icon")}
              onClick={() => {
                setShowForm(true);
                setBlogToEdit(item);
                setReadOnlyForm(true);
              }}
            >
              {eyeIcon}
            </div>
            <div
              className="mr-2 action-view "
              title={t("edit_tooltip_icon")}
              onClick={() => {
                setShowForm(true);
                setBlogToEdit(item);
              }}
            >
              {editIcon}
            </div>
            <div className="action-view " title={t("delete_tooltip_icon")}>
              <ICSweetAlertModal onConfirm={handleDeleteCfm} itemId={item.id} />
            </div>
          </div>
        )}
      </div>
    );
  };
  const allBlogList = blogList.map((val) => ({
    ...val,
    IsDeleted: val.deleted === "1" ? true : false,
  }));
  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        handleOnClickAdd={() => setShowForm(true)}
        handleSearchChange={(text) => {
          setEnterpriseSearch(text);
        }}
        searchText={enterpriseSearch}
        handleDeleteCheckBox={(ans) => {
          setDeleteAll(ans);
        }}
        filterCheckBox={deleteAll}
        refreshTable={() => fetchBlog(pagination)}
      />
      <ICTable<IBlog>
        isLoading={isLoading}
        totalFlex="grid grid-cols-23"
        fields={getBlogFields(customActionView, t)}
        data={allBlogList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchBlog({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchBlog({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchBlog({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
      {showForm && (
        <BlogsFormView
          handleClose={handleCloseForm}
          blogToEdit={blogToEdit}
          readOnly={readOnlyForm}
        />
      )}
    </div>
  );
};
export default BlogView;
