import React, { useEffect, useState } from "react";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import ICTable from "../../core-component/ICTable";
import usePagination from "../../hooks/UsePaginationHook";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import { IPagination } from "../../data/AppInterface";
import {
  fetchPaymentDueApiCall,
  getPaymentDueFields,
  getTotalAmount,
  handleOnClickPay,
  IPay,
  IPaymentDue,
} from "./PaymentDueController";
import { ORDER_BY } from "../../utils/AppConstants";
import { eyeIcon, linkIcon } from "../../assets/icons/SvgIconList";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import PaymentDueSummary from "./PaymentDueSummary";
import { Link } from "react-router-dom";
import { routeAdmin, routeMaster } from "../../routes/RouteHome";
import { encryptData } from "../../utils/AppFunctions";

const PaymentDue = () => {
  const { t } = UseTranslationHook();
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [readOnlyForm, setReadOnlyForm] = useState(false);
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [paymentDueList, setPaymentDueList] = useState<IPaymentDue[]>([]);
  const [paymentDueToEdit, setPaymentDueToEdit] = useState<number | null>(null);
  const [selectedItems, setSelectedItems] = useState<IPay[]>([]);
  const [totalAmount, setTotalAmount] = useState<string>();
  const [indeterminate, setIndeterminate] = React.useState(false);
  const { pagination, setPagination } = usePagination({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });
  let timer: NodeJS.Timeout;
  useEffect(() => {
    fetchPaymentDue(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch]);

  const fetchPaymentDue = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchPaymentDueApiCall(
        setPaymentDueList,
        setPagination,
        payloadPagination,
        {
          enterpriseSearch,
        }
      );
      setIsLoading(false);
    }, 500);
  };

  useEffect(() => {
    if (selectedItems.length === paymentDueList.length) {
      setIndeterminate(false);
    } else if (selectedItems.length === 0) {
      setIndeterminate(false);
    } else {
      setIndeterminate(true);
    }
  }, [selectedItems, paymentDueList]);

  useEffect(() => {
    const totalAmount = getTotalAmount(selectedItems);
    setTotalAmount(totalAmount.toFixed(2));
  }, [selectedItems]);

  const handleSelectAll = () => {
    if (selectedItems.length === paymentDueList.length) {
      setSelectedItems([]);
    } else {
      const allSelectedItems =
        paymentDueList &&
        paymentDueList.length > 0 &&
        paymentDueList.map((item) => ({
          trainer_id: item.trainer_id,
          amount: item.total_payable,
        }));
      allSelectedItems && setSelectedItems(allSelectedItems);
    }
  };
  const customActionView = (item: IPaymentDue) => {
    return (
      <div className="flex item-center justify-center ml-2">
        <div
          className="mr-2 action-view flex items-center"
          title={t("view_tooltip_icon")}
          onClick={() => {
            setShowForm(true);
            setPaymentDueToEdit(item.trainer_id);
            setReadOnlyForm(true);
          }}
        >
          {eyeIcon}
        </div>
        <div
          className="flex items-center justify-center w-fit px-3 p-1 text-xs font-medium text-skin-advance-search-text-color border rounded-full  focus:outline-none bg-skin-primary-btn cursor-pointer"
          onClick={() =>
            handleOnClickPay(
              item.total_payable,
              [{ trainer_id: item.trainer_id, amount: item.total_payable }],
              fetchPaymentDue,
              pagination,
              t
            )
          }
        >
          {t("pay")}
        </div>
      </div>
    );
  };
  const customTrainerView = (item: IPaymentDue) => {
    const encryptId = encryptData(item.trainer_id.toString());
    return (
      <div className="flex item-center justify-start">
        <div>{item.trainer_name}</div>
        <div className="h-2.5 w-2.5 ml-2 mt-1 cursor-pointer">
          <Link
            to={`/${routeAdmin.admin}/${routeMaster.paymentDue}/${encryptId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkIcon}
          </Link>
        </div>
      </div>
    );
  };

  const handleChange = (value: IPaymentDue) => {
    if (selectedItems.some((item) => item.trainer_id === value.trainer_id)) {
      setSelectedItems(
        selectedItems.filter((item) => item.trainer_id !== value.trainer_id)
      );
    } else {
      setSelectedItems([
        ...selectedItems,
        { trainer_id: value.trainer_id, amount: value.total_payable },
      ]);
    }
  };
  const customCheckBoxView = (items: IPaymentDue) => {
    return (
      <div className="flex mt-1 justify-start">
        <input
          type="checkbox"
          checked={selectedItems.some(
            (item) => item.trainer_id === items.trainer_id
          )}
          onChange={() => handleChange(items)}
        />
      </div>
    );
  };

  const checkBoxLabelComponent = (
    <div className="flex !justify-start">
      <input
        type="checkbox"
        checked={
          !isLoading &&
          paymentDueList.length > 0 &&
          selectedItems.length === paymentDueList.length
        }
        ref={(el) => {
          if (el) {
            el.indeterminate = indeterminate;
          }
        }}
        onChange={handleSelectAll}
      />
    </div>
  );

  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        customButtonText={t("pay_to_selected_trainers")}
        handleCustomButtonOnClick={
          selectedItems.length > 1
            ? () =>
                handleOnClickPay(
                  totalAmount ? totalAmount : "",
                  selectedItems,
                  fetchPaymentDue,
                  pagination,
                  t
                )
            : undefined
        }
        handleSearchChange={(text) => {
          setEnterpriseSearch(text);
        }}
        searchText={enterpriseSearch}
        refreshTable={() => fetchPaymentDue(pagination)}
      />
      <ICTable<IPaymentDue>
        isLoading={isLoading}
        totalFlex="grid grid-cols-26"
        fields={getPaymentDueFields(
          customTrainerView,
          customActionView,
          customCheckBoxView,
          checkBoxLabelComponent,
          t
        )}
        data={paymentDueList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchPaymentDue({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchPaymentDue({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchPaymentDue({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
      {showForm && paymentDueToEdit && (
        <PaymentDueSummary
          setShowForm={setShowForm}
          paymentDueId={paymentDueToEdit}
          readOnly={readOnlyForm}
        />
      )}
    </div>
  );
};

export default PaymentDue;
