import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { IFaqs } from "./FaqsViewController";
import {
  IFaqsForm,
  faqsValidationSchema,
  faqsTypeDropDown,
  fetchFaqsById,
  getFaqsInitialValues,
  handleSubmitFaqs,
} from "./FaqsFormController";
import { hasAnyModification } from "../../utils/AppFunctions";
import ICModal from "../../core-component/ICModal";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import ICButton from "../../core-component/ICButton";
import { Form, Formik, FieldArray, FormikProvider, ErrorMessage } from "formik";
import ICFormikTextInput from "../../components/formik-input/ICFormikTextInput";
import { deleteIcon } from "../../assets/icons/SvgIconList";
import ICFormikSwitch from "../../components/formik-input/ICFormikSwitch";
import ICFormikDropDown from "../../components/formik-input/ICFormikDropDown";
import ICFormikTextArea from "../../components/formik-input/ICFormikTextIArea";
import ICTextInput from "../../core-component/ICTextInput";

interface IFaqsFormProps {
  handleClose: (status: boolean) => void;
  faqsToEdit?: IFaqs;
  readOnly?: boolean;
}

const FaqsForm: React.FC<IFaqsFormProps> = ({
  handleClose,
  faqsToEdit,
  readOnly,
}) => {
  const { t } = UseTranslationHook();
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [faqsFormInitialValues, setFaqsFormInitialValues] =
    useState<IFaqsForm>();
  const checkFormModification = (values: IFaqsForm) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      faqsFormInitialValues &&
      hasAnyModification(values, faqsFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };
  useEffect(() => {
    if (faqsToEdit) {
      fetchFaqsById(faqsToEdit.id, setFaqsFormInitialValues, handleClose);
    } else {
      setFaqsFormInitialValues(getFaqsInitialValues(faqsToEdit));
    }
  }, []);

  return (
    <div>
      {faqsFormInitialValues && (
        <Formik
          initialValues={faqsFormInitialValues}
          validationSchema={() => faqsValidationSchema(t)}
          enableReinitialize
          onSubmit={(values) =>
            handleSubmitFaqs(values, faqsToEdit?.id, handleClose, t)
          }
        >
          {(formikPorps) => {
            const { handleSubmit, values } = formikPorps;
            return (
              <FormRightModalWrapper
                onClose={() => checkFormModification(values)}
                title={t("faqs")}
              >
                <Form onSubmit={handleSubmit} className="flex flex-col h-full">
                  <div className="flex-1 overflow-y-auto pt-2.5">
                    <ICFormikDropDown
                      {...formikPorps}
                      name={"faq_type"}
                      placeholder={t("faq_type")}
                      options={faqsTypeDropDown.dropDownOption}
                      disabled={readOnly}
                      required
                      searchable
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"question"}
                      placeholder={t("question")}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikTextArea
                      {...formikPorps}
                      name={"answer"}
                      placeholder={t("answer")}
                      disabled={readOnly}
                      required
                    />

                    <FormikProvider value={formikPorps}>
                      <div className="flex gap-4">
                        <FieldArray
                          name={"tags"}
                          render={(arrayHelpers) => (
                            <div className="w-full">
                              <p className="mb-2">{t("tags")}</p>
                              {formikPorps.values.tags.map((item, index) => (
                                <div className="mb-4">
                                  <div key={index} className="flex gap-4">
                                    <div className="w-full">
                                      <ICTextInput
                                        containerClassName="services-input mt-2"
                                        label={t("tags")}
                                        name={`tags[${index}]`}
                                        value={formikPorps.values.tags[index]}
                                        onChange={formikPorps.handleChange}
                                        disabled={readOnly}
                                      />
                                    </div>
                                    {!readOnly && (
                                      <button
                                        disabled={
                                          formikPorps.values.tags?.length === 0
                                        }
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        className="deleteButton action-view"
                                      >
                                        {deleteIcon}
                                      </button>
                                    )}
                                  </div>
                                  <ErrorMessage
                                    name={`tags[${index}]`}
                                    component="div"
                                    className="typo-error"
                                  />
                                </div>
                              ))}
                              {!readOnly && (
                                <button
                                  className="custom-button typo-btn-primary bg-skin-primary mb-6 w-full"
                                  type="button"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  {t("add_tags")}
                                </button>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </FormikProvider>
                    <FormikProvider value={formikPorps}>
                      <div className="flex gap-4">
                        <FieldArray
                          name={"reference_links"}
                          render={(arrayHelpers) => (
                            <div className="w-full">
                              <p className="mb-2">{t("reference_links")}</p>
                              {formikPorps.values.reference_links.map(
                                (link, index) => (
                                  <div className="mb-6" key={index}>
                                    <div
                                      className="flex gap-4 "
                                      onDoubleClick={() => {
                                        if (link) {
                                          window.open(link, "_blank");
                                        }
                                      }}
                                    >
                                      <div className="w-full">
                                        <ICTextInput
                                          containerClassName="services-input mt-2"
                                          label={t("add_reference_links")}
                                          name={`reference_links[${index}]`}
                                          value={link}
                                          onChange={(e) => {
                                            const updatedLinks: string[] = [
                                              ...formikPorps.values
                                                .reference_links,
                                            ];
                                            updatedLinks[index] =
                                              e.target.value;
                                            formikPorps.setFieldValue(
                                              "reference_links",
                                              updatedLinks
                                            );
                                          }}
                                        />
                                      </div>
                                      {!readOnly && (
                                        <button
                                          disabled={
                                            formikPorps.values.reference_links
                                              ?.length === 0
                                          }
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                          className="deleteButton action-view"
                                        >
                                          {deleteIcon}
                                        </button>
                                      )}
                                    </div>
                                    <ErrorMessage
                                      name={`reference_links[${index}]`}
                                      component="div"
                                      className="typo-error"
                                    />
                                  </div>
                                )
                              )}
                              {!readOnly && (
                                <button
                                  className="custom-button typo-btn-primary bg-skin-primary mb-6 w-full"
                                  type="button"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  {t("add_reference_links")}
                                </button>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </FormikProvider>

                    <div className="checkbox-wrapper">
                      <label>
                        <input
                          type="checkbox"
                          name={t("is_common_for_service")}
                          checked={
                            formikPorps.values.is_common_for_service === "1"
                          }
                          onChange={(e) => {
                            formikPorps.handleChange(e);
                            formikPorps.setFieldValue(
                              "is_common_for_service",
                              e.target.checked ? "1" : "0"
                            );
                          }}
                          disabled={readOnly}
                        />
                        <span className="ml-3">
                          {t("is_common_for_service")}
                        </span>
                      </label>
                    </div>

                    {values.id && (
                      <ICFormikSwitch
                        {...formikPorps}
                        containerClassName={
                          "flex items-center justify-between mt-4"
                        }
                        name={"is_active"}
                        placeholder={t("is_active")}
                        checkedValue={"1"}
                        unCheckedValue={"0"}
                        disabled={readOnly}
                        required
                      />
                    )}
                  </div>
                  {!readOnly && (
                    <ICButton className={"w-full"}>{t("submit")}</ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}
      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};
export default FaqsForm;
