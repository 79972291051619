import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import { initialValuesOtp, submitOtp } from "./OtpController";
import { submitLogin } from "../login/LoginControllers";
import ICOtpInput from "../../../core-component/ICOtpInput";
import Timer from "../../../core-component/ICTimer";
import { editIcon } from "../../../assets/icons/SvgIconList";
import { localStorageUtils } from "../../../utils/LocalStorageUtil";
const VerifyOTP = () => {
  const { t } = UseTranslationHook();
  const navigation = useNavigate();
  const location = useLocation();
  const localUserName = location?.state?.username;
  const localPassword = location?.state?.password;
  const [otpValue, setOtpValue] = useState("");
  const handleOtpChange = (otp: string) => {
    setOtpValue(otp);
  };
  const formik = useFormik({
    initialValues: initialValuesOtp,
    onSubmit: async (values) => {
      submitOtp(
        { ...values, otp: otpValue, username: localUserName },
        navigation,
        t
      );
      setOtpValue("");
      localStorageUtils.removeAll();
    },
  });
  function ResendOTP() {
    submitLogin(
      { username: localUserName, password: localPassword },
      navigation,
      t
    );
    setOtpValue("");
  }
  const handleBackToLogin = () => {
    localStorageUtils.setUserInfo(localUserName);
    navigation(-1);
  };
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 hidden md:flex md:col-span-6 lg:col-span-7 xl:col-span-8 h-screen w-full  items-center justify-center">
        <img
          className="h-full"
          src={require("../../../assets/image/Yoga_img3.jpg")}
          alt={t("poster_image")}
        />
      </div>
      <div className="flex col-span-12  lg:col-span-5 xl:col-span-4 h-screen items-center justify-center bg-skin-otp-verify  landscape:h-full">
        <section>
          <div className="flex justify-center h-full mt-10">
            <div className="flex h-full flex-wrap items-end justify-center lg:justify-between">
              <div className="mb-12">
                <div className="text-center mt-5">
                  <p className="font-bold comman-text text-3xl">
                    {t("otp_title")}
                  </p>
                  <p className="comman-grey">{t("otp_email_massage")}</p>
                </div>
                <div className="flex mt-3 items-center justify-center text-center">
                  <p className="mx-5">{localUserName}</p>
                  <div
                    className="h-5 w-5  cursor border "
                    onClick={handleBackToLogin}
                  >
                    {editIcon}
                  </div>
                </div>
                <form className="mt-5" onSubmit={formik.handleSubmit}>
                  <div className="relative mb-6 flex items-center justify-center">
                    <ICOtpInput
                      value={otpValue}
                      onChange={handleOtpChange}
                      numInputs={4} // Set the desired length of OTP
                    />
                  </div>
                  <Timer
                    initialTime={"0:30"}
                    maxResend={3}
                    onResend={ResendOTP}
                  />
                  <div className="mb-6 flex items-center justify-between"></div>
                  <div className="text-center lg:text-left">
                    <button
                      type="submit"
                      className={`bg-skin-otp-verify-btn text-skin-otp-verify-btn
                   px-7 pb-2.5 pt-3 text-sm font-medium uppercase rounded shadow-[0_4px_9px_-4px_#3B71CA]  w-full ${
                     otpValue.length !== 4
                       ? "cursor-not-allowed comman-disablebtn"
                       : "comman-btn"
                   }`}
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      disabled={otpValue.length !== 4} // Disable the button if the OTP length is not 4
                    >
                      {t("verify_and_continue")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default VerifyOTP;
