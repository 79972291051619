import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import {
  ACTIVE_INACTIVE_BLOG_CATEGORY,
  DELETE_BLOG_CATEGORY,
  GET_ALL_BLOG_CATEGORIES,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
  toastSuccess,
} from "../../utils/AppFunctions";

export interface IBlogeCategory {
  id: number;
  category_title: string;
  category_meta_title: string;
  parent_category_id?: number;
  slug: string;
  is_active: string;
  deleted?: string;
}

export function getBlogCategoryFields<T>(
  handleStatusChange: (item: T) => void,
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("blog_category_title"),
      key: "category_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("blog_category_meta_title"),
      key: "category_meta_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      limit: 20,
      title: "category_meta_title",
    },
    {
      name: t("blog_slug"),
      key: "slug",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
      limit: 10,
      title: "slug",
    },

    {
      name: t("status"),
      key: "is_active",
      sort: false,
      type: TABLE_FIELD_TYPE.switch,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      handleCheck: handleStatusChange,
      flex: "col-span-1",
    },
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },

    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}

export const fetchBlogCategoryApiCall = async (
  setBlogCategoryList: TReactSetState<IBlogeCategory[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const blogCategoryList = await GET_ALL_BLOG_CATEGORIES(pagination, search);
    if (
      blogCategoryList &&
      blogCategoryList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (blogCategoryList.data && blogCategoryList.data.item.length > 0) {
        setBlogCategoryList(blogCategoryList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(blogCategoryList.data.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetBlogCategoryList = await GET_ALL_BLOG_CATEGORIES(
          resetPagination,
          search
        );
        if (
          resetBlogCategoryList &&
          resetBlogCategoryList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setBlogCategoryList(resetBlogCategoryList.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              resetBlogCategoryList.data.pagination
            ),
          });
        } else {
          setBlogCategoryList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(blogCategoryList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.blogCategoryList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleDeleteBlogCategory = async (
  id: number,
  t: TUseTranslationTfn
) => {
  try {
    const blogCategoryDelete = await DELETE_BLOG_CATEGORY(id);
    if (
      blogCategoryDelete &&
      blogCategoryDelete.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(blogCategoryDelete.message);
      return true;
    } else {
      SweetAlertError(
        blogCategoryDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
    return false;
  } catch (e: any) {
    SweetAlertError(
      e?.blogCategoryDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};

export const handleActiveInactiveBlogCategory = async (
  id: number,
  values: string
) => {
  try {
    const blogCategoryActiveInactive = await ACTIVE_INACTIVE_BLOG_CATEGORY(id, {
      is_active: values,
    });
    if (
      blogCategoryActiveInactive &&
      blogCategoryActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      toastSuccess(blogCategoryActiveInactive.message);
      return true;
    }
    return false;
  } catch (e: any) {
    toastError(
      e?.blogCategoryActiveInactive?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};
