import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { ISystemHelp } from "./SystemHelpController";
import {
  ISystemHelpForm,
  fetchSystemHelpById,
  getSystemHelpInitialValues,
  handleSubmitSystemHelp,
  systemHelpValidationSchema,
} from "./SystemHelpFormController";
import { SweetAlertError, hasAnyModification } from "../../utils/AppFunctions";
import ICModal from "../../core-component/ICModal";
import { Form, Formik, FieldArray, FormikProvider, ErrorMessage } from "formik";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import ICFormikTextInput from "../../components/formik-input/ICFormikTextInput";
import ICFormikTextArea from "../../components/formik-input/ICFormikTextIArea";
import ICTextInput from "../../core-component/ICTextInput";
import { WithContext as ReactTags } from "react-tag-input";
import ICButton from "../../core-component/ICButton";
import ICFormikSwitch from "../../components/formik-input/ICFormikSwitch";
import { deleteIcon } from "../../assets/icons/SvgIconList";
import ICFileUpload from "../../core-component/ICFileUpload";
import { fetchUploadImageService } from "../global/image-service/ImageServices";
import ICFormikDropDown from "../../components/formik-input/ICFormikDropDown";
import {
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
  TARGET_AUDIENCE,
} from "../../utils/AppConstants";
import { fetchCategoryHelpDropDown } from "../global/GlobalController";
import { IDDL } from "../../data/AppInterface";
import { IMAGE_TYPE } from "../../utils/AppEnumerations";
interface ISystemHelpFormProps {
  handleClose: (status: boolean) => void;
  systemHelpToEdit?: ISystemHelp;
  readOnly?: boolean;
}

const SystemHelpForm: React.FC<ISystemHelpFormProps> = ({
  handleClose,
  systemHelpToEdit,
  readOnly,
}) => {
  const { t } = UseTranslationHook();
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [systemHelpFormInitialValues, setSystemHelpFormInitialValues] =
    useState<ISystemHelpForm>();
  const [tag, setTags] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [systemHelpImage, setSystemHelpImage] = useState<string[]>([]);
  const [categoryHelpData, setCategoryHelpData] = useState<IDDL[]>([]);
  const [loading, setLoading] = useState(false);
  const checkFormModification = (values: ISystemHelpForm) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      systemHelpFormInitialValues &&
      hasAnyModification(values, systemHelpFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };

  useEffect(() => {
    if (systemHelpToEdit) {
      fetchSystemHelpById(
        systemHelpToEdit.id,
        setSystemHelpFormInitialValues,
        handleClose,
        setTags
      );
    } else {
      setSystemHelpFormInitialValues(
        getSystemHelpInitialValues(systemHelpToEdit)
      );
    }
    fetchCategoryHelpDropDown(setCategoryHelpData);
  }, []);

  useEffect(() => {
    setSystemHelpImage(
      systemHelpFormInitialValues?.help_video_url
        ? [systemHelpFormInitialValues.help_video_url]
        : []
    );
  }, [systemHelpFormInitialValues]);
  const handleSubmitSystemHelpWithFileUpload = async (
    values: ISystemHelpForm
  ) => {
    try {
      setLoading(true);
      const updatedSystemHelpMedia = systemHelpImage
        ? systemHelpImage.map((url) => ({
            media_url: url,
          }))
        : [];

      for (let i = 0; i < selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append(`myImageFile`, selectedFiles[i]);
        formData.append(`type`, IMAGE_TYPE.SystemHelp);
        values.id && formData.append(`typeId`, values.id.toString());
        const mediaUrl = await fetchUploadImageService(formData);
        if (!mediaUrl) {
          return null;
        }
        const newMediaItem = {
          media_url: mediaUrl,
        };
        updatedSystemHelpMedia.push(newMediaItem);
      }
      await handleSubmitSystemHelp(
        {
          ...values,
          help_video_url: updatedSystemHelpMedia[0]?.media_url || "",
          tags: tag,
        },
        systemHelpToEdit?.id,
        handleClose,

        t
      );
    } catch (e) {
      SweetAlertError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
    } finally {
      setLoading(false);
    }
  };

  const handleDrag = (
    tag: { id: string; text: string },
    currPos: number,
    newPos: number
  ) => {};
  return (
    <div>
      {systemHelpFormInitialValues && (
        <Formik
          initialValues={systemHelpFormInitialValues}
          validationSchema={() => systemHelpValidationSchema(t)}
          enableReinitialize
          onSubmit={(values) => handleSubmitSystemHelpWithFileUpload(values)}
        >
          {(formikPorps) => {
            const { handleSubmit, values } = formikPorps;
            return (
              <FormRightModalWrapper
                onClose={() => checkFormModification(values)}
                title={t("system_help")}
              >
                <Form onSubmit={handleSubmit} className="flex flex-col h-full">
                  <div className="flex-1 overflow-y-auto  pt-2.5">
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"topic"}
                      placeholder={t("topic")}
                      disabled={readOnly}
                      autoFocus
                      required
                    />
                    <ICFormikTextArea
                      {...formikPorps}
                      name={"help_description"}
                      placeholder={t("help_description")}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikDropDown
                      {...formikPorps}
                      name={"help_category_id"}
                      placeholder={t("help_category")}
                      options={categoryHelpData.map((item) => ({
                        label: item.display_value,
                        value: Number(item.data_value),
                      }))}
                      disabled={readOnly}
                      searchable
                      required
                    />
                    <div className="w-full mb-4">
                      <p className="mb-2">{t("tags")}</p>
                      <div className="inline-flex w-full flex-wrap">
                        <ReactTags
                          tags={tag.map((tag) => ({ id: tag, text: tag }))}
                          handleDelete={(index) =>
                            setTags(tag.filter((_, i) => i !== index))
                          }
                          handleDrag={handleDrag}
                          handleAddition={(tags) =>
                            setTags([...tag, tags.text])
                          }
                          readOnly={true ? readOnly : false}
                          classNames={{
                            tags: "flex w-full",
                            tagInput: "flex",
                            tagInputField:
                              "outline-none bg-skin-react-tags border border-skin-react-tags-input text-skin-react-tag-input-text-tags  text-sm rounded-sm focus:ring-skin-react-tag-ring-input focus:border-skin-react-tags-input-focus block w-full p-2.5",
                            selected: "selected-tag w-full",
                            tag: "inline-flex items-center bg-skin-library-tags-box text-skin-library-tags-box text-sm font-medium rounded-full px-3 py-1 mr-2 mb-2",
                            remove: "ml-1",
                          }}
                          placeholder={t("add_new_tag")}
                          autofocus={false}
                        />
                      </div>
                    </div>
                    <ICFormikTextInput
                      {...formikPorps}
                      type="number"
                      fieldName={"display_sequence"}
                      placeholder={t("display_sequence")}
                      disabled={readOnly}
                      required
                    />
                    <FormikProvider value={formikPorps}>
                      <div className="flex gap-4">
                        <FieldArray
                          name={"reference_links"}
                          render={(arrayHelpers) => (
                            <div className="w-full">
                              <p className="mb-2">{t("reference_links")}</p>
                              {formikPorps.values.reference_links.map(
                                (link, index) => (
                                  <div className="mb-6" key={index}>
                                    <div
                                      className="flex gap-4 "
                                      onDoubleClick={() => {
                                        if (link) {
                                          window.open(link, "_blank");
                                        }
                                      }}
                                    >
                                      <div className="w-full">
                                        <ICTextInput
                                          containerClassName="services-input mt-2"
                                          label={t("add_reference_links")}
                                          name={`reference_links[${index}]`}
                                          value={link}
                                          onChange={(e) => {
                                            const updatedLinks: string[] = [
                                              ...formikPorps.values
                                                .reference_links,
                                            ];
                                            updatedLinks[index] =
                                              e.target.value;
                                            formikPorps.setFieldValue(
                                              "reference_links",
                                              updatedLinks
                                            );
                                          }}
                                        />
                                      </div>
                                      {!readOnly && (
                                        <button
                                          disabled={
                                            formikPorps.values.reference_links
                                              ?.length === 0
                                          }
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                          className="deleteButton action-view"
                                        >
                                          {deleteIcon}
                                        </button>
                                      )}
                                    </div>
                                    <ErrorMessage
                                      name={`reference_links[${index}]`}
                                      component="div"
                                      className="typo-error"
                                    />
                                  </div>
                                )
                              )}
                              {!readOnly && (
                                <div
                                  className="custom-button typo-btn-primary tag-btn bg-skin-primary mb-6 w-full"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  {t("add_reference_links")}
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </FormikProvider>
                    <ICFormikDropDown
                      {...formikPorps}
                      name={"target_audiance"}
                      placeholder={t("target_audience")}
                      options={TARGET_AUDIENCE}
                      disabled={readOnly}
                      required
                    />
                    <ICFileUpload
                      title={t("upload_video")}
                      maxFiles={1}
                      maxFileSizeMB={5000}
                      acceptedFileTypes={["mp4"]}
                      onFilesSelected={setSelectedFiles}
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      data={systemHelpImage}
                      setData={setSystemHelpImage}
                      disabled={readOnly}
                    />

                    {values.id && (
                      <ICFormikSwitch
                        {...formikPorps}
                        containerClassName={
                          "flex items-center justify-between mt-4"
                        }
                        name={"is_active"}
                        placeholder={t("is_active")}
                        checkedValue={"1"}
                        unCheckedValue={"0"}
                        disabled={readOnly}
                        required
                      />
                    )}
                  </div>
                  {!readOnly && (
                    <ICButton
                      disabled={loading ? true : false}
                      loading={loading}
                      className={"w-full"}
                    >
                      {t("submit")}
                    </ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}
      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};
export default SystemHelpForm;
