import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import {
  ACTIVE_INACTIVE_USER,
  DELETE_USER,
  GET_ALL_USERS,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_DECRYPTION,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  decryptData,
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
  toastSuccess,
} from "../../utils/AppFunctions";

export interface IMediaItem {
  media_type?: string;
  media_url?: string;
  media_title?: string;
}
export interface IUser {
  id: number;
  app_user_id: number;
  app_user: {
    username: string;
    user_role: string;
    last_login_date: string;
    is_active: string;
  };
  is_active: string;
  full_name: string;
  gender_id: number;
}

export const INITIAL_USER_LIST = {
  user_gender: undefined,
  user_role: undefined,
  user_id: "",
};

export function getUsersFields<T>(
  customComponentUserNameView: (item: T) => JSX.Element,
  customComponentUSerRoleView: (item: T) => JSX.Element,
  customComponentUSerGenderView: (item: T) => JSX.Element,
  handleUsersStatus: (item: T) => void,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("full_name"),
      key: "full_name",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
    },
    {
      name: t("user_id"),
      key: "user_id",
      sort: false,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
    },
    {
      name: t("username"),
      key: "username",
      sort: true,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
      component: customComponentUserNameView,
    },

    {
      name: t("gender"),
      key: "gender_id",
      sort: true,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
      component: customComponentUSerGenderView,
    },
    {
      name: t("user_role"),
      key: "user_role",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
      component: customComponentUSerRoleView,
    },
    {
      name: t("status"),
      key: "is_active",
      sort: false,
      type: TABLE_FIELD_TYPE.switch,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      handleCheck: handleUsersStatus,
      flex: "col-span-1",
    },

    {
      name: t("last_login_date"),
      key: "last_login_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
  ];
}

export const fetchUsersApiCall = async (
  setUsersList: TReactSetState<IUser[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const usersList = await GET_ALL_USERS(pagination, search);
    if (usersList && usersList.code === DEFAULT_STATUS_CODE_SUCCESS) {
      const decryptedData = decryptData(usersList.data);
      if (!decryptedData) {
        throw new Error(MESSAGE_UNKNOWN_ERROR_DECRYPTION);
      }
      const userListData = JSON.parse(decryptedData);
      if (userListData && userListData.item.length > 0) {
        setUsersList(
          userListData.item.map((obj: IUser) => ({
            ...obj,
            is_active: obj.app_user.is_active,
          }))
        );
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(userListData.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetUsersList = await GET_ALL_USERS(resetPagination, search);
        if (
          resetUsersList &&
          resetUsersList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          const resetDecryptedData = decryptData(resetUsersList.data);
          if (!resetDecryptedData) {
            throw new Error(MESSAGE_UNKNOWN_ERROR_DECRYPTION);
          }
          const resetUserListData = JSON.parse(resetDecryptedData);
          setUsersList(
            resetUserListData.item.map((obj: IUser) => ({
              ...obj,
              is_active: obj.app_user.is_active,
            })) || []
          );
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(resetUserListData.pagination),
          });
        } else {
          setUsersList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      throw new Error(usersList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleActiveInactiveUSers = async (
  id: number | undefined,
  values: string
) => {
  try {
    const userActiveInactive = await ACTIVE_INACTIVE_USER(id, {
      is_active: values,
    });
    if (
      userActiveInactive &&
      userActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      toastSuccess(userActiveInactive.message);
      return true;
    }
    return false;
  } catch (e: any) {
    toastError(
      e?.userActiveInactive?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};
