import React, { useEffect, useState } from "react";
import SubscriptionPackageForm from "./SubscriptionPackageForm";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  ISubscriptionPackage,
  fetchSubscriptionApiCall,
  getSubscriptionFields,
  handleActiveInactiveSubscription,
  handleDeleteSubscription,
} from "./SubscriptionPackagesViewController";
import UsePaginationHook from "../../hooks/UsePaginationHook";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import { IPagination } from "../../data/AppInterface";
import {
  copyIcon,
  editIcon,
  eyeIcon,
  restoreIcon,
} from "../../assets/icons/SvgIconList";
import ICSweetAlertModal from "../../core-component/ICSweetAlertModal";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import ICTable from "../../core-component/ICTable";
import { ORDER_BY } from "../../utils/AppConstants";

const SubscriptionPackagesView = () => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [subscriptionList, setSubscriptionList] = useState<
    ISubscriptionPackage[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [copyPackage, setCopyPackage] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [subscriptionToEdit, setSubscriptionToEdit] =
    useState<ISubscriptionPackage>();
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [deleteAll, setDeleteAll] = useState(false);
  const { pagination, setPagination } = UsePaginationHook({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "package_title",
      orderBy: "ASC",
    },
  });
  useEffect(() => {
    fetchSubscription(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, deleteAll]);

  const fetchSubscription = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchSubscriptionApiCall(
        setSubscriptionList,
        setPagination,
        payloadPagination,
        {
          enterpriseSearch,
          deleteAll,
        }
      );
      setIsLoading(false);
    }, 500);
  };
  const handleSubscriptionStatus = async (values: ISubscriptionPackage) => {
    var isStatusChange = await handleActiveInactiveSubscription(
      values.id,
      values.is_active === "1" ? "0" : "1"
    );
    if (isStatusChange) {
      const updatedSubscriptionList = subscriptionList.map((subscription) => {
        if (subscription.id === values.id) {
          return {
            ...subscription,
            is_active: values.is_active === "1" ? "0" : "1",
          };
        }
        return subscription;
      });

      setSubscriptionList(updatedSubscriptionList);
    }
  };

  const handleDeleteCfm = async (id: number) => {
    const data = await handleDeleteSubscription(id, t);
    if (data) {
      fetchSubscription(pagination);
    }
  };

  const customActionView = (item: ISubscriptionPackage) => {
    return (
      <div>
        {item.deleted === "1" ? (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              title={t("restore")}
              onClick={() => handleDeleteCfm(item.id)}
            >
              {restoreIcon}
            </div>
          </div>
        ) : (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              onClick={() => {
                setShowForm(true);
                setSubscriptionToEdit(item);
                setReadOnly(true);
                setCopyPackage(false);
              }}
            >
              {eyeIcon}
            </div>
            <div
              className="mr-2 action-view "
              title={t("edit_tooltip_icon")}
              onClick={() => {
                setShowForm(true);
                setSubscriptionToEdit(item);
                setCopyPackage(false);
              }}
            >
              {editIcon}
            </div>
            <div className="action-view mr-2" title={t("delete_tooltip_icon")}>
              <ICSweetAlertModal onConfirm={handleDeleteCfm} itemId={item.id} />
            </div>
            <div
              className="action-view "
              title={t("edit_tooltip_icon")}
              onClick={() => {
                setShowForm(true);
                setSubscriptionToEdit({ ...item });
                setCopyPackage(true);
              }}
            >
              {copyIcon}
            </div>
          </div>
        )}
      </div>
    );
  };
  const allSubscriptionList = subscriptionList.map((val) => ({
    ...val,
    IsDeleted: val.deleted === "1" ? true : false,
  }));
  const handleCloseForm = (status: boolean) => {
    setShowForm(false);
    setReadOnly(false);
    if (subscriptionToEdit) {
      setSubscriptionToEdit(undefined);
    } else {
      setEnterpriseSearch("");
    }
    if (status) {
      fetchSubscription(pagination);
    }
  };
  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        handleOnClickAdd={() => setShowForm(true)}
        handleSearchChange={(text) => {
          setEnterpriseSearch(text);
        }}
        searchText={enterpriseSearch}
        handleDeleteCheckBox={(ans) => {
          setDeleteAll(ans);
        }}
        filterCheckBox={deleteAll}
        refreshTable={() => fetchSubscription(pagination)}
      />
      <ICTable<ISubscriptionPackage>
        isLoading={isLoading}
        totalFlex="grid grid-cols-23"
        fields={getSubscriptionFields(
          handleSubscriptionStatus,
          customActionView,
          t
        )}
        data={allSubscriptionList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) => {
          fetchSubscription({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          });
        }}
        handleChangePage={(page) => {
          fetchSubscription({ ...pagination, currentPage: page });
        }}
        handleSort={(field) => {
          fetchSubscription({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          });
        }}
      />
      {showForm && (
        <SubscriptionPackageForm
          handleClose={handleCloseForm}
          subscriptionToEdit={subscriptionToEdit}
          readOnly={readOnly}
          copyPackage={copyPackage}
        />
      )}
    </div>
  );
};

export default SubscriptionPackagesView;
