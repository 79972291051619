import React from "react";
import { useFormikContext } from "formik";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { string } from "yup";

interface IFormikWeekdaysSelectProps {
  name: string;
  label: string;
  weekdays: string[];
  readOnly: boolean;
}

const FormikWeekdaysSelect: React.FC<IFormikWeekdaysSelectProps> = ({
  name,
  label,
  weekdays,
  readOnly,
}) => {
  const formik = useFormikContext<any>();

  const handleWeekdayChange = (weekday: string) => {
    const selectedWeekdays = formik.values[name] || [];
    const updatedWeekdays = selectedWeekdays.includes(weekday)
      ? selectedWeekdays.filter((day: string) => day !== weekday)
      : [...selectedWeekdays, weekday];

    formik.setFieldValue(name, updatedWeekdays);
  };

  return (
    <div className="h-full overflow-hidden">
      <label>{label}:</label>
      <div className="flex flex-wrap w-full">
        {weekdays.map((weekday) => (
          <FormControlLabel
            key={weekday}
            control={
              <Checkbox
                checked={(formik.values[name] || []).includes(weekday)}
                onChange={() => handleWeekdayChange(weekday)}
                disabled={readOnly}
                sx={{
                  "&.Mui-checked": {
                    color: "#1976d2", // Blue color for both checked and disabled
                  },
                  "&.Mui-disabled": {
                    color: "#1976d2", // Blue color for both checked and disabled
                  },
                }}
              />
            }
            label={weekday}
          />
        ))}
      </div>
    </div>
  );
};

export default FormikWeekdaysSelect;
