import { serviceMaker } from "..";
import { ISearch, IPagination, IActiveInactive } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

interface IHighlightsPayload {
  // id: number;
  highlight_text: string;
  cancellation_charge_percentage: number | null;
  end_date: Date | null;
  is_active?: string;
}
export const GET_ALL_HIGHLIGHTS = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_highlights.url +
      `?query=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&sd=${search.deleteAll}&currentPage=${
        pagination.currentPage
      }&perPageRows=${pagination.perPageRows}&sortBy=${
        pagination.sortBy
      }&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_highlights.method
  );
};

export const POST_HIGHLIGHTS = (payload: IHighlightsPayload) => {
  return serviceMaker(
    API_ENDPOINTS.post_highlights.url,
    API_ENDPOINTS.post_highlights.method,
    payload
  );
};

export const UPDATE_HIGHLIGHTS = (payload: IHighlightsPayload, id: number) => {
  return serviceMaker(
    API_ENDPOINTS.update_highlights.url + `/${id}`,
    API_ENDPOINTS.update_highlights.method,
    payload
  );
};

export const GET_HIGHLIGHTS_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_highlights_by_id.url + `/${id}`,
    API_ENDPOINTS.get_highlights_by_id.method
  );
};

export const DELETE_HIGHLIGHTS = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.delete_highlights.url + `/${id}`,
    API_ENDPOINTS.delete_highlights.method
  );
};

export const ACTIVE_INACTIVE_HIGHLIGHTS = async (
  id: number | undefined,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_inactive_highlights.url + `/${id}`,
    API_ENDPOINTS.active_inactive_highlights.method,
    activeInactiveValue
  );
};
