import { serviceMaker } from "..";
import { IActiveInactive, IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

interface ISubscriptionPackagePayload {
  id?: number;
  package_title: string;
  package_description: string;
  target_audience: string;
  package_price?: number;
  package_discounted_price?: number;
  expire_in_days: number | null;
  allow_extension: string;
  extension_price?: number;
  extension_expire_in_days?: number;
  publish_date?: Date;
  is_active: string;
  deleted?: string;
  subscription_consent?: string;
}

export const GET_ALL_SUBSCRIPTION = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_subscription.url +
      `?query=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&sd=${search.deleteAll}&currentPage=${
        pagination.currentPage
      }&perPageRows=${pagination.perPageRows}&sortBy=${
        pagination.sortBy
      }&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_subscription.method
  );
};

export const DELETE_SUBSCRIPTION = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.delete_subscription.url + `/${id}`,
    API_ENDPOINTS.delete_subscription.method
  );
};

export const ACTIVE_INACTIVE_SUBSCRIPTION = async (
  id: number,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.create_subscription.url + `/${id}`,
    API_ENDPOINTS.create_subscription.method,
    activeInactiveValue
  );
};
export const GET_SUBSCRIPTION_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_subscription.url + `/${id}`,
    API_ENDPOINTS.get_all_subscription.method
  );
};

export const CREATE_SUBSCRIPTION = (payload: ISubscriptionPackagePayload) => {
  return serviceMaker(
    API_ENDPOINTS.create_subscription.url,
    API_ENDPOINTS.create_subscription.method,
    payload
  );
};

export const UPDATE_SUBSCRIPTION = (
  payload: ISubscriptionPackagePayload,
  id: number
) => {
  return serviceMaker(
    API_ENDPOINTS.update_subscription.url + `/${id}`,
    API_ENDPOINTS.update_subscription.method,
    payload
  );
};
