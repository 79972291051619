import { serviceMaker } from "..";
import { IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

export const GET_ALL_SESSIONS = (pagination: IPagination, search: ISearch) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_sessions.url +
      `?currentPage=${pagination.currentPage}&perPageRows=${
        pagination.perPageRows
      }&sortBy=${pagination.sortBy}&orderBy=${pagination.orderBy}&${
        search.advanceSearch?.trainer_name
          ? `trainerName=${encodeURIComponent(
              search.advanceSearch?.trainer_name
            )}`
          : ""
      }&${
        search.advanceSearch?.service_title
          ? `serviceTitle=${encodeURIComponent(
              search.advanceSearch?.service_title
            )}`
          : ""
      }&fromDate=${search.advanceSearch?.from_date}&toDate=${
        search.advanceSearch?.to_date
      }&${
        search.advanceSearch?.show_historical
          ? `showHistorical=${search.advanceSearch?.show_historical}`
          : ""
      }`,
    API_ENDPOINTS.get_all_sessions.method
  );
};
