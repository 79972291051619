import {
  ITabelFieldViewProps,
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IMediaItemImage, IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import { DELETE_BLOG, GET_ALL_BLOGS } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
} from "../../utils/AppFunctions";

export interface IBlog {
  id: number;
  blog_title: string;
  blog_meta_title: string;
  blog_content: string;
  slug: string;
  published: string;
  publish_date?: Date;
  expiry_date?: Date;
  blog_categories: number[];
  post_anonymously: string;
  cover_image: string;
  deleted?: string;
  hash_tags: string[];
  blogs_media: IMediaItemImage[];
  is_active: string;
}
export function getBlogFields<T>(
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("blog_title"),
      key: "blog_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
    },
    {
      name: t("blog_meta_title"),
      key: "blog_meta_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
    },
    {
      name: t("blog_categories"),
      key: "blog_categories",
      sort: false,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-3",
    },
    {
      name: t("publish_date"),
      key: "publish_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("expiry_date"),
      key: "expiry_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },

    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
      component: actionComponent,
    },
  ];
}

export const fetchBlogCategoryApiCall = async (
  setBlogList: TReactSetState<IBlog[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const blogList = await GET_ALL_BLOGS(pagination, search);
    if (blogList && blogList.code === DEFAULT_STATUS_CODE_SUCCESS) {
      if (blogList.data && blogList.data.item.length > 0) {
        setBlogList(blogList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(blogList.data.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetBlogList = await GET_ALL_BLOGS(resetPagination, search);
        if (
          resetBlogList &&
          resetBlogList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setBlogList(resetBlogList.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(resetBlogList.data.pagination),
          });
        } else {
          setBlogList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(blogList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.blogList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleDeleteBlog = async (id: number, t: TUseTranslationTfn) => {
  try {
    const blogDelete = await DELETE_BLOG(id);
    if (blogDelete && blogDelete.code === DEFAULT_STATUS_CODE_SUCCESS) {
      SweetAlertSuccess(blogDelete.message);
      return true;
    } else {
      SweetAlertError(blogDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    return false;
  } catch (e: any) {
    SweetAlertError(e?.blogDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    return false;
  }
};
