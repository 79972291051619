import React, { useEffect, useState } from "react";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { ORDER_BY } from "../../utils/AppConstants";
import {
  fetchPaymentDueSummaryApiCall,
  getPaymentDueSummaryFields,
  IPaymentDueSummary,
  IPaymentDueSummaryList,
} from "./PaymentDueSummaryController";
import UsePaginationHook from "../../hooks/UsePaginationHook";
import { TReactSetState } from "../../data/AppType";
import ICTable from "../../core-component/ICTable";
import { IPagination } from "../../data/AppInterface";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { decryptData } from "../../utils/AppFunctions";
import { routeAdmin, routeMaster } from "../../routes/RouteHome";

interface IPaymentDueSummaryProps {
  setShowForm?: TReactSetState<boolean>;
  paymentDueId?: number;
  readOnly?: boolean;
}
const PaymentDueSummary: React.FC<IPaymentDueSummaryProps> = ({
  setShowForm,
  paymentDueId,
  readOnly,
}) => {
  const { t } = UseTranslationHook();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentDueSummaryHeader, setPaymentDueSummaryHeader] =
    useState<IPaymentDueSummary | null>(null);
  const [paymentDueSummaryList, setPaymentDueSummaryList] = useState<
    IPaymentDueSummaryList[]
  >([]);
  const { pagination, setPagination } = UsePaginationHook({
    pagination: {
      perPageRows: 50,
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });
  let timer: NodeJS.Timeout;
  const navigate = useNavigate();
  const { encryptId } = useParams<{ encryptId: string }>();
  const id = paymentDueId || Number(decryptData(encryptId ? encryptId : ""));

  useEffect(() => {
    if (id) {
      fetchPaymentDueSummary(pagination);
    } else {
      setShowForm && setShowForm(false);
    }
  }, []);
  const fetchPaymentDueSummary = (payloadPagination: IPagination) => {
    setIsLoading(true);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (id)
        await fetchPaymentDueSummaryApiCall(
          setPaymentDueSummaryHeader,
          setPaymentDueSummaryList,
          setPagination,
          payloadPagination,
          id
        );
      setIsLoading(false);
    }, 500);
  };

  return (
    <div>
      <FormRightModalWrapper
        containerClassName="!w-[100%]"
        onClose={() => {
          setShowForm && setShowForm(false);
          navigate(`/${routeAdmin.admin}/${routeMaster.paymentDue}`);
        }}
        title={t("payment_due_summary")}
      >
        <div>
          <div className="w-full flex justify-between border p-2 bg-skin-disabled">
            <div className="flex space-x-1">
              <div>{t("trainer_name")}:</div>
              <div>{paymentDueSummaryHeader?.trainer_name}</div>
            </div>
            <div className="flex space-x-1">
              <div>{t("total_amount")}:</div>
              <div>{paymentDueSummaryHeader?.total_amount}</div>
            </div>
          </div>
          <div>
            <ICTable<IPaymentDueSummaryList>
              isLoading={isLoading}
              totalFlex="grid grid-cols-20"
              fields={getPaymentDueSummaryFields(t)}
              data={paymentDueSummaryList}
              pagination={pagination}
              handleChangePerPageRows={(perPageRow) =>
                fetchPaymentDueSummary({
                  ...pagination,
                  perPageRows: perPageRow,
                  currentPage: 1,
                })
              }
              handleChangePage={(page) =>
                fetchPaymentDueSummary({ ...pagination, currentPage: page })
              }
              handleSort={(field) =>
                fetchPaymentDueSummary({
                  ...pagination,
                  sortBy: field.key,
                  orderBy:
                    pagination.orderBy === ORDER_BY.desc
                      ? ORDER_BY.asc
                      : ORDER_BY.desc,
                })
              }
            />
          </div>
        </div>
      </FormRightModalWrapper>
    </div>
  );
};

export default PaymentDueSummary;
