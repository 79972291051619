import { useEffect, useState } from "react";
import {
  INITIAL_SESSIONS,
  ISessions,
  fetchSessionsApiCall,
  getSessionsFields,
} from "./SessionsController";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import usePagination from "../../hooks/UsePaginationHook";
import { IAdvanceSearch, IPagination } from "../../data/AppInterface";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import ICTable from "../../core-component/ICTable";
import { ORDER_BY } from "../../utils/AppConstants";
import ICButton from "../../core-component/ICButton";
import { filterClearIcon, searchIcon } from "../../assets/icons/SvgIconList";
import ICDatePicker from "../../core-component/ICDatePicker";
import { getLocalDate } from "../../utils/AppFunctions";

const SessionsView = () => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [isLoading, setIsLoading] = useState(true);
  const [sessionsList, setSessionsList] = useState<ISessions[]>([]);

  const { pagination, setPagination } = usePagination({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });
  const [advanceSearch, setAdvanceSearch] =
    useState<IAdvanceSearch>(INITIAL_SESSIONS);
  useEffect(() => {
    fetchSessions(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [advanceSearch]);

  const fetchSessions = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchSessionsApiCall(
        setSessionsList,
        setPagination,
        payloadPagination,
        {
          advanceSearch,
        }
      );
      setIsLoading(false);
    }, 500);
  };

  const filterView = (
    <div className="w-full grid grid-cols-3 gap-4 mt-3 bg-skin-background">
      <div className="floating-input ">
        <div className="custom-text-input-container">
          <div className="text-input-leading !w-full !p-2">
            <input
              type="search"
              id="default-search"
              className="custom-text-input typo-input-placeholder typo-input-value"
              placeholder="Trainer Name"
              onChange={(event) =>
                setAdvanceSearch((prev) => ({
                  ...prev,
                  trainer_name: event.target.value,
                }))
              }
              value={advanceSearch.trainer_name}
              required
            />
          </div>
        </div>
      </div>
      <div className="floating-input ">
        <div className="custom-text-input-container">
          <div className="text-input-leading !w-full !p-2">
            <input
              type="search"
              id="default-search"
              className="custom-text-input typo-input-placeholder typo-input-value"
              placeholder="Service Name"
              onChange={(event) =>
                setAdvanceSearch((prev) => ({
                  ...prev,
                  service_title: event.target.value,
                }))
              }
              value={advanceSearch.service_title}
              required
            />
          </div>
        </div>
      </div>
      <div className="checkbox-wrapper m-2">
        <label className="flex items-center">
          <input
            type="checkbox"
            className="h-4 w-4 mr-2 rounded-md"
            checked={advanceSearch.show_historical}
            onChange={(event) =>
              setAdvanceSearch((prev) => ({
                ...prev,
                show_historical: event.target.checked,
              }))
            }
          />
          <span className="text-lg">{t("show_historical")}</span>
        </label>
      </div>
      <ICDatePicker
        useValue
        label={t("from_date")}
        placeholder={t("from_date")}
        value={
          advanceSearch.from_date !== ""
            ? getLocalDate(advanceSearch.from_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            from_date: event.target.value,
          }))
        }
      />
      <ICDatePicker
        useValue
        label={t("to_date")}
        min={getLocalDate(advanceSearch.from_date)}
        placeholder={t("to_date")}
        value={
          advanceSearch.to_date !== ""
            ? getLocalDate(advanceSearch.to_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            to_date: event.target.value,
          }))
        }
      />

      <ICButton
        onClick={() => setAdvanceSearch(INITIAL_SESSIONS)}
        className="flex"
      >
        <div className="w-5 h-5">{filterClearIcon}</div>&nbsp;{t("clear")}
      </ICButton>
    </div>
  );
  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        filterNode={filterView}
        refreshTable={() => fetchSessions(pagination)}
      />
      <ICTable<ISessions>
        isLoading={isLoading}
        totalFlex="grid grid-cols-12"
        fields={getSessionsFields(t)}
        data={sessionsList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchSessions({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchSessions({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchSessions({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
    </div>
  );
};

export default SessionsView;
