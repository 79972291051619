import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import {
  GET_BLOG_CATEGORY_BY_ID,
  POST_BLOG_CATEGORY,
  UPDATE_BLOG_CATEGORY,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import { IBlogeCategory } from "./BlogCategoryController";
import * as Yup from "yup";

export interface IBlogCategoryForm
  extends Omit<
    IBlogeCategory,
    "id" | "created_by" | "created_date" | "modified_by" | "modified_date"
  > {
  id: number | undefined;
}
export const blogCategoryValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    category_title: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("blog_category_title")],
        ])
      )
      .max(
        50,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("blog_category_title")],
          ["max", "50"],
        ])
      ),
    category_meta_title: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("blog_category_meta_title")],
        ])
      )
      .max(
        50,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("blog_category_meta_title")],
          ["max", "50"],
        ])
      ),
    slug: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("blog_slug")],
        ])
      )
      .max(
        50,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("blog_slug")],
          ["max", "50"],
        ])
      ),
  });

export const getBlogCategoryInitialValues = (
  blogCategoryToEdit: IBlogeCategory | undefined
): IBlogCategoryForm => {
  return {
    id: blogCategoryToEdit?.id,
    category_title: blogCategoryToEdit?.category_title || "",
    category_meta_title: blogCategoryToEdit?.category_meta_title || "",
    slug: blogCategoryToEdit?.slug || "",
    is_active: blogCategoryToEdit?.is_active || "1",
  };
};

export const fetchBlogCategoryId = async (
  id: number,
  setBlogCategoryList: TReactSetState<IBlogCategoryForm | undefined>,
  handleClose: (status: boolean) => void
) => {
  try {
    const blogCategoryId = await GET_BLOG_CATEGORY_BY_ID(id);
    if (blogCategoryId && blogCategoryId.code === DEFAULT_STATUS_CODE_SUCCESS) {
      setBlogCategoryList({
        id: blogCategoryId.data.id,
        category_title: blogCategoryId.data.category_title,
        category_meta_title: blogCategoryId.data.category_meta_title,
        slug: blogCategoryId.data.slug,
        is_active: blogCategoryId.data.is_active,
      });
    } else {
      handleClose(false);
      SweetAlertError(blogCategoryId.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(
      e?.blogCategoryId?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleSubmitBlogCategory = async (
  values: IBlogCategoryForm,
  id: number | undefined,
  handleClose: (status: boolean) => void,
  t: TUseTranslationTfn
) => {
  try {
    let resultBlogCategory;
    if (!id) {
      resultBlogCategory = await POST_BLOG_CATEGORY(values);
    } else {
      resultBlogCategory = await UPDATE_BLOG_CATEGORY(values, id);
    }
    if (
      resultBlogCategory &&
      resultBlogCategory.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      id
        ? SweetAlertSuccess(
            prepareMessageFromParams(t("update_data_successfully"), [
              ["updatedName", t("blog_category")],
            ])
          )
        : SweetAlertSuccess(
            prepareMessageFromParams(t("add_data_successfully"), [
              ["addName", t("blog_category")],
            ])
          );
      return handleClose(true);
    } else {
      SweetAlertError(
        resultBlogCategory?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    SweetAlertError(
      e?.resultBlogCategory?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
