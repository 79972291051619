import {
  ITabelFieldViewProps,
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import { GET_ALL_GRIEVANCES } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
} from "../../utils/AppFunctions";

export interface IGrievance {
  id: number;
  complaint_date: string;
  complaint_title: string;
  complaint_detail: string;
  grievance_status: string;
  unread_messages: number;
}

export function getGrievancesFields<T>(
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("complaint_title"),
      key: "complaint_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("complaint_detail"),
      key: "complaint_detail",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      limit: 25,
      title: "complaint_detail",
    },
    {
      name: t("complaint_date"),
      key: "complaint_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("grievance_status"),
      key: "grievance_status",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("unread_messages"),
      key: "unread_messages",
      sort: true,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      format: (item: ITabelFieldViewProps<T>) =>
        item.data["unread_messages"] > 0 ? item.data["unread_messages"] : "-",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}

export const fetchGrievancesApiCall = async (
  setGrievancesList: TReactSetState<IGrievance[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const grievancesList = await GET_ALL_GRIEVANCES(pagination, search);
    if (grievancesList && grievancesList.code === DEFAULT_STATUS_CODE_SUCCESS) {
      if (grievancesList.data && grievancesList.data.item.length > 0) {
        setGrievancesList(grievancesList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(grievancesList.data.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetGrievancesList = await GET_ALL_GRIEVANCES(
          resetPagination,
          search
        );
        if (
          resetGrievancesList &&
          resetGrievancesList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setGrievancesList(resetGrievancesList.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              resetGrievancesList.data.pagination
            ),
          });
        } else {
          setGrievancesList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(grievancesList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.grievancesList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
