import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import Skeleton, { SkeletonTypeMap } from "@mui/material/Skeleton";

function RectChartSkeleton({
  height,
  ...props
}: DefaultComponentProps<SkeletonTypeMap>) {
  return <Skeleton variant="rectangular" height={height || 390} {...props} />;
}

export default RectChartSkeleton;
