import { TReactSetState } from "../../data/AppType";
import { GET_BOOKINGS_DETAILS_BY_ID } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import { SweetAlertError } from "../../utils/AppFunctions";

interface IMediaUrl {
  media_type: string;
  media_url: string;
}

export interface IBookingView {
  id: number;
  booking_status_label: string;
  booking_status?: string;
  service_cost: number;
  service_discounted_price: number;
  booking_date: Date;
  schedule_time: string;
  schedule_start_date: Date;
  cancellation_reason: string;
  booking_number: string;
  user: {
    id: number;
    user_name: string;
  };
  trainer: {
    id: number;
    trainer_name: string;
  };
  service: {
    id: number;
    service_title: string;
    service_desc: string;
    app_media: IMediaUrl[];
  };
  booking_cancellation_date: Date;
}

export const fetchBookingDetailsById = async (
  id: number,
  setBookingDetailsFormInitialValues: TReactSetState<IBookingView | undefined>,
  handleClose: (status: boolean) => void
) => {
  try {
    if (id) {
      const bookingDetailsById = await GET_BOOKINGS_DETAILS_BY_ID(id);
      if (
        bookingDetailsById &&
        bookingDetailsById.code === DEFAULT_STATUS_CODE_SUCCESS
      ) {
        setBookingDetailsFormInitialValues(bookingDetailsById.data);
      } else {
        handleClose(false);
        SweetAlertError(
          bookingDetailsById.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
        );
      }
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(
      e?.bookingDetailsById?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
