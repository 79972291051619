import React, { useEffect, useRef, useState } from "react";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import { IGrievance } from "./GrievancesController";
import {
  dateFormat,
  getLocalDate,
  timeFormat,
  toastError,
} from "../../utils/AppFunctions";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import ICDropDown from "../../core-component/ICDropDown";
import {
  GRIEVANCE_STATUS_OPTION,
  MIN_MAX_LENGTH_COMPARE,
} from "../../utils/AppConstants";
import {
  updateMessageIcon,
  editMessageIcon,
  sendIcon,
  pendingIcon,
  clipIcon,
  deleteIcon,
  deleteImageIcon,
} from "../../assets/icons/SvgIconList";
import {
  IGrievanceConversationsList,
  IGrievanceForm,
  fetchGrievanceConversationById,
  handleGrievanceConversationApiCall,
  limitString,
  markReadGrievance,
  updateGrievanceStatusApiCall,
} from "./GrievancesFormController";
import { IMAGE_TYPE, USER_STATIC_ROLE } from "../../utils/AppEnumerations";
import ICTooltip from "../../core-component/ICTooltip";
import ICImageView from "../../core-component/ic-image/ICImageView";
import { TOnChangeInput } from "../../data/AppType";
import { fetchUploadImageService } from "../global/image-service/ImageServices";
import ICButton from "../../core-component/ICButton";

interface IGrievancesFormProps {
  handleClose: (
    status: boolean,
    selectedGrievanceStatus: number,
    id: number
  ) => void;
  grievanceToEdit: IGrievance;
  readOnly?: boolean;
}

const GrievancesFormView: React.FC<IGrievancesFormProps> = ({
  handleClose,
  grievanceToEdit,
  readOnly,
}) => {
  const { t } = UseTranslationHook();
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const [messageId, setMessageId] = useState<number>();
  const [selectedGrievanceStatus, setSelectedGrievanceStatus] =
    useState<number>(0);
  const [grievancesMessages, setGrievancesMessages] = useState<
    IGrievanceConversationsList[]
  >([]);
  const [inputText, setInputText] = useState<string | null>(null);
  const [hoveredMessageId, setHoveredMessageId] = useState<number>();
  const [grievanceFormInitialValues, setGrievanceFormInitialValues] =
    useState<IGrievanceForm>();
  const [hasUnreadMessage, setHasUnreadMessage] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (grievanceToEdit) {
      fetchGrievanceConversationById(
        grievanceToEdit.id,
        setGrievanceFormInitialValues,
        setGrievancesMessages,
        setSelectedGrievanceStatus,
        setHasUnreadMessage
      );
    }
  }, []);

  useEffect(() => {
    const updateGrievanceValue = {
      grievance_status: `${selectedGrievanceStatus}`,
    };
    if (grievanceFormInitialValues)
      updateGrievanceStatusApiCall(
        updateGrievanceValue,
        grievanceFormInitialValues.id
      );
  }, [selectedGrievanceStatus]);

  useEffect(() => {
    if (hasUnreadMessage) {
      markReadGrievance(grievanceToEdit.id);
      setHasUnreadMessage(false);
    }
  }, [hasUnreadMessage]);

  useEffect(() => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollTop =
        mainContainerRef.current.scrollHeight;
    }
  }, [grievancesMessages]);

  const addUserMessage = (message: string | null, filePath: string | null) => {
    setGrievancesMessages([
      ...grievancesMessages,
      {
        comment: message,
        file_path: filePath,
        role_id: USER_STATIC_ROLE.SuperAdmin,
        log_date: getLocalDate().toISOString(),
      },
    ]);
  };

  const updateComment = (objectId: number, newComment: string) => {
    const index = grievancesMessages.findIndex((obj) => obj.id === objectId);

    if (index !== -1) {
      const updatedMessages: IGrievanceConversationsList[] = [
        ...grievancesMessages.slice(0, index),
        { ...grievancesMessages[index], comment: newComment },
        ...grievancesMessages.slice(index + 1),
      ];
      setGrievancesMessages(updatedMessages);
    }
  };
  const handleGrievanceMessageSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (inputText === null && selectedFile === null) return;
    setLoading(true);
    let imageUrl = null;
    setInputText(null);
    if (messageId) {
      // updateComment(messageId, inputText);
      if (grievanceFormInitialValues) {
        const updatedCommentValue = {
          grievance_id: grievanceFormInitialValues.id,
          comment: inputText,
          file_path: imageUrl,
        };

        await handleGrievanceConversationApiCall(
          updatedCommentValue,
          messageId
        );
      }
    } else {
      // addUserMessage(inputText, imageUrl);
      if (selectedFile) {
        const formData = new FormData();
        formData.append("myImageFile", selectedFile);
        formData.append("type", IMAGE_TYPE.Grievances);
        grievanceToEdit.id &&
          formData.append("typeId", `${grievanceToEdit.id}`);
        const response = await fetchUploadImageService(formData);
        imageUrl = response;
      }
      if (grievanceFormInitialValues) {
        const commentValue = {
          grievance_id: grievanceFormInitialValues.id,
          comment: imageUrl ? null : inputText,
          file_path: imageUrl,
        };
        await handleGrievanceConversationApiCall(commentValue, messageId);
      }
    }

    if (grievanceToEdit)
      await fetchGrievanceConversationById(
        grievanceToEdit.id,
        setGrievanceFormInitialValues,
        setGrievancesMessages,
        setSelectedGrievanceStatus,
        setHasUnreadMessage
      );
    setMessageId(undefined);
    setSelectedFile(null);
    setLoading(false);
  };

  if (!inputText?.length && messageId) {
    setMessageId(undefined);
  }

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = (event: TOnChangeInput) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      if (
        (file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg") &&
        file.size <= 10 * 1024 * 1024
      ) {
        setSelectedFile(file);
      } else {
        toastError(t("max_size_error"));
      }
    }
  };

  return (
    <FormRightModalWrapper
      containerClassName={"!w-1/2"}
      onClose={() =>
        handleClose(false, selectedGrievanceStatus, grievanceToEdit.id)
      }
      title={t("grievance")}
    >
      {selectedFile ? (
        <div className="w-full aspect-16/9 border-box flex items-center justify-center flex-col relative h-full  overflow-hidden">
          <img
            src={URL.createObjectURL(selectedFile)}
            alt="Preview"
            className="w-full aspect-16/9 object-contain"
          />
          <button
            type="button"
            disabled={loading}
            className="text-skin-trainer-validation mt-2 absolute top-0 right-0"
            onClick={() => setSelectedFile(null)}
          >
            <div className="w-10 h-10">{deleteImageIcon}</div>
          </button>
          <ICButton
            type="button"
            loading={loading}
            children={
              <div className="w-10 h-10 fill-skin-grievance-send-button">
                {sendIcon}
              </div>
            }
            className="text-skin-trainer-validation mt-2 absolute bottom-1 right-0 !rounded-full"
            onClick={(event) => handleGrievanceMessageSubmit(event)}
          />
        </div>
      ) : (
        <div className="shadow-md rounded-lg  w-full flex flex-col h-[calc(100vh-82px)] ">
          <div className="p-2 border-b rounded-t-lg flex space-x-2">
            <div className="w-full grid grid-cols-9  gap-y-1">
              {grievanceFormInitialValues?.service_title && (
                <div className="col-span-3 font-semibold">
                  {t("service_title")}
                </div>
              )}
              {grievanceFormInitialValues?.service_title && (
                <div className="col-span-4">
                  : {grievanceFormInitialValues.service_title}
                </div>
              )}

              {grievanceFormInitialValues?.complaint_title && (
                <div className="col-span-3 font-semibold">
                  {t("complaint_title")}
                </div>
              )}
              {grievanceFormInitialValues?.complaint_title && (
                <div className="col-span-6 flex space-x-1">
                  <div>:</div>
                  <div>
                    <ICTooltip
                      position={2}
                      text={grievanceFormInitialValues?.complaint_title}
                      children={limitString(
                        grievanceFormInitialValues?.complaint_title,
                        25
                      )}
                    />
                  </div>
                </div>
              )}

              <div className="col-span-3 font-semibold">
                {t("complaint_detail")}
              </div>
              {grievanceFormInitialValues?.complaint_detail && (
                <div className="col-span-6 flex space-x-1">
                  <div> : </div>
                  <div>
                    <ICTooltip
                      text={grievanceFormInitialValues.complaint_detail}
                      children={limitString(
                        grievanceFormInitialValues.complaint_detail,
                        15
                      )}
                    />
                  </div>
                </div>
              )}
              <div className="font-semibold col-span-3">
                {t("complaint_date")}
              </div>
              <div className="col-span-6">
                :{" "}
                {grievanceFormInitialValues?.complaint_date
                  ? dateFormat(
                      getLocalDate(grievanceFormInitialValues?.complaint_date)
                    )
                  : ""}
              </div>
            </div>
            <div className="w-3/4">
              {grievanceFormInitialValues?.booking_number && (
                <div className="font-semibold">{`${t("booking_number")} : ${
                  grievanceFormInitialValues.booking_number
                }`}</div>
              )}

              <div className="font-semibold">{t("grievance_status")}</div>
              <div className="w-full ">
                <ICDropDown
                  options={GRIEVANCE_STATUS_OPTION}
                  onSelect={(option) =>
                    setSelectedGrievanceStatus(option.value)
                  }
                  selected={selectedGrievanceStatus}
                  disabled={readOnly}
                />
              </div>
            </div>
          </div>
          <div className="flex-1 flex justify-between flex-col">
            <div
              ref={mainContainerRef}
              className="p-2 h-[calc(100vh-290px)] overflow-y-scroll "
            >
              {grievancesMessages.map((message, index) => (
                <div
                  key={index}
                  className={`flex items-end mb-[10px] ${
                    message.role_id === USER_STATIC_ROLE.SuperAdmin
                      ? "justify-end"
                      : "justify-start"
                  }`}
                >
                  <div>
                    <div className=" flex justify-between items-center mb-0.5 space-x-3">
                      <div className="text-xs">{message.modified_by_name}</div>
                      <div className="text-xs">
                        {message.log_date
                          ? timeFormat(getLocalDate(message.log_date))
                          : ""}
                      </div>
                    </div>
                    <div
                      className={`flex  ${
                        message.role_id === USER_STATIC_ROLE.SuperAdmin
                          ? "justify-end -mr-2"
                          : "justify-start ml-1.5"
                      }`}
                      onMouseEnter={() => setHoveredMessageId(message.id)}
                      onMouseLeave={() => setHoveredMessageId(undefined)}
                    >
                      {message.comment && (
                        <div
                          className={`${
                            message.role_id === USER_STATIC_ROLE.SuperAdmin
                              ? "text-right bg-skin-admin-message  rounded-bl-lg rounded-tl-lg rounded-tr-lg "
                              : "text-left  bg-skin-user-message rounded-tr-lg rounded-bl-lg rounded-br-lg "
                          } p-2 text-skin-grievance-message max-w-96 shadow-md w-full text-base`}
                        >
                          {message.comment}
                        </div>
                      )}
                      {message.file_path && (
                        <div
                          className={`${
                            message.role_id === USER_STATIC_ROLE.SuperAdmin
                              ? "text-right bg-skin-admin-message  rounded-bl-lg rounded-tl-lg rounded-tr-lg "
                              : "text-left  bg-skin-user-message rounded-tr-lg rounded-bl-lg rounded-br-lg "
                          } p-2 text-skin-grievance-message max-w-96 shadow-md w-full text-base`}
                        >
                          <ICImageView
                            className="w-full aspect-16/9 object-contain"
                            url={message.file_path}
                            isPrivate
                          />
                        </div>
                      )}
                      {hoveredMessageId === message.id &&
                        message.id !== undefined &&
                        message.comment &&
                        !readOnly &&
                        message.role_id === USER_STATIC_ROLE.SuperAdmin && (
                          <div
                            className="cursor-pointer h-4 w-4 mx-0.5"
                            onClick={() => {
                              setMessageId(message.id);
                              setInputText(message.comment);
                            }}
                          >
                            {editMessageIcon}
                          </div>
                        )}
                      {message.id === undefined && (
                        <div className="cursor-pointer h-4 w-4">
                          {pendingIcon}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              {!readOnly && (
                <div className="p-2 border-t">
                  <form
                    className=" flex p-[10px] gap-x-2"
                    onSubmit={handleGrievanceMessageSubmit}
                  >
                    <div className="relative w-full">
                      {!messageId && (
                        <div
                          className="absolute left-2 top-3 w-5 h-5 cursor-pointer"
                          onClick={handleIconClick}
                        >
                          {clipIcon}
                        </div>
                      )}
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept=".png, .jpeg, .jpg"
                        className="hidden"
                        onChange={handleFileSelect}
                      />
                      <input
                        type="text"
                        className={`flex-grow p-[10px] w-full rounded-[25px] border outline-none pr-20 mr-4 ${
                          messageId ? "pl-4" : "pl-10"
                        } `}
                        placeholder={t("type_message")}
                        value={inputText ? inputText : ""}
                        onChange={(e) => setInputText(e.target.value)}
                      />
                      <p className="grievance-message-input grievance-message-text-color">{`${
                        inputText ? inputText.length : 0
                      }/${MIN_MAX_LENGTH_COMPARE.Thousands}`}</p>
                    </div>
                    <button
                      className={`bg-skin-primary px-4 py-2 rounded-full hover:bg-skin-admin-message transition duration-300 ${
                        inputText
                          ? inputText.length
                          : 0 > MIN_MAX_LENGTH_COMPARE.Thousands
                          ? "disabled cursor-not-allowed"
                          : ""
                      } `}
                    >
                      <div className="h-7 w-7 fill-skin-grievance-send-button">
                        {inputText && messageId ? updateMessageIcon : sendIcon}
                      </div>
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </FormRightModalWrapper>
  );
};

export default GrievancesFormView;
