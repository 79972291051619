import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import {
  ACTIVE_INACTIVE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  GET_ALL_SUBSCRIPTION,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
  toastSuccess,
} from "../../utils/AppFunctions";
import { ILibraryItem } from "../product-services/add-product-service/pages/course-content/CourseContentFormController";

export interface ISubscriptionPackage {
  id: number;
  package_title: string;
  package_description: string;
  target_audience: string;
  package_price?: number;
  package_discounted_price?: number;
  expire_in_days: number | null;
  allow_extension: string;
  extension_price?: number;
  extension_expire_in_days?: number;
  publish_date?: Date;
  is_active: string;
  deleted?: string;
  library_directory?: number[];
  library_content?: number[];
  library_items?: ILibraryItem[];
  subscription_consent?: string;
}

export function getSubscriptionFields<T>(
  handleStatusChange: (item: T) => void,
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("package_title"),
      key: "package_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
    },
    {
      name: t("package_description"),
      key: "package_description",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      limit: 30,
      title: "package_description",
    },
    {
      name: t("target_audience"),
      key: "target_audience",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("package_price"),
      key: "package_price",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
    },
    {
      name: t("package_discounted_price"),
      key: "package_discounted_price",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },

    {
      name: t("publish_date"),
      key: "publish_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("status"),
      key: "is_active",
      sort: false,
      type: TABLE_FIELD_TYPE.switch,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      handleCheck: handleStatusChange,
      flex: "col-span-1",
    },
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },

    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
      component: actionComponent,
    },
  ];
}

export const fetchSubscriptionApiCall = async (
  setSubscriptionList: TReactSetState<ISubscriptionPackage[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const listSubscription = await GET_ALL_SUBSCRIPTION(pagination, search);
    if (
      listSubscription &&
      listSubscription.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (listSubscription.data && listSubscription.data.item.length > 0) {
        setSubscriptionList(listSubscription.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(listSubscription.data.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetSubscriptionList = await GET_ALL_SUBSCRIPTION(
          resetPagination,
          search
        );
        if (
          resetSubscriptionList &&
          resetSubscriptionList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setSubscriptionList(resetSubscriptionList.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              resetSubscriptionList.data.pagination
            ),
          });
        } else {
          setSubscriptionList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(listSubscription.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.listSubscription?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleActiveInactiveSubscription = async (
  id: number,
  values: string
) => {
  try {
    const subscriptionActiveInactive = await ACTIVE_INACTIVE_SUBSCRIPTION(id, {
      is_active: values,
    });
    if (
      subscriptionActiveInactive &&
      subscriptionActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      toastSuccess(subscriptionActiveInactive.message);
      return true;
    }
    return false;
  } catch (e: any) {
    toastError(
      e?.subscriptionActiveInactive?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};

export const handleDeleteSubscription = async (
  id: number,
  t: TUseTranslationTfn
) => {
  try {
    const subscriptionDelete = await DELETE_SUBSCRIPTION(id);
    if (
      subscriptionDelete &&
      subscriptionDelete.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(subscriptionDelete.message);
      return true;
    } else {
      SweetAlertError(
        subscriptionDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
    return false;
  } catch (e: any) {
    SweetAlertError(
      e?.subscriptionDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};
