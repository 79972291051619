import React, { useState } from "react";
import { dateFormat, getLocalDate } from "../utils/AppFunctions";
import { calendarIcon } from "../assets/icons/SvgIconList";
import { DATE_FORMAT } from "../utils/AppEnumerations";
import { TDateFormat, TOnChangeInput } from "../data/AppType";
export interface ICDatePickerProps {
  useValue?: boolean;
  value?: Date;
  min?: Date;
  max?: Date;
  onChangeDate?: (event: TOnChangeInput) => void;
  placeholder?: string;
  displayFormat?: TDateFormat;
  errorMessage?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
}
const ICDatePicker: React.FC<ICDatePickerProps> = (props) => {
  const {
    useValue,
    value,
    placeholder,
    onChangeDate,
    min,
    max,
    displayFormat,
    errorMessage,
    label,
    required,
    disabled,
  } = props;
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [isFocused, setIsFocused] = useState(false);
  const handleDateChange = (event: TOnChangeInput) => {
    if (!useValue) {
      setSelectedDate(
        event.target.value ? getLocalDate(event.target.value) : undefined
      );
    }
    if (onChangeDate) {
      onChangeDate(event);
    }
  };
  const getFormattedDisplayValue = (date: Date) => {
    return dateFormat(date, displayFormat || DATE_FORMAT["YYYY-MM-DD"]);
  };
  const handleFocusDatePicker = () => {
    setIsFocused(true);
  };
  const handleBlurDatePicker = () => {
    setIsFocused(false);
  };
  return (
    <div>
      <div
        className={`ic-date-input-container relative pt-0 pb-0  ${
          errorMessage ? "error-border" : ""
        }`}
      >
        {label && (
          <label
            className={`absolute flex items-center  ${
              isFocused || selectedDate || value
                ? "floating-datePicker top-0  px-1 typo-placeholder-floating "
                : "  pl-1 typo-floating-label"
            } `}
          >
            {label + (required ? "*" : "")}
          </label>
        )}
        <div
          className="flex justify-between items-center w-full h-6"
          onClick={(event) => event.stopPropagation()}
        >
          <div>
            {value ? (
              getFormattedDisplayValue(value)
            ) : selectedDate ? (
              getFormattedDisplayValue(selectedDate)
            ) : placeholder ? (
              <span className="text-skin-date-picker">{""}</span>
            ) : (
              getFormattedDisplayValue(getLocalDate())
            )}
          </div>
          <div className="text-input-trailing">{calendarIcon}</div>
        </div>
        {!disabled && (
          <input
            min={min ? dateFormat(min, DATE_FORMAT["YYYY-MM-DD"]) : undefined}
            max={max ? dateFormat(max, DATE_FORMAT["YYYY-MM-DD"]) : undefined}
            className="ic-date-input"
            type="date"
            onChange={handleDateChange}
            onFocus={handleFocusDatePicker}
            onBlur={handleBlurDatePicker}
            value={
              selectedDate?.toISOString().split("T")[0] ||
              value?.toISOString().split("T")[0]
            }
          />
        )}
      </div>
      {errorMessage && <div className="typo-error">{errorMessage}</div>}
    </div>
  );
};
export default ICDatePicker;
