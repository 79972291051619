import { TReactSetState } from "../../data/AppType";
import {
  ADD_GRIEVANCE_CONVERSATION,
  GET_GRIEVANCE_CONVERSATION_BY_ID,
  UPDATE_GRIEVANCE_CONVERSATION,
  UPDATE_GRIEVANCE_STATUS,
} from "../../services/Endpoints";
import { MARK_READ_GRIEVANCE } from "../../services/grievances/GrievancesServices";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import { SweetAlertError, toastError } from "../../utils/AppFunctions";

export interface IGrievanceConversation {
  grievance_id: number;
  comment: string | null;
  file_path: string | null;
}
export interface IGrievanceConversationsList {
  id?: number;
  comment: string | null;
  file_path: string | null;
  log_date: string;
  modified_by_name?: string;
  role_id: number;
}
export interface IGrievanceForm {
  id: number;
  complaint_date: string;
  complainer: string;
  complaint_title: string;
  complaint_detail: string;
  grievance_status: number;
  booking_id: number | null;
  booking_number: number | null;
  service_title: string | null;
  modified_date: string;
  modified_by: string;
  grievance_conversations: IGrievanceConversationsList[];
}

export const limitString = (inputString: string, maxCharacters?: number) => {
  if (maxCharacters) {
    if (
      inputString &&
      maxCharacters >= 0 &&
      inputString.length > maxCharacters
    ) {
      return inputString.substring(0, maxCharacters) + "...";
    } else {
      return inputString;
    }
  }
  return inputString;
};

export const fetchGrievanceConversationById = async (
  id: number,
  setGrievancesFormInitialValues: TReactSetState<IGrievanceForm | undefined>,
  setGrievancesMessages: TReactSetState<IGrievanceConversationsList[]>,
  setSelectedStatus: TReactSetState<number>,
  setHasUnreadMessage: TReactSetState<boolean>
) => {
  try {
    let resultGrievanceConversation;

    resultGrievanceConversation = await GET_GRIEVANCE_CONVERSATION_BY_ID(id);
    if (
      resultGrievanceConversation &&
      resultGrievanceConversation.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      setGrievancesFormInitialValues(resultGrievanceConversation.data);
      setSelectedStatus(resultGrievanceConversation.data.grievance_status);
      setGrievancesMessages(
        resultGrievanceConversation.data.grievance_conversations
      );
      setHasUnreadMessage(resultGrievanceConversation.data.unread_messages > 0);
    } else {
      SweetAlertError(
        resultGrievanceConversation.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    SweetAlertError(
      e?.resultGrievanceConversation?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const updateGrievanceStatusApiCall = async (
  Value: { grievance_status: string },
  id: number
) => {
  try {
    let resultUpdateGrievance;

    resultUpdateGrievance = await UPDATE_GRIEVANCE_STATUS(Value, id);
    if (
      resultUpdateGrievance &&
      resultUpdateGrievance.code !== DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertError(
        resultUpdateGrievance.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    SweetAlertError(
      e?.resultGrievanceConversation?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleGrievanceConversationApiCall = async (
  values: IGrievanceConversation,
  id: number | undefined
) => {
  try {
    let resultGrievanceConversation;

    if (id) {
      resultGrievanceConversation = await UPDATE_GRIEVANCE_CONVERSATION(
        values,
        id
      );
    } else {
      resultGrievanceConversation = await ADD_GRIEVANCE_CONVERSATION(values);
      if (
        resultGrievanceConversation &&
        resultGrievanceConversation.code !== DEFAULT_STATUS_CODE_SUCCESS
      ) {
        SweetAlertError(
          resultGrievanceConversation.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
        );
      }
    }
  } catch (e: any) {
    SweetAlertError(
      e?.resultGrievanceConversation?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const markReadGrievance = async (grievanceId: number) => {
  try {
    await MARK_READ_GRIEVANCE(grievanceId);
  } catch {
    toastError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
