import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import ICButton from "../../../../../core-component/ICButton";
import {
  ITimeSlotInitialValues,
  fetchTimeSlot,
  handleSubmitTimeSlot,
  timeSlotInitialValuesFromController,
} from "./TimeSlotViewController";
import ICTimePicker, {
  ICTimePickerProps,
} from "../../../../../core-component/ICTimePicker";
import ICTextInputValue, {
  ICustomInputProps,
} from "../../../../../core-component/ICInputWithValue";
import { SweetAlertError } from "../../../../../utils/AppFunctions";
import { cancelIcon } from "../../../../../assets/icons/SvgIconList";
import UseTranslationHook from "../../../../../hooks/UseTranslationHook";
import { TReactSetState } from "../../../../../data/AppType";
import { useLocation, useNavigate } from "react-router-dom";
import { BIT_VALUE } from "../../../../../utils/AppEnumerations";

interface IAdditionalInfoProps {
  setCurrentIndex: TReactSetState<number>;
}
const TimeSlotView: React.FC<IAdditionalInfoProps> = ({ setCurrentIndex }) => {
  const { t } = UseTranslationHook();
  const location = useLocation();
  const [timeSlotInitialValues, settimeSlotInitialValues] =
    useState<ITimeSlotInitialValues>();
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [selectedTimeManual, setSelectedTimeManual] = useState<string>("");
  const [inputSlot, setInputSlot] = useState<number>(0);
  const [slots, setSlots] = useState<string[]>([]);
  const [selection, setSelection] = useState<string>("manual");
  const [hoursStr, minutesStr] = selectedTime.split(":");
  const [inputHoursStr, setInputHoursStr] = useState<string>("");
  const [inputMinutesStr, setInputMinutesStr] = useState<string>("");
  const [buttonController, setButtonController] = useState(true);
  const [incompleteSession, setIncompleteSession] = useState<string>("0");

  const navigate = useNavigate();
  const serviceId: number = location?.state?.productServiceId;
  const readOnly: boolean = location?.state?.readOnly;

  useEffect(() => {
    if (serviceId) {
      timeSlotInitialValuesFromController(settimeSlotInitialValues);
      fetchTimeSlot(serviceId, setSlots, setIncompleteSession);
    }
  }, []);

  const handleChangeTime: ICTimePickerProps["onChangeTime"] = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleChangeTimeManual: ICTimePickerProps["onChangeTime"] = (event) => {
    setSelectedTimeManual(event.target.value);
  };

  const handleChangeValue: ICustomInputProps["onChangeValue"] = (value) => {
    setInputHoursStr(value);
  };

  const handleChangeValueMints: ICustomInputProps["onChangeValue"] = (
    value
  ) => {
    setInputMinutesStr(value);
  };

  const handleChangeSlot: ICustomInputProps["onChangeValue"] = (value) => {
    setInputSlot(parseInt(value));
  };

  const handelReset = () => {
    setSelectedTime("");
    setInputHoursStr("");
    setInputMinutesStr("");
    setInputSlot(0);
    setSlots([]);
  };

  const handleSelectionChange = (value: string) => {
    setSelection(value);
  };

  const calculateTimeDifferenceFromMidnight = (
    hours: number,
    minutes: number
  ): string => {
    // If minutes is 0, set it to 60
    if (minutes === 0) {
      minutes = 60;
      hours -= 1; // Adjusting hours
    }

    // Convert time to minutes
    let totalMinutes = hours * 60 + minutes;

    // Calculate the difference from 24 hours (1440 minutes)
    let differenceInMinutes = 1440 - totalMinutes;

    // Convert difference back to hours and minutes
    let differenceHours = Math.floor(differenceInMinutes / 60);
    let differenceMinutes = differenceInMinutes % 60;

    // Format the result
    let formattedDifference = `${differenceHours
      .toString()
      .padStart(2, "0")}:${differenceMinutes.toString().padStart(2, "0")}`;

    return formattedDifference;
  };

  const generateSlots = () => {
    if (!selectedTime || !inputHoursStr || !inputSlot) {
      SweetAlertError(t("provide_all_values"));
      return;
    }

    let hours = parseInt(hoursStr);
    let minutes = parseInt(minutesStr);

    let difference = calculateTimeDifferenceFromMidnight(hours, minutes);

    const inputHours = parseInt(inputHoursStr);
    const inputMinutes = parseInt(inputMinutesStr) || 0; // Default to 0 if minutes are not provided

    const totalHours = inputHours * inputSlot + (inputMinutes * inputSlot) / 60;

    // Parse difference into hours and minutes
    const [differenceHours, differenceMinutes] = difference
      .split(":")
      .map(Number);

    if (
      totalHours > differenceHours ||
      (totalHours === differenceHours && inputMinutes > differenceMinutes)
    ) {
      SweetAlertError(t("generate_slot_after"));
      return;
    }

    let generatedSlots: string[] = [];

    for (let i = 0; i < inputSlot; i++) {
      let slot = "";

      // Convert hours to 12-hour format
      const period = hours >= 12 ? "PM" : "AM";
      const displayHours = hours % 12 || 12;
      if (displayHours < 10) {
        slot += "0";
      }
      slot += displayHours + ":";

      if (minutes < 10) {
        slot += "0";
      }
      slot += minutes + " " + period;

      generatedSlots.push(slot);

      // Increment time by inputHours and inputMinutes
      hours += inputHours;
      minutes += inputMinutes;

      // Handle when minutes exceed 60
      if (minutes >= 60) {
        hours++;
        minutes -= 60;
      }

      // Handle when hours exceed 24
      if (hours >= 24) {
        hours -= 24;
      }
    }

    setSlots(generatedSlots);
  };

  // State to hold the converted 12-hour time

  const convertTo12HourFormat = (time24: string): string => {
    const [hours, minutes] = time24.split(":").map(Number);

    const meridiem = hours < 12 ? "AM" : "PM";
    let hours12 = hours % 12;
    hours12 = hours12 || 12; // if hours12 is 0, set it to 12

    return `${hours12?.toString().padStart(2, "0")}:${minutes
      ?.toString()
      .padStart(2, "0")} ${meridiem}`;
  };

  const generateSlotsManual = () => {
    if (!selectedTimeManual) {
      SweetAlertError(t("time_slots_required"));
      return;
    }

    const convertedTime = convertTo12HourFormat(selectedTimeManual);
    if (slots.includes(convertedTime)) {
      SweetAlertError(t("slot_already_book"));
    } else {
      setSlots((prevSlots) => [...prevSlots, convertedTime]);
    }
  };

  const handleRemoveSlot = (data: string) => {
    setSlots(slots.filter((slot) => slot !== data));
  };

  const handleSubmitTime = () => {
    handleSubmitTimeSlot(
      {
        service_id: serviceId,
        slot: slots,
        is_active: "1",
      },
      serviceId,
      navigate,
      setCurrentIndex,
      buttonController,
      t
    );
    setSelectedTime("");
    setInputHoursStr("");
    setInputMinutesStr("");
    setInputSlot(0);
  };

  const handleNextButton = () => {
    setButtonController(true);
  };
  const handleSaveButton = () => {
    setButtonController(false);
  };
  return (
    <div className="p-4">
      {timeSlotInitialValues && (
        <Formik
          initialValues={timeSlotInitialValues}
          enableReinitialize
          onSubmit={handleSubmitTime}
        >
          {(formikPorps) => {
            const { handleSubmit } = formikPorps;

            return (
              <>
                <Form onSubmit={handleSubmit}>
                  <div className="flex justify-center mt-10">
                    <div>
                      {!readOnly && incompleteSession === BIT_VALUE.Zero && (
                        <>
                          <div className="flex space-x-4">
                            <h1>{t("generate_time_slot")}</h1>
                            <div className="flex ">
                              <div className="flex items-center space-x-2 ">
                                <input
                                  type="radio"
                                  id="manual"
                                  name="selection"
                                  value="manual"
                                  checked={selection === "manual"}
                                  onChange={() =>
                                    handleSelectionChange("manual")
                                  }
                                  className="form-radio h-4 w-4 "
                                />
                                <label htmlFor="manual">{t("manual")}</label>
                              </div>
                              <div className="flex items-center space-x-2 mx-3">
                                <input
                                  type="radio"
                                  id="auto"
                                  name="selection"
                                  value="auto"
                                  checked={selection === "auto"}
                                  onChange={() => handleSelectionChange("auto")}
                                  className="form-radio h-4 w-4"
                                />
                                <label htmlFor="auto">{t("auto")}</label>
                              </div>
                            </div>
                          </div>
                          {selection === "auto" && (
                            <div className="border-2 p-5">
                              <ICTimePicker
                                value={selectedTime}
                                onChangeTime={handleChangeTime}
                                placeholder={t("start_at")}
                                label={t("start_at")}
                                required
                              />
                              <div className="mt-3">
                                <ICTextInputValue
                                  type="number"
                                  label={t("no_of_slots")}
                                  placeholder={t("no_of_slots")}
                                  value={inputSlot}
                                  onChangeValue={handleChangeSlot}
                                  required
                                />
                              </div>
                              <div className="flex justify-between gap-2 my-3">
                                <ICTextInputValue
                                  type="number"
                                  label={t("HH")}
                                  placeholder={t("HH")}
                                  value={inputHoursStr}
                                  onChangeValue={handleChangeValue}
                                  required
                                />
                                <ICTextInputValue
                                  type="number"
                                  label={t("MM")}
                                  placeholder={t("MM")}
                                  value={inputMinutesStr}
                                  onChangeValue={handleChangeValueMints}
                                />
                              </div>

                              <div className="flex gap-5">
                                <div>
                                  <ICButton
                                    className={"w-full  "}
                                    type="button"
                                    onClick={generateSlots}
                                  >
                                    {t("Generate")}
                                  </ICButton>
                                </div>

                                <div>
                                  <ICButton
                                    className={"w-full"}
                                    type="button"
                                    onClick={handelReset}
                                  >
                                    {t("Reset")}
                                  </ICButton>
                                </div>
                              </div>
                            </div>
                          )}
                          {selection === "manual" && (
                            <div className="mt-5 border-2 p-5">
                              <ICTimePicker
                                value={selectedTimeManual}
                                onChangeTime={handleChangeTimeManual}
                                placeholder={t("time_slots")}
                                label={t("time_slots")}
                                required
                              />

                              <ICButton
                                className={"w-full"}
                                type="button"
                                onClick={generateSlotsManual}
                              >
                                {t("Add Slot")}
                              </ICButton>
                            </div>
                          )}
                        </>
                      )}

                      <div className="mt-5 border-2  p-5 grid grid-cols-4 gap-4 ">
                        {slots &&
                          slots.length > 0 &&
                          slots.map((slot, index) => (
                            <div
                              key={index}
                              className="flex items-center border p-2 rounded-lg bg-skin-primary  gap-2 text-skin-on-primary"
                            >
                              <div className="">{slot}</div>
                              {!readOnly && (
                                <div
                                  className="cursor"
                                  onClick={() => {
                                    handleRemoveSlot(slot);
                                  }}
                                >
                                  {cancelIcon}
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                      {!readOnly && incompleteSession === BIT_VALUE.One && (
                        <div className="text-yellow-500 max-w-xl">
                          {t("service_edit_disable")}
                        </div>
                      )}
                      {!readOnly && incompleteSession === BIT_VALUE.Zero && (
                        <div className=" grid grid-cols-2 space-x-4 mt-5 w-full">
                          <>
                            <div onClick={handleSaveButton}>
                              <ICButton className={"w-full  "} type="submit">
                                {t("save_button")}
                              </ICButton>
                            </div>

                            <div onClick={handleNextButton}>
                              <ICButton className={"w-full"} type="submit">
                                {t("save_and_next_button")}
                              </ICButton>
                            </div>
                          </>
                        </div>
                      )}
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default TimeSlotView;
