import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  IConfigPayload,
  IConfigValuePayload,
} from "../../services/system-configurations/SystemConfigurationsService";
import {
  fetchSystemSettings,
  handleSubmitSystemConfiguration,
} from "./SystemSettingController";
import { Form, Formik, FormikProps } from "formik";
import ICFormikTextInput from "../formik-input/ICFormikTextInput";
import ICFormikSwitch from "../formik-input/ICFormikSwitch";
import ICFormikTextArea from "../formik-input/ICFormikTextIArea";
import ICButton from "../../core-component/ICButton";
import { CONTROL_TYPE } from "../../utils/AppEnumerations";
import CustomEditor from "../../core-component/CustomEditor";
import ColorPicker from "../../core-component/ICColorPiker";

interface ISystemSettingsProps {
  menuId: number;
}

const SystemSettings: React.FC<ISystemSettingsProps> = ({ menuId }) => {
  const { t } = UseTranslationHook();
  const [systemSettings, setSystemSettings] = useState<IConfigPayload[]>([]);
  const [activeConfigTab, setActiveConfigTab] = useState(
    systemSettings[0]?.tab_title || ""
  );

  const sortedSettings = systemSettings.sort(
    (a, b) => a.tab_sequence - b.tab_sequence
  );

  const uniqueTabs = Array.from(
    new Set(sortedSettings.map((setting) => setting.tab_title))
  );

  const handleTabClick = (tabName: string) => {
    setActiveConfigTab(tabName);
  };

  useEffect(() => {
    fetchSystemSettings(setSystemSettings, menuId);
  }, []);

  const renderControl = (
    item: IConfigPayload,
    type: CONTROL_TYPE,
    t: (key: string) => string,
    formikProps: FormikProps<any>
  ) => {
    switch (type) {
      case CONTROL_TYPE.TextInput:
        return (
          <ICFormikTextInput
            {...formikProps}
            fieldName={item.config_key as never}
            placeholder={t(item.user_friendly_name as never)}
            maxLength={item.max_length ? item.max_length : undefined}
            type="text"
          />
        );
      case CONTROL_TYPE.NumberInput:
        return (
          <ICFormikTextInput
            {...formikProps}
            fieldName={item.config_key as never}
            placeholder={t(item.user_friendly_name as never)}
            type="number"
            maxLength={item.max_length ? item.max_length : undefined}
          />
        );
      case CONTROL_TYPE.Switch:
        return (
          <ICFormikSwitch
            {...formikProps}
            containerClassName="flex items-center justify-between mt-4 setting-switch"
            name={item.config_key as never}
            placeholder={t(item.user_friendly_name as never)}
            checkedValue="1"
            unCheckedValue="0"
          />
        );
      case CONTROL_TYPE.PasswordInput:
        return (
          <ICFormikTextInput
            {...formikProps}
            fieldName={item.config_key as never}
            placeholder={t(item.user_friendly_name as never)}
            type="password"
            maxLength={item.max_length ? item.max_length : undefined}
          />
        );
      case CONTROL_TYPE.TextArea:
        return (
          <ICFormikTextArea
            {...formikProps}
            name={item.config_key as never}
            placeholder={t(item.user_friendly_name as never)}
            maxLength={item.max_length ? item.max_length : undefined}
          />
        );
      case CONTROL_TYPE.Editor:
        return (
          <CustomEditor
            formikPorps={formikProps}
            fieldName={item.config_key as never}
            text={`${item.config_value}`}
            displayName={t(item.user_friendly_name as never)}
          />
        );
      case CONTROL_TYPE.ColorPiker:
        return (
          <ColorPicker
            formikProps={formikProps}
            fieldName={item.config_key as never}
            text={`${item.config_value}`}
            maxLength={item.max_length ? item.max_length : undefined}
            displayName={t(item.user_friendly_name as never)}
          />
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    setActiveConfigTab(systemSettings[0]?.tab_title || "");
  }, [systemSettings]);
  // Create initial values object from systemSettings
  const initialValues: Record<string, any> = {};
  systemSettings.forEach((item) => {
    initialValues[item.config_key] = item.config_value;
  });

  function filterAndGroupSettings(configTab: string) {
    // Filter systemSettings by config_tab
    const filteredSettings = systemSettings.filter(
      (item) => item.tab_title === configTab
    );

    // Group filtered settings by group_display_sequence and then sort by display_sequence
    const groupedSettings: Record<string, IConfigPayload[]> = {};
    filteredSettings.forEach((item) => {
      const groupDisplaySequence = parseFloat(item.group_display_sequence);
      const groupKey = `${groupDisplaySequence}_${item.group_title}`;
      if (!groupedSettings[groupKey]) {
        groupedSettings[groupKey] = [];
      }
      groupedSettings[groupKey].push(item);
    });

    // Sort groups by group_display_sequence
    const sortedGroups: [string, IConfigPayload[]][] = Object.entries(
      groupedSettings
    ).sort(([aKey], [bKey]) => {
      const aSequence = parseFloat(aKey.split("_")[0]);
      const bSequence = parseFloat(bKey.split("_")[0]);
      return aSequence - bSequence;
    });

    // Sort settings within each group by display_sequence
    sortedGroups.forEach(([groupKey, settings]) => {
      settings.sort(
        (a, b) =>
          parseFloat(a.display_sequence) - parseFloat(b.display_sequence)
      );
    });

    return sortedGroups;
  }

  return (
    <div className="mx-auto pt-5 mt-2 px-0 overflow-auto">
      <div className="w-[60rem] justify-center">
        <div className="w-full mx-auto">
          <div className="bg-skin-tab rounded-md p-5">
            <div className="setting-card-sec">
              <div className="w-full">
                <div className="relative right-0">
                  <div
                    className="relative flex flex-wrap list-none rounded-lg"
                    data-tabs="tabs"
                    role="list"
                  >
                    {uniqueTabs.map((tabTitle) => (
                      <div className="flex text-center" key={tabTitle}>
                        <div
                          className={`z-20 flex items-center justify-center w-full px-3 py-2 mb-0 transition-all ease-in-out rounded-t-lg cursor-pointer text-skin-tab-text ${
                            activeConfigTab === tabTitle
                              ? "bg-skin-active-tab select-card-sec"
                              : ""
                          }`}
                          data-tab-target=""
                          role="tab"
                          aria-selected={
                            activeConfigTab === tabTitle ? "true" : "false"
                          }
                          onClick={() => handleTabClick(tabTitle)}
                        >
                          <div className="ml-1">{t(`${tabTitle}`)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex setting-card-bottom">
              <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={async (values) => {
                  const payload: IConfigValuePayload[] = [];

                  // Iterate over the submitted values
                  for (const [key, value] of Object.entries(values)) {
                    // Find the corresponding systemSettings entry based on config_key and config_tab
                    const setting = systemSettings.find(
                      (item) => item.config_key === key
                    );

                    // If a matching setting is found, create the payload entry
                    if (setting) {
                      payload.push({
                        id: setting.id,
                        config_value: value,
                      });
                    }
                  }

                  await handleSubmitSystemConfiguration(payload);
                  await fetchSystemSettings(setSystemSettings, menuId);
                }}
              >
                {(formikProps) => {
                  const { handleSubmit, values } = formikProps;
                  return (
                    <Form onSubmit={handleSubmit} className="w-full">
                      <div className="w-full z-20 bg-skin-system">
                        {activeConfigTab &&
                          filterAndGroupSettings(activeConfigTab).length > 0 &&
                          filterAndGroupSettings(activeConfigTab).map(
                            ([groupKey, settings]) => (
                              <fieldset key={groupKey} className="pricing-sec">
                                {settings?.length > 0 && (
                                  <>
                                    <legend className="font-semibold">
                                      {settings[0].group_title}
                                    </legend>
                                    {settings.map((item) => (
                                      <div key={item.id}>
                                        <div>
                                          {renderControl(
                                            item,
                                            item.control_type,
                                            t,
                                            formikProps
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </fieldset>
                            )
                          )}
                      </div>

                      <ICButton className={"w-full"}>{t("submit")}</ICButton>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemSettings;
