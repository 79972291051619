import React, { useEffect, useState } from "react";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import ICTable from "../../core-component/ICTable";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  IBookingCancellationCharges,
  fetchBookingCancellationApiCall,
  getBookingCancellationFields,
  handleActiveInactiveBookingCancellation,
  handleDeleteBookingCancellation,
} from "./BookingCancellationViewController";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import usePagination from "../../hooks/UsePaginationHook";
import { IPagination } from "../../data/AppInterface";
import { ORDER_BY } from "../../utils/AppConstants";
import {
  addIcon,
  editIcon,
  eyeIcon,
  restoreIcon,
} from "../../assets/icons/SvgIconList";
import BookingCancellationForm from "./BookingCancellationForm";
import { TReactSetState } from "../../data/AppType";
import ICButton from "../../core-component/ICButton";
import { useNavigate } from "react-router-dom";
import ICSweetAlertModal from "../../core-component/ICSweetAlertModal";
import { routeAdmin, routeMaster } from "../../routes/RouteHome";

interface IBookingCancellationChargesProps {
  serviceId?: number;
  isRender?: boolean;
  setCurrentIndex?: TReactSetState<number>;
  productReadOnly?: boolean;
}

const BookingCancellationChargesView: React.FC<
  IBookingCancellationChargesProps
> = ({ serviceId, isRender, setCurrentIndex, productReadOnly }) => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const [bookingCancellationList, setBookingCancellationList] = useState<
    IBookingCancellationCharges[]
  >([]);
  const [bookingCancellationToEdit, setBookingCancellationToEdit] =
    useState<IBookingCancellationCharges>();
  const navigation = useNavigate();
  const { pagination, setPagination } = usePagination({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });

  useEffect(() => {
    fetchBookingCancellation(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [deleteAll]);

  const fetchBookingCancellation = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchBookingCancellationApiCall(
        setBookingCancellationList,
        setPagination,
        serviceId,
        payloadPagination,
        {
          deleteAll,
        }
      );
      setIsLoading(false);
    }, 500);
  };

  const handleDeleteCfm = async (id: number) => {
    const data = await handleDeleteBookingCancellation(id, t);
    if (data) {
      fetchBookingCancellation(pagination);
    }
  };
  const customActionView = (item: IBookingCancellationCharges) => {
    return (
      <div>
        {item.deleted === "1" ? (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              title={t("restore")}
              onClick={() => handleDeleteCfm(item.id)}
            >
              {restoreIcon}
            </div>
          </div>
        ) : (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              title={t("view_tooltip_icon")}
              onClick={() => {
                setShowForm(true);
                setBookingCancellationToEdit(item);
                setReadOnly(true);
              }}
            >
              {eyeIcon}
            </div>
            {!productReadOnly && (
              <>
                {" "}
                <div
                  className="mr-2 action-view "
                  title={t("edit_tooltip_icon")}
                  onClick={() => {
                    setShowForm(true);
                    setBookingCancellationToEdit(item);
                  }}
                >
                  {editIcon}
                </div>
                <div className="action-view " title={t("delete_tooltip_icon")}>
                  <ICSweetAlertModal
                    onConfirm={handleDeleteCfm}
                    itemId={item.id}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  };
  const allBookingCancellationList = bookingCancellationList.map((val) => ({
    ...val,
    IsDeleted: val.deleted === "1" ? true : false,
  }));

  const handleBookingCancellationStatus = async (
    values: IBookingCancellationCharges
  ) => {
    var isStatusChange = await handleActiveInactiveBookingCancellation(
      values.id,
      values.is_active === "1" ? "0" : "1"
    );
    if (isStatusChange) {
      const updatedBookingCancellationList = bookingCancellationList.map(
        (bookingCancellation) => {
          if (bookingCancellation.id === values.id) {
            return {
              ...bookingCancellation,
              is_active: values.is_active === "1" ? "0" : "1",
            };
          }
          return bookingCancellation;
        }
      );

      setBookingCancellationList(updatedBookingCancellationList);
    }
  };

  const handleCloseForm = (status: boolean) => {
    setShowForm(false);
    setReadOnly(false);
    if (bookingCancellationToEdit) {
      setBookingCancellationToEdit(undefined);
    }
    if (status) {
      fetchBookingCancellation(pagination);
    }
  };
  const handleNextButton = () => {
    if (setCurrentIndex) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };
  const handleSaveButton = () => {
    navigation("/" + routeAdmin.admin + "/" + routeMaster.serviceTable);
  };
  return (
    <div className="w-full overflow-auto scrollbar-remove">
      {isRender ? (
        !productReadOnly && (
          <div className="flex justify-end m-3">
            <ICButton type="button" onClick={() => setShowForm(true)}>
              <div className="flex gap-1 items-center">
                {addIcon}
                {t("add")}
              </div>
            </ICButton>
          </div>
        )
      ) : (
        <AdvanceSearch
          handleOnClickAdd={() => setShowForm(true)}
          handleDeleteCheckBox={(ans) => {
            setDeleteAll(ans);
          }}
          filterCheckBox={deleteAll}
          refreshTable={() => fetchBookingCancellation(pagination)}
        />
      )}
      <ICTable<IBookingCancellationCharges>
        isLoading={isLoading}
        totalFlex="grid grid-cols-15"
        fields={getBookingCancellationFields(
          handleBookingCancellationStatus,
          customActionView,
          t
        )}
        data={allBookingCancellationList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchBookingCancellation({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchBookingCancellation({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchBookingCancellation({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
      {showForm && (
        <BookingCancellationForm
          handleClose={handleCloseForm}
          bookingCancellationToEdit={bookingCancellationToEdit}
          readOnly={readOnly}
          serviceId={serviceId}
        />
      )}
      <div className="flex justify-end mr-3">
        <div className=" grid grid-cols-2 space-x-2 mt-3 ">
          {isRender && !productReadOnly && (
            <>
              <div onClick={handleSaveButton}>
                <ICButton className={"w-full  "}>{t("save_button")}</ICButton>
              </div>

              <div onClick={handleNextButton}>
                <ICButton className={"w-full"}>{t("next")}</ICButton>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingCancellationChargesView;
