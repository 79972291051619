import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import UsePaginationHook from "../../hooks/UsePaginationHook";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import {
  INITIAL_USER_LIST,
  IUser,
  fetchUsersApiCall,
  getUsersFields,
  handleActiveInactiveUSers,
} from "./UserListViewController";
import { IAdvanceSearch, IPagination } from "../../data/AppInterface";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import ICTable from "../../core-component/ICTable";
import {
  ORDER_BY,
  USER_ROLE_STATUS,
  USER_TYPE_STATUS,
} from "../../utils/AppConstants";
import { GENDER_LABEL, USER_GENDER_VALUES } from "../../utils/AppEnumerations";
import { maskEmail, maskMobileNumber } from "../../utils/AppFunctions";
import {
  eyeIcon,
  filterClearIcon,
  visibilityEyeIcon,
} from "../../assets/icons/SvgIconList";
import ICDropDown from "../../core-component/ICDropDown";
import ICButton from "../../core-component/ICButton";
import ICTextInput from "../../core-component/ICTextInput";

const UserListView = () => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [isLoading, setIsLoading] = useState(true);
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [usersList, setUsersList] = useState<IUser[]>([]);
  const [visibleMobileNumbers, setVisibleMobileNumbers] = useState<{
    [key: number]: boolean;
  }>({});
  const [advanceSearch, setAdvanceSearch] =
    useState<IAdvanceSearch>(INITIAL_USER_LIST);
  const { pagination, setPagination } = UsePaginationHook({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "id",
      orderBy: "DESC",
    },
  });
  const toggleMobileNumberVisibility = (id: number) => {
    setVisibleMobileNumbers((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  useEffect(() => {
    fetchUsers(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, advanceSearch]);

  const fetchUsers = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchUsersApiCall(setUsersList, setPagination, payloadPagination, {
        enterpriseSearch,
        advanceSearch,
      });
      setIsLoading(false);
    }, 500);
  };

  const handleUsersStatus = async (values: IUser) => {
    var isStatusChange = await handleActiveInactiveUSers(
      values.app_user_id,
      values.is_active === "1" ? "0" : "1"
    );
    if (isStatusChange) {
      const updatedUserList = usersList.map((user) => {
        if (user.id === values.id) {
          return {
            ...user,
            is_active: values.is_active === "1" ? "0" : "1",
          };
        }
        return user;
      });

      setUsersList(updatedUserList);
    }
  };
  const customComponentUserNameView = (item: IUser) => {
    return (
      <div className="grid grid-cols-6 ">
        <div className="col-span-2">
          {visibleMobileNumbers[item.id] ? (
            <div>{item.app_user.username}</div>
          ) : (
            <>
              {item.app_user.user_role === "Customer" ||
              item.app_user.user_role === "Trainer" ? (
                <div>{maskMobileNumber(item.app_user.username ?? "")}</div>
              ) : (
                <div>{maskEmail(item.app_user.username ?? "")}</div>
              )}
            </>
          )}
        </div>
        <div
          className="cursor-pointer w-5 h-5 col-span-4"
          onClick={() => toggleMobileNumberVisibility(item.id)}
        >
          {visibleMobileNumbers[item.id] ? eyeIcon : visibilityEyeIcon}
        </div>
      </div>
    );
  };

  const customComponentUSerRoleView = (item: IUser) => {
    return <div>{item.app_user.user_role}</div>;
  };
  const customComponentUSerGenderView = (item: IUser): JSX.Element => {
    switch (item.gender_id) {
      case USER_GENDER_VALUES.Male:
        return <div>{GENDER_LABEL.Male}</div>;
      case USER_GENDER_VALUES.Female:
        return <div>{GENDER_LABEL.Female}</div>;
      case USER_GENDER_VALUES.Diverse:
        return <div>{GENDER_LABEL.Diverse}</div>;
      default:
        return <div>{GENDER_LABEL.Unknown}</div>;
    }
  };

  const filterView = (
    <div className="w-full grid grid-cols-4 gap-4 mt-3 bg-skin-background">
      <ICTextInput
        label={t("user_id")}
        onChange={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            user_id: event.target.value,
          }))
        }
        value={advanceSearch.user_id}
      />
      <ICDropDown
        placeholder={t("gender")}
        options={USER_TYPE_STATUS}
        onSelect={(option) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            user_gender: option.value,
          }))
        }
        selected={advanceSearch.user_gender}
        required
      />
      <ICDropDown
        placeholder={t("user_role")}
        options={USER_ROLE_STATUS}
        onSelect={(option) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            user_role: option.value,
          }))
        }
        selected={advanceSearch.user_role}
        required
      />

      <ICButton
        onClick={() => setAdvanceSearch(INITIAL_USER_LIST)}
        className="flex"
      >
        <div className="w-5 h-5">{filterClearIcon}</div>&nbsp;{t("clear")}
      </ICButton>
    </div>
  );

  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        filterNode={filterView}
        handleSearchChange={(text) => {
          setEnterpriseSearch(text);
        }}
        searchText={enterpriseSearch}
        refreshTable={() => fetchUsers(pagination)}
      />
      <ICTable<IUser>
        isLoading={isLoading}
        totalFlex="grid grid-cols-14"
        fields={getUsersFields(
          customComponentUserNameView,
          customComponentUSerRoleView,
          customComponentUSerGenderView,
          handleUsersStatus,
          t
        )}
        data={usersList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchUsers({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchUsers({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchUsers({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
    </div>
  );
};

export default UserListView;
