import { serviceMaker } from "..";
import { IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

interface ISectionReviewStatusPayload {
  section: number;
  status: number;
  reason?: string | undefined;
}
interface ITrainerApplicationPayload {
  status: number;
  reason?: string;
  section_review_status?: ISectionReviewStatusPayload[];
}

export const GET_ALL_TRAINER_APPLICATIONS = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_trainer_applications.url +
      `?search=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&currentPage=${pagination.currentPage}&perPageRows=${
        pagination.perPageRows
      }&sortBy=${pagination.sortBy}&orderBy=${
        pagination.orderBy
      }&submitted_from_date=${
        search.advanceSearch?.submitted_from_date
      }&submitted_to_date=${search.advanceSearch?.submitted_to_date}&${
        search.advanceSearch?.status
          ? `status=${search.advanceSearch?.status}`
          : ""
      }`,
    API_ENDPOINTS.get_all_trainer_applications.method
  );
};
export const GET_TRAINER_APPLICATION_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_trainer_application_by_id.url + "/" + id,
    API_ENDPOINTS.get_trainer_application_by_id.method
  );
};

export const GET_ALL_REVIEW_LOGS = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_trainer_application_by_id.url + "/" + id + "/review-logs",
    API_ENDPOINTS.get_trainer_application_by_id.method
  );
};
export const UPDATE_TRAINER_APPLICATION_STATUS = (
  id: number,
  payload: ITrainerApplicationPayload
) => {
  return serviceMaker(
    API_ENDPOINTS.update_trainer_application_status.url + "/" + id + "/status",
    API_ENDPOINTS.update_trainer_application_status.method,
    payload
  );
};
