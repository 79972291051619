import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import {
  GET_FAQS_BY_ID,
  POST_FAQS,
  UPDATE_FAQS,
} from "../../services/faqs/FaqsServices";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import { IFaqs } from "./FaqsViewController";
import * as Yup from "yup";

export interface IFaqsForm
  extends Omit<
    IFaqs,
    "id" | "created_by" | "created_date" | "modified_by" | "modified_date"
  > {
  id: number | undefined;
}

export const faqsTypeDropDown = {
  dropDownOption: [
    { label: "Services", value: 1 },
    { label: "Application", value: 2 },
  ],
};

export const faqsValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    question: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("question")],
        ])
      )
      .max(
        500,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("question")],
          ["max", "500"],
        ])
      ),
    answer: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("answer")],
        ])
      )
      .max(
        4000,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("answer")],
          ["max", "4000"],
        ])
      ),
    faq_type: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("faq_type")],
      ])
    ),
    tags: Yup.array().of(
      Yup.string()
        .required(
          prepareMessageFromParams(t("error_message_required"), [
            ["fieldName", t("tags")],
          ])
        )
        .max(
          40,
          prepareMessageFromParams(t("error_message_max_length"), [
            ["fieldName", t("tags")],
            ["max", "40"],
          ])
        )
    ),
    reference_links: Yup.array().of(
      Yup.string().required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("reference_links")],
        ])
      )
    ),
  });

export const getFaqsInitialValues = (
  faqsToEdit: IFaqs | undefined
): IFaqsForm => {
  return {
    id: faqsToEdit?.id,
    faq_type: faqsToEdit?.faq_type || "",
    question: faqsToEdit?.question || "",
    answer: faqsToEdit?.answer || "",
    reference_links: faqsToEdit?.reference_links || [],
    tags: faqsToEdit?.tags || [],
    is_common_for_service: faqsToEdit?.is_common_for_service || "1",
    is_active: faqsToEdit?.is_active || "0",
  };
};

export const fetchFaqsById = async (
  id: number,
  setFaqsList: TReactSetState<IFaqsForm | undefined>,
  handleClose: (status: boolean) => void
) => {
  try {
    const faqsId = await GET_FAQS_BY_ID(id);
    if (faqsId && faqsId.code === DEFAULT_STATUS_CODE_SUCCESS) {
      setFaqsList({
        id: faqsId.data.id,
        faq_type: faqsId.data.faq_type,
        question: faqsId.data.question,
        answer: faqsId.data.answer,
        reference_links: faqsId.data.reference_links,
        tags: faqsId.data.tags,
        is_common_for_service: faqsId.data.is_common_for_service,
        is_active: faqsId.data.is_active,
      });
    } else {
      handleClose(false);
      SweetAlertError(faqsId.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(e?.faqsId?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleSubmitFaqs = async (
  values: IFaqsForm,
  id: number | undefined,
  handleClose: (status: boolean) => void,
  t: TUseTranslationTfn
) => {
  try {
    let resultFaqs;
    if (!id) {
      resultFaqs = await POST_FAQS(values);
    } else {
      resultFaqs = await UPDATE_FAQS(values, id);
    }
    if (resultFaqs && resultFaqs.code === DEFAULT_STATUS_CODE_SUCCESS) {
      id
        ? SweetAlertSuccess(
            prepareMessageFromParams(t("update_data_successfully"), [
              ["updatedName", t("faqs")],
            ])
          )
        : SweetAlertSuccess(
            prepareMessageFromParams(t("add_data_successfully"), [
              ["addName", t("faqs")],
            ])
          );
      return handleClose(true);
    } else {
      SweetAlertError(resultFaqs?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    SweetAlertError(e?.resultFaqs?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
