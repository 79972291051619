import { serviceMaker } from "..";
import { IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

export const GET_ALL_PAYMENT_TRANSACTION = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_payment_transaction.url +
      `?search=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&${
        search.advanceSearch?.transaction_type
          ? `tt=${search.advanceSearch?.transaction_type}`
          : ""
      }&${
        search.advanceSearch?.entity_type
          ? `et=${search.advanceSearch?.entity_type}`
          : ""
      }&${
        search.advanceSearch?.transaction_status
          ? `ts=${search.advanceSearch?.transaction_status}`
          : ""
      }&fd=${search.advanceSearch?.from_date}&td=${
        search.advanceSearch?.to_date
      }&currentPage=${pagination.currentPage}&perPageRows=${
        pagination.perPageRows
      }&sortBy=${pagination.sortBy}&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_payment_transaction.method
  );
};
