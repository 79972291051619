import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import ICTextInput from "../../../core-component/ICTextInput";
import ICButton from "../../../core-component/ICButton";
import {
  initialValuesConfirmPassword,
  createNewPassword,
  validationSchemaConfirm,
} from "./ResetPasswordControllers";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import { eyeIcon, visibilityEyeIcon } from "../../../assets/icons/SvgIconList";
import { calculatePasswordStrength } from "../../../utils/AppFunctions";

const ResetPassword = () => {
  const { t } = UseTranslationHook();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [passwordStrengthColor, setPasswordStrengthColor] = useState("");
  const navigation = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const validationSchema = validationSchemaConfirm(t);

  const formik = useFormik({
    initialValues: initialValuesConfirmPassword,
    validationSchema,
    onSubmit: (values) => {
      createNewPassword(values, navigation);
    },
  });

  const handleNewPasswordChange = (e: any) => {
    const { value } = e.target;
    formik.handleChange(e);
    const { strength, color } = calculatePasswordStrength(value);
    setPasswordStrength(strength);
    setPasswordStrengthColor(color);
  };

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 hidden md:flex md:col-span-6 lg:col-span-7 xl:col-span-8 h-screen w-full items-center justify-center">
        <img
          className="h-full"
          src={require("../../../assets/image/Yoga_img2.jpg")}
          alt={t("poster_image")}
        />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-5 xl:col-span-4 h-screen flex items-center justify-center bg-[#efefef85]">
        <div className="!w-full m-[50px]">
          <p className="font-bold text-3xl text-gray-700">
            {t("set_new_password")}
          </p>
          <form className="rounded py-8" onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <ICTextInput
                required
                containerClassName="p-2.5"
                label={t("old_password")}
                type={showOldPassword ? "text" : "password"}
                name="old_password"
                value={formik.values.old_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.old_password &&
                  formik.errors.old_password &&
                  formik.errors.old_password
                }
                trailing={
                  showOldPassword ? (
                    <div
                      className="w-5 h-5"
                      onClick={toggleOldPasswordVisibility}
                    >
                      {eyeIcon}
                    </div>
                  ) : (
                    <div
                      className="w-5 h-5"
                      onClick={toggleOldPasswordVisibility}
                    >
                      {visibilityEyeIcon}
                    </div>
                  )
                }
              />
            </div>
            <div className="mb-4">
              <ICTextInput
                required
                containerClassName="p-2.5"
                label={t("new_password")}
                type={showNewPassword ? "text" : "password"}
                name="new_password"
                value={formik.values.new_password}
                onChange={handleNewPasswordChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.new_password &&
                  formik.errors.new_password &&
                  formik.errors.new_password
                }
                trailing={
                  showNewPassword ? (
                    <div
                      className="w-5 h-5"
                      onClick={toggleNewPasswordVisibility}
                    >
                      {eyeIcon}
                    </div>
                  ) : (
                    <div
                      className="w-5 h-5"
                      onClick={toggleNewPasswordVisibility}
                    >
                      {visibilityEyeIcon}
                    </div>
                  )
                }
              />
              <div
                className="mt-2 text-sm"
                style={{ color: passwordStrengthColor }}
              >
                {t("password_strength")}: {passwordStrength}
              </div>
            </div>
            <div>
              <ICTextInput
                required
                label={t("confirm_password")}
                containerClassName="p-2.5"
                type={showPassword ? "text" : "password"}
                trailing={
                  showPassword ? (
                    <div className="w-5 h-5" onClick={togglePasswordVisibility}>
                      {eyeIcon}
                    </div>
                  ) : (
                    <div className="w-5 h-5" onClick={togglePasswordVisibility}>
                      {visibilityEyeIcon}
                    </div>
                  )
                }
                name="confirm_password"
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.confirm_password &&
                  formik.errors.confirm_password &&
                  formik.errors.confirm_password
                }
              />
            </div>
            <div className="items-center justify-center pt-4">
              <ICButton className="w-full" type="submit">
                {t("change_password")}
              </ICButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
