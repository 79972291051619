import { useEffect, useState } from "react";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import usePagination from "../../../hooks/UsePaginationHook";
import { PER_PAGE_ROWS } from "../../../config/AppConfig";
import { IPagination } from "../../../data/AppInterface";
import { ORDER_BY } from "../../../utils/AppConstants";
import AdvanceSearch from "../../../components/page-layouts/AdvanceSearch";
import ICTable from "../../../core-component/ICTable";
import {
  editIcon,
  copyIcon,
  eyeIcon,
  restoreIcon,
} from "../../../assets/icons/SvgIconList";
import { useNavigate } from "react-router-dom";
import {
  IServices,
  fetchServiceApiCall,
  getServiceTableFields,
  handleActiveInactiveService,
  handleDeleteService,
} from "./ProductServicesTableController";
import ICImageView from "../../../core-component/ic-image/ICImageView";
import ICSweetAlertModal from "../../../core-component/ICSweetAlertModal";
import {
  BIT_VALUE,
  PRODUCT_SERVICE_TAB_NAVIGATION,
} from "../../../utils/AppEnumerations";
import { routeAdmin, routeMaster } from "../../../routes/RouteHome";

const ProductServicesTable = () => {
  const navigate = useNavigate();
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [isLoading, setIsLoading] = useState(true);
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [deleteAll, setDeleteAll] = useState(false);
  const [serviceList, setServiceList] = useState<IServices[]>([]);
  const { pagination, setPagination } = usePagination({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "service_title",
      orderBy: "ASC",
    },
  });

  useEffect(() => {
    fetchService(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, deleteAll]);

  const fetchService = async (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchServiceApiCall(
        setServiceList,
        setPagination,
        payloadPagination,
        {
          enterpriseSearch,
          deleteAll,
        }
      );
      setIsLoading(false);
    }, 500);
  };
  const handleDeleteCfm = async (id: number) => {
    const data = await handleDeleteService(id, t);
    if (data) {
      fetchService(pagination);
    }
  };

  const handleServiceStatus = async (values: IServices) => {
    var isStatusChange = await handleActiveInactiveService(
      values.id,
      values.is_active === BIT_VALUE.One ? BIT_VALUE.Zero : BIT_VALUE.One
    );
    if (isStatusChange) {
      const updatedServiceList = serviceList.map((service) => {
        if (service.id === values.id) {
          return {
            ...service,
            is_active:
              values.is_active === BIT_VALUE.One
                ? BIT_VALUE.Zero
                : BIT_VALUE.One,
          };
        }
        return service;
      });

      setServiceList(updatedServiceList);
    }
  };

  const customActionView = (item: IServices) => {
    return (
      <div className="flex item-center justify-center">
        {item.deleted === "1" ? (
          <div
            className="mr-2 action-view"
            title={t("restore")}
            onClick={() => handleDeleteCfm(item.id)}
          >
            {restoreIcon}
          </div>
        ) : (
          <>
            <div
              className="mr-2 action-view"
              title={t("view_tooltip_icon")}
              onClick={() =>
                navigate(
                  "/" + routeAdmin.admin + "/" + routeMaster.addService,
                  {
                    state: {
                      productServiceId: item.id,
                      readOnly: true,
                      copyService: false,
                      productServiceTabNavigationState:
                        PRODUCT_SERVICE_TAB_NAVIGATION.BasicInfo,
                    },
                  }
                )
              }
            >
              {eyeIcon}
            </div>

            <div
              className="mr-2 action-view"
              title={t("edit_tooltip_icon")}
              onClick={() =>
                navigate(
                  "/" + routeAdmin.admin + "/" + routeMaster.addService,
                  {
                    state: {
                      productServiceId: item.id,
                      copyService: false,
                      readOnly: false,
                      productServiceTabNavigationState:
                        PRODUCT_SERVICE_TAB_NAVIGATION.BasicInfo,
                    },
                  }
                )
              }
            >
              {editIcon}
            </div>
            <div className="mr-2 action-view" title={t("delete_tooltip_icon")}>
              <ICSweetAlertModal onConfirm={handleDeleteCfm} itemId={item.id} />
            </div>
            <div
              className="mr-2 action-view "
              title={t("copy_tooltip_icon")}
              onClick={() =>
                navigate(
                  "/" + routeAdmin.admin + "/" + routeMaster.addService,
                  {
                    state: {
                      productServiceId: item.id,
                      copyService: true,
                      readOnly: false,
                      productServiceTabNavigationState:
                        PRODUCT_SERVICE_TAB_NAVIGATION.BasicInfo,
                    },
                  }
                )
              }
            >
              {copyIcon}
            </div>
          </>
        )}
      </div>
    );
  };

  const customServiceDuration: (item: IServices) => JSX.Element = (
    item: IServices
  ) => (
    <>{`${item.service_duration} ${
      item.service_duration > 1 ? t("days") : t("day")
    }`}</>
  );

  const customVisibility = (item: IServices) => {
    if (item.is_published === BIT_VALUE.Zero) {
      return <div>{t("draft")}</div>;
    } else {
      return <div>{t("published")}</div>;
    }
  };

  const customComponentImgView = (item: IServices) => {
    return (
      <div className="flex items-center">
        <div className="flex lg:justify-center justify-end">
          {item.app_media && item.app_media.length > 0 ? (
            <ICImageView
              url={`${item.app_media[0].media_url}`}
              className="h-6 w-6 rounded-full"
            />
          ) : (
            <img
              className="w-6 h-6 rounded-full"
              src={require("../../../assets/image/noImage.png")}
              alt={t("poster_image")}
            />
          )}
        </div>
        <div className="ml-1">{item.service_title}</div>
      </div>
    );
  };
  const allServiceList = serviceList.map((val) => ({
    ...val,
    IsDeleted: val.deleted === BIT_VALUE.One ? true : false,
  }));

  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <div>
        <AdvanceSearch
          handleOnClickAdd={() =>
            navigate("/" + routeAdmin.admin + "/" + routeMaster.addService, {
              state: {
                productServiceTabNavigationState:
                  PRODUCT_SERVICE_TAB_NAVIGATION.BasicInfo,
              },
            })
          }
          handleSearchChange={(text) => {
            setEnterpriseSearch(text);
          }}
          searchText={enterpriseSearch}
          handleDeleteCheckBox={(ans) => {
            setDeleteAll(ans);
          }}
          filterCheckBox={deleteAll}
          refreshTable={() => fetchService(pagination)}
        />
      </div>

      <div className="">
        <ICTable<IServices>
          isLoading={isLoading}
          totalFlex={`grid grid-cols-14`}
          fields={getServiceTableFields(
            handleServiceStatus,
            customActionView,
            customComponentImgView,
            customServiceDuration,
            customVisibility,
            t
          )}
          data={allServiceList}
          pagination={pagination}
          handleChangePerPageRows={(perPageRow) =>
            fetchService({
              ...pagination,
              perPageRows: perPageRow,
              currentPage: 1,
            })
          }
          handleChangePage={(page) =>
            fetchService({ ...pagination, currentPage: page })
          }
          handleSort={(field) =>
            fetchService({
              ...pagination,
              sortBy: field.key,
              orderBy:
                pagination.orderBy === ORDER_BY.desc
                  ? ORDER_BY.asc
                  : ORDER_BY.desc,
            })
          }
        />
      </div>
    </div>
  );
};

export default ProductServicesTable;
