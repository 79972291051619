import { serviceMaker } from "..";
import { IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

export const GET_ALL_NL_SUBSCRIPTION = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_newsletter_subscription.url +
      `?currentPage=${pagination.currentPage}&fromDate=${search.advanceSearch?.from_date}&toDate=${search.advanceSearch?.to_date}&perPageRows=${pagination.perPageRows}&sortBy=${pagination.sortBy}&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_newsletter_subscription.method
  );
};
