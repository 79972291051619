import CategoryWiseSessionView from "./report/CategoryWiseSessionView";
import CityWiseBookingRevenueView from "./report/CityWiseBookingRevenueView";
import CustomerSatisfactionView from "./report/CustomerSatisfactionView";
import MonthlyRevenueView from "./report/MonthlyRevenueView";
import MonthlySubscriptionView from "./report/MonthlySubscriptionView";
import MonthlyUserEngagementView from "./report/MonthlyUserEngagementView";
import RevenueReportView from "./report/RevenueReportView";
import SessionReportView from "./report/SessionReportView";
import TopServicesView from "./report/TopServicesView";
import TopTrainerView from "./report/TopTrainerView";
import VisitorImpressionView from "./report/VisitorImpressionView";

const DashboardView = () => {
  return (
    <div className="flex-1 mt-1 p-4 overflow-auto scrollbar-remove">
      <div className="grid grid-cols-1 xl:grid-cols-5 gap-4">
        <div className="col-span-1 xl:col-span-3">
          <RevenueReportView />
        </div>
        <div className="col-span-1 xl:col-span-2">
          <SessionReportView />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-6">
        <div className="col-span-1 chart-card p-4">
          <MonthlyRevenueView />
        </div>
        <div className="col-span-1 chart-card p-4">
          <MonthlySubscriptionView />
        </div>
        <div className="col-span-1 chart-card p-4">
          <CityWiseBookingRevenueView />
        </div>
        <div className="col-span-1 chart-card p-4">
          <VisitorImpressionView />
        </div>
        <div className="col-span-1 chart-card p-4">
          <CustomerSatisfactionView />
        </div>
        <div className="col-span-1 chart-card p-4">
          <MonthlyUserEngagementView />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
        <div className="col-span-1 chart-card p-4">
          <TopServicesView />
        </div>
        <div className="col-span-1 chart-card p-4">
          <TopTrainerView />
        </div>
        <div className="col-span-1 chart-card p-4">
          <CategoryWiseSessionView />
        </div>
      </div>
    </div>
  );
};

export default DashboardView;
