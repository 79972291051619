import {
  ITabelFieldViewProps,
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import {
  ACTIVE_INACTIVE_SYSTEM_HELP,
  DELETE_SYSTEM_HELP,
  GET_ALL_SYSTEM_HELP,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
  toastSuccess,
} from "../../utils/AppFunctions";

export interface ISystemHelp {
  id: number;
  topic: string;
  display_sequence: number;
  help_description: string;
  reference_links: [];
  tags: string[];
  is_active: string;
  help_video_url: string;
  help_category_id?: number;
  help_category?: {
    id: number;
    category_title: string;
    display_sequence: number;
    is_active: string;
    deleted: string;
  };
  target_audiance: string;
  deleted?: string;
}

export function getSystemHelpFields<T>(
  handleStatusChange: (item: T) => void,
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("topic"),
      key: "topic",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("help_description"),
      key: "help_description",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      limit: 20,
      title: "help_description",
    },
    {
      name: t("help_category"),
      key: "help_category",
      sort: false,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      format: ({ field, data }: ITabelFieldViewProps<T>) =>
        data["help_category"].category_title,
    },
    {
      name: t("tags"),
      key: "tags",
      sort: false,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
      format: ({ field, data }: ITabelFieldViewProps<T>) =>
        data[field.key] ? data[field.key].join(" , ") : [],
    },
    {
      name: t("status"),
      key: "is_active",
      sort: false,
      type: TABLE_FIELD_TYPE.switch,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      handleCheck: handleStatusChange,
      flex: "col-span-1",
    },
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },

    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}

export const fetchSystemHelpApiCall = async (
  setSystemHelpList: TReactSetState<ISystemHelp[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const systemHelpList = await GET_ALL_SYSTEM_HELP(pagination, search);
    if (systemHelpList && systemHelpList.code === DEFAULT_STATUS_CODE_SUCCESS) {
      if (systemHelpList.data && systemHelpList.data.item.length > 0) {
        setSystemHelpList(systemHelpList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(systemHelpList.data.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetSystemHelpList = await GET_ALL_SYSTEM_HELP(
          resetPagination,
          search
        );
        if (
          resetSystemHelpList &&
          resetSystemHelpList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setSystemHelpList(resetSystemHelpList.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              resetSystemHelpList.data.pagination
            ),
          });
        } else {
          setSystemHelpList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(systemHelpList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.systemHelpList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleDeleteSystemHelp = async (id: number) => {
  try {
    const systemHelpDelete = await DELETE_SYSTEM_HELP(id);
    if (
      systemHelpDelete &&
      systemHelpDelete.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(systemHelpDelete.message);
      return true;
    } else {
      SweetAlertError(
        systemHelpDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
    return false;
  } catch (e: any) {
    SweetAlertError(
      e?.systemHelpDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};

export const handleActiveInactiveFaqs = async (id: number, values: string) => {
  try {
    const systemHelpActiveInactive = await ACTIVE_INACTIVE_SYSTEM_HELP(id, {
      is_active: values,
    });
    if (
      systemHelpActiveInactive &&
      systemHelpActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      toastSuccess(systemHelpActiveInactive.message);
      return true;
    }
    return false;
  } catch (e: any) {
    toastError(
      e?.systemHelpActiveInactive?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};
