import React, { useState } from "react";
import AppRouter from "./routes/Router";
import { useSelector } from "react-redux";
import { RootState } from "./redux/Store";
import { useLocation } from "react-router-dom";

function App() {
  const theme = useSelector(
    (state: RootState) => state.preference.systemPreference.theme
  );

  return (
    <div className={theme}>
      <AppRouter />
    </div>
  );
}

export default App;
