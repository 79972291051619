/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import { routeResetPassword } from "../../../routes/RouteHome";
import { localStorageUtils } from "../../../utils/LocalStorageUtil";
import { fetchSystemSettingsByConfigKey } from "../../../components/system-settings/SystemSettingController";
import { IConfigPayload } from "../../../services/system-configurations/SystemConfigurationsService";
import { SYSTEM_CONFIGURATION_CONFIG_KEY } from "../../../utils/AppEnumerations";

const NavBar: React.FC = () => {
  const { t } = UseTranslationHook();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [pinbarShow, setPinBarShow] = useState(true);
  const [count, setCount] = useState(0);
  const profileRef = useRef<HTMLDivElement | null>(null);
  const [systemSettings, setSystemSettings] = useState<IConfigPayload>();

  const navigate = useNavigate();

  useEffect(() => {
    const handleOutsideClick = (event: Event) => {
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isDropdownOpen]);

  const myfunction1 = async () => {
    await new Promise((res_, _) => setTimeout(() => res_(null), 500));
    const pinnedUsersData = localStorage.getItem("pinnedData");

    if (pinnedUsersData) {
      setCount(parseInt(JSON.parse(pinnedUsersData).length));
    }
  };

  useEffect(() => {
    window.addEventListener("localStorageChange", myfunction1);

    const pinnedUsersData = localStorage.getItem("pinnedData");

    if (pinnedUsersData) {
      setCount(parseInt(JSON.parse(pinnedUsersData).length));
    }
  }, []);

  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen((prev) => !prev);
  };
  useEffect(() => {
    const handleProfileOutsideClick = (event: Event) => {
      if (
        isProfileDropdownOpen &&
        profileRef.current &&
        !profileRef.current.contains(event.target as Node)
      ) {
        setProfileDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleProfileOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleProfileOutsideClick);
    };
  }, [isProfileDropdownOpen]);

  const [isFocused, setIsFocused] = useState(false);

  const adminName = localStorageUtils.getProfileName();
  const adminProfile = localStorageUtils.getProfileUrl();

  useEffect(() => {
    fetchSystemSettingsByConfigKey(
      setSystemSettings,
      SYSTEM_CONFIGURATION_CONFIG_KEY.AdminVersion
    );
  }, []);

  return (
    <div className="z-10 bg-white border-y border-grey-500 top-0 w-full">
      <div className=" shadow-sm">
        <div className="flex justify-between px-4 xl:px-4 py-2 ">
          <div className="col-span-1 flex items-center justify-start gap-7 ">
            <div className="flex justify-start  flex-shrink-0 items-center">
              <img
                className="font-semibold text-4xl tracking-widest h-16"
                src={require("../../../assets/image/vp_logo_header.png")}
              />
            </div>
          </div>

          <div
            className={` flex justify-end items-center relative gap-2  ${
              isFocused ? " col-span-11 " : "col-span-3"
            }`}
          >
            <div className="relative">
              <div className="flex relative" ref={profileRef}>
                <button
                  type="button"
                  onClick={toggleProfileDropdown}
                  className="relative flex items-center justify-center cursor-pointer rounded-full text-sm focus:outline-none"
                  id="user-menu-button"
                >
                  <img
                    className="h-10 w-12 rounded-full border-1"
                    src={require("../../../assets/image/logo_vp.png")}
                    alt="Profile"
                  />
                </button>

                {isProfileDropdownOpen && (
                  <div className="relative z-30">
                    <div className="absolute z-10 -right-4 top-12 mt-2 w-52 text-sm font-medium bg-skin-profile border  rounded shadow-sm divide-y">
                      <div className="px-3 py-3 text-sm text-skin-profile-text flex">
                        <div>
                          <img
                            className="h-9 w-10 rounded-full border-1"
                            src={require("../../../assets/image/logo_vp.png")}
                            alt="Profile"
                          />
                        </div>
                        <div className="ps-2 flex items-center">
                          <div>{adminName ? adminName : t("vp_admin")}</div>
                        </div>
                      </div>

                      <div className="mt-2">
                        <div className="block px-3 py-2 text-sm cursor-pointer">
                          <div
                            onClick={() => {
                              navigate("/" + routeResetPassword.resetPassword);
                            }}
                            className="inline-block align-baseline text-sm text-skin-profile-text"
                          >
                            {t("change_password")}
                          </div>
                        </div>
                        <div
                          className="block px-3 py-2 text-sm text-skin-profile-text cursor-pointer"
                          onClick={() => {
                            localStorage.clear();
                            navigate("/log-in");
                          }}
                        >
                          {t("sign_out")}
                        </div>
                        {systemSettings && (
                          <div className="block px-3 py-2 text-sm text-skin-profile-text app-versions">
                            {t("version")}: {systemSettings.config_value}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      id="my-element"
                      className={
                        "absolute  z-10 right-4 top-11 w-5 h-5 bg-skin-profile transform rotate-45 origin-bottom top-arrow-border"
                      }
                    ></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
