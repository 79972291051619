import { useState } from "react";
import { IPagination } from "../data/AppInterface";

interface IUsePaginationProps {
  pagination?: Omit<IPagination, "totalItems" | "totalPages">;
}

const INITIAL_PAGINATION: IPagination = {
  perPageRows: 10,
  currentPage: 1,
  sortBy: "",
  orderBy: "DESC",
  totalPages: 0,
  totalItems: 0,
};

const UsePaginationHook = (props?: IUsePaginationProps) => {
  const [pagination, changePagination] = useState({
    ...INITIAL_PAGINATION,
    ...(props?.pagination ? props.pagination : {}),
  });

  const setPagination = (value: IPagination) => {
    changePagination(value);
  };
  return { pagination, setPagination };
};

export default UsePaginationHook;
