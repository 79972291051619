import { useEffect, useState } from "react";
import ICTable, { ITabelFieldViewProps } from "../../core-component/ICTable";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  premiumIcon,
  editIcon,
  eyeIcon,
  restoreIcon,
} from "../../assets/icons/SvgIconList";
import {
  ILibrary,
  SearchLibrary,
  fetchLibraryApiCall,
  getLibraryFields,
  handleActiveInactiveFile,
  handleActiveInactiveFolder,
  handleDeleteLibrary,
  handleDeleteLibraryFile,
} from "./LibraryViewController";
import LibraryHeder from "../../components/page-layouts/LibraryHeder";
import { IItem, ISearch } from "../../data/AppInterface";
import LibraryAddFolderForm from "./LibraryAddFolderForm";
import LibraryAddFileForm from "./LibraryAddFileForm";
import React from "react";
import ICCheckBox from "../../core-component/ICCheckBox";
import ICSweetAlertModal from "../../core-component/ICSweetAlertModal";
import {
  BIT_VALUE,
  LIBRARY_FILE_TYPE,
  LIBRARY_STATUS_VALUE,
} from "../../utils/AppEnumerations";
import ICImageView from "../../core-component/ic-image/ICImageView";

interface ILibraryProps {
  isRender?: boolean;
  courseSpecific?: boolean;
  selectedItems?: IItem[];
  setSelectedItems?: (item: IItem[]) => void;
}

const LibraryView: React.FC<ILibraryProps> = ({
  isRender = false,
  courseSpecific = true,
  setSelectedItems,
  selectedItems,
}) => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const defaultValue: number = 1;

  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showFileForm, setShowFileForm] = useState(false);
  const [libraryList, setLibraryList] = useState<ILibrary[]>([]);
  const [addLibraryToEdit, setAddLibraryToEdit] = useState<ILibrary>();
  const [readOnlyForm, setReadOnlyForm] = useState(false);
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [isPrivet, setIsPrivet] = useState<string>();
  const [isCourseSpecific, setIsCourseSpecific] = useState<string>();
  const [navigationPath, setNavigationPath] = useState<ILibrary[]>([]);
  const [addFolderAndFile, setAddFolderAndFile] =
    useState<number>(defaultValue);
  const [deleteAll, setDeleteAll] = useState(false);
  const searchCriteria: ISearch = { enterpriseSearch };
  const fetchLibrary = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchLibraryApiCall(
        addFolderAndFile,
        setLibraryList,
        courseSpecific,
        isRender,
        {
          deleteAll,
        }
      );
      if (enterpriseSearch.trim() !== "") {
        SearchLibrary(searchCriteria, setLibraryList);
      }
      setIsLoading(false);
    }, 500);
  };

  useEffect(() => {
    fetchLibrary();
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, deleteAll]);

  const handleLibraryStatus = async (values: ILibrary) => {
    if (values.file_type === LIBRARY_FILE_TYPE.Directory) {
      await handleActiveInactiveFolder(
        values.object_id,
        values.is_active === BIT_VALUE.One ? BIT_VALUE.Zero : BIT_VALUE.One,
        fetchLibrary,
        t,
        BIT_VALUE.Zero
      );
    } else {
      await handleActiveInactiveFile(
        values.object_id,
        values.is_active === BIT_VALUE.One ? BIT_VALUE.Zero : BIT_VALUE.One,
        fetchLibrary,
        t,
        BIT_VALUE.Zero
      );
    }
  };
  const handleDoubleClick = (
    objectId: number,
    objectName: string,
    isPrivet: string,
    CS: string
  ) => {
    return () => {
      setIsPrivet(isPrivet);
      setIsCourseSpecific(CS);
      fetchLibraryApiCall(objectId, setLibraryList, courseSpecific, isRender, {
        deleteAll,
      });
      setAddFolderAndFile(objectId);
      setNavigationPath((prevPath) => [
        ...prevPath,
        { object_id: objectId, title: objectName },
      ]);
    };
  };

  const handleLibraryFormat = (item: ITabelFieldViewProps<ILibrary>) => {
    if (
      item.data.file_type === LIBRARY_FILE_TYPE.Directory &&
      item.data.is_premium === "1"
    ) {
      return (
        <div
          className={`flex items-center ${courseSpecific && "cursor-pointer"}`}
          onDoubleClick={
            courseSpecific
              ? handleDoubleClick(
                  item.data.object_id,
                  item.data.title,
                  item.data.is_premium,
                  item.data.is_course_specific
                )
              : undefined
          }
        >
          <div className="flex lg:justify-center justify-end">
            {item.data.cover_image_path ? (
              <ICImageView
                url={item.data.cover_image_path}
                className="w-6 h-6 rounded-full"
                isPrivate
              />
            ) : (
              <img
                className="w-6 h-6 rounded-full"
                src={require("../../assets/image/Folder.jpg")}
                alt="posterImage"
              />
            )}
          </div>
          <div className="ml-1">{item.data.title}</div>
          <div className="ml-1">{premiumIcon}</div>
        </div>
      );
    } else if (item.data.file_type === LIBRARY_FILE_TYPE.Directory) {
      return (
        <div
          className={`flex items-center ${courseSpecific && "cursor-pointer"}`}
          onDoubleClick={
            courseSpecific
              ? handleDoubleClick(
                  item.data.object_id,
                  item.data.title,
                  item.data.is_premium,
                  item.data.is_course_specific
                )
              : undefined
          }
        >
          <div className="flex lg:justify-center justify-end">
            {item.data.cover_image_path ? (
              <ICImageView
                url={item.data.cover_image_path}
                className="w-6 h-6 rounded-full"
                isPrivate
              />
            ) : (
              <img
                className="w-6 h-6 rounded-full"
                src={require("../../assets/image/Folder.jpg")}
                alt="posterImage"
              />
            )}
          </div>
          <div className="ml-1">{item.data.title}</div>
        </div>
      );
    } else if (
      item.data.file_type === LIBRARY_FILE_TYPE.Pdf &&
      item.data.is_premium === "1"
    ) {
      return (
        <div className="flex items-center">
          <div className="flex lg:justify-center justify-end">
            <img
              className="w-6 h-6 rounded-full"
              src={require("../../assets/image/pdf.png")}
              alt="posterImage"
            />
          </div>
          <div className="ml-1">{item.data.title}</div>
          <div className="ml-1">{premiumIcon}</div>
        </div>
      );
    } else if (item.data.file_type === LIBRARY_FILE_TYPE.Pdf) {
      return (
        <div className="flex items-center">
          <div className="flex lg:justify-center justify-end">
            <img
              className="w-6 h-6 rounded-full"
              src={require("../../assets/image/pdf.png")}
              alt="posterImage"
            />
          </div>
          <div className="ml-1">{item.data.title}</div>
        </div>
      );
    } else if (
      item.data.file_type === LIBRARY_FILE_TYPE.Image &&
      item.data.is_premium === "1"
    ) {
      return (
        <div className="flex items-center">
          <div className="flex lg:justify-center justify-end">
            <ICImageView
              url={item.data.file_path}
              className="w-6 h-6 rounded-full"
              isPrivate
            />
          </div>
          <div className="ml-1">{item.data.title}</div>
          <div className="ml-1">{premiumIcon}</div>
        </div>
      );
    } else if (item.data.file_type === LIBRARY_FILE_TYPE.Image) {
      return (
        <div className="flex items-center">
          <div className="flex lg:justify-center justify-end">
            <ICImageView
              url={item.data.file_path}
              className="w-6 h-6 rounded-full"
              isPrivate
            />
          </div>
          <div className="ml-1">{item.data.title}</div>
        </div>
      );
    } else if (
      item.data.file_type === LIBRARY_FILE_TYPE.Video &&
      item.data.is_premium === "1"
    ) {
      return (
        <div className="flex items-center">
          <div className="flex lg:justify-center justify-end">
            <img
              className="w-6 h-6 rounded-full"
              src={require("../../assets/image/video1.png")}
              alt="posterImage"
            />
          </div>
          <div className="ml-1">{item.data.title}</div>
          <div className="ml-1">{premiumIcon}</div>
        </div>
      );
    } else if (item.data.file_type === LIBRARY_FILE_TYPE.Video) {
      return (
        <div className="flex items-center">
          <div className="flex lg:justify-center justify-end">
            <img
              className="w-6 h-6 rounded-full"
              src={require("../../assets/image/video1.png")}
              alt="posterImage"
            />
          </div>
          <div className="ml-1">{item.data.title}</div>
        </div>
      );
    } else if (
      item.data.file_type === LIBRARY_FILE_TYPE.Audio &&
      item.data.is_premium === "1"
    ) {
      return (
        <div className="flex items-center">
          <div className="flex lg:justify-center justify-end">
            <img
              className="w-6 h-6 rounded-full"
              src={require("../../assets/image/pngwingMp3.png")}
              alt="posterImage"
            />
          </div>
          <div className="ml-1">{item.data.title}</div>
          <div className="ml-1">{premiumIcon}</div>
        </div>
      );
    } else if (item.data.file_type === LIBRARY_FILE_TYPE.Audio) {
      return (
        <div className="flex items-center">
          <div className="flex lg:justify-center justify-end">
            <img
              className="w-6 h-6 rounded-full"
              src={require("../../assets/image/pngwingMp3.png")}
              alt="posterImage"
            />
          </div>
          <div className="ml-1">{item.data.title}</div>
        </div>
      );
    } else {
      return "" + <div className="ml-1">{item.data.title}</div>;
    }
  };

  const handleCloseForm = (status: boolean) => {
    setShowForm(false);
    setShowFileForm(false);
    setReadOnlyForm(false);
    if (addLibraryToEdit) {
      setAddLibraryToEdit(undefined);
    } else {
      setEnterpriseSearch("");
    }
    if (status) {
      fetchLibrary();
    }
  };

  const checkBoxComponent = (item: ILibrary) => {
    return (
      <div className="flex item-center justify-center ml-2">
        <div className="mr-2 action-view">
          <ICCheckBox
            id={item.object_id}
            type={`${item.file_type}`}
            title={item.title}
            selectedItems={selectedItems as IItem[]}
            setSelectedItems={setSelectedItems}
          />
        </div>
      </div>
    );
  };

  const handleDeleteCfm = async (id: number) => {
    let itemFolderFile = libraryList.find((item) => item.object_id === id);

    if (itemFolderFile?.file_type === LIBRARY_FILE_TYPE.Directory) {
      await handleDeleteLibrary(id, BIT_VALUE.Zero, t, fetchLibrary);
    } else {
      await handleDeleteLibraryFile(id, BIT_VALUE.Zero, t, fetchLibrary);
    }
  };
  const customActionView = (item: ILibrary) => {
    return (
      <div>
        {item.deleted === "1" ? (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              title={t("restore")}
              onClick={() => handleDeleteCfm(item.object_id)}
            >
              {restoreIcon}
            </div>
          </div>
        ) : (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              title={t("view_tooltip_icon")}
              onClick={() => {
                if (item.file_type === "DIRECTORY") {
                  setShowForm(true);
                  setAddLibraryToEdit(item);
                  setReadOnlyForm(true);
                } else {
                  setShowFileForm(true);
                  setAddLibraryToEdit(item);
                  setReadOnlyForm(true);
                }
              }}
            >
              {eyeIcon}
            </div>
            <div
              className="mr-2 action-view"
              title={t("edit_tooltip_icon")}
              onClick={() => {
                if (item.file_type === "DIRECTORY") {
                  setShowForm(true);
                  setAddLibraryToEdit(item);
                } else {
                  setShowFileForm(true);
                  setAddLibraryToEdit(item);
                }
              }}
            >
              {editIcon}
            </div>
            <div className="mr-2 action-view " title={t("delete_tooltip_icon")}>
              <ICSweetAlertModal
                onConfirm={handleDeleteCfm}
                itemId={item.object_id}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const breadCrumb = ({
    navigationPath,
    onNavigate,
  }: {
    navigationPath: ILibrary[];
    onNavigate: (index: number) => void;
  }) => {
    return (
      <div className="ml-3 flex items-center space-x-2">
        <div
          className={` ${
            navigationPath.length > 0
              ? "cursor-pointer text-skin-navigation-next-bar"
              : "text-skin-profile-text"
          }`}
          onClick={() => {
            onNavigate(0);
            setIsPrivet(undefined);
            setIsCourseSpecific(undefined);
          }}
        >
          {t("home")}
        </div>
        {navigationPath.map((folder, index) => (
          <div key={folder.object_id} className="flex items-center space-x-2">
            <div className="text-skin-navigation-arrow"> {" > "}</div>
            <div
              className={`${
                navigationPath.length > index + 1
                  ? " text-skin-navigation-next-bar cursor-pointer"
                  : "text-skin-profile-text"
              }`}
              onClick={() => onNavigate(index + 1)}
            >
              {folder.title}
            </div>
          </div>
        ))}
      </div>
    );
  };
  const handleNavigate = async (index: number) => {
    if (index >= 0 && index < navigationPath.length) {
      let objectId: number | null = null;
      if (index === 0) {
        objectId = 1;
      } else {
        objectId = navigationPath[index - 1].object_id;
      }
      await fetchLibraryApiCall(
        objectId,
        setLibraryList,
        courseSpecific,
        isRender,
        {
          deleteAll,
        }
      );
      setAddFolderAndFile(objectId);
      setNavigationPath((prevPath) => prevPath.slice(0, index));
    }
  };
  const allLibraryList = libraryList.map((val) => ({
    ...val,
    IsDeleted: val.deleted === "1" ? true : false,
  }));

  const handleRefresh = async () => {
    if (enterpriseSearch.trim() !== "") {
      await SearchLibrary(searchCriteria, setLibraryList);
    } else {
      await fetchLibraryApiCall(
        addFolderAndFile,
        setLibraryList,
        courseSpecific,
        isRender,
        {
          deleteAll,
        }
      );
    }
  };

  return (
    <div className={`w-full overflow-auto scrollbar-remove`}>
      {!isRender && (
        <LibraryHeder
          handleOnClickAdd={() => setShowForm(true)}
          handleOnClickAddFile={() => setShowFileForm(true)}
          handleSearchChange={(text) => {
            setEnterpriseSearch(text);
          }}
          searchText={enterpriseSearch}
          handleDeleteCheckBox={(ans) => {
            setDeleteAll(ans);
          }}
          filterCheckBox={deleteAll}
          refreshTable={handleRefresh}
        />
      )}
      {breadCrumb({ navigationPath, onNavigate: handleNavigate })}
      <ICTable<ILibrary>
        isRender={isRender}
        isLoading={isLoading}
        totalFlex="grid grid-cols-16"
        fields={getLibraryFields(
          handleLibraryFormat,
          handleLibraryStatus,
          customActionView,
          checkBoxComponent,
          isRender,
          t
        )}
        data={allLibraryList}
        handleChangePerPageRows={(perPageRow) => {}}
        handleChangePage={(page) => {}}
        handleSort={(field) => {}}
      />
      {!isRender && showForm && (
        <LibraryAddFolderForm
          isPremium={isPrivet}
          CS={isCourseSpecific}
          handleClose={handleCloseForm}
          addFolderToEdit={addLibraryToEdit}
          readOnly={readOnlyForm}
          libraryDirectory={addFolderAndFile ?? defaultValue}
        />
      )}
      {!isRender && showFileForm && (
        <LibraryAddFileForm
          isPremium={isPrivet}
          CS={isCourseSpecific}
          handleClose={handleCloseForm}
          addFileToEdit={addLibraryToEdit}
          readOnly={readOnlyForm}
          libraryDirectory={addFolderAndFile ?? defaultValue}
        />
      )}
    </div>
  );
};

export default LibraryView;
