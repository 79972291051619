import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  INITIAL_BOOKINGS_DETAILS,
  IBookingsDetails,
  fetchBookingDetailsApiCall,
  getBookingsDetailsFields,
} from "./BookingDetailsController";
import UsePaginationHook from "../../hooks/UsePaginationHook";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import { IAdvanceSearch, IPagination } from "../../data/AppInterface";
import {
  BOOKINGS_DETAILS_DATE_TYPE,
  BOOKINGS_DETAILS_STATUS,
  ORDER_BY,
} from "../../utils/AppConstants";
import ICTable from "../../core-component/ICTable";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import ICDatePicker from "../../core-component/ICDatePicker";
import { getLocalDate } from "../../utils/AppFunctions";
import ICDropDown from "../../core-component/ICDropDown";
import ICButton from "../../core-component/ICButton";
import { eyeIcon, filterClearIcon } from "../../assets/icons/SvgIconList";
import BookingView from "./BookingView";

const BookingsDetailsView = () => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [readOnlyForm, setReadOnlyForm] = useState(false);
  const [bookingDetailsToEdit, setBookingDetailsToEdit] = useState<
    IBookingsDetails | undefined
  >();
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [bookingDetailsList, setBookingDetailsList] = useState<
    IBookingsDetails[]
  >([]);
  const { pagination, setPagination } = UsePaginationHook({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });

  const [advanceSearch, setAdvanceSearch] = useState<IAdvanceSearch>(
    INITIAL_BOOKINGS_DETAILS
  );

  useEffect(() => {
    fetchBookingDetails(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, advanceSearch]);

  const handleCloseForm = (status: boolean) => {
    setShowForm(false);
    setReadOnlyForm(false);
    if (status) fetchBookingDetails(pagination);
  };

  const fetchBookingDetails = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchBookingDetailsApiCall(
        setBookingDetailsList,
        setPagination,
        payloadPagination,
        {
          enterpriseSearch,
          advanceSearch,
        }
      );
      setIsLoading(false);
    }, 500);
  };

  const filterView = (
    <div className="w-full grid grid-cols-4 gap-4 mt-3 bg-skin-background">
      <ICDropDown
        placeholder={t("date_type")}
        options={BOOKINGS_DETAILS_DATE_TYPE}
        onSelect={(option) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            date_type: option.value,
          }))
        }
        selected={advanceSearch.date_type}
        required
      />
      <ICDatePicker
        useValue
        label={t("from_date")}
        placeholder={t("from_date")}
        value={
          advanceSearch.from_date !== ""
            ? getLocalDate(advanceSearch.from_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            from_date: event.target.value,
          }))
        }
      />
      <ICDatePicker
        useValue
        label={t("to_date")}
        min={getLocalDate(advanceSearch.from_date)}
        placeholder={t("to_date")}
        value={
          advanceSearch.to_date !== ""
            ? getLocalDate(advanceSearch.to_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            to_date: event.target.value,
          }))
        }
      />

      <ICDropDown
        placeholder={t("booking_status")}
        options={BOOKINGS_DETAILS_STATUS}
        onSelect={(option) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            booking_status: option.value,
          }))
        }
        selected={advanceSearch.booking_status}
        required
      />

      <ICButton
        onClick={() => setAdvanceSearch(INITIAL_BOOKINGS_DETAILS)}
        className="flex"
      >
        <div className="w-5 h-5">{filterClearIcon}</div>&nbsp;{t("clear")}
      </ICButton>
    </div>
  );
  const customActionView = (item: IBookingsDetails) => (
    <div className="flex item-center justify-center ml-2">
      <div
        className="mr-2 action-view"
        title={t("view_tooltip_icon")}
        onClick={() => {
          setShowForm(true);
          setReadOnlyForm(true);
          setBookingDetailsToEdit(item);
        }}
      >
        {eyeIcon}
      </div>
    </div>
  );
  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        filterNode={filterView}
        handleSearchChange={(text) => {
          setEnterpriseSearch(text);
        }}
        searchText={enterpriseSearch}
        refreshTable={() => fetchBookingDetails(pagination)}
      />
      <ICTable<IBookingsDetails>
        isLoading={isLoading}
        totalFlex="grid grid-cols-16"
        fields={getBookingsDetailsFields(customActionView, t)}
        data={bookingDetailsList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchBookingDetails({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchBookingDetails({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchBookingDetails({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
      {showForm && (
        <BookingView
          handleClose={handleCloseForm}
          bookingDetailsToEdit={bookingDetailsToEdit}
          readOnly={readOnlyForm}
        />
      )}
    </div>
  );
};
export default BookingsDetailsView;
