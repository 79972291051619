import { serviceMaker } from "../../..";
import {
  IActiveInactive,
  IPagination,
  ISearch,
} from "../../../../data/AppInterface";
import { ICourseContentForm } from "../../../../screens/product-services/add-product-service/pages/course-content/CourseContentFormController";
import { API_ENDPOINTS } from "../../../../utils/ApiEndPoint";

export const GET_ALL_COURSE_CONTENT = (
  pagination: IPagination,
  search: ISearch,
  serviceId: number
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_course_content.url +
      `?sId=${serviceId}&&query=${search.enterpriseSearch}&currentPage=${pagination.currentPage}&perPageRows=${pagination.perPageRows}&sortBy=${pagination.sortBy}&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_course_content.method
  );
};

export const DELETE_COURSE_CONTENT_BY_ID = (id: number | undefined) => {
  return serviceMaker(
    API_ENDPOINTS.delete_course_content_by_id.url + `/${id}`,
    API_ENDPOINTS.delete_course_content_by_id.method
  );
};

export const POST_COURSE_CONTENT = (payload: ICourseContentForm) => {
  return serviceMaker(
    API_ENDPOINTS.post_course_content.url,
    API_ENDPOINTS.post_course_content.method,
    payload
  );
};

export const GET_COURSE_CONTENT_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_course_content.url + `/${id}`,
    API_ENDPOINTS.get_all_course_content.method
  );
};

export const UPDATE_COURSE_CONTENT = (
  payload: ICourseContentForm,
  id: number
) => {
  return serviceMaker(
    API_ENDPOINTS.update_course_content.url + `/${id}`,
    API_ENDPOINTS.update_course_content.method,
    payload
  );
};

export const ACTIVE_INACTIVE__COURSE_CONTENT = async (
  id: number,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_inactive_course_content.url + `/${id}`,
    API_ENDPOINTS.active_inactive_course_content.method,
    activeInactiveValue
  );
};
