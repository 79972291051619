import { useEffect, useState } from "react";
import {
  SweetAlertError,
  hasAnyModification,
} from "../../../../../utils/AppFunctions";
import { fetchUploadImageService } from "../../../../global/image-service/ImageServices";
import { Form, Formik } from "formik";
import FormRightModalWrapper from "../../../../../components/FormRightModalWrapper";
import ICFormikTextInput from "../../../../../components/formik-input/ICFormikTextInput";
import ICFileUpload from "../../../../../core-component/ICFileUpload";
import ICFormikSwitch from "../../../../../components/formik-input/ICFormikSwitch";
import ICButton from "../../../../../core-component/ICButton";
import ICModal from "../../../../../core-component/ICModal";
import { useLocation } from "react-router-dom";
import { IItem, IMediaItem } from "../../../../../data/AppInterface";
import {
  ICourseContentForm,
  courseContentValidationSchema,
  fetchCourseContentById,
  getCourseContentInitialValues,
  handleSubmitCourseContent,
} from "./CourseContentFormController";
import UseTranslationHook from "../../../../../hooks/UseTranslationHook";
import {
  IMAGE_TYPE,
  LIBRARY_CONTENT_TYPES,
} from "../../../../../utils/AppEnumerations";
import LibrarySelectionView from "../../../../library/LibrarySelectionView";
import { ICourseContent } from "./CourseContentViewController";
import { MESSAGE_UNKNOWN_ERROR_OCCURRED } from "../../../../../utils/AppConstants";

interface ICourseContentFormProps {
  handleClose: (status: boolean) => void;
  courseContentToEdit?: ICourseContent;
  readOnly: boolean;
}

const CourseContentFormView: React.FC<ICourseContentFormProps> = ({
  handleClose,
  courseContentToEdit,
  readOnly,
}) => {
  const { t } = UseTranslationHook();
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [courseContentFormInitialValues, setCourseContentFormInitialValues] =
    useState<ICourseContentForm>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [courseContentImagedata, setCourseContentImageData] = useState<
    string[]
  >([]);
  const [selectedItems, setSelectedItems] = useState<IItem[]>([]);
  const libraryContent: number[] = [];
  const libraryDirectory: number[] = [];
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  let serviceId: number = location?.state?.productServiceId;

  const checkFormModification = (values: ICourseContentForm) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      courseContentFormInitialValues &&
      hasAnyModification(values, courseContentFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };

  useEffect(() => {
    if (courseContentToEdit) {
      fetchCourseContentById(
        courseContentToEdit.id,
        setCourseContentFormInitialValues,
        setSelectedItems,
        handleClose
      );
    }
    setCourseContentFormInitialValues(getCourseContentInitialValues(serviceId));
  }, []);

  useEffect(() => {
    setCourseContentImageData(
      courseContentFormInitialValues?.content_image_url
        ? [courseContentFormInitialValues.content_image_url]
        : []
    );
  }, [courseContentFormInitialValues]);

  const handleSubmitCourseContentWithFileUpload = async (
    values: ICourseContentForm
  ) => {
    try {
      setLoading(true);
      const updatedCourseContentMedia: IMediaItem[] = courseContentImagedata
        ? courseContentImagedata.map((url) => ({
            content_image_url: url,
          }))
        : [];

      for (let i = 0; i < selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append(`myImageFile`, selectedFiles[i]);
        formData.append(`type`, IMAGE_TYPE.Services);
        formData.append(`typeId`, serviceId.toString());
        const mediaUrl = await fetchUploadImageService(formData);

        const newMediaItem: any = {
          content_image_url: mediaUrl,
        };

        updatedCourseContentMedia.push(newMediaItem);
      }

      selectedItems.forEach((item) => {
        if (item.type === LIBRARY_CONTENT_TYPES.Directory) {
          libraryDirectory.push(item.id);
        } else {
          libraryContent.push(item.id);
        }
      });

      await handleSubmitCourseContent(
        {
          ...values,
          content_image_url:
            updatedCourseContentMedia[0]?.content_image_url || "",
          library_content: libraryContent,
          library_directory: libraryDirectory,
        },
        courseContentToEdit?.id,
        handleClose,
        t
      );
    } catch (e) {
      SweetAlertError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {courseContentFormInitialValues && (
        <Formik
          initialValues={courseContentFormInitialValues}
          validationSchema={() => courseContentValidationSchema(t)}
          enableReinitialize
          onSubmit={(values) => handleSubmitCourseContentWithFileUpload(values)}
        >
          {(formikPorps) => {
            const { handleSubmit, values } = formikPorps;
            return (
              <FormRightModalWrapper
                onClose={() => checkFormModification(values)}
                title={t("course_content")}
              >
                <Form onSubmit={handleSubmit} className="flex flex-col h-full">
                  <div className="flex-1 overflow-y-auto pt-2.5">
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"content_title"}
                      placeholder={t("content_title")}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"content_desc"}
                      placeholder={t("content_desc")}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      type="number"
                      fieldName={"content_delivery_time"}
                      placeholder={t("content_delivery_time")}
                      disabled={readOnly}
                      required
                    />
                    <ICFileUpload
                      title={t("poster_image")}
                      maxFiles={1}
                      maxFileSizeMB={5000}
                      acceptedFileTypes={["jpg", "png", "jpeg", "mp4"]}
                      onFilesSelected={setSelectedFiles}
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      data={courseContentImagedata}
                      setData={setCourseContentImageData}
                      disabled={readOnly}
                    />
                    {values && (
                      <ICFormikSwitch
                        {...formikPorps}
                        containerClassName={
                          "flex items-center justify-between mt-4"
                        }
                        name={"is_active"}
                        placeholder={t("is_active")}
                        checkedValue={"1"}
                        unCheckedValue={"0"}
                        disabled={readOnly}
                        required
                      />
                    )}
                    <LibrarySelectionView
                      readOnly={readOnly}
                      courseSpecific={true}
                      sectionTitle="library_selection"
                      selectedItem={selectedItems}
                      setSelectedItem={setSelectedItems}
                    />
                  </div>
                  {!readOnly && (
                    <ICButton
                      disabled={loading ? true : false}
                      loading={loading}
                      className={"w-full"}
                    >
                      {t("submit")}
                    </ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}

      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};

export default CourseContentFormView;
