import React, { useState, useEffect } from "react";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { FormikProps } from "formik";

interface ICustomEditorProps {
  formikPorps: FormikProps<any>;
  fieldName: string;
  text: string;
  displayName?: string;
}

const createEditorState = (text: string): EditorState => {
  const contentState = stateFromHTML(text);
  return EditorState.createWithContent(contentState);
};

const CustomEditor: React.FC<ICustomEditorProps> = ({
  formikPorps,
  fieldName,
  text,
  displayName,
}) => {
  const [editorState, setEditorState] = useState<EditorState>(() =>
    createEditorState(text || "")
  );

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const html = stateToHTML(editorState.getCurrentContent());
    formikPorps.setFieldValue(fieldName, html);
  };

  return (
    <div className="editor-border">
      <div className="editor-bottom-border">
        <p className="ml-2 p-2 font-semibold">{displayName}</p>
      </div>
      <div className="p-3">
        <Editor
          editorState={editorState}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "list",
              "link",
              "image",
              "colorPicker",
            ],
          }}
          onEditorStateChange={onEditorStateChange}
        />
      </div>
    </div>
  );
};

export default CustomEditor;
