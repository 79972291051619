import React, { useEffect, useState } from "react";
import { ErrorMessage, FieldArray, Form, Formik, FormikProvider } from "formik";
import { deleteIcon } from "../../../../../assets/icons/SvgIconList";
import {
  additionalInfoValidationSchema,
  getServiceData,
  handleOnSubmitAdditionalInfo,
} from "./AdditionalInfoController";
import ICButton from "../../../../../core-component/ICButton";
import { useLocation, useNavigate } from "react-router-dom";
import { IAddService } from "../../../../../data/AppInterface";
import { TReactSetState } from "../../../../../data/AppType";
import ICTextInput from "../../../../../core-component/ICTextInput";
import UseTranslationHook from "../../../../../hooks/UseTranslationHook";

interface IAdditionalInfoProps {
  setCurrentIndex: TReactSetState<number>;
}

const AdditionalInfo: React.FC<IAdditionalInfoProps> = ({
  setCurrentIndex,
}) => {
  const { t } = UseTranslationHook();
  const [additionalInfoInitialValues, setAdditionalInfoInitialValues] =
    useState<IAddService | undefined>(undefined);
  const [getService, setGetService] = useState<IAddService[]>([]);
  const [buttonController, setButtonController] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const serviceId: number = location?.state?.productServiceId;
  const readOnly: boolean = location?.state?.readOnly;

  useEffect(() => {
    const fetchServiceData = async () => {
      const result = await getServiceData(serviceId, setGetService);
      if (result) {
        setAdditionalInfoInitialValues(result);
      }
    };
    fetchServiceData();
  }, []);
  const handleNextButton = () => {
    setButtonController(true);
  };
  const handleSaveButton = () => {
    setButtonController(false);
  };
  return (
    <div className="w-full p-4">
      {additionalInfoInitialValues && (
        <Formik
          initialValues={additionalInfoInitialValues}
          validationSchema={additionalInfoValidationSchema(t)}
          enableReinitialize
          onSubmit={(values) => {
            if (values.service_params && Array.isArray(values.service_params)) {
              const transformedValues: { [key: string]: string }[] = [];
              values.service_params.forEach(
                (item: { key: string; value: string }) => {
                  const obj: { [key: string]: string } = {};
                  obj[item.key] = item.value;
                  transformedValues.push(obj);
                }
              );
              handleOnSubmitAdditionalInfo(
                {
                  ...getService,
                  service_params: transformedValues as [],
                  service_precautions: values.service_precautions,
                  service_other_notes: values.service_other_notes,
                  service_pros: values.service_pros,
                  service_media: values.service_media,
                },
                serviceId,
                navigate,
                setCurrentIndex,
                buttonController,
                t
              );
            }
          }}
        >
          {(formikPorps) => {
            const { handleSubmit } = formikPorps;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1  xl:grid-cols-12 space-x-4">
                  <div className="grid col-span-6 space-y-4">
                    <div className="border rounded-md p-4">
                      <FormikProvider value={formikPorps}>
                        <div className="flex gap-4">
                          <FieldArray
                            name="service_params"
                            render={(arrayHelpers) => (
                              <div className="w-full">
                                {formikPorps?.values?.service_params?.map(
                                  (
                                    item: { key: string; value: string },
                                    index: number
                                  ) => (
                                    <div className="mb-4">
                                      <div
                                        key={index}
                                        className="flex gap-4 w-full"
                                      >
                                        <div className="w-1/2">
                                          <ICTextInput
                                            label={t("key")}
                                            name={`service_params[${index}].key`}
                                            value={item.key}
                                            onChange={(e) => {
                                              formikPorps.handleChange(e);
                                              arrayHelpers.replace(index, {
                                                ...item,
                                                key: e.target.value,
                                              });
                                            }}
                                            containerClassName="services-input"
                                            disabled={readOnly}
                                          />
                                        </div>
                                        <div className="w-1/2">
                                          <ICTextInput
                                            label={t("value")}
                                            name={`service_params[${index}].value`}
                                            value={item.value}
                                            onChange={(e) => {
                                              formikPorps.handleChange(e);
                                              arrayHelpers.replace(index, {
                                                ...item,
                                                value: e.target.value,
                                              });
                                            }}
                                            containerClassName="services-input"
                                            disabled={readOnly}
                                          />
                                        </div>
                                        {!readOnly && (
                                          <button
                                            disabled={
                                              !formikPorps.values.service_params
                                            }
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                            className="deleteButton action-view"
                                          >
                                            {deleteIcon}
                                          </button>
                                        )}
                                      </div>
                                      <ErrorMessage
                                        name={`service_params[${index}]`}
                                        component="div"
                                        className="typo-error "
                                      />
                                    </div>
                                  )
                                )}
                                {!readOnly && (
                                  <ICButton
                                    className="custom-button typo-btn-primary bg-skin-primary mb-4 w-full"
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.push({ key: "", value: "" })
                                    }
                                  >
                                    {t("add_service_parameters")}
                                  </ICButton>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </FormikProvider>
                    </div>
                    <div className="border rounded-md p-4">
                      <FormikProvider value={formikPorps}>
                        <div className="flex gap-4">
                          <FieldArray
                            name="service_pros"
                            render={(arrayHelpers) => (
                              <div className="w-full">
                                {formikPorps?.values?.service_pros?.map(
                                  (item, index) => (
                                    <div className="mb-4">
                                      <div key={index} className="flex gap-4">
                                        <div className="w-full">
                                          <ICTextInput
                                            label={t("service_pros")}
                                            name={`service_pros[${index}]`}
                                            value={
                                              formikPorps?.values
                                                ?.service_pros?.[index]
                                            }
                                            onChange={formikPorps.handleChange}
                                            disabled={readOnly}
                                            containerClassName="services-input"
                                          />
                                        </div>
                                        {!readOnly && (
                                          <button
                                            disabled={
                                              formikPorps.values.service_pros
                                                ?.length === 0
                                            }
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                            className="deleteButton action-view"
                                          >
                                            {deleteIcon}
                                          </button>
                                        )}
                                      </div>
                                      <ErrorMessage
                                        name={`service_pros[${index}]`}
                                        component="div"
                                        className="typo-error"
                                      />
                                    </div>
                                  )
                                )}
                                {!readOnly && (
                                  <ICButton
                                    className="custom-button typo-btn-primary bg-skin-primary mb-6 w-full"
                                    type="button"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    {t("add_service_pros")}
                                  </ICButton>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </FormikProvider>
                    </div>
                  </div>
                  <div className="grid col-span-6 space-y-4">
                    <div className="border rounded-md p-4">
                      <FormikProvider value={formikPorps}>
                        <div className="flex gap-4">
                          <FieldArray
                            name="service_other_notes"
                            render={(arrayHelpers) => (
                              <div className="w-full">
                                {formikPorps?.values?.service_other_notes?.map(
                                  (item, index) => (
                                    <div className="mb-6">
                                      <div key={index} className="flex gap-4 ">
                                        <div className="w-full">
                                          <ICTextInput
                                            label={t("service_other_notes")}
                                            name={`service_other_notes[${index}]`}
                                            value={
                                              formikPorps?.values
                                                ?.service_other_notes?.[index]
                                            }
                                            onChange={formikPorps.handleChange}
                                            disabled={readOnly}
                                            containerClassName="services-input"
                                          />
                                        </div>
                                        {!readOnly && (
                                          <button
                                            disabled={
                                              formikPorps.values
                                                .service_other_notes?.length ===
                                              0
                                            }
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                            className="deleteButton action-view"
                                          >
                                            {deleteIcon}
                                          </button>
                                        )}
                                      </div>
                                      <ErrorMessage
                                        name={`service_other_notes[${index}]`}
                                        component="div"
                                        className="typo-error"
                                      />
                                    </div>
                                  )
                                )}
                                {!readOnly && (
                                  <ICButton
                                    className="custom-button typo-btn-primary bg-skin-primary mb-4 w-full"
                                    type="button"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    {t("add_service_other_notes")}
                                  </ICButton>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </FormikProvider>
                    </div>
                    <div className="border rounded-md p-4">
                      <FormikProvider value={formikPorps}>
                        <div className="flex gap-4">
                          <FieldArray
                            name="service_precautions"
                            render={(arrayHelpers) => (
                              <div className="w-full">
                                {formikPorps?.values?.service_precautions?.map(
                                  (item, index) => (
                                    <div className="mb-6">
                                      <div key={index} className="flex gap-4 ">
                                        <div className="w-full">
                                          <ICTextInput
                                            label={t("service_precautions")}
                                            name={`service_precautions[${index}]`}
                                            value={
                                              formikPorps?.values
                                                ?.service_precautions?.[index]
                                            }
                                            onChange={formikPorps.handleChange}
                                            disabled={readOnly}
                                            containerClassName="services-input"
                                          />
                                        </div>
                                        {!readOnly && (
                                          <button
                                            disabled={
                                              formikPorps.values
                                                .service_precautions?.length ===
                                              0
                                            }
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                            className="deleteButton action-view"
                                          >
                                            {deleteIcon}
                                          </button>
                                        )}
                                      </div>
                                      <ErrorMessage
                                        name={`service_precautions[${index}]`}
                                        component="div"
                                        className="typo-error"
                                      />
                                    </div>
                                  )
                                )}
                                {!readOnly && (
                                  <ICButton
                                    className="custom-button typo-btn-primary bg-skin-primary  w-full"
                                    type="button"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    {t("add_service_precautions")}
                                  </ICButton>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </FormikProvider>
                    </div>
                    <div className=" grid grid-cols-2 space-x-4 mt-5 w-full">
                      {!readOnly && (
                        <>
                          <div onClick={handleSaveButton}>
                            <ICButton className={"w-full  "}>
                              {t("save_button")}
                            </ICButton>
                          </div>

                          <div onClick={handleNextButton}>
                            <ICButton className={"w-full"}>
                              {t("save_and_next_button")}
                            </ICButton>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default AdditionalInfo;
