import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import UsePaginationHook from "../../hooks/UsePaginationHook";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import { IAdvanceSearch, IPagination } from "../../data/AppInterface";
import {
  eyeIcon,
  filterClearIcon,
  lockIcon,
  timeLineIcon,
  viewApplicationIcon,
} from "../../assets/icons/SvgIconList";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import ICTable from "../../core-component/ICTable";
import {
  APPLICATION_STATUS_OPTION,
  APPLICATION_STATUS_OPTION_FOR_STATUS_LOCKED,
  ORDER_BY,
} from "../../utils/AppConstants";
import {
  fetchTrainerApplicationsApiCall,
  getTrainerApplicationFields,
  handleUpdateTrainerApplicationStatus,
  IDropdownOptions,
  INITIAL_TRAINER_APPLICATION_ADVANCE_SEARCH,
  ITrainerApplication,
} from "./TrainerApplicationController";
import TrainerApplicationFormView from "./TrainerApplicationFormView";
import ModalComponent from "../../core-component/ICModalComponent";
import ICButton from "../../core-component/ICButton";
import ICDropDown from "../../core-component/ICDropDown";
import ICDatePicker from "../../core-component/ICDatePicker";
import { getLocalDate } from "../../utils/AppFunctions";
import TrainerApplicationTimeLineView from "./TrainerApplicationTimeLineView";
import {
  BIT_VALUE,
  TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS,
  TRAINER_APPLICATION_STATUS,
} from "../../utils/AppEnumerations";

const TrainerApplicationView: React.FC = () => {
  const { t } = UseTranslationHook();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showTimeLine, setShowTimeLine] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number>();
  const [textareaValue, setTextareaValue] = useState<string>("");
  const [readOnlyForm, setReadOnlyForm] = useState<boolean>(false);
  const [enterpriseSearch, setEnterpriseSearch] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<number>(0);
  const [trainerApplicationsList, setTrainerApplicationsList] = useState<
    ITrainerApplication[]
  >([]);
  const [trainerApplicationToEdit, setTrainerApplicationToEdit] = useState<
    ITrainerApplication | undefined
  >();

  const [advanceSearch, setAdvanceSearch] = useState<IAdvanceSearch>(
    INITIAL_TRAINER_APPLICATION_ADVANCE_SEARCH
  );

  const { pagination, setPagination } = UsePaginationHook({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "modified_date",
      orderBy: "DESC",
    },
  });
  let timer: NodeJS.Timeout;

  useEffect(() => {
    fetchTrainerApplications(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, advanceSearch]);

  const fetchTrainerApplications = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchTrainerApplicationsApiCall(
        setTrainerApplicationsList,
        setPagination,
        payloadPagination,
        {
          enterpriseSearch,
          advanceSearch,
        }
      );
      setIsLoading(false);
    }, 500);
  };

  const handleSelectDropDown = (option: IDropdownOptions) => {
    setSelectedStatus(option.value);
  };

  const handleCloseForm = (status: boolean) => {
    setShowForm(false);
    setShowTimeLine(false);
    setReadOnlyForm(false);
    if (status) fetchTrainerApplications(pagination);
  };

  const handleModalButtonClick = () => {
    handleUpdateTrainerApplicationStatus(
      { status: selectedStatus, reason: textareaValue },
      itemId!,
      handleCloseForm
    );
    setTextareaValue("");
    setSelectedStatus(0);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setTextareaValue("");
    setShowModal(false);
  };
  const handleChangeStatus = (item: ITrainerApplication) => {
    if (
      item?.application_status ===
      TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Rejected
    ) {
      handleUpdateTrainerApplicationStatus(
        { status: TRAINER_APPLICATION_STATUS.ReviewInProgress },
        item.id
      ).then(() => {
        setShowForm(true);
        setTrainerApplicationToEdit(item);
      });
    } else {
      setShowForm(true);
      setTrainerApplicationToEdit(item);
    }
  };

  const customActionView = (item: ITrainerApplication) => (
    <div className="flex ">
      {item.application_status !== TRAINER_APPLICATION_STATUS.Locked ? (
        <div
          className="mr-2 action-view"
          title={t("view_tooltip_icon")}
          onClick={() => {
            setTrainerApplicationToEdit(item);
            setShowForm(true);
            setReadOnlyForm(true);
          }}
        >
          {eyeIcon}
        </div>
      ) : (
        <div
          className="mr-2 action-view"
          title={t("lock_tooltip_icon")}
          onClick={() => {
            setShowModal(true);
            setItemId(item.id);
          }}
        >
          {lockIcon}
        </div>
      )}

      {item.application_status !== TRAINER_APPLICATION_STATUS.Draft && (
        <div
          className="mr-2 action-view"
          title={t("history_tooltip_icon")}
          onClick={() => {
            setTrainerApplicationToEdit(item);
            setShowTimeLine(true);
            setReadOnlyForm(true);
          }}
        >
          {timeLineIcon}
        </div>
      )}

      {item.is_user_deleted !== BIT_VALUE.One &&
      (item.application_status ===
        TRAINER_APPLICATION_STATUS.SubmittedForReview ||
        item.application_status ===
          TRAINER_APPLICATION_STATUS.ReviewInProgress ||
        item.application_status ===
          TRAINER_APPLICATION_STATUS.ScheduledInterview ||
        item.application_status === TRAINER_APPLICATION_STATUS.Hold ||
        item.application_status ===
          TRAINER_APPLICATION_STATUS.PartialApproved) ? (
        <div
          className="mr-2 action-view"
          title={t("edit_tooltip_icon")}
          onClick={() => {
            handleChangeStatus(item);
          }}
        >
          {viewApplicationIcon}
        </div>
      ) : (
        ""
      )}
    </div>
  );

  const updatedList = trainerApplicationsList.map((val) => ({
    ...val,
    IsDeleted:
      val.application_status === TRAINER_APPLICATION_STATUS.Locked
        ? true
        : false,
  }));

  const filterView = (
    <div className="w-full grid grid-cols-4 gap-4 mt-3 bg-skin-background">
      <ICDatePicker
        useValue
        label={t("from_date")}
        placeholder={t("from_date")}
        value={
          advanceSearch.submitted_from_date !== ""
            ? getLocalDate(advanceSearch.submitted_from_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            submitted_from_date: event.target.value,
          }))
        }
      />
      <ICDatePicker
        useValue
        label={t("to_date")}
        placeholder={t("to_date")}
        value={
          advanceSearch.submitted_to_date !== ""
            ? getLocalDate(advanceSearch.submitted_to_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            submitted_to_date: event.target.value,
          }))
        }
      />

      <ICDropDown
        options={APPLICATION_STATUS_OPTION}
        onSelect={(option) =>
          setAdvanceSearch((prev) => ({ ...prev, status: option.value }))
        }
        selected={advanceSearch.status}
        required
      />
      <ICButton
        onClick={() =>
          setAdvanceSearch(INITIAL_TRAINER_APPLICATION_ADVANCE_SEARCH)
        }
        className="flex"
      >
        <div className="w-5 h-5">{filterClearIcon}</div>&nbsp;{t("clear")}
      </ICButton>
    </div>
  );
  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        filterNode={filterView}
        handleSearchChange={(text) => setEnterpriseSearch(text)}
        searchText={enterpriseSearch}
        refreshTable={() => fetchTrainerApplications(pagination)}
      />

      <ICTable<ITrainerApplication>
        isLoading={isLoading}
        totalFlex="grid grid-cols-30"
        fields={getTrainerApplicationFields(customActionView, t)}
        data={updatedList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchTrainerApplications({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchTrainerApplications({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchTrainerApplications({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
      {showForm && (
        <TrainerApplicationFormView
          readOnly={readOnlyForm}
          handleClose={handleCloseForm}
          trainerApplicationToEdit={trainerApplicationToEdit}
        />
      )}
      {showTimeLine && (
        <TrainerApplicationTimeLineView
          handleClose={handleCloseForm}
          trainerApplicationToEdit={trainerApplicationToEdit}
          readOnly={readOnlyForm}
        />
      )}
      {showModal && (
        <ModalComponent
          title={t("unlock_application")}
          content={
            <div className="p-5">
              <div className="my-3">
                <h1 className="typo-form-title">
                  {t("change_application_status")}
                </h1>
                <ICDropDown
                  options={APPLICATION_STATUS_OPTION_FOR_STATUS_LOCKED}
                  onSelect={handleSelectDropDown}
                  selected={selectedStatus}
                  className="mt-2"
                  required
                />
              </div>
              <h1 className="typo-form-title">{t("why_changing_status")}</h1>
              <div className="my-3 custom-text-area-container">
                <textarea
                  className="h-52 w-full custom-text-area typo-input-value"
                  value={textareaValue}
                  onChange={(event) => setTextareaValue(event.target.value)}
                />
              </div>

              <div className="flex justify-end">
                <ICButton
                  onClick={handleModalButtonClick}
                  className={`${
                    textareaValue.length < 1 || selectedStatus <= 0
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                  disabled={textareaValue.length < 1 || selectedStatus <= 0}
                >
                  {t("save")}
                </ICButton>
              </div>
            </div>
          }
          open={showModal}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default TrainerApplicationView;
