import { serviceMaker } from "..";
import { IMediaItemImage, IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

export interface IBlogPayload {
  id?: number;
  blog_title: string;
  blog_meta_title: string;
  blog_content: string;
  slug: string;
  published: string;
  publish_date?: Date;
  expiry_date?: Date;
  blog_categories: number[];
  hash_tags: string[];
  blogs_media: IMediaItemImage[];
  post_anonymously: string;
  is_active: string;
  cover_image: string;
}

export const GET_ALL_BLOGS = (pagination: IPagination, search: ISearch) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_blog.url +
      `?query=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&sd=${search.deleteAll}&currentPage=${
        pagination.currentPage
      }&perPageRows=${pagination.perPageRows}&sortBy=${
        pagination.sortBy
      }&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_blog.method
  );
};
export const GET_BLOGS_CATEGORY_DDL = () => {
  return serviceMaker(
    API_ENDPOINTS.get_blogs_category.url + `?&PageSize=-1`,
    API_ENDPOINTS.get_blogs_category.method
  );
};

export const CREATE_BLOG = (payload: IBlogPayload) => {
  return serviceMaker(
    API_ENDPOINTS.create_blog.url,
    API_ENDPOINTS.create_blog.method,
    payload
  );
};
export const GET_BLOG_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_blog.url + `/${id}`,
    API_ENDPOINTS.get_all_blog.method
  );
};

export const UPDATE_BLOG = (payload: IBlogPayload, id: number) => {
  return serviceMaker(
    API_ENDPOINTS.update_blog.url + `/${id}`,
    API_ENDPOINTS.update_blog.method,
    payload
  );
};

export const DELETE_BLOG = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.delete_blog.url + `/${id}`,
    API_ENDPOINTS.delete_blog.method
  );
};
