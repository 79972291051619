import { FormikProps } from "formik";
import ICDropDown from "../../core-component/ICDropDown";
import { getFormikErrorMessage } from "../../utils/AppFunctions";

interface IOption {
  label: string;
  value: string | number;
}

interface ICustomFormikDropDown<T> extends FormikProps<T> {
  name: keyof T;
  placeholder: string;
  options: IOption[];
  containerFormikClassName?: string;
  searchable?: boolean;
  disabled?: boolean;
  defaultOption?: IOption;
  required?: boolean;
  onChange?: any;
}

const ICFormikDropDown = <T extends Object>(
  props: ICustomFormikDropDown<T>
) => {
  const { touched, errors, values, setFieldValue, onChange } = props;
  const {
    name,
    placeholder,
    containerFormikClassName,
    options,
    searchable,
    disabled,
    defaultOption,
    required,
  } = props;
  const fieldName = props.name as string;

  const handleSelect = (option: IOption) => {
    setFieldValue(fieldName, option.value);

    if (onChange) {
      onChange(option.value);
    }
  };

  return (
    <div className={`mb-6 ${containerFormikClassName || ""}`}>
      <ICDropDown<string | number>
        options={options}
        selected={values[name] as string}
        onSelect={handleSelect}
        searchable={searchable}
        disabled={disabled}
        label={placeholder}
        errorMessage={
          touched[name] && errors[name]
            ? getFormikErrorMessage<T>(errors, touched, name)
            : undefined
        }
        defaultOption={defaultOption}
        required={required}
      />
    </div>
  );
};

export default ICFormikDropDown;
