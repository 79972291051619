import {
  ITabelFieldViewProps,
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import { APPROVE_INQUIRY, GET_ALL_INQUIRY } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  decryptData,
  generatePaginationFromApiRes,
  maskEmail,
  maskMobileNumber,
  resetPaginationWithPpr,
  toastError,
} from "../../utils/AppFunctions";

export interface IInquiry {
  id: number;
  first_name: string;
  last_name: string;
  contact_email_address: string;
  contact_number: string;
  inquiry_date: Date;
  inquiry_subject: string;
  addressed_date?: Date;
  addressed_by?: string;
}

export function getInquiryFields<T>(
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("inquiry_subject"),
      key: "inquiry_subject",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("first_name"),
      key: "first_name",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      limit: 25,
    },
    {
      name: t("last_name"),
      key: "last_name",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("contact_email_address"),
      key: "contact_email_address",
      sort: true,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      format: (item: ITabelFieldViewProps<T>) =>
        maskEmail(item.data["contact_email_address"]),
    },
    {
      name: t("contact_number"),
      key: "contact_number",
      sort: true,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      format: (item: ITabelFieldViewProps<T>) =>
        item.data["contact_number"]
          ? maskMobileNumber(item.data["contact_number"])
          : "-",
    },

    {
      name: t("inquiry_date"),
      key: "inquiry_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}
export const INITIAL_INQUIRY = {
  to_date: "",
  from_date: "",
};
export const fetchInquiryApiCall = async (
  setInquiryList: TReactSetState<IInquiry[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const inquiryList = await GET_ALL_INQUIRY(pagination, search);
    if (inquiryList && inquiryList.code === DEFAULT_STATUS_CODE_SUCCESS) {
      const inquiryListData = JSON.parse(decryptData(inquiryList.data));
      if (inquiryListData && inquiryListData.item.length > 0) {
        setInquiryList(inquiryListData.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(inquiryListData.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetGrievancesList = await GET_ALL_INQUIRY(
          resetPagination,
          search
        );
        const resetInquiryListData = JSON.parse(decryptData(inquiryList.data));
        if (
          resetGrievancesList &&
          resetGrievancesList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setInquiryList(resetInquiryListData.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(resetInquiryListData.pagination),
          });
        } else {
          setInquiryList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(inquiryList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.inquiryList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleCompletedInquiry = async (id: number) => {
  try {
    const completedInquiry = await APPROVE_INQUIRY(id);
    if (
      completedInquiry &&
      completedInquiry.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(completedInquiry.message);
      return true;
    } else {
      SweetAlertError(
        completedInquiry.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
    return false;
  } catch (e: any) {
    SweetAlertError(
      e?.completedInquiry?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};
