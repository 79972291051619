import { useEffect, useState } from "react";
import ICTable from "../../core-component/ICTable";
import {
  IEvents,
  fetchEventApiCall,
  getEventsFields,
  handleActiveInactiveEvent,
  handleDeleteEvent,
} from "./EventsViewController";
import { editIcon, eyeIcon, restoreIcon } from "../../assets/icons/SvgIconList";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";

import EventsForm from "./EventForm";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import { IPagination } from "../../data/AppInterface";
import { ORDER_BY } from "../../utils/AppConstants";
import UsePaginationHook from "../../hooks/UsePaginationHook";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import ICSweetAlertModal from "../../core-component/ICSweetAlertModal";

const EventsView = () => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [eventsList, setEventsList] = useState<IEvents[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [readOnlyForm, setReadOnlyForm] = useState(false);
  const [eventsToEdit, setEventsToEdit] = useState<IEvents>();
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [deleteAll, setDeleteAll] = useState(false);
  const { pagination, setPagination } = UsePaginationHook({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "title",
      orderBy: "ASC",
    },
  });
  useEffect(() => {
    fetchEvents(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, deleteAll]);
  const fetchEvents = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchEventApiCall(setEventsList, setPagination, payloadPagination, {
        enterpriseSearch,
        deleteAll,
      });
      setIsLoading(false);
    }, 500);
  };
  const handleEventsStatus = async (values: IEvents) => {
    var isStatusChange = await handleActiveInactiveEvent(
      values.id,
      values.is_active === "1" ? "0" : "1"
    );
    if (isStatusChange) {
      const updatedEventList = eventsList.map((event) => {
        if (event.id === values.id) {
          return {
            ...event,
            is_active: values.is_active === "1" ? "0" : "1",
          };
        }
        return event;
      });

      setEventsList(updatedEventList);
    }
  };

  const handleDeleteCfm = async (id: number) => {
    const data = await handleDeleteEvent(id, t);
    if (data) {
      fetchEvents(pagination);
    }
  };

  const customActionView = (item: IEvents) => {
    return (
      <div>
        {item.deleted === "1" ? (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              title={t("restore")}
              onClick={() => handleDeleteCfm(item.id)}
            >
              {restoreIcon}
            </div>
          </div>
        ) : (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              title={t("view_tooltip_icon")}
              onClick={() => {
                setShowForm(true);
                setEventsToEdit(item);
                setReadOnlyForm(true);
              }}
            >
              {eyeIcon}
            </div>

            <div
              className="mr-2 action-view "
              title={t("edit_tooltip_icon")}
              onClick={() => {
                setShowForm(true);
                setEventsToEdit(item);
              }}
            >
              {editIcon}
            </div>
            <div className="action-view mr-2 " title={t("delete_tooltip_icon")}>
              <ICSweetAlertModal onConfirm={handleDeleteCfm} itemId={item.id} />
            </div>
          </div>
        )}
      </div>
    );
  };
  const allEventsList = eventsList.map((val) => ({
    ...val,
    IsDeleted: val.deleted === "1" ? true : false,
  }));

  const handleCloseForm = (status: boolean) => {
    setShowForm(false);
    setReadOnlyForm(false);
    if (eventsToEdit) {
      setEventsToEdit(undefined);
    } else {
      setEnterpriseSearch("");
    }
    if (status) {
      fetchEvents(pagination);
    }
  };
  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        handleOnClickAdd={() => setShowForm(true)}
        handleSearchChange={(text) => {
          setEnterpriseSearch(text);
        }}
        searchText={enterpriseSearch}
        handleDeleteCheckBox={(ans) => {
          setDeleteAll(ans);
        }}
        filterCheckBox={deleteAll}
        refreshTable={() => fetchEvents(pagination)}
      />
      <ICTable<IEvents>
        isLoading={isLoading}
        totalFlex="grid grid-cols-22"
        fields={getEventsFields(handleEventsStatus, customActionView, t)}
        data={allEventsList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) => {
          fetchEvents({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          });
        }}
        handleChangePage={(page) => {
          fetchEvents({ ...pagination, currentPage: page });
        }}
        handleSort={(field) => {
          fetchEvents({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          });
        }}
      />
      {showForm && (
        <EventsForm
          handleClose={handleCloseForm}
          eventsToEdit={eventsToEdit}
          readOnly={readOnlyForm}
        />
      )}
    </div>
  );
};
export default EventsView;
