import React, { useState } from "react";
import { TReactSetState } from "../../data/AppType";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { cancelIcon } from "../../assets/icons/SvgIconList";
import ICButton from "../../core-component/ICButton";
import ModalComponent from "../../core-component/ICModalComponent";
import LibraryView from "./LibraryView";
import { ILibraryItems } from "../../data/AppInterface";

interface ILibrarySelection {
  readOnly: boolean;
  courseSpecific: boolean;
  sectionTitle: string;
  selectedItem: ILibraryItems[];
  setSelectedItem: TReactSetState<ILibraryItems[]>;
}

const LibrarySelectionView: React.FC<ILibrarySelection> = ({
  readOnly,
  courseSpecific,
  sectionTitle,
  selectedItem,
  setSelectedItem,
}) => {
  const { t } = UseTranslationHook();
  const [showLibrary, setShowLibrary] = useState<boolean>(false);

  const handleRemoveLibraryList = (type: string, id: number) => () => {
    let arr = selectedItem.filter(
      (element) => element.type !== type || element.id !== id
    );
    setSelectedItem(arr);
  };
  return (
    <div className="space-y-5">
      <div className="typo-input-label">{t(sectionTitle)}</div>
      <div className="w-full flex flex-wrap">
        {selectedItem &&
          selectedItem.length > 0 &&
          selectedItem.map((value) => (
            <ul>
              <li className="inline-flex items-center bg-skin-course-content-tag text-skin-course-content-selected text-sm font-medium rounded-full px-3 py-1 mr-2 mb-2">
                {value.title}
                {!readOnly && (
                  <span
                    className="ml-2"
                    onClick={handleRemoveLibraryList(value.type, value.id)}
                  >
                    {cancelIcon}
                  </span>
                )}
              </li>
            </ul>
          ))}
      </div>
      <div>
        {!readOnly && (
          <ICButton
            className={"w-full"}
            type="button"
            onClick={() => setShowLibrary(true)}
          >
            {t("select_from_library")}
          </ICButton>
        )}
      </div>
      {showLibrary && (
        <ModalComponent
          title={t("library_title")}
          customClass="w-full"
          content={
            <LibraryView
              isRender={showLibrary}
              courseSpecific={courseSpecific}
              selectedItems={selectedItem}
              setSelectedItems={setSelectedItem}
            />
          }
          open={showLibrary}
          onClose={() => setShowLibrary(false)}
        />
      )}
    </div>
  );
};

export default LibrarySelectionView;
