import React, { InputHTMLAttributes, useEffect, useState } from "react";

export interface ICustomInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  leading?: JSX.Element | string;
  trailing?: JSX.Element | string;
  error?: boolean;
  errorMessage?: any;
  label?: string;
  onChangeValue?: (value: string) => void;
  type?: string;
}

const ICTextInputValue: React.FC<ICustomInputProps> = ({
  leading,
  trailing,
  error,
  errorMessage,
  label,
  onChangeValue,
  required,
  value,
  type, // Add value prop
  ...inputProps
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  // Update local state when the value prop changes
  useEffect(() => {
    setInputValue(String(value || "")); // Convert value to string explicitly
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    if (onChangeValue) {
      onChangeValue(value); // Pass the updated value back to the parent
    }
  };

  return (
    <div className="floating-input">
      <div
        className={`custom-text-input-container h-input ${
          errorMessage ? "error-border" : ""
        }`}
      >
        {leading && <div className="text-input-leading ">{leading}</div>}
        <div className=" flex w-full ">
          <input
            className=" custom-text-input typo-input-placeholder typo-input-value"
            type={type ? type : "text"}
            {...inputProps}
            value={inputValue}
            onChange={handleChange} // Handle onChange event
            placeholder=""
          />
          {label && (
            <label className=" pl-1 typo-floating-label ">
              {label + (required ? "*" : "")}
            </label>
          )}
        </div>
        {trailing && <div className="text-input-trailing">{trailing}</div>}
      </div>
      {errorMessage && <div className="typo-error">{errorMessage}</div>}
    </div>
  );
};

export default ICTextInputValue;
