import React, { useEffect, useState } from "react";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { ORDER_BY } from "../../utils/AppConstants";
import UsePaginationHook from "../../hooks/UsePaginationHook";
import { TReactSetState } from "../../data/AppType";
import ICTable from "../../core-component/ICTable";
import { IPagination } from "../../data/AppInterface";
import {
  fetchPaymentHistoryDetailApiCall,
  getPaymentHistoryDetailFields,
  IPaymentHistoryDetailList,
  IPaymentHistoryHeaderDetail,
} from "./PaymentHistoryDetailsController";

interface IPaymentHistoryDetailProps {
  setShowForm: TReactSetState<boolean>;
  paymentDetailId: number;
}
const PaymentHistoryDetail: React.FC<IPaymentHistoryDetailProps> = ({
  setShowForm,
  paymentDetailId,
}) => {
  const { t } = UseTranslationHook();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentHistoryDetailHeader, setPaymentHistoryDetailHeader] =
    useState<IPaymentHistoryHeaderDetail | null>(null);
  const [paymentHistoryDetailList, setPaymentHistoryDetailList] = useState<
    IPaymentHistoryDetailList[]
  >([]);
  const { pagination, setPagination } = UsePaginationHook({
    pagination: {
      perPageRows: 50,
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });
  let timer: NodeJS.Timeout;

  useEffect(() => {
    if (paymentDetailId) {
      fetchPaymentHistoryDetails(pagination);
    } else {
      setShowForm(false);
    }
  }, []);

  const fetchPaymentHistoryDetails = (payloadPagination: IPagination) => {
    setIsLoading(true);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (paymentDetailId)
        await fetchPaymentHistoryDetailApiCall(
          setPaymentHistoryDetailHeader,
          setPaymentHistoryDetailList,
          setPagination,
          payloadPagination,
          paymentDetailId
        );
      setIsLoading(false);
    }, 500);
  };

  return (
    <div>
      <FormRightModalWrapper
        containerClassName="!w-[100%]"
        onClose={() => setShowForm(false)}
        title={t("payment_history")}
      >
        <div>
          <div className="w-full flex justify-between border p-2 bg-skin-disabled">
            <div className="flex space-x-1">
              <div>{t("trainer_name")}:</div>
              <div>{paymentHistoryDetailHeader?.trainer_name}</div>
            </div>
            <div className="flex space-x-1">
              <div>{t("total_amount")}:</div>
              <div>{paymentHistoryDetailHeader?.amount_paid}</div>
            </div>
          </div>
          <div>
            <ICTable<IPaymentHistoryDetailList>
              isLoading={isLoading}
              totalFlex="grid grid-cols-20"
              fields={getPaymentHistoryDetailFields(t)}
              data={paymentHistoryDetailList}
              pagination={pagination}
              handleChangePerPageRows={(perPageRow) =>
                fetchPaymentHistoryDetails({
                  ...pagination,
                  perPageRows: perPageRow,
                  currentPage: 1,
                })
              }
              handleChangePage={(page) =>
                fetchPaymentHistoryDetails({ ...pagination, currentPage: page })
              }
              handleSort={(field) =>
                fetchPaymentHistoryDetails({
                  ...pagination,
                  sortBy: field.key,
                  orderBy:
                    pagination.orderBy === ORDER_BY.desc
                      ? ORDER_BY.asc
                      : ORDER_BY.desc,
                })
              }
            />
          </div>
        </div>
      </FormRightModalWrapper>
    </div>
  );
};

export default PaymentHistoryDetail;
