import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginView from "../screens/auth/login/LoginView";
import ForgotPasswordView from "../screens/auth/forgot-password/ForgotPasswordView";
import PublicLayout from "../components/layouts/public/PublicLayout";
import OtpVerify from "../screens/auth/otp-verify/OtpVerify";
import UnauthorizedAccess from "../screens/auth/unauthorizedAccess/UnauthorizedAccess";

const PublicRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route path="" element={<LoginView />} />+
        <Route path={routeLogin.login} element={<LoginView />} />
        <Route path={otpVerify.verifyOtp} element={<OtpVerify />} />
        <Route
          path={unauthorizedAccess.unauthorizedAccess}
          element={<UnauthorizedAccess />}
        />
      </Route>
    </Routes>
  );
};

export default PublicRouter;

export const routeLogin = {
  login: "log-in",
};

export const otpVerify = {
  verifyOtp: "verifyOtp",
};

export const unauthorizedAccess = {
  unauthorizedAccess: "unauthorizedAccess",
};
