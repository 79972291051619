import React, { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  IBlogsForm,
  blogsValidationSchema,
  createSlug,
  fetchBlogById,
  fetchBlogsCategoryDdl,
  getBlogsInitialValues,
  handleSubmitBlog,
  removeSpecialCharacters,
} from "./BlogFormViewController";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import ICFormikSwitch from "../../components/formik-input/ICFormikSwitch";
import ICButton from "../../core-component/ICButton";
import ICModal from "../../core-component/ICModal";
import ICFormikTextInput from "../../components/formik-input/ICFormikTextInput";
import ICFormikDatePicker from "../../components/formik-input/ICFormikDatePicker";
import {
  DATE_FORMAT,
  IMAGE_TYPE,
  FILE_UPLOAD,
  FILE_UPLOAD_RETURN_TYPE,
  FILE_UPLOAD_TYPE,
} from "../../utils/AppEnumerations";
import { ErrorMessage, Form, Formik } from "formik";
import { IMediaItemImage } from "../../data/AppInterface";
import { fetchUploadImageService } from "../global/image-service/ImageServices";
import ICFileUpload from "../../core-component/ICFileUpload";
import CustomEditor from "../../core-component/CustomEditor";
import {
  SweetAlertError,
  getLocalDate,
  hasAnyModification,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import { WithContext as ReactTags } from "react-tag-input";
import ICMultiSelectDropDown, {
  IOption,
} from "../../core-component/ICMultiSelectDropDown";
import { IBlog } from "./BlogController";
import { MESSAGE_UNKNOWN_ERROR_OCCURRED } from "../../utils/AppConstants";

interface IBlogsFormProps {
  handleClose: (status: boolean) => void;
  blogToEdit?: IBlog;
  readOnly?: boolean;
}

const BlogsFormView: React.FC<IBlogsFormProps> = ({
  handleClose,
  blogToEdit,
  readOnly,
}) => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timer;
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [blogsFormInitialValues, setBlogsFormInitialValues] =
    useState<IBlogsForm>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [blogsImage, setBlogsImage] = useState<IMediaItemImage[]>([]);
  const [selectedCoverImage, setSelectedCoverImage] = useState<File[]>([]);
  const [coverImage, setCoverImage] = useState<string[]>([]);
  const [blogsCategoryList, setBlogsCategoryList] = useState<IOption<number>[]>(
    []
  );

  const [tags, setTags] = useState<string[]>([]);
  const [selectedBlogCategory, setSelectedBlogCategory] = useState<
    IOption<number>[]
  >([]);
  const [loading, setLoading] = useState(false);
  const handleDrag = (tag: any, currPos: any, newPos: any) => {};
  const checkFormModification = (values: IBlogsForm) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      blogsFormInitialValues &&
      hasAnyModification(values, blogsFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };
  useEffect(() => {
    getBlogById();
  }, []);

  const getBlogById = async () => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimeout(async () => {
      if (blogToEdit) {
        const blogValue = await fetchBlogById(
          blogToEdit?.id,
          handleClose,
          setTags
        );
        blogValue && setBlogsFormInitialValues(blogValue);
        const categoryIds = blogValue?.blog_categories.map(
          (category: { id: number; category_title: string }) => category.id
        );
        fetchBlogsCategoryDdl(
          setBlogsCategoryList,
          setSelectedBlogCategory,
          categoryIds
        );
      } else {
        setBlogsFormInitialValues(getBlogsInitialValues());
        fetchBlogsCategoryDdl(setBlogsCategoryList);
      }
    }, 500);
  };
  useEffect(() => {
    if (
      blogsFormInitialValues?.blogs_media &&
      blogsFormInitialValues.blogs_media.length > 0
    ) {
      const transformedData = blogsFormInitialValues.blogs_media.map(
        (item) => ({
          ...item,
        })
      );
      setBlogsImage(transformedData);
    } else {
      setBlogsImage([]);
    }
    if (blogsFormInitialValues?.cover_image) {
      setCoverImage([blogsFormInitialValues.cover_image]);
    } else {
      setCoverImage([]);
    }
  }, [blogsFormInitialValues]);

  function checkFileType(fileType: string): string | null {
    if (fileType) {
      if (fileType === FILE_UPLOAD.Pdf) {
        return FILE_UPLOAD_RETURN_TYPE.Pdf;
      } else if (
        fileType === FILE_UPLOAD.Png ||
        fileType === FILE_UPLOAD.Jpeg
      ) {
        return FILE_UPLOAD_RETURN_TYPE.Image;
      } else if (fileType === FILE_UPLOAD.Video) {
        return FILE_UPLOAD_RETURN_TYPE.Video;
      }
    }
    return null;
  }

  const handleSubmit = async (values: IBlogsForm) => {
    try {
      setLoading(true);
      const updatedBlogsMedia = blogsImage ? [...blogsImage] : [];
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const fileType = checkFileType(file.type);
        const formData = new FormData();
        formData.append(`myImageFile`, file);
        formData.append(`type`, IMAGE_TYPE.Blogs);
        values.id && formData.append(`typeId`, values.id.toString());
        const mediaUrl = await fetchUploadImageService(formData);
        if (!mediaUrl) {
          return null;
        }
        const newMediaItem = {
          media_type: fileType !== null ? fileType : "",
          media_url: mediaUrl,
          media_title: file.name,
        };
        updatedBlogsMedia.push(newMediaItem);
      }

      const updatedBlogsCoverImage = coverImage ? coverImage : [];
      for (let i = 0; i < selectedCoverImage.length; i++) {
        const formData = new FormData();
        formData.append(`myImageFile`, selectedCoverImage[i]);
        formData.append(`type`, IMAGE_TYPE.Blogs);
        values.id && formData.append(`typeId`, values.id.toString());
        const mediaUrl = await fetchUploadImageService(formData);
        if (!mediaUrl) {
          return null;
        }
        const newMediaItem = {
          media_type: "i",
          media_url: mediaUrl,
          media_title: selectedCoverImage[i].name,
        };
        updatedBlogsCoverImage.push(newMediaItem.media_url);
      }
      await handleSubmitBlog(
        {
          ...values,
          blogs_media: updatedBlogsMedia,
          slug: createSlug(values.blog_title),
          blog_meta_title: removeSpecialCharacters(values.blog_meta_title),
          cover_image: updatedBlogsCoverImage[0],
          hash_tags: tags,
          blog_categories: selectedBlogCategory.map((val) => Number(val.value)),
        },
        blogToEdit?.id,
        handleClose,
        t
      );
    } catch (e) {
      SweetAlertError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitBlogsWithFileUpload = async (values: IBlogsForm) => {
    if (
      coverImage.length + selectedCoverImage.length === 0 ||
      blogsImage.length + selectedFiles.length === 0
    ) {
      return;
    } else {
      handleSubmit(values);
    }
  };

  const handleSelect = (options: IOption<number>[]) => {
    setSelectedBlogCategory(options);
  };

  return (
    <div>
      {blogsFormInitialValues && (
        <Formik
          initialValues={blogsFormInitialValues}
          validationSchema={() => blogsValidationSchema(t)}
          enableReinitialize
          onSubmit={(values) => handleSubmitBlogsWithFileUpload(values)}
        >
          {(formikPorps) => {
            const { handleSubmit, values, submitCount } = formikPorps;

            return (
              <FormRightModalWrapper
                containerClassName={"!w-2/5"}
                onClose={() => checkFormModification(values)}
                title={t("blog")}
              >
                <Form onSubmit={handleSubmit} className="flex flex-col h-full">
                  <div className="flex-1 grid grid-cols-2 gap-3 p-2">
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"blog_title"}
                      placeholder={t("blog_title")}
                      disabled={readOnly}
                      autoFocus
                      required
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"blog_meta_title"}
                      placeholder={t("blog_meta_title")}
                      disabled={readOnly}
                      required
                    />

                    <div className="my-3 col-span-2">
                      <h1>
                        {t("blog_content")}
                        {"*"}
                      </h1>
                      <CustomEditor
                        formikPorps={formikPorps}
                        fieldName="blog_content"
                        text={`${values.blog_content}`}
                      />
                      <ErrorMessage
                        name={"blog_content"}
                        component="div"
                        className="typo-error"
                      />
                    </div>
                    <ICFormikDatePicker
                      {...formikPorps}
                      fieldName={"publish_date"}
                      placeholder={t("publish_date")}
                      dateProps={{
                        displayFormat: DATE_FORMAT["DD-Mon-YYYY"],
                        min: values.id ? values.publish_date : getLocalDate(),
                        max: values.expiry_date,
                      }}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikDatePicker
                      {...formikPorps}
                      fieldName={"expiry_date"}
                      placeholder={t("expiry_date")}
                      dateProps={{
                        displayFormat: DATE_FORMAT["DD-Mon-YYYY"],
                        min:
                          values.id && values.expiry_date
                            ? values.expiry_date
                            : getLocalDate(),
                      }}
                      disabled={readOnly}
                    />

                    <ICMultiSelectDropDown
                      onSelect={handleSelect}
                      options={blogsCategoryList}
                      selected={selectedBlogCategory}
                      searchable
                      label={t("select_blogs_category")}
                      disabled={readOnly}
                    />

                    <div className="w-full">
                      <div className="inline-flex w-full flex-wrap">
                        {!readOnly && (
                          <ReactTags
                            tags={tags.map((tag) => ({ id: tag, text: tag }))}
                            handleDelete={(index) =>
                              setTags(tags.filter((_, i) => i !== index))
                            }
                            handleAddition={(tag) =>
                              setTags([...tags, `#${tag.text}`])
                            }
                            readOnly={true ? readOnly : false}
                            handleDrag={handleDrag}
                            classNames={{
                              tags: "flex w-full",
                              tagInput: "flex",
                              tagInputField:
                                "outline-none bg-skin-react-tags border border-skin-react-tags-input text-skin-react-tag-input-text-tags  text-sm rounded-sm focus:ring-skin-react-tag-ring-input focus:border-skin-react-tags-input-focus block w-full p-2.5",
                              selected: "selected-tag w-full",
                              tag: "inline-flex items-center bg-skin-library-tags-box text-skin-library-tags-box text-sm font-medium rounded-full px-3 py-1 mr-2 mb-2",
                              remove: "ml-1",
                            }}
                            placeholder={t("hash_tag")}
                            autofocus={false}
                            maxLength={50}
                            delimiters={[13, 32]}
                          />
                        )}
                        {readOnly && (
                          <div className="readonly-tags">
                            {tags.map((tag, index) => (
                              <span key={index} className="readonly-tag">
                                {tag}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="checkbox-wrapper mb-6 flex items-center justify-between mt-4">
                      <label>
                        <input
                          type="checkbox"
                          name="post_anonymously"
                          checked={formikPorps.values.post_anonymously === "1"}
                          onChange={(e) => {
                            formikPorps.handleChange(e);
                            formikPorps.setFieldValue(
                              "post_anonymously",
                              e.target.checked ? "1" : "0"
                            );
                          }}
                          disabled={readOnly}
                        />
                        <span className="ml-3">{t("post_anonymously")}</span>
                      </label>
                    </div>

                    <ICFormikSwitch
                      {...formikPorps}
                      containerClassName={
                        "flex items-center justify-between mt-4"
                      }
                      name={"published"}
                      placeholder={t("published")}
                      checkedValue={"1"}
                      unCheckedValue={"0"}
                      disabled={readOnly}
                      required
                    />
                    <div className="col-span-2">
                      <ICFileUpload
                        title={t("upload_cover_image")}
                        maxFiles={1}
                        maxFileSizeMB={5000}
                        acceptedFileTypes={[
                          FILE_UPLOAD_TYPE.Png,
                          FILE_UPLOAD_TYPE.Jpeg,
                          FILE_UPLOAD_TYPE.Jpg,
                        ]}
                        onFilesSelected={setSelectedCoverImage}
                        selectedFiles={selectedCoverImage}
                        setSelectedFiles={setSelectedCoverImage}
                        data={coverImage}
                        setData={setCoverImage}
                        disabled={readOnly}
                      />
                      {submitCount > 0 &&
                        coverImage.length + selectedCoverImage.length === 0 && (
                          <div className="typo-error">
                            {prepareMessageFromParams(
                              t("error_message_required"),
                              [["fieldName", t("upload_cover_image")]]
                            )}
                          </div>
                        )}
                    </div>
                    <div className="col-span-2">
                      <ICFileUpload
                        title={t("upload_other_files")}
                        maxFiles={5}
                        maxFileSizeMB={5000}
                        acceptedFileTypes={[
                          FILE_UPLOAD_TYPE.Jpg,
                          FILE_UPLOAD_TYPE.Pdf,
                          FILE_UPLOAD_TYPE.Png,
                          FILE_UPLOAD_TYPE.Jpeg,
                          FILE_UPLOAD_TYPE.Video,
                        ]}
                        onFilesSelected={setSelectedFiles}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        data={blogsImage}
                        setData={setBlogsImage}
                        disabled={readOnly}
                      />
                      {submitCount > 0 &&
                        blogsImage.length + selectedFiles.length === 0 && (
                          <div className="typo-error">
                            {prepareMessageFromParams(
                              t("error_message_required"),
                              [["fieldName", t("upload_other_files")]]
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                  {!readOnly && (
                    <ICButton
                      disabled={loading ? true : false}
                      loading={loading}
                      className={"w-full"}
                    >
                      {t("submit")}
                    </ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}
      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};
export default BlogsFormView;
