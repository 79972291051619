import { serviceMaker } from "..";
import {
  IActiveInactive,
  IMediaItemImage,
  IPagination,
  ISearch,
} from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

interface IEventPayload {
  id?: number;
  title: string;
  description: string;
  event_start_date?: Date;
  event_end_date?: Date;
  event_start_time?: string;
  event_end_time?: string;
  target_audience: string;
  is_paid_event: string;
  registration_fee?: number | null;
  venue: string;
  venue_lat: string;
  venue_long: string;
  permission_letter_submit_date?: Date;
  permission_approval_date?: Date;
  is_active: string;
  events_media: IMediaItemImage[];
}

export const GET_ALL_EVENTS = (pagination: IPagination, search: ISearch) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_events.url +
      `?query=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&sd=${search.deleteAll}&currentPage=${
        pagination.currentPage
      }&perPageRows=${pagination.perPageRows}&sortBy=${
        pagination.sortBy
      }&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_events.method
  );
};

export const ADD_EVENT = (payload: IEventPayload) => {
  return serviceMaker(
    API_ENDPOINTS.add_event.url,
    API_ENDPOINTS.add_event.method,
    payload
  );
};
export const GET_EVENT_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_events.url + `/${id}`,
    API_ENDPOINTS.get_all_events.method
  );
};
export const ACTIVE_INACTIVE_EVENT = async (
  id: number,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_inactive_event.url + `/${id}`,
    API_ENDPOINTS.active_inactive_event.method,
    activeInactiveValue
  );
};

export const UPDATE_EVENT = (payload: IEventPayload, id: number) => {
  return serviceMaker(
    API_ENDPOINTS.update_event.url + `/${id}`,
    API_ENDPOINTS.update_event.method,
    payload
  );
};

export const DELETE_EVENT = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.delete_event.url + `/${id}`,
    API_ENDPOINTS.delete_event.method
  );
};
