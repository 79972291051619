import React, { ButtonHTMLAttributes } from "react";
import { animateSpinIcon } from "../assets/icons/SvgIconList";

type TCustomButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  ClassName?: string;
  containerClassName?: string;
  loading?: boolean;
};

const ICButton: React.FC<TCustomButtonProps> = ({
  children,
  className,
  containerClassName,
  loading,
  ...rest
}) => {
  return (
    <div className={`py-2 ${containerClassName}`}>
      <button
        className={`custom-button typo-btn-primary bg-skin-primary ${className}`}
        type="submit"
        {...rest}
      >
        {loading ? (
          <div className="flex justify-center">
            <div className="h-5 w-5">{animateSpinIcon}</div>
          </div>
        ) : (
          children
        )}
      </button>
    </div>
  );
};

export default ICButton;
