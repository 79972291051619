import { serviceMaker } from "..";
import { IActiveInactive, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

export const GET_LIBRARY_DIRECTORY = (
  id: number,
  courseSpecific: boolean,
  isRender: boolean | undefined,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_library_directories.url +
      `/${id}?q=${isRender ? "exin" : ""}&sd=${search.deleteAll}${
        courseSpecific ? `&cs=true` : ""
      }`,
    API_ENDPOINTS.get_all_library_directories.method
  );
};
export const GET_LIBRARY_DIRECTORY_SEARCH = (search: ISearch) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_library_directories.url +
      `?q=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}`,
    API_ENDPOINTS.get_all_library_directories.method
  );
};
// --------------Folders---------------

export interface ILibraryFolder {
  directory_name: string;
  description: string;
  duration: number;
  is_premium: string;
  is_public: string;
  is_active: string;
  status: number;
  course_price?: number;
  course_discounted_price?: number;
}

export const CREATE_FOLDER = (payload: ILibraryFolder) => {
  return serviceMaker(
    API_ENDPOINTS.create_folder.url,
    API_ENDPOINTS.create_folder.method,
    payload
  );
};

export const GET_FOLDER_BY_ID = (parent_directory_id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_by_folder.url + `/${parent_directory_id}`,
    API_ENDPOINTS.get_by_folder.method
  );
};

export const UPDATE_FOLDER_LIBRARY = (
  payload: ILibraryFolder,
  parent_directory_id: number
) => {
  return serviceMaker(
    API_ENDPOINTS.Update_folder.url + `/${parent_directory_id}`,
    API_ENDPOINTS.Update_folder.method,
    payload
  );
};

export const DELETE_FOLDER_LIBRARY = (
  id: number,
  subscription_consent: string
) => {
  return serviceMaker(
    API_ENDPOINTS.delete_folder.url +
      `/${id}?subscription_consent=${subscription_consent}`,
    API_ENDPOINTS.delete_folder.method
  );
};

export const ACTIVE_INACTIVE_FOLDER = async (
  id: number,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_folder.url + `/${id}`,
    API_ENDPOINTS.active_folder.method,
    activeInactiveValue
  );
};
// ----------------------------file---------------------------
export interface ILibraryFile {
  file_type: number;
  title: string;
  description: string;
  duration: number;
  is_premium: string;
  is_public: string;
  is_active: string;
  status: number;
  content_price?: number;
  content_discounted_price?: number;
  content_tags: string[];
  file_path: string;
  directory_id: number;
}

export const CREATE_FILE = (payload: ILibraryFile) => {
  return serviceMaker(
    API_ENDPOINTS.create_file.url,
    API_ENDPOINTS.create_file.method,
    payload
  );
};

export const GET_FILE_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_by_file.url + `/${id}`,
    API_ENDPOINTS.get_by_file.method
  );
};

export const UPDATE_FILE_LIBRARY = (payload: ILibraryFile, id: number) => {
  return serviceMaker(
    API_ENDPOINTS.Update_file.url + `/${id}`,
    API_ENDPOINTS.Update_file.method,
    payload
  );
};

export const DELETE_FILE_LIBRARY = (
  id: number,
  subscription_consent: string
) => {
  return serviceMaker(
    API_ENDPOINTS.delete_file.url +
      `/${id}?subscription_consent=${subscription_consent}`,
    API_ENDPOINTS.delete_file.method
  );
};
export const ACTIVE_INACTIVE_FILE = async (
  id: number,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_file.url + `/${id}`,
    API_ENDPOINTS.active_file.method,
    activeInactiveValue
  );
};
// ----ldc----

export interface ILdc {
  library_content_id: number;
  library_directory_id: number;
}

export const CHECK_LDC = (payload: ILdc) => {
  return serviceMaker(
    API_ENDPOINTS.get_library_directories_contend.url,
    API_ENDPOINTS.get_library_directories_contend.method,
    payload
  );
};
