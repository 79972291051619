import React, { useEffect, useState } from "react";
import { IRevenueReport, getReportById } from "../DashboardController";
import { REPORT_ID } from "../../../utils/AppEnumerations";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import ReportTileView from "./ReportTileView";
import RectChartSkeleton from "../skelton-report/RectChartSkeleton";

const RevenueReportView = () => {
  const { t } = UseTranslationHook();
  const [revenueReport, setRevenueReport] = useState<IRevenueReport | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const fetchRevenueReport = async () => {
    const data = await getReportById(REPORT_ID.TotalRevenue, setLoading);
    if (data) {
      setRevenueReport(data);
    }
  };
  useEffect(() => {
    fetchRevenueReport();
  }, []);

  return loading ? (
    <RectChartSkeleton className="rounded-2xl" height={245} />
  ) : (
    <div className="bg-[#DCFCE7] rounded-2xl p-4">
      <h1 className="mb-3 typo-title-large">{t("revenue")}</h1>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <ReportTileView
          title={t("service_current_month")}
          total={
            revenueReport ? revenueReport.p_service_current_month_revenue : 0
          }
          className={"bg-[#B8F1CC]"}
          growth={
            revenueReport
              ? revenueReport.p_service_revenue_growth_percentage
              : 0
          }
          currency_sign={true}
        />
        <ReportTileView
          title={t("package_current_month")}
          total={
            revenueReport
              ? revenueReport.p_subscription_current_month_revenue
              : 0
          }
          className={"bg-[#B8F1CC]"}
          growth={
            revenueReport
              ? revenueReport.p_subscription_revenue_growth_percentage
              : 0
          }
          currency_sign={true}
        />
        <ReportTileView
          title={t("total")}
          className={"bg-[#B8F1CC] !font-semibold"}
          total={revenueReport ? revenueReport.p_total_revenue : 0}
          currency_sign={true}
        />
      </div>
    </div>
  );
};

export default RevenueReportView;
