import React, { useState, ChangeEvent } from "react";
import OTPInput from "react-otp-input";

interface IOtpInputCommonProps {
  value: string;
  onChange: (otp: string) => void;
  numInputs: number;
  // Add any additional props specific to the OTPInput component
}
const ICOtpInput: React.FC<IOtpInputCommonProps> = ({
  value,
  onChange,
  numInputs,
}) => {
  return (
    <OTPInput
      shouldAutoFocus
      inputType="text"
      value={value}
      onChange={onChange}
      numInputs={numInputs}
      renderInput={(props) => <input {...props} />}
      inputStyle={{
        marginRight: "20px",
        border: "1px solid grey",
        outline: "none",
        height: "42px",
        width: "42px",
        borderRadius: "5px",
      }}
    />
  );
};
export default ICOtpInput;
