import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { localStorageUtils } from "../../../utils/LocalStorageUtil";
import {
  routeLogin,
  unauthorizedAccess,
} from "../../../routes/RoutePublic";
import { routeAdmin, routeMaster } from "../../../routes/RouteHome";

const PublicLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const storedAccessToken = localStorageUtils.getAccessToken();
    if (location.pathname !== "/" + unauthorizedAccess.unauthorizedAccess) {
      if (storedAccessToken) {
        navigate(routeAdmin.admin + "/" + routeMaster.dashboard);
      } else {
        navigate(routeLogin.login);
      }
    }
  }, []);

  return (
    <>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default PublicLayout;
