import React, { useState } from "react";
import ICDropDown, {
  ICustomDropdownProps,
} from "../../core-component/ICDropDown";
import { useFormik } from "formik";
import { NOTIFICATION_SEND_OPTION } from "../../utils/AppConstants";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  handleSendNotifications,
  initialValuesNotification,
  notificationValidationSchema,
} from "./notificationController";
import ICTextInput from "../../core-component/ICTextInput";
import ICFileUpload from "../../core-component/ICFileUpload";
import ICButton from "../../core-component/ICButton";
import { fetchUploadImageService } from "../global/image-service/ImageServices";
import ICTextArea from "../../core-component/ICTextArea";

export const Notification = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const { t } = UseTranslationHook();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: initialValuesNotification,
    enableReinitialize: true,
    validationSchema: notificationValidationSchema(t),

    onSubmit: async (values) => {
      await handleSendNotifications(
        values,
        t,
        setLoading,
        selectedFiles.length > 0 ? selectedFiles : undefined
      );

      formik.resetForm();
      setSelectedFiles([]);
    },
  });

  return (
    <div className="flex w-full flex-col items-center overflow-hidden ">
      <div className="text-skin-advance-search-text-label-color  text-lg font-semibold mt-3">
        {t("notification")}
      </div>
      <form
        className="rounded py-8 h-full w-full overflow-auto max-w-xl"
        onSubmit={formik.handleSubmit}
      >
        <div className=" mt-5">
          <ICDropDown
            options={NOTIFICATION_SEND_OPTION}
            onSelect={(option) =>
              formik.setFieldValue("notfication_for", option.value)
            }
            selected={formik.values.notfication_for as unknown as number}
            errorMessage={
              formik.touched.notfication_for &&
              formik.errors.notfication_for &&
              formik.errors.notfication_for
            }
            placeholder="Send Notification To..."
            className="w-full"
          />
        </div>
        <div className="mt-5">
          <ICTextInput
            required
            containerClassName="p-2.5"
            label={t("notification_title")}
            name="notification_title"
            value={formik.values.notification_title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.notification_title &&
              formik.errors.notification_title &&
              formik.errors.notification_title
            }
          />
        </div>
        <div className="mt-5">
          <ICTextArea
            required
            containerClassName="p-2.5"
            label={t("notification_body")}
            name="notification_body"
            value={formik.values.notification_body}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.notification_body &&
              formik.errors.notification_body &&
              formik.errors.notification_body
            }
          />
        </div>

        <div className="mt-5">
          <ICFileUpload
            title={t("upload_image")}
            maxFiles={1}
            maxFileSizeMB={5000}
            acceptedFileTypes={["jpg", "png", "jpeg"]}
            onFilesSelected={setSelectedFiles}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            data={undefined}
            setData={undefined}
          />
        </div>

        <ICButton
          className="w-full mt-5"
          type="submit"
          disabled={loading ? true : false}
          loading={formik.isSubmitting || loading}
        >
          {t("send_notification")}
        </ICButton>
      </form>
    </div>
  );
};

export default Notification;
