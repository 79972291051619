import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { IBookingCancellationCharges } from "./BookingCancellationViewController";
import { hasAnyModification } from "../../utils/AppFunctions";
import ICModal from "../../core-component/ICModal";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import ICButton from "../../core-component/ICButton";
import { Form, Formik } from "formik";
import ICFormikTextInput from "../../components/formik-input/ICFormikTextInput";
import ICFormikSwitch from "../../components/formik-input/ICFormikSwitch";
import ICFormikDropDown from "../../components/formik-input/ICFormikDropDown";
import {
  IBookingCancellationForm,
  bookingCancellationValidationSchema,
  fetchBookingCancellationById,
  fetchServiceTitleApiCall,
  getBookingCancellationInitialValues,
  handleSubmitBookingCancellation,
} from "./BookingCancellationFormController";
import { IAddService } from "../../data/AppInterface";

interface IBookingCancellationFormProps {
  handleClose: (status: boolean) => void;
  bookingCancellationToEdit?: IBookingCancellationCharges;
  readOnly?: boolean;
  serviceId?: number;
}

const BookingCancellationForm: React.FC<IBookingCancellationFormProps> = ({
  handleClose,
  bookingCancellationToEdit,
  readOnly,
  serviceId,
}) => {
  const { t } = UseTranslationHook();
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [
    bookingCancellationFormInitialValues,
    setBookingCancellationFormInitialValues,
  ] = useState<IBookingCancellationForm>();
  const [serviceTitle, setServiceTitle] = useState<IAddService[]>([]);
  const checkFormModification = (values: IBookingCancellationForm) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      bookingCancellationFormInitialValues &&
      hasAnyModification(values, bookingCancellationFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };
  useEffect(() => {
    if (bookingCancellationToEdit) {
      fetchBookingCancellationById(
        bookingCancellationToEdit.id,
        setBookingCancellationFormInitialValues,
        handleClose
      );
    } else {
      setBookingCancellationFormInitialValues(
        getBookingCancellationInitialValues(
          bookingCancellationToEdit,
          serviceId
        )
      );
    }
  }, []);

  useEffect(() => {
    fetchServiceTitleApiCall(setServiceTitle);
  }, []);

  const serviceTitleOptions = serviceTitle?.map(({ id, service_title }) => ({
    value: id,
    label: service_title,
  })) as { value: number; label: string }[];
  return (
    <div>
      {bookingCancellationFormInitialValues && (
        <Formik
          initialValues={bookingCancellationFormInitialValues}
          validationSchema={() => bookingCancellationValidationSchema(t)}
          enableReinitialize
          onSubmit={(values) =>
            handleSubmitBookingCancellation(
              values,
              bookingCancellationToEdit?.id,
              handleClose,
              t
            )
          }
        >
          {(formikPorps) => {
            const { handleSubmit, values } = formikPorps;
            return (
              <FormRightModalWrapper
                onClose={() => checkFormModification(values)}
                title={t("booking_cancellation")}
              >
                <Form onSubmit={handleSubmit} className="flex flex-col h-full">
                  <div className="flex-1 overflow-y-auto pt-2.5">
                    {!serviceId && (
                      <ICFormikDropDown
                        {...formikPorps}
                        name={"service_id"}
                        placeholder={t("service_title")}
                        options={serviceTitleOptions}
                        disabled={readOnly}
                        required
                      />
                    )}
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"cancellation_cutoff_hours"}
                      placeholder={t("cancellation_cutoff_hours")}
                      disabled={readOnly}
                      type="number"
                      required
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"cancellation_refund_percentage"}
                      placeholder={t("cancellation_refund_percentage")}
                      disabled={readOnly}
                      type="number"
                      required
                    />

                    {values && (
                      <ICFormikSwitch
                        {...formikPorps}
                        containerClassName={
                          "flex items-center justify-between mt-4"
                        }
                        name={"is_active"}
                        placeholder={t("is_active")}
                        checkedValue={"1"}
                        unCheckedValue={"0"}
                        disabled={readOnly}
                        required
                      />
                    )}
                  </div>
                  {!readOnly && (
                    <ICButton className={"w-full"}>{t("submit")}</ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}
      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};
export default BookingCancellationForm;
