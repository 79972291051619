import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import { GET_ALL_PAYMENT_TRANSACTION } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
} from "../../utils/AppFunctions";

export interface IPaymentTransaction {
  id: number;
  service_title: string;
  full_name: string;
  entity_type_label: string;
  transaction_initiate_date: Date;
  transaction_amount: number;
  transaction_currency: string;
  transaction_type: string;
  payment_transaction_status_label: string;
}

export function getPaymentTransactionFields<T>(
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("payment_transaction_user_name"),
      key: "full_name",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("entity_title"),
      key: "service_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("transaction_currency"),
      key: "transaction_currency",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },

    {
      name: t("entity_type"),
      key: "entity_type_label",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("transaction_status"),
      key: "payment_transaction_status_label",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("transaction_type"),
      key: "transaction_type",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("transaction_initiate_date"),
      key: "transaction_initiate_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("transaction_amount"),
      key: "transaction_amount",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-2",
    },
  ];
}
export const INITIAL_PAYMENT_TRANSACTION = {
  to_date: "",
  from_date: "",
};

export const fetchPaymentTransactionApiCall = async (
  setPaymentTransactionList: TReactSetState<IPaymentTransaction[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const paymentTransactionList = await GET_ALL_PAYMENT_TRANSACTION(
      pagination,
      search
    );
    if (
      paymentTransactionList &&
      paymentTransactionList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (
        paymentTransactionList.data &&
        paymentTransactionList.data.item.length > 0
      ) {
        setPaymentTransactionList(paymentTransactionList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(
            paymentTransactionList.data.pagination
          ),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetPaymentTransactionList = await GET_ALL_PAYMENT_TRANSACTION(
          resetPagination,
          search
        );
        if (
          resetPaymentTransactionList &&
          resetPaymentTransactionList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setPaymentTransactionList(
            resetPaymentTransactionList.data.item || []
          );
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              resetPaymentTransactionList.data.pagination
            ),
          });
        } else {
          setPaymentTransactionList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(
        paymentTransactionList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    toastError(
      e?.paymentTransactionList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
