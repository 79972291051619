import { serviceMaker } from "..";
import { IActiveInactive, IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

export const GET_ALL_USERS = (pagination: IPagination, search: ISearch) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_users.url +
      `?query=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&currentPage=${pagination.currentPage}&perPageRows=${
        pagination.perPageRows
      }&sortBy=${pagination.sortBy}&orderBy=${pagination.orderBy}${
        search.advanceSearch?.user_gender
          ? `&gender=${search.advanceSearch.user_gender}`
          : ""
      }${
        search.advanceSearch?.user_role
          ? `&user_role=${search.advanceSearch.user_role}`
          : ""
      }${
        search.advanceSearch?.user_id
          ? `&user_id=${encodeURIComponent(search.advanceSearch.user_id)}`
          : ""
      }`,
    API_ENDPOINTS.get_all_users.method
  );
};

export const DELETE_USER = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.delete_user.url + `/${id}`,
    API_ENDPOINTS.delete_user.method
  );
};

export const ACTIVE_INACTIVE_USER = async (
  id: number | undefined,
  payload: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_inactive_user.url + `/${id}/active-inactive`,
    API_ENDPOINTS.active_inactive_user.method,
    payload
  );
};
