import React, { useEffect, useState } from "react";
import ModalComponent from "../../../../../core-component/ICModalComponent";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IGetServiceFaqs,
  IServiceQuestionId,
  IServiceFaqs,
  addFaqsByServiceId,
  fetchFaqsByServiceId,
  getFaqsByService,
  getServiceFaqsFields,
  handleDeleteServiceFaqs,
} from "./ServiceFaqsController";
import ICTable from "../../../../../core-component/ICTable";
import UseTranslationHook from "../../../../../hooks/UseTranslationHook";
import { addIcon } from "../../../../../assets/icons/SvgIconList";
import ICButton from "../../../../../core-component/ICButton";
import ICSweetAlertModal from "../../../../../core-component/ICSweetAlertModal";
import { routeAdmin, routeMaster } from "../../../../../routes/RouteHome";

const ServiceFaqs = () => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [highlightsList, setHighlightsList] = useState<IServiceFaqs[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [faqsList, setFaqsList] = useState<IGetServiceFaqs[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const serviceId = location?.state?.productServiceId;
  const readOnly: boolean = location?.state?.readOnly;
  useEffect(() => {
    fetchFaqsByServiceId(serviceId, setHighlightsList);
    fetchServiceFaqs(serviceId);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [serviceId]);

  const fetchServiceFaqs = (id: number) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await getFaqsByService(id, setFaqsList);
      setIsLoading(false);
    }, 500);
  };

  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    faqId: number
  ) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedIds([...selectedIds, faqId]);
    } else {
      setSelectedIds(selectedIds.filter((id) => id !== faqId));
    }
  };

  const handelAddFaqs = () => {
    const serviceFAQs: IServiceQuestionId[] = selectedIds.map((id) => ({
      question_id: id,
    }));

    const payload = {
      service_id: serviceId,
      service_faqs: serviceFAQs,
    };
    addFaqsByServiceId(payload, t);
    setSelectedIds([]);
    handleCloseModal();
    fetchFaqsByServiceId(serviceId, setHighlightsList);
    getFaqsByService(serviceId, setFaqsList);
  };

  const handleDeleteCfm = async (objectId: number) => {
    const data = await handleDeleteServiceFaqs(objectId, t);
    if (data) {
      fetchServiceFaqs(serviceId);
      fetchFaqsByServiceId(serviceId, setHighlightsList);
    }
  };

  const customActionView = (item: IGetServiceFaqs) => {
    return (
      <div>
        {item.is_common_for_service === "1" ? (
          " "
        ) : (
          <div className="flex item-center justify-center ml-2">
            <div className=" action-view " title={t("delete_tooltip_icon")}>
              <ICSweetAlertModal
                onConfirm={handleDeleteCfm}
                itemId={item.service_faqs_id}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  const handleSaveButton = () => {
    navigate("/" + routeAdmin.admin + "/" + routeMaster.serviceTable);
  };
  return (
    <>
      <div>
        {!readOnly && (
          <div>
            <ICButton
              type="button"
              className="ml-auto mx-2 mt-2 item-center
        flex custom-button typo-btn-primary bg-skin-primary"
              onClick={handleOpenModal}
            >
              <div className="h-5 w-5 content-center ">{addIcon}</div>
              {t("select_faqs")}
            </ICButton>
          </div>
        )}
        <ModalComponent
          open={openModal}
          onClose={handleCloseModal}
          title={t("select_faqs")}
          content={
            <div>
              {highlightsList.map((item, index) => (
                <div className="flex items-center mt-4" key={index}>
                  <div className="flex items-center">
                    <input
                      id="checked-checkbox"
                      type="checkbox"
                      onChange={(event) =>
                        handleCheckboxChange(event, item.faq_id)
                      }
                      checked={selectedIds.includes(item.faq_id)}
                    />
                  </div>
                  <p className="mx-3">{item.question}</p>
                </div>
              ))}
              <div className="flex items-center justify-center">
                <ICButton
                  type="button"
                  className=" flex custom-button typo-btn-primary bg-skin-primary"
                  onClick={handelAddFaqs}
                >
                  {addIcon}
                  {t("add_faqs")}
                </ICButton>
              </div>
            </div>
          }
        />
      </div>
      <div className="mt-3">
        <ICTable<IGetServiceFaqs>
          isLoading={isLoading}
          totalFlex="grid grid-cols-12"
          fields={getServiceFaqsFields(customActionView, t)}
          data={faqsList}
          handleChangePerPageRows={(perPageRow) => {}}
          handleChangePage={(page) => {}}
          handleSort={(field) => {}}
        />
      </div>
      <div className="flex justify-end mr-3">
        <div onClick={handleSaveButton}>
          <ICButton className={"w-full"}>{t("save_button")}</ICButton>
        </div>
      </div>
    </>
  );
};

export default ServiceFaqs;
