import { TReactSetState } from "../../data/AppType";
import { GET_BY_ID_INQUIRY } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import { decryptData, SweetAlertError } from "../../utils/AppFunctions";

export interface IInquiryDetails {
  id: number;
  first_name: string;
  last_name: string;
  contact_email_address: string;
  contact_number: string;
  inquiry_date: Date;
  inquiry_subject: string;
  message_content: string;
  addressed_date: Date;
  addressed_by: string;
}

export const fetchBookingDetailsById = async (
  id: number,
  setInquiryDetailsFormInitialValues: TReactSetState<
    IInquiryDetails | undefined
  >,
  handleClose: (status: boolean) => void
) => {
  try {
    if (id) {
      const inquiryDetailsById = await GET_BY_ID_INQUIRY(id);
      if (
        inquiryDetailsById &&
        inquiryDetailsById.code === DEFAULT_STATUS_CODE_SUCCESS
      ) {
        setInquiryDetailsFormInitialValues(JSON.parse(decryptData(inquiryDetailsById.data)));
      } else {
        handleClose(false);
        SweetAlertError(
          inquiryDetailsById.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
        );
      }
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(
      e?.inquiryDetailsById?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
