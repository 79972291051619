import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../../core-component/ICTable";
import {
  IMediaItemImage,
  IPagination,
  ISearch,
} from "../../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../../data/AppType";
import {
  ACTIVE_INACTIVE__SERVICE,
  DELETE_SERVICE_BY_ID,
  GET_ALL_SERVICES,
} from "../../../services/Endpoints";

import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
  toastSuccess,
} from "../../../utils/AppFunctions";

export interface IServices {
  id: number;
  service_title: string;
  service_cost: number;
  service_discounted_price: number;
  is_active: string;
  service_duration: number;
  isClicked: boolean;
  modified_date: string;
  is_published: string;
  app_media: [IMediaItemImage];
  deleted?: string;
}

export function getServiceTableFields<T>(
  handleServiceStatus: (item: T) => void,
  actionComponent: (item: T) => JSX.Element,
  customComponent: (item: T) => JSX.Element,
  customServiceDuration: (item: T) => JSX.Element,
  customVisibility: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("service_title"),
      key: "service_title",
      sort: true,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      component: customComponent,
    },
    {
      name: t("service_cost"),
      key: "service_cost",
      sort: false,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      checkedValue: true,
      flex: "col-span-1",
    },
    {
      name: t("service_discounted_price"),
      key: "service_discounted_price",
      sort: false,
      type: TABLE_FIELD_TYPE.currency,
      align: TABLE_FIELD_ALIGN.right,
      checkedValue: true,
      flex: "col-span-1",
    },
    {
      name: t("service_visibility"),
      key: "is_published",
      sort: true,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: customVisibility,
    },
    {
      name: t("duration"),
      key: "service_duration",
      sort: true,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: customServiceDuration,
    },
    {
      name: t("status"),
      key: "is_active",
      sort: false,
      type: TABLE_FIELD_TYPE.switch,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      handleCheck: handleServiceStatus,
      flex: "col-span-1",
    },
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}

export const fetchServiceApiCall = async (
  setServiceList: TReactSetState<IServices[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const serviceList = await GET_ALL_SERVICES(pagination, search);
    if (serviceList && serviceList.code === DEFAULT_STATUS_CODE_SUCCESS) {
      if (serviceList.data && serviceList.data.item.length > 0) {
        setServiceList(serviceList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(serviceList.data.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetServiceList = await GET_ALL_SERVICES(
          resetPagination,
          search
        );
        if (
          resetServiceList &&
          resetServiceList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setServiceList(resetServiceList.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(resetServiceList.data.pagination),
          });
        } else {
          setServiceList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(serviceList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.allServiceList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleActiveInactiveService = async (
  id: number,
  is_active: string
) => {
  try {
    const serviceActiveInactive = await ACTIVE_INACTIVE__SERVICE(id, {
      is_active: is_active,
    });
    if (
      serviceActiveInactive &&
      serviceActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      toastSuccess(serviceActiveInactive.message);
      return true;
    }
    return false;
  } catch (e: any) {
    toastError(
      e?.serviceActiveInactive?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};

export const handleDeleteService = async (
  id: number | undefined,
  t: TUseTranslationTfn
) => {
  try {
    const serviceCategoryDelete = await DELETE_SERVICE_BY_ID(id);
    if (
      serviceCategoryDelete &&
      serviceCategoryDelete.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(serviceCategoryDelete.message);
      return true;
    } else {
      SweetAlertError(
        serviceCategoryDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
    return false;
  } catch (e: any) {
    SweetAlertError(
      e?.serviceCategoryDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};
