import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../../../../core-component/ICTable";
import { IPagination, ISearch } from "../../../../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../../../../data/AppType";
import {
  ACTIVE_INACTIVE__COURSE_CONTENT,
  DELETE_COURSE_CONTENT_BY_ID,
  GET_ALL_COURSE_CONTENT,
} from "../../../../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  generatePaginationFromApiRes,
  toastError,
  toastSuccess,
} from "../../../../../utils/AppFunctions";

export interface ICourseContent {
  id: number;
  content_title: string;
  content_desc: string;
  content_image_url: string;
  content_sequence: number;
  is_active: string;
  library_content?: [];
  library_directory?: [];
  content_delivery_time: number | null;
  service_id: number | null;
}

export function getCourseContentFields<T>(
  actionComponent: (item: T) => JSX.Element,
  handleImageFormat: (item: T) => JSX.Element,
  handleStatusChange: (item: T) => void,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("content_title"),
      key: "content_title",
      sort: true,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      component: handleImageFormat,
    },
    {
      name: t("content_desc"),
      key: "content_desc",
      sort: false,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
    },
    {
      name: t("content_delivery_time"),
      key: "content_delivery_time",
      sort: false,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
    },

    {
      name: t("status"),
      key: "is_active",
      sort: false,
      type: TABLE_FIELD_TYPE.switch,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      handleCheck: handleStatusChange,
      flex: "col-span-1",
    },
    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}

export const fetchCourseContentApiCall = async (
  setCourseContentList: TReactSetState<ICourseContent[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  serviceId: number,
  search: ISearch
) => {
  try {
    const courseContentList = await GET_ALL_COURSE_CONTENT(
      pagination,
      search,
      serviceId
    );

    if (
      courseContentList &&
      courseContentList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (courseContentList.data) {
        setCourseContentList(courseContentList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(courseContentList.data.pagination),
        });
      }
    } else {
      toastError(courseContentList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch {
    toastError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleActiveInactiveCourseContent = async (
  id: number,
  is_active: string
) => {
  try {
    const CourseContentActiveInactive = await ACTIVE_INACTIVE__COURSE_CONTENT(
      id,
      {
        is_active: is_active,
      }
    );
    if (
      CourseContentActiveInactive &&
      CourseContentActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      toastSuccess(CourseContentActiveInactive.message);
      return true;
    }
    return false;
  } catch (e: any) {
    toastError(
      e?.CourseContentActiveInactive?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};

export const handleDeleteCourseContent = async (
  id: number | undefined,
  t: TUseTranslationTfn
) => {
  try {
    const courseContentDelete = await DELETE_COURSE_CONTENT_BY_ID(id);
    if (
      courseContentDelete &&
      courseContentDelete.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(courseContentDelete.message);
      return true;
    } else {
      SweetAlertError(
        courseContentDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
    return false;
  } catch (e: any) {
    SweetAlertError(
      e?.courseContentDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};
