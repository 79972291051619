import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import { GET_PAYMENT_HISTORY_BY_ID } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
} from "../../utils/AppFunctions";

export interface IPaymentHistoryHeaderDetail {
  id: number;
  payment_date: string;
  amount_paid: string;
  failure_reason: string;
  payment_status: string;
  payment_mode: string;
  trainer_id: string;
  trainer_name: string;
  from_date: string;
  to_date: string;
  no_of_bookings: number;
  no_of_sessions: number;
}

export interface IPaymentHistoryDetailList {
  booking_date: string;
  booking_number: string;
  customer: string;
  service_title: string;
  session_date: string;
  service_cost: string;
  revenue_split_percentage: string;
  total_amount: string;
}

export function getPaymentHistoryDetailFields<T>(
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("booking_date"),
      key: "booking_date",
      sort: false,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("booking_number"),
      key: "booking_number",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-3",
    },
    {
      name: t("customer"),
      key: "customer",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-4",
    },
    {
      name: t("service_title"),
      key: "service_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-5",
    },

    {
      name: t("session_date"),
      key: "session_date",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
    },
    {
      name: t("service_cost"),
      key: "service_cost",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-1",
    },
    {
      name: t("revenue_split_percentage"),
      key: "revenue_split_percentage",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("total_amount"),
      key: "total_amount",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-2",
    },
  ];
}

export const fetchPaymentHistoryDetailApiCall = async (
  setPaymentHistoryDetailHeader: TReactSetState<IPaymentHistoryHeaderDetail | null>,
  setPaymentHistoryDetailList: TReactSetState<IPaymentHistoryDetailList[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  trainerId: number
) => {
  try {
    const PaymentHistoryDetailResponse = await GET_PAYMENT_HISTORY_BY_ID(
      trainerId,
      pagination
    );
    if (
      PaymentHistoryDetailResponse &&
      PaymentHistoryDetailResponse.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (
        PaymentHistoryDetailResponse.data &&
        PaymentHistoryDetailResponse.data.item.length > 0
      ) {
        setPaymentHistoryDetailHeader(
          PaymentHistoryDetailResponse.data.header_details
        );
        setPaymentHistoryDetailList(PaymentHistoryDetailResponse.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(
            PaymentHistoryDetailResponse.data.pagination
          ),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const PaymentHistoryDetailResponse = await GET_PAYMENT_HISTORY_BY_ID(
          trainerId,
          resetPagination
        );
        if (
          PaymentHistoryDetailResponse &&
          PaymentHistoryDetailResponse.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setPaymentHistoryDetailList(
            PaymentHistoryDetailResponse.data.item || []
          );
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              PaymentHistoryDetailResponse.data.pagination
            ),
          });
        } else {
          setPaymentHistoryDetailList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(
        PaymentHistoryDetailResponse.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    toastError(
      e?.PaymentHistoryDetailResponse?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
