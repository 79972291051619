import React, { useEffect, useState } from "react";
import BasicInfo from "./pages/basic-Information/BasicInfo";
import AdditionalInfo from "./pages/additional-info/AdditionalInfo";
import TimeSlots from "./pages/time-slot/TimeSlotView";
import { useLocation, useNavigate } from "react-router-dom";
import CourseContentView from "./pages/course-content/CourseContentView";
import ServiceFaqs from "./pages/service-faqs/ServiceFaqs";
import { PRODUCT_SERVICE_TAB_NAVIGATION } from "../../../utils/AppEnumerations";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import BookingCancellationChargesView from "../../booking-cancellation-charges/BookingCancellationView";
import ICSweetAlertInfo from "../../../core-component/ICSweetAlertInfo";
import { routeAdmin, routeMaster } from "../../../routes/RouteHome";

const AddProductService = () => {
  const { t } = UseTranslationHook();
  const navigate = useNavigate();
  const location = useLocation();
  const tabIndex: number = location?.state?.productServiceTabNavigationState;
  const serviceId: number = location?.state?.productServiceId;
  const readOnly: boolean = location?.state?.readOnly;
  const copyService = location?.state?.copyService;

  const [currentInx, setCurrentInx] = useState(tabIndex);

  const handleTabClick = (tabId: number) => {
    setCurrentInx(tabId);
  };

  useEffect(() => {
    if (currentInx !== tabIndex) {
      navigate("/" + routeAdmin.admin + "/" + routeMaster.addService, {
        replace: true,
        state: {
          productServiceId: serviceId,
          readOnly: readOnly,
          copyService: copyService,
          productServiceTabNavigationState: currentInx,
        },
      });
    }
  }, [currentInx]);

  return (
    <div className="w-full flex-1 flex flex-col">
      <div className=" border-b border-skin-add-service ">
        <ul
          className="flex flex-wrap -mb-px text-sm font-medium text-center"
          id="myTab"
          data-tabs-toggle="#myTabContent"
          role="tablist"
        >
          <li className="mr-2" role="presentation">
            <div
              className={`inline-block p-3 px-3 border-b-2 rounded-t-lg text-skin-add-service cursor-pointer ${
                currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.BasicInfo
                  ? "border-skin-select-add-service"
                  : ""
              }`}
              id="BasicInfo-tab"
              data-tabs-target="#BasicInfo"
              role="tab"
              aria-controls="BasicInfo"
              aria-selected={
                currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.BasicInfo
              }
              onClick={() =>
                handleTabClick(PRODUCT_SERVICE_TAB_NAVIGATION.BasicInfo)
              }
            >
              {t("basic_info")}
            </div>
          </li>
          <li className="mr-2" role="presentation">
            <div
              className={`inline-block p-3 px-3 border-b-2 rounded-t-lg text-skin-add-service cursor-pointer ${
                currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.AdditionalInfo
                  ? "border-skin-select-add-service"
                  : ""
              }`}
              id="AdditionalInfo-tab"
              data-tabs-target="#AdditionalInfo"
              role="tab"
              aria-controls="AdditionalInfo"
              aria-selected={
                currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.AdditionalInfo
              }
            >
              {serviceId && !copyService ? (
                <div
                  onClick={() =>
                    handleTabClick(
                      PRODUCT_SERVICE_TAB_NAVIGATION.AdditionalInfo
                    )
                  }
                >
                  {t("additional_information")}
                </div>
              ) : (
                <ICSweetAlertInfo
                  type="info"
                  title={t("additional_information")}
                  fieldTitle={t("additional_information")}
                  text={t("ic_sweet_alert_info_basic_info_message")}
                />
              )}
            </div>
          </li>
          <li className="mr-2" role="presentation">
            <div
              className={`inline-block p-3 px-3 border-b-2 rounded-t-lg text-skin-add-service cursor-pointer ${
                currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.CourseContent
                  ? "border-skin-select-add-service"
                  : ""
              }`}
              id="CourseContent-tab"
              data-tabs-target="#CourseContent"
              role="tab"
              aria-controls="CourseContent"
              aria-selected={
                currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.CourseContent
              }
            >
              {serviceId && !copyService ? (
                <div
                  onClick={() =>
                    handleTabClick(PRODUCT_SERVICE_TAB_NAVIGATION.CourseContent)
                  }
                >
                  {t("course_content")}
                </div>
              ) : (
                <ICSweetAlertInfo
                  type="info"
                  title={t("course_content")}
                  fieldTitle={t("course_content")}
                  text={t("ic_sweet_alert_info_additional_info_message")}
                />
              )}
            </div>
          </li>
          <li role="presentation">
            <div
              className={`inline-block p-3 px-3 border-b-2 rounded-t-lg text-skin-add-service cursor-pointer ${
                currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.TimeSlots
                  ? "border-skin-select-add-service"
                  : ""
              }`}
              id="TimeSlots-tab"
              data-tabs-target="#TimeSlots"
              role="tab"
              aria-controls="TimeSlots"
              aria-selected={
                currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.TimeSlots
              }
            >
              {serviceId && !copyService ? (
                <div
                  onClick={() =>
                    handleTabClick(PRODUCT_SERVICE_TAB_NAVIGATION.TimeSlots)
                  }
                >
                  {t("time_slots")}
                </div>
              ) : (
                <ICSweetAlertInfo
                  type="info"
                  title={t("time_slots")}
                  fieldTitle={t("time_slots")}
                  text={t("ic_sweet_alert_info_course_content_message")}
                />
              )}
            </div>
          </li>
          <li role="presentation">
            <div
              className={`inline-block p-3 px-3 border-b-2 rounded-t-lg text-skin-add-service cursor-pointer ${
                currentInx ===
                PRODUCT_SERVICE_TAB_NAVIGATION.CancellationCharges
                  ? "border-skin-select-add-service"
                  : ""
              }`}
              id="CancellationCharges-tab"
              data-tabs-target="#CancellationCharges"
              role="tab"
              aria-controls="CancellationCharges"
              aria-selected={
                currentInx ===
                PRODUCT_SERVICE_TAB_NAVIGATION.CancellationCharges
              }
            >
              {serviceId && !copyService ? (
                <div
                  onClick={() =>
                    handleTabClick(
                      PRODUCT_SERVICE_TAB_NAVIGATION.CancellationCharges
                    )
                  }
                >
                  {t("cancellation_charges")}
                </div>
              ) : (
                <ICSweetAlertInfo
                  type="info"
                  title={t("cancellation_charges")}
                  fieldTitle={t("cancellation_charges")}
                  text={t("ic_sweet_alert_info_time_slot_message")}
                />
              )}
            </div>
          </li>
          <li role="presentation">
            <div
              className={`inline-block p-3 px-3 border-b-2 rounded-t-lg text-skin-add-service cursor-pointer ${
                currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.FaqsTab
                  ? "border-skin-select-add-service"
                  : ""
              }`}
              id="faqs-tab"
              data-tabs-target="#faqs"
              role="tab"
              aria-controls="faqs"
              aria-selected={
                currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.FaqsTab
              }
            >
              {serviceId && !copyService ? (
                <div
                  onClick={() =>
                    handleTabClick(PRODUCT_SERVICE_TAB_NAVIGATION.FaqsTab)
                  }
                >
                  {t("faqs")}
                </div>
              ) : (
                <ICSweetAlertInfo
                  type="info"
                  title={t("faqs")}
                  fieldTitle={t("faqs")}
                  text={t("ic_sweet_alert_info_cancellation_charge_message")}
                />
              )}
            </div>
          </li>
        </ul>
      </div>
      <div className="overflow-scroll flex-1">
        {currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.BasicInfo && (
          <>
            <BasicInfo setCurrentIndex={setCurrentInx} />
          </>
        )}
        {currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.AdditionalInfo && (
          <>
            <AdditionalInfo setCurrentIndex={setCurrentInx} />
          </>
        )}
        {currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.CourseContent && (
          <>
            <CourseContentView setCurrentIndex={setCurrentInx} />
          </>
        )}
        {currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.TimeSlots && (
          <>
            <TimeSlots setCurrentIndex={setCurrentInx} />
          </>
        )}
        {currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.CancellationCharges && (
          <>
            <BookingCancellationChargesView
              setCurrentIndex={setCurrentInx}
              isRender={true}
              serviceId={serviceId}
              productReadOnly={readOnly}
            />
          </>
        )}
        {currentInx === PRODUCT_SERVICE_TAB_NAVIGATION.FaqsTab && (
          <>
            <ServiceFaqs />
          </>
        )}
      </div>
    </div>
  );
};

export default AddProductService;
