import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { ILibrary } from "./LibraryViewController";
import {
  ILibraryAddFileForm,
  fetchLibraryAddFileById,
  getLibraryAddFileInitialValues,
  handleSubmitLibraryAddFile,
  libraryAddFileValidationSchema,
} from "./LibraryAddFileFormController";
import {
  SweetAlertError,
  hasAnyModification,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import ICModal from "../../core-component/ICModal";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import ICButton from "../../core-component/ICButton";
import { Form, Formik, FormikProps } from "formik";
import ICFormikTextInput from "../../components/formik-input/ICFormikTextInput";
import ICFormikTextArea from "../../components/formik-input/ICFormikTextIArea";
import ICFormikRadioBtn from "../../components/formik-input/ICFormikRadioBtn";
import ICFormikSwitch from "../../components/formik-input/ICFormikSwitch";
import ICFileUpload from "../../core-component/ICFileUpload";
import { fetchUploadImageService } from "../global/image-service/ImageServices";
import { WithContext as ReactTags } from "react-tag-input";
import {
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
  RADIO_BUTTON_LIBRARY_STATUS,
} from "../../utils/AppConstants";
import {
  BIT_VALUE,
  IMAGE_TYPE,
  IS_PREMIUM,
  LIBRARY_STATUS_VALUE,
} from "../../utils/AppEnumerations";
interface ILibraryAddFileFormProps {
  handleClose: (status: boolean) => void;
  addFileToEdit?: ILibrary;
  libraryDirectory: number;
  readOnly?: boolean;
  isPremium?: string;
  CS?: string;
}

const LibraryAddFileForm: React.FC<ILibraryAddFileFormProps> = ({
  handleClose,
  addFileToEdit,
  libraryDirectory,
  readOnly,
  isPremium,
  CS,
}) => {
  const { t } = UseTranslationHook();
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [libraryAddFileFormInitialValues, setLibraryAddFileFormInitialValues] =
    useState<ILibraryAddFileForm>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [libraryImageData, setLibraryImageData] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const checkFormModification = (values: ILibraryAddFileForm) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      libraryAddFileFormInitialValues &&
      hasAnyModification(values, libraryAddFileFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };
  function checkFileType(): number | undefined {
    if (selectedFiles.length > 0) {
      const fileType = selectedFiles[selectedFiles.length - 1].type;
      if (fileType === "application/pdf") {
        return 1;
      } else if (fileType === "image/png" || fileType === "image/jpeg") {
        return 2;
      } else if (fileType === "video/mp4") {
        return 3;
      } else if (fileType === "audio/mpeg" || fileType === "audio/x-m4a") {
        return 4;
      }
    }
    return undefined;
  }
  const fileTypeResult: number | undefined = checkFileType();

  useEffect(() => {
    if (addFileToEdit) {
      fetchLibraryAddFileById(
        addFileToEdit.object_id,
        setLibraryAddFileFormInitialValues,
        handleClose,
        setTags
      );
    } else {
      setLibraryAddFileFormInitialValues(
        getLibraryAddFileInitialValues(addFileToEdit)
      );
    }
  }, []);

  useEffect(() => {
    setLibraryImageData(
      libraryAddFileFormInitialValues?.file_path
        ? [libraryAddFileFormInitialValues.file_path]
        : []
    );
  }, [libraryAddFileFormInitialValues]);

  const handleSubmitLibraryWithFileUpload = async (
    values: ILibraryAddFileForm
  ) => {
    if (
      values.is_public === BIT_VALUE.Zero &&
      values.is_premium === BIT_VALUE.Zero
    ) {
      return;
    } else {
      try {
        setLoading(true);
        const updatedLibraryMedia = libraryImageData
          ? libraryImageData.map((url) => ({
              media_url: url,
            }))
          : [];

        for (let i = 0; i < selectedFiles.length; i++) {
          const formData = new FormData();
          formData.append(`myImageFile`, selectedFiles[i]);
          formData.append(`type`, IMAGE_TYPE.LibraryContent);
          values.id && formData.append(`typeId`, values.id.toString());
          formData.append(
            "isPremium",
            values.is_premium == IS_PREMIUM.No ? "true" : "false"
          );
          const mediaUrl = await fetchUploadImageService(formData);
          if (!mediaUrl) {
            return null;
          }
          const newMediaItem = {
            media_url: mediaUrl,
          };
          updatedLibraryMedia.push(newMediaItem);
        }
        if (updatedLibraryMedia.length > 0) {
          await handleSubmitLibraryAddFile(
            {
              ...values,
              file_path: updatedLibraryMedia[0]?.media_url || "",
              content_tags: tags,
              file_type: fileTypeResult !== undefined ? fileTypeResult : 0,
            },
            addFileToEdit?.object_id,
            handleClose,
            libraryDirectory,
            t
          );
        } else {
          SweetAlertError(t("you_have_to_select_file"));
        }
      } catch (e) {
        SweetAlertError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleDrag = (
    tag: { id: string; text: string },
    currPos: number,
    newPos: number
  ) => {};

  const handleOnchangePermission = (
    permissionType: string,
    formikPorps: FormikProps<ILibraryAddFileForm>
  ) => {
    if (permissionType === BIT_VALUE.One) {
      formikPorps.setFieldValue("is_premium", BIT_VALUE.One);
      formikPorps.setFieldValue("is_public", BIT_VALUE.Zero);
    } else {
      formikPorps.setFieldValue("is_premium", BIT_VALUE.Zero);
      formikPorps.setFieldValue("is_public", BIT_VALUE.One);
    }
  };

  return (
    <div className="transition">
      {libraryAddFileFormInitialValues && (
        <Formik
          initialValues={libraryAddFileFormInitialValues}
          validationSchema={() => libraryAddFileValidationSchema(t, isPremium)}
          enableReinitialize
          onSubmit={(values) =>
            handleSubmitLibraryWithFileUpload({
              ...values,
              is_course_specific: CS ? CS : values.is_course_specific,
              is_premium:
                isPremium && isPremium === BIT_VALUE.One
                  ? BIT_VALUE.One
                  : values.is_premium,
              is_public:
                isPremium && isPremium === BIT_VALUE.Zero
                  ? BIT_VALUE.One
                  : values.is_public,
            })
          }
        >
          {(formikPorps) => {
            const { handleSubmit, values, submitCount } = formikPorps;
            return (
              <FormRightModalWrapper
                containerClassName={"!w-2/5"}
                onClose={() => checkFormModification(values)}
                title={t("add_file")}
              >
                <Form onSubmit={handleSubmit} className="flex flex-col h-full">
                  <div className="flex-1 overflow-y-auto table-scroll pt-2.5">
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"title"}
                      placeholder={t("file_title")}
                      disabled={readOnly}
                      autoFocus
                      required
                    />
                    <ICFormikTextArea
                      {...formikPorps}
                      name={"description"}
                      placeholder={t("description")}
                      disabled={readOnly}
                    />
                    <ICFormikRadioBtn
                      {...formikPorps}
                      name={"status"}
                      placeholder={t("status")}
                      radioGroup={RADIO_BUTTON_LIBRARY_STATUS.radioGroup}
                      disabled={
                        readOnly ||
                        libraryAddFileFormInitialValues?.status ===
                          LIBRARY_STATUS_VALUE.Discontinued
                          ? true
                          : false
                      }
                      required
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"duration"}
                      placeholder={t("duration_Hours")}
                      disabled={readOnly}
                      type="number"
                    />
                    <div className="w-full mb-4">
                      <p className="mb-2">{t("content_tags")}</p>
                      <div className="inline-flex w-full flex-wrap">
                        <ReactTags
                          tags={tags.map((tag) => ({ id: tag, text: tag }))}
                          handleDelete={(index) =>
                            setTags(tags.filter((_, i) => i !== index))
                          }
                          handleDrag={handleDrag}
                          handleAddition={(tag) => setTags([...tags, tag.text])}
                          readOnly={true ? readOnly : false}
                          classNames={{
                            tags: "flex w-full",
                            tagInput: "flex",
                            tagInputField:
                              "outline-none bg-skin-react-tags border border-skin-react-tags-input text-skin-react-tag-input-text-tags  text-sm rounded-sm focus:ring-skin-react-tag-ring-input focus:border-skin-react-tags-input-focus block w-full p-2.5",
                            selected: "selected-tag w-full",
                            tag: "inline-flex items-center bg-skin-library-tags-box text-skin-library-tags-box text-sm font-medium rounded-full px-3 py-1 mr-2 mb-2",
                            remove: "ml-1",
                          }}
                          placeholder={t("add_new_tag")}
                          autofocus={false}
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="checkbox-wrapper">
                        <label>
                          <input
                            type="checkbox"
                            name="is_course_specific"
                            checked={
                              formikPorps.values.is_course_specific ===
                                BIT_VALUE.One || CS === BIT_VALUE.One
                            }
                            onChange={(e) => {
                              formikPorps.handleChange(e);
                              formikPorps.setFieldValue(
                                "is_course_specific",
                                e.target.checked
                                  ? BIT_VALUE.One
                                  : BIT_VALUE.Zero || CS
                              );
                            }}
                            disabled={
                              readOnly ||
                              CS !== undefined ||
                              values.id !== undefined
                            }
                          />
                          <span className="ml-3">
                            {t("is_course_specific")}
                          </span>
                        </label>
                      </div>
                      <div className="flex space-x-5 items-end my-3">
                        <div className="font-semibold ">{t("permission")}*</div>
                        <div className="flex items-end">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              className="h-[15px] w-[15px]"
                              name="is_public"
                              checked={
                                formikPorps.values.is_public ===
                                  BIT_VALUE.One ||
                                (isPremium && isPremium === BIT_VALUE.Zero
                                  ? true
                                  : false)
                              }
                              onChange={() => {
                                handleOnchangePermission(
                                  BIT_VALUE.Zero,
                                  formikPorps
                                );
                              }}
                              disabled={
                                readOnly ||
                                (isPremium === BIT_VALUE.Zero ||
                                isPremium === BIT_VALUE.One
                                  ? true
                                  : false) ||
                                values.id !== undefined
                              }
                            />
                            <span className="ml-1">{t("public")}</span>
                          </label>
                        </div>
                        <div className="flex items-end">
                          <label className="flex items-center">
                            <input
                              className="h-[15px] w-[15px]"
                              type="radio"
                              name="is_premium"
                              checked={
                                formikPorps.values.is_premium ===
                                  BIT_VALUE.One ||
                                (isPremium && isPremium === BIT_VALUE.One
                                  ? true
                                  : false)
                              }
                              onChange={() => {
                                handleOnchangePermission(
                                  BIT_VALUE.One,
                                  formikPorps
                                );
                              }}
                              disabled={
                                readOnly ||
                                (isPremium === BIT_VALUE.Zero ||
                                isPremium === BIT_VALUE.One
                                  ? true
                                  : false) ||
                                values.id !== undefined
                              }
                            />
                            <span className="ml-1">{t("private")}</span>
                          </label>
                        </div>
                      </div>

                      {submitCount > 0 &&
                        isPremium === undefined &&
                        values.is_premium === BIT_VALUE.Zero &&
                        values.is_public === BIT_VALUE.Zero && (
                          <div className="typo-error">
                            {prepareMessageFromParams(
                              t("error_message_required"),
                              [["fieldName", t("permission")]]
                            )}
                          </div>
                        )}
                      {values.id === undefined && (
                        <div className="text-yellow-500 mb-5">
                          {t("library_warning_note")}
                        </div>
                      )}
                    </div>

                    {values.is_premium === BIT_VALUE.One &&
                      isPremium === undefined && (
                        <ICFormikTextInput
                          {...formikPorps}
                          fieldName={"content_price"}
                          placeholder={t("price")}
                          disabled={readOnly}
                          type="number"
                          required
                        />
                      )}
                    {values.is_premium === BIT_VALUE.One &&
                      isPremium === undefined && (
                        <ICFormikTextInput
                          {...formikPorps}
                          fieldName={"content_discounted_price"}
                          placeholder={t("discounted_price")}
                          disabled={readOnly}
                          type="number"
                          required
                        />
                      )}
                    {values.id && (
                      <ICFormikSwitch
                        {...formikPorps}
                        containerClassName={
                          "flex items-center justify-between mt-4"
                        }
                        name={"is_active"}
                        placeholder={t("library_active")}
                        checkedValue={"1"}
                        unCheckedValue={"0"}
                        disabled={readOnly}
                        required
                      />
                    )}
                    <div className="mt-5">
                      <ICFileUpload
                        title="Upload File"
                        maxFiles={1}
                        maxFileSizeMB={5000}
                        acceptedFileTypes={[
                          "jpg",
                          "png",
                          "jpeg",
                          "mp4",
                          "pdf",
                          "mp3",
                          "m4a",
                        ]}
                        onFilesSelected={setSelectedFiles}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        data={libraryImageData}
                        setData={setLibraryImageData}
                        disabled={readOnly}
                      />
                      {submitCount > 0 && selectedFiles.length === 0 && (
                        <div className="typo-error mb-1">
                          {prepareMessageFromParams(
                            t("error_message_required"),
                            [["fieldName", t("library_file")]]
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {!readOnly && (
                    <ICButton
                      disabled={loading ? true : false}
                      loading={loading}
                      className={"w-full"}
                    >
                      {t("submit")}
                    </ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}
      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};

export default LibraryAddFileForm;
