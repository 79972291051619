import React, { useEffect, useState } from "react";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import {
  IHighlights,
  fetchHighlightsApiCall,
  getHighlightsFields,
  handleActiveInactiveHighlights,
  handleDeleteHighlights,
} from "./HighlightsViewController";
import ICTable from "../../core-component/ICTable";
import usePagination from "../../hooks/UsePaginationHook";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import { IPagination } from "../../data/AppInterface";
import { ORDER_BY } from "../../utils/AppConstants";
import { editIcon, eyeIcon, restoreIcon } from "../../assets/icons/SvgIconList";
import HighlightsForm from "./HighlightsForm";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import ICImageView from "../../core-component/ic-image/ICImageView";
import ICSweetAlertModal from "../../core-component/ICSweetAlertModal";

const HighlightsView = () => {
  const { t } = UseTranslationHook();
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [readOnlyForm, setReadOnlyForm] = useState(false);
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [highlightsList, setHighlightsList] = useState<IHighlights[]>([]);
  const [highlightsToEdit, setHighlightsToEdit] = useState<IHighlights>();
  const [deleteAll, setDeleteAll] = useState(false);
  const { pagination, setPagination } = usePagination({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });
  let timer: NodeJS.Timeout;

  useEffect(() => {
    fetchHighlights(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, deleteAll]);

  const fetchHighlights = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchHighlightsApiCall(
        setHighlightsList,
        setPagination,
        payloadPagination,
        {
          enterpriseSearch,
          deleteAll,
        }
      );
      setIsLoading(false);
    }, 500);
  };

  const handleHighlightsStatus = async (values: IHighlights) => {
    var isStatusChange = await handleActiveInactiveHighlights(
      values.id,
      values.is_active === "1" ? "0" : "1"
    );
    if (isStatusChange) {
      const updatedHighlightList = highlightsList.map((highlight) => {
        if (highlight.id === values.id) {
          return {
            ...highlight,
            is_active: values.is_active === "1" ? "0" : "1",
          };
        }
        return highlight;
      });

      setHighlightsList(updatedHighlightList);
    }
  };

  const handleCloseForm = (status: boolean) => {
    setShowForm(false);
    setReadOnlyForm(false);
    if (highlightsToEdit) {
      setHighlightsToEdit(undefined);
    } else {
      setEnterpriseSearch("");
    }
    if (status) {
      fetchHighlights(pagination);
    }
  };

  const handleDeleteCfm = async (id: number) => {
    const data = await handleDeleteHighlights(id, t);
    if (data) {
      fetchHighlights(pagination);
    }
  };

  const customActionView = (item: IHighlights) => {
    return (
      <div>
        {item.deleted === "1" ? (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              title={t("restore")}
              onClick={() => handleDeleteCfm(item.id)}
            >
              {restoreIcon}
            </div>
          </div>
        ) : (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              title={t("view_tooltip_icon")}
              onClick={() => {
                setShowForm(true);
                setHighlightsToEdit(item);
                setReadOnlyForm(true);
              }}
            >
              {eyeIcon}
            </div>
            <div
              className="mr-2 action-view "
              title={t("edit_tooltip_icon")}
              onClick={() => {
                setShowForm(true);
                setHighlightsToEdit(item);
              }}
            >
              {editIcon}
            </div>
            <div className="action-view " title={t("delete_tooltip_icon")}>
              <ICSweetAlertModal onConfirm={handleDeleteCfm} itemId={item.id} />
            </div>
          </div>
        )}
      </div>
    );
  };

  const customComponentImgView = (item: IHighlights) => {
    return (
      <div className="flex items-center">
        <div className="flex lg:justify-center justify-end">
          {item.app_media && item.app_media.length > 0 ? (
            <ICImageView
              url={`${item.app_media[0].media_url}`}
              className="h-6 w-6 rounded-full"
            />
          ) : (
            <img
              className="w-6 h-6 rounded-full"
              src={require("../../assets/image/noImage.png")}
              alt={t("poster_image")}
            />
          )}
        </div>
        <div className="ml-1">{item.highlight_text}</div>
      </div>
    );
  };
  const allHighlightsList = highlightsList.map((val) => ({
    ...val,
    IsDeleted: val.deleted === "1" ? true : false,
  }));
  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        handleOnClickAdd={() => setShowForm(true)}
        handleSearchChange={(text) => {
          setEnterpriseSearch(text);
        }}
        searchText={enterpriseSearch}
        handleDeleteCheckBox={(ans) => {
          setDeleteAll(ans);
        }}
        filterCheckBox={deleteAll}
        refreshTable={() => fetchHighlights(pagination)}
      />
      <ICTable<IHighlights>
        isLoading={isLoading}
        totalFlex="grid grid-cols-16"
        fields={getHighlightsFields(
          customActionView,
          customComponentImgView,
          handleHighlightsStatus,
          t
        )}
        data={allHighlightsList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchHighlights({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchHighlights({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchHighlights({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
      {showForm && (
        <HighlightsForm
          handleClose={handleCloseForm}
          highlightsToEdit={highlightsToEdit}
          readOnly={readOnlyForm}
        />
      )}
    </div>
  );
};

export default HighlightsView;
