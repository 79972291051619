import { useEffect, useState } from "react";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import { IServiceSubCategory } from "./ProductServiceSubCategoryController";
import {
  IServiceSubCategoryForm,
  fetchServiceSubCategoryById,
  getServiceSubCategoryInitialValueDdl,
  getServiceSubCategoryInitialValues,
  handleSubmitServiceSubCategory,
  serviceCategorySubValidationSchema,
} from "./ProductServiceSubCategoryFormController";
import { hasAnyModification } from "../../../utils/AppFunctions";
import ICModal from "../../../core-component/ICModal";
import { Form, Formik } from "formik";
import FormRightModalWrapper from "../../../components/FormRightModalWrapper";
import ICFormikSwitch from "../../../components/formik-input/ICFormikSwitch";
import ICFormikTextInput from "../../../components/formik-input/ICFormikTextInput";
import ICButton from "../../../core-component/ICButton";
import ICFormikDropDown from "../../../components/formik-input/ICFormikDropDown";
import { fetchServiceCategoryDdl } from "../product-service-category/ProductServiceCategoryController";
import { IDDL } from "../../../data/AppInterface";

interface IServiceCategorySubFormProps {
  handleClose: (status: boolean) => void;
  serviceSubCategoryToEdit?: IServiceSubCategory;
  readOnly?: boolean;
  showForm?: boolean;
}

const ProductServiceSubCategoryForm: React.FC<IServiceCategorySubFormProps> = ({
  handleClose,
  serviceSubCategoryToEdit,
  readOnly,
  showForm,
}) => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [
    serviceSubCategoryFormInitialValues,
    setServiceSubCategoryFormInitialValues,
  ] = useState<IServiceSubCategoryForm>();
  const [apiOptionsServiceCategory, setApiOptionsServiceCategory] = useState<
    IDDL[]
  >([]);
  const checkFormModification = (values: IServiceSubCategoryForm) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      serviceSubCategoryFormInitialValues &&
      hasAnyModification(values, serviceSubCategoryFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };

  useEffect(() => {
    fetchSubCategory();
  }, []);
  const fetchSubCategory = async () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (serviceSubCategoryToEdit) {
        const subCategoryById = await fetchServiceSubCategoryById(
          serviceSubCategoryToEdit.id,
          handleClose
        );
        setServiceSubCategoryFormInitialValues(subCategoryById);
        await fetchServiceCategoryDdl(
          setApiOptionsServiceCategory,
          subCategoryById?.service_category_id
        );
      } else {
        setServiceSubCategoryFormInitialValues(
          getServiceSubCategoryInitialValues(serviceSubCategoryToEdit)
        );
        await fetchServiceCategoryDdl(setApiOptionsServiceCategory);
      }
    }, 500);
  };

  return (
    <div>
      {serviceSubCategoryFormInitialValues && (
        <Formik
          initialValues={getServiceSubCategoryInitialValueDdl(
            serviceSubCategoryFormInitialValues,
            apiOptionsServiceCategory
          )}
          validationSchema={() => serviceCategorySubValidationSchema(t)}
          enableReinitialize
          onSubmit={(values) =>
            handleSubmitServiceSubCategory(
              values,
              serviceSubCategoryToEdit?.id,
              handleClose,
              t
            )
          }
        >
          {(formikPorps) => {
            const { handleSubmit, values } = formikPorps;
            return (
              <FormRightModalWrapper
                onClose={() => checkFormModification(values)}
                title={t("service_category_sub")}
              >
                <Form onSubmit={handleSubmit} className="flex  flex-col h-full">
                  <div className="flex-1 overflow-y-auto pt-2.5">
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"sub_category_title"}
                      placeholder={t("category_sub_title")}
                      disabled={readOnly}
                      autoFocus
                      required
                    />
                    <ICFormikDropDown
                      {...formikPorps}
                      name={"service_category_id"}
                      placeholder={t("category_title")}
                      options={apiOptionsServiceCategory.map((item) => ({
                        label: item.display_value,
                        value: Number(item.data_value),
                      }))}
                      disabled={readOnly}
                      required
                      searchable
                    />

                    {values.id && (
                      <ICFormikSwitch
                        {...formikPorps}
                        containerClassName={
                          "flex items-center justify-between mt-4"
                        }
                        name={"is_active"}
                        placeholder={t("is_active")}
                        checkedValue={"1"}
                        unCheckedValue={"0"}
                        disabled={readOnly}
                        required
                      />
                    )}
                  </div>
                  {!readOnly && (
                    <ICButton className={"w-full"}>{t("submit")}</ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}
      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};

export default ProductServiceSubCategoryForm;
