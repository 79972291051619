import { useLocation, useNavigate } from "react-router-dom";
import { routeMaster } from "../../routes/RouteHome";
import {
  cancellationChargeSvg,
  designService,
  eventsIcon,
  faqSvg,
  highlightsSvg,
  iconBxsCategory,
  libraryIcon,
  manageIcon,
  subCatSvg,
  settingIcon,
  blogIcon,
  blogCategoryIcon,
  subscriptionPackageIcon,
  userIcon,
  trainerApplicationIcon,
  grievancesIcon,
  helpIcon,
  detailsIcon,
  paymentIcon,
  sessionsIcon,
  notificationIcon,
  dashboardIcon,
  inquiryIcon,
  subscriptionsIcon,
  paymentDueICon,
  historyIcon,
} from "../../assets/icons/SvgIconList";
import UseTranslationHook from "../../hooks/UseTranslationHook";
const SidebarMenu = () => {
  const { t } = UseTranslationHook();
  const navigate = useNavigate();
  const location = useLocation();
  const heading = location?.pathname?.replace("/admin/", "");

  return (
    <div>
      <div className="py-4 overflow-y-auto items-start self-start ">
        <ul className="space-y-2 font-medium flex flex-col justify-center items-start">
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.dashboard && "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.dashboard);
              }}
            >
              <div className="h-5 w-5">{dashboardIcon}</div>
              <p className="ml-2 text-sm">{t("dashboard")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.serviceCategory &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.serviceCategory);
              }}
            >
              <div className="mr-2">{iconBxsCategory}</div>
              <p className="text-sm">{t("service_category")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colorsgroup ${
                heading === routeMaster.serviceSubCategory &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.serviceSubCategory);
              }}
            >
              <div className="mr-2">{subCatSvg}</div>
              <p className="text-sm">{t("service_sub_category")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                (heading === routeMaster.serviceTable ||
                  heading === routeMaster.addService) &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.serviceTable);
              }}
            >
              <div className="mr-2">{designService}</div>
              <p className="text-sm">{t("service")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.bookingsDetails &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.bookingsDetails);
              }}
            >
              <div className="h-5 w-5">{detailsIcon}</div>
              <p className="text-sm ml-2"> {t("bookings_details")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.trainerApplication &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.trainerApplication);
              }}
            >
              <div className="mr-2 h-6 w-6">{trainerApplicationIcon}</div>
              <p className="text-sm">{t("trainer_applications")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.sessions && "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.sessions);
              }}
            >
              <div className="mr-2 h-6 w-6">{sessionsIcon}</div>
              <p className="text-sm">{t("sessions")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.faqs && "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.faqs);
              }}
            >
              <div className="mr-2">{faqSvg}</div>
              <p className="text-sm">{t("faqs")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.highlights && "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.highlights);
              }}
            >
              <div className="mr-2">{highlightsSvg}</div>
              <p className="text-sm">{t("highlights")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.bookingCancellationChargesView &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.bookingCancellationChargesView);
              }}
            >
              <div className="mr-2">{cancellationChargeSvg}</div>
              <p className="text-sm">{t("booking_cancellation_charges")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.library && "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.library);
              }}
            >
              <div className="h-5 w-5">{libraryIcon}</div>
              <p className="text-sm ml-2"> {t("library")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.events && "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.events);
              }}
            >
              <div className="h-5 w-5">{eventsIcon}</div>
              <p className="text-sm ml-2"> {t("events")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.blogCategory &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.blogCategory);
              }}
            >
              <div className="h-5 w-5">{blogCategoryIcon}</div>
              <p className="text-sm ml-2"> {t("blog_category")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.blog && "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.blog);
              }}
            >
              <div className="h-5 w-5">{blogIcon}</div>
              <p className="text-sm ml-2"> {t("blog")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.subscriptionPackages &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.subscriptionPackages);
              }}
            >
              <div className="h-5 w-5">{subscriptionPackageIcon}</div>
              <p className="text-sm ml-2"> {t("subscription_packages")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.userList && "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.userList);
              }}
            >
              <div className="h-5 w-5">{userIcon}</div>
              <p className="text-sm ml-2"> {t("users")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.grievances && "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.grievances);
              }}
            >
              <div className="h-5 w-5">{grievancesIcon}</div>
              <p className="text-sm ml-2"> {t("grievances")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.systemConfiguration &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.systemConfiguration);
              }}
            >
              <div className="h-5 w-5">{settingIcon}</div>
              <p className="ml-2 text-sm"> {t("system_settings")}</p>
            </div>
          </li>

          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.contentManagement &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.contentManagement);
              }}
            >
              <div className="h-5 w-5">{manageIcon}</div>
              <p className="ml-2 text-sm"> {t("content_management")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.paymentDue && "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.paymentDue);
              }}
            >
              <div className="h-5 w-5">{paymentDueICon}</div>
              <p className="ml-2 text-sm"> {t("payment_due")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.paymentHistory &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.paymentHistory);
              }}
            >
              <div className="h-4 w-4">{historyIcon}</div>
              <p className="ml-2 text-sm"> {t("payment_history")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.paymentTransaction &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.paymentTransaction);
              }}
            >
              <div className="h-5 w-5">{paymentIcon}</div>
              <p className="ml-2 text-sm"> {t("payment_transaction")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.notification &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.notification);
              }}
            >
              <div className="h-5 w-5">{notificationIcon}</div>
              <p className="ml-2 text-sm"> {t("notification")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.inquiry && "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.inquiry);
              }}
            >
              <div className="h-5 w-5">{inquiryIcon}</div>
              <p className="ml-2 text-sm"> {t("inquiry")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.newsletterSubscription &&
                "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.newsletterSubscription);
              }}
            >
              <div className="h-5 w-5">{subscriptionsIcon}</div>
              <p className="ml-2 text-sm"> {t("newsletter_Subscription")}</p>
            </div>
          </li>
          <li className="w-full cursor-pointer">
            <div
              className={`flex items-center p-2 text-skin-sidebar-menu-tab rounded-lg transition-colors group ${
                heading === routeMaster.systemHelp && "bg-skin-sidebar-menu-tab"
              }`}
              onClick={() => {
                navigate(routeMaster.systemHelp);
              }}
            >
              <div className="h-5 w-5">{helpIcon}</div>
              <p className="ml-2 text-sm"> {t("system_help")}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SidebarMenu;
