import React, { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  ChartOptions,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import {
  IMonthlyUserEngagementReport,
  getReportById,
} from "../DashboardController";
import { REPORT_ID } from "../../../utils/AppEnumerations";
import { monthShortNames } from "../../../utils/AppConstants";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import RectChartSkeleton from "../skelton-report/RectChartSkeleton";
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
);

const MonthlyUserEngagementView = () => {
  const { t } = UseTranslationHook();
  const [userEngagement, setUserEngagement] = useState<
    IMonthlyUserEngagementReport[]
  >([]);
  const [loading, setLoading] = useState(true);
  const fetchRevenueReport = async () => {
    const data = await getReportById(
      REPORT_ID.MonthlyUserEngagement,
      setLoading
    );
    if (data) {
      setUserEngagement(data.reverse());
    }
  };
  useEffect(() => {
    fetchRevenueReport();
  }, []);

  const options = useMemo((): ChartOptions<"line"> => {
    return {
      aspectRatio: window.innerWidth < 480 ? 1 / 0.7 : undefined,
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: (item) => {
              return item.dataset.label + ": " + item.raw + "%";
            },
          },
        },
        title: {
          display: true,
          text: t("user_engagement"),
          color: "#000000",
          font: {
            family: "Segoe UI",
            size: 20,
            weight: "normal",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              family: "Segoe UI",
              size: 13,
            },
          },
        },
        y: {
          ticks: {
            callback: (value) => value + "%",
            font: {
              family: "Segoe UI",
              size: 10,
            },
          },
        },
      },
    };
  }, [t]);

  const data = useMemo(() => {
    return {
      labels: userEngagement
        .slice(-6)
        .map((item) => monthShortNames[item.month - 1]),
      datasets: [
        {
          label: t("engagement_rate"),
          data: userEngagement.slice(-6).map((item) => item.engagement_rate),
          backgroundColor: "#0095FF",
          borderColor: "#0095FF",
        },
      ],
    };
  }, [userEngagement, t]);

  return loading ? (
    <>
      <div className="xl:hidden">
        <RectChartSkeleton height={220} />
      </div>
      <div className="xl:block hidden">
        <RectChartSkeleton height={390} />
      </div>
    </>
  ) : (
    <div className="w-full h-full flex justify-center">
      <Line options={options} data={data} />
    </div>
  );
};

export default MonthlyUserEngagementView;
