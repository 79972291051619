import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../../core-component/ICTable";
import { IPagination, ISearch } from "../../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../../data/AppType";
import {
  ACTIVE_INACTIVE_SERVICE_SUB_CATEGORY,
  DELETE_SERVICE_SUB_CATEGORY,
  GET_SERVICE_SUBCATEGORY,
} from "../../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
  toastSuccess,
} from "../../../utils/AppFunctions";

export interface IServiceSubCategory {
  id: number;
  sub_category_title: string;
  category_title?: string;
  is_active: string;
  deleted?: string;
  service_category_id?: number;
}

export function getAdmitTypeFields<T>(
  handleStatusChange: (item: T) => void,
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("category_sub_title"),
      key: "sub_category_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("category_title"),
      key: "category_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("status"),
      key: "is_active",
      sort: false,
      type: TABLE_FIELD_TYPE.switch,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      handleCheck: handleStatusChange,
      flex: "col-span-1",
    },
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },

    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}

export const fetchServiceSubCategoryApiCall = async (
  setServiceSubCategoryList: TReactSetState<IServiceSubCategory[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const serviceSubCategoryList = await GET_SERVICE_SUBCATEGORY(
      pagination,
      search
    );
    if (
      serviceSubCategoryList &&
      serviceSubCategoryList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (
        serviceSubCategoryList.data &&
        serviceSubCategoryList.data.item.length > 0
      ) {
        setServiceSubCategoryList(serviceSubCategoryList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(
            serviceSubCategoryList.data.pagination
          ),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetServiceSubCategoryList = await GET_SERVICE_SUBCATEGORY(
          resetPagination,
          search
        );
        if (
          resetServiceSubCategoryList &&
          resetServiceSubCategoryList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setServiceSubCategoryList(
            resetServiceSubCategoryList.data.item || []
          );
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              resetServiceSubCategoryList.data.pagination
            ),
          });
        } else {
          setServiceSubCategoryList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(
        serviceSubCategoryList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    toastError(
      e?.serviceSubCategoryList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleDeleteServiceSubCategory = async (
  id: number,
  t: TUseTranslationTfn
) => {
  try {
    const serviceSubCategoryDelete = await DELETE_SERVICE_SUB_CATEGORY(id);
    if (
      serviceSubCategoryDelete &&
      serviceSubCategoryDelete.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(serviceSubCategoryDelete.message);
      return true;
    } else {
      SweetAlertError(
        serviceSubCategoryDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
    return false;
  } catch (e: any) {
    SweetAlertError(
      e?.serviceSubCategoryDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};

export const handleActiveInactiveServiceSubCategory = async (
  id: number,
  values: string
) => {
  try {
    const serviceSubCategoryActiveInactive =
      await ACTIVE_INACTIVE_SERVICE_SUB_CATEGORY(id, { is_active: values });
    if (
      serviceSubCategoryActiveInactive &&
      serviceSubCategoryActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      toastSuccess(serviceSubCategoryActiveInactive.message);
      return true;
    }
    return false;
  } catch (e: any) {
    toastError(
      e?.serviceSubCategoryActiveInactive?.message ||
        MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};
