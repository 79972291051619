import React, { useState, useEffect } from "react";

import { clearSession } from "../utils/AppFunctions";

import { useNavigate } from "react-router-dom";
import { toastError } from "../utils/AppFunctions";
import { SESSION_ITA, SESSION_SIT } from "../config/AppConfig";
import UseTranslationHook from "../hooks/UseTranslationHook";
import { routeLogin, unauthorizedAccess } from "../routes/RoutePublic";
interface Props {}

const SessionTimeoutModal: React.FC<Props> = (props) => {
  const { t } = UseTranslationHook();
  const navigate = useNavigate();
  const [showSessionTimeoutModal, setShowSessionTimeoutModal] = useState(false);
  const [openSessionTimeoutModal, setOpenSessionTimeoutModal] = useState(false);
  const [logoutFromSession, setLogoutFromSession] = useState(false);
  const [waring, setWaring] = useState(false);
  const [SIT, setSIT] = useState(20);
  const [ITA, setITA] = useState(18);
  const [idSIT, setIdSIT] = useState<NodeJS.Timeout | null>(null);
  const [idITA, setIdITA] = useState<NodeJS.Timeout | null>(null);
  const [idSAC, setIdSAC] = useState<NodeJS.Timeout | null>(null);
  const [sessionAlertCounter, setSessionAlertCounter] = useState(0);
  const [keyPress, setKeyPress] = useState(0);
  const [idHandleEventTimer, setIdHandleEventTimer] =
    useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    let tempId = setInterval(() => {
      const token = localStorage.getItem("AccessToken");
      if (!token) {
        navigate("/" + routeLogin.login);
      }
    }, 5000);
    return () => {
      clearInterval(tempId);
      setWaring(true);
    };
  }, []);

  useEffect(() => {
    fetchData();

    document.addEventListener("keydown", sessionEventListener);
    document.addEventListener("click", sessionEventListener);
    return () => {
      document.removeEventListener("keydown", sessionEventListener);
      document.removeEventListener("click", sessionEventListener);
      if (idSIT) clearTimeout(idSIT);
      if (idITA) clearTimeout(idITA);
      if (idHandleEventTimer) clearTimeout(idHandleEventTimer);
      if (idSAC) clearInterval(idSAC);
      setWaring(true);
    };
  }, []);

  useEffect(() => {
    if (SIT !== 0 && ITA !== 0) {
      setSessionAlertCounter((SIT - ITA) * 60);
      initializeSystemIdleTimeout();
    }
    return () => {
      setWaring(true);
    };
  }, [SIT, ITA]);

  useEffect(() => {
    if (openSessionTimeoutModal) {
      setOpenSessionTimeoutModal(false);
      handleOpenSessionTimeoutModal();
    }
    return () => {
      setWaring(true);
    };
  }, [openSessionTimeoutModal]);

  useEffect(() => {
    if (logoutFromSession) {
      setLogoutFromSession(false);
      handleLogoutFromSession();
    }
    return () => {
      setWaring(true);
    };
  }, [logoutFromSession]);

  useEffect(() => {
    if (keyPress !== 0) {
      handleMouseKeyboardEvent();
    }
    return () => {
      setWaring(true);
    };
  }, [keyPress]);

  const fetchData = async () => {
    try {
      setITA(Number(SESSION_ITA));
      setSIT(Number(SESSION_SIT));
    } catch (e) {
      toastError("globalUnknownErrorTryAgain");
    }
    // try {
    //   const data = await FETCH_SESSION_TIME();
    //   if (data.code === 200 || data.code === "200") {
    //     setSIT(Number(data.data.system_idle_timeout));
    //     setITA(Number(data.data.idle_timeout_alert));
    //   } else {
    //     toast.error(data?.message || t("globalUnknownErrorTryAgain"));
    //   }
    // } catch (e) {
    //   toast.error(e?.data?.message || t("globalUnknownErrorTryAgain"));
    // }
  };

  const sessionEventListener = (event: Event) => {
    setKeyPress((prev) => (prev >= 1000 ? 1 : prev + 1));
  };

  const handleMouseKeyboardEvent = () => {
    if (idHandleEventTimer) {
      clearTimeout(idHandleEventTimer);
    }
    if (!showSessionTimeoutModal) {
      const tempHandleEventTimer = setTimeout(() => {
        initializeSystemIdleTimeout();
      }, 1000);
      setIdHandleEventTimer(tempHandleEventTimer);
    }
  };

  const clearAllTimer = () => {
    if (idSIT) clearTimeout(idSIT);
    if (idITA) clearTimeout(idITA);
    if (idSAC) clearInterval(idSAC);
    setSessionAlertCounter((SIT - ITA) * 60);
  };

  const initializeSystemIdleTimeout = () => {
    clearAllTimer();
    const tempIdSIT = setTimeout(() => {
      setLogoutFromSession(true);
    }, SIT * 60000);
    const tempIdITA = setTimeout(
      () => setOpenSessionTimeoutModal(true),
      ITA * 60000
    );
    setIdSIT(tempIdSIT);
    setIdITA(tempIdITA);
  };

  const handleOpenSessionTimeoutModal = () => {
    setShowSessionTimeoutModal(true);
    const tempIdSAC = setInterval(
      () => setSessionAlertCounter((prev) => prev - 1),
      1000
    );
    setIdSAC(tempIdSAC);
  };

  const closeSessionTimeoutModal = (status: boolean) => {
    setShowSessionTimeoutModal(false);
    if (status) {
      handleLogoutFromSession();
    } else {
      initializeSystemIdleTimeout();
    }
  };

  const handleLogoutFromSession = async () => {
    try {
      clearAllTimer();
      await logout();
    } catch (e) {}
  };

  const logout = async () => {
    // try {
    //   const data = await LOGOUT();
    //   if (data.code === 200 || data.code === "200") {
    //   } else {
    //     toast.error(data?.message);
    //   }
    // } catch (e) {}

    // localStorage.removeItem("AccessToken")
    clearSession();
    navigate("/log-in");
    // closeSessionTimeoutModal(true);
    // props.addMainLoginInfoHandler(null);
    // toast.success(t("logoutSuccessfully"));
  };

  return (
    <React.Fragment>
      {showSessionTimeoutModal && (
        <div className="modal-overlay-model  py-5 z-50">
          <div className="modal-custom lg:w-1/2 w-[350px]">
            <div className="custom-modal-div">
              <h2 className="title-custom-model py-2">
                {t("session_time_out_title")}
              </h2>
            </div>
            <div>
              <hr className="button-horizontal-line-space" />
              <div className="mt-2">
                <div className="text-center w-100">
                  <p className="flex mb-1 text-4">
                    {t("session_time_out_message")}
                  </p>
                </div>
                <div className="flex justify-center mt-4">
                  <button
                    className="mr-2 px-4 cancel-button-model"
                    onClick={() => closeSessionTimeoutModal(true)}
                  >
                    {t("session_log_of")}
                  </button>
                  <button
                    className="px-4 confirm-button-model"
                    onClick={() => closeSessionTimeoutModal(false)}
                  >{`STAY LOGGED IN (${
                    sessionAlertCounter >= 0 ? sessionAlertCounter : 0
                  })`}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SessionTimeoutModal;
