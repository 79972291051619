import { serviceMaker } from "..";
import { IActiveInactive, IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

export interface IBlogeCategoryPayload {
  category_title: string;
  category_meta_title: string;
  parent_category_id?: number;
  slug: string;
  is_active: string;
  deleted?: string;
}

export const GET_ALL_BLOG_CATEGORIES = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_blog_categories.url +
      `?query=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&sd=${search.deleteAll}&currentPage=${
        pagination.currentPage
      }&perPageRows=${pagination.perPageRows}&sortBy=${
        pagination.sortBy
      }&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_blog_categories.method
  );
};

export const POST_BLOG_CATEGORY = (payload: IBlogeCategoryPayload) => {
  return serviceMaker(
    API_ENDPOINTS.create_blog_category.url,
    API_ENDPOINTS.create_blog_category.method,
    payload
  );
};

export const UPDATE_BLOG_CATEGORY = (
  payload: IBlogeCategoryPayload,
  id: number
) => {
  return serviceMaker(
    API_ENDPOINTS.update_blog_category.url + `/${id}`,
    API_ENDPOINTS.update_blog_category.method,
    payload
  );
};

export const DELETE_BLOG_CATEGORY = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.delete_blog_category.url + `/${id}`,
    API_ENDPOINTS.delete_blog_category.method
  );
};

export const ACTIVE_INACTIVE_BLOG_CATEGORY = async (
  id: number,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_inactive_blog_category.url + `/${id}`,
    API_ENDPOINTS.active_inactive_blog_category.method,
    activeInactiveValue
  );
};

export const GET_BLOG_CATEGORY_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_blog_categories.url + `/${id}`,
    API_ENDPOINTS.get_all_blog_categories.method
  );
};
