import { createSlice } from "@reduxjs/toolkit";
import { THEMES } from "../../utils/AppEnumerations";

export interface ICounterState {
  systemPreference: {
    timeZoneOffset: string;
    localeCode: string;
    theme: string;
    ISDCode: string;
    phoneFormat: string;
    currencySymbol: string;
    currencyCode: string;
    logo: string;
  };
}

const initialState: ICounterState = {
  systemPreference: {
    timeZoneOffset: "+330",
    localeCode: "en-US",
    theme: THEMES.Light,
    ISDCode: "+1",
    phoneFormat: "(XXX)XXX-XXXX",
    currencySymbol: "$",
    currencyCode: "USD",
    logo: "https://github.com/AmitDKasundra/VP-Admin/blob/feature/VP1-01-authentication-authorization/src/assets/image/logo_vp.png",
  },
};

export const preferenceSlice = createSlice({
  name: "Preference",
  initialState,
  reducers: {
    ChangeTheme: (state, data) => {
      state.systemPreference.theme = data.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { ChangeTheme } = preferenceSlice.actions;

export default preferenceSlice.reducer;
