import { Outlet } from "react-router-dom";
import NavBar from "../../../screens/public/header/Navbars";
import HomeView from "../../../screens/public/home/HomeView";
import SessionTimeoutModal from "../../SessionTimeoutModalComponent";

const AdminLayout = () => {
  return (
    <div className="flex flex-col h-screen">
      <NavBar />
      <div className="flex full-height">
        <HomeView />
        <Outlet />
      </div>
      <SessionTimeoutModal />
    </div>
  );
};

export default AdminLayout;
