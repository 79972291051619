import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import {
  GET_HIGHLIGHTS_BY_ID,
  POST_HIGHLIGHTS,
  UPDATE_HIGHLIGHTS,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  getLocalDate,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import { IHighlights } from "./HighlightsViewController";
import * as Yup from "yup";

export const highlightsValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    highlight_text: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("highlight_text")],
        ])
      )
      .max(
        15,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("highlight_text")],
          ["max", "15"],
        ])
      ),
    end_date: Yup.date().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("end_date")],
      ])
    ),
  });
export const getHighlightsInitialValues = (): IHighlights => {
  return {
    id: 0,
    highlight_text: "",
    cancellation_charge_percentage: 10,
    end_date: null,
    is_active: "1",
    app_media: undefined,
  };
};

export const handleSubmitHighlights = async (
  values: IHighlights,
  id: number | undefined,
  handleClose: (status: boolean) => void,
  t: TUseTranslationTfn
) => {
  try {
    let resultHighlights;
    if (!id) {
      resultHighlights = await POST_HIGHLIGHTS(values);
      if (resultHighlights.code === DEFAULT_STATUS_CODE_SUCCESS) {
        SweetAlertSuccess(resultHighlights.message);
        return handleClose(true);
      } else {
        SweetAlertError(resultHighlights.message);
      }
    } else {
      resultHighlights = await UPDATE_HIGHLIGHTS(values, id);
      if (resultHighlights.code === DEFAULT_STATUS_CODE_SUCCESS) {
        SweetAlertSuccess(resultHighlights.message);
        return handleClose(true);
      } else {
        SweetAlertError(resultHighlights.message);
      }
    }
  } catch (e: any) {
    SweetAlertError(e?.resultFaqs?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const fetchHighlightsById = async (
  id: number | undefined,
  setHighlightsList: TReactSetState<IHighlights | undefined>,
  handleClose: (status: boolean) => void
) => {
  try {
    if (id) {
      const highlightsId = await GET_HIGHLIGHTS_BY_ID(id);
      if (highlightsId && highlightsId.code === DEFAULT_STATUS_CODE_SUCCESS) {
        setHighlightsList({
          id: highlightsId.data.id,
          highlight_text: highlightsId.data.highlight_text,
          cancellation_charge_percentage:
            highlightsId.data.cancellation_charge_percentage,
          end_date: getLocalDate(highlightsId.data.end_date),
          is_active: highlightsId.data.is_active,
          app_media: highlightsId.data.app_media,
        });
      } else {
        handleClose(false);
        SweetAlertError(highlightsId.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(e?.highlightsId?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
