import { serviceMaker } from "../..";
import {
  IActiveInactive,
  IPagination,
  ISearch,
} from "../../../data/AppInterface";
import { API_ENDPOINTS } from "../../../utils/ApiEndPoint";

export const GET_ALL_SERVICES = (pagination: IPagination, search: ISearch) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_services.url +
      `?query=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&sd=${search.deleteAll}&currentPage=${
        pagination.currentPage
      }&perPageRows=${pagination.perPageRows}&sortBy=${
        pagination.sortBy
      }&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_services.method
  );
};

export const DELETE_SERVICE_BY_ID = (id: number | undefined) => {
  return serviceMaker(
    API_ENDPOINTS.delete_service_by_id.url + `/${id}`,
    API_ENDPOINTS.delete_service_by_id.method
  );
};

export const ACTIVE_INACTIVE__SERVICE = async (
  id: number,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_inactive_service.url + `/${id}`,
    API_ENDPOINTS.active_inactive_service.method,
    activeInactiveValue
  );
};
