import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  IGrievance,
  fetchGrievancesApiCall,
  getGrievancesFields,
} from "./GrievancesController";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import usePagination from "../../hooks/UsePaginationHook";
import { IPagination } from "../../data/AppInterface";
import { editIcon, eyeIcon } from "../../assets/icons/SvgIconList";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import ICTable from "../../core-component/ICTable";
import {
  GRIEVANCE_STATUS_VALUE_TABLE,
  ORDER_BY,
} from "../../utils/AppConstants";
import GrievancesFormView from "./GrievancesFormView";
import { updateGrievanceStatusApiCall } from "./GrievancesFormController";
import {
  GRIEVANCE_STATUS,
  GRIEVANCE_STATUS_VALUE,
} from "../../utils/AppEnumerations";

const GrievancesView = () => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [grievanceToEdit, setGrievanceToEdit] = useState<IGrievance>();
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [readOnlyForm, setReadOnlyForm] = useState(false);
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [grievancesList, setGrievancesList] = useState<IGrievance[]>([]);
  const { pagination, setPagination } = usePagination({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "complaint_title",
      orderBy: "ASC",
    },
  });
  useEffect(() => {
    fetchGrievances(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch]);

  const fetchGrievances = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchGrievancesApiCall(
        setGrievancesList,
        setPagination,
        payloadPagination,
        { enterpriseSearch }
      );
      setIsLoading(false);
    }, 500);
  };

  useEffect(() => {
    fetchGrievances(pagination);
  }, [enterpriseSearch]);

  const updateGrievanceStatus = (item: IGrievance) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await updateGrievanceStatusApiCall(
        { grievance_status: `${GRIEVANCE_STATUS.InReview}` },
        item.id
      );
      setIsLoading(false);
      setShowForm(true);
      setGrievanceToEdit(item);
    }, 500);
  };

  const handleEditGrievance = (item: IGrievance) => {
    if (item.grievance_status === GRIEVANCE_STATUS_VALUE.Pending) {
      updateGrievanceStatus(item);
    } else {
      setShowForm(true);
      setGrievanceToEdit(item);
    }
  };

  const customActionView = (item: IGrievance) => {
    return (
      <div className="flex item-center  ml-8">
        <div
          className="mr-2 action-view"
          title={t("view_tooltip_icon")}
          onClick={() => {
            setShowForm(true);
            setGrievanceToEdit(item);
            setReadOnlyForm(true);
          }}
        >
          {eyeIcon}
        </div>
        {item.grievance_status !== GRIEVANCE_STATUS_VALUE.Close &&
          item.grievance_status !== GRIEVANCE_STATUS_VALUE.Invalid && (
            <div
              className="mr-2 action-view"
              title={t("edit_tooltip_icon")}
              onClick={() => {
                handleEditGrievance(item);
              }}
            >
              {editIcon}
            </div>
          )}
      </div>
    );
  };

  const handleCloseForm = (
    status: boolean,
    selectedGrievanceStatus: number,
    id: number
  ) => {
    setShowForm(status);
    setReadOnlyForm(status);
    if (grievanceToEdit) {
      setGrievanceToEdit(undefined);
    } else {
      setEnterpriseSearch("");
    }
    const updatedGrievanceList = grievancesList.map((grievance) => {
      if (grievance.id === id) {
        return {
          ...grievance,
          grievance_status:
            GRIEVANCE_STATUS_VALUE_TABLE[
              selectedGrievanceStatus as keyof typeof GRIEVANCE_STATUS_VALUE_TABLE
            ],
          unread_messages: 0,
        };
      }
      return grievance;
    });

    setGrievancesList(updatedGrievanceList);
  };

  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        handleSearchChange={(text) => {
          setEnterpriseSearch(text);
        }}
        searchText={enterpriseSearch}
        refreshTable={() => fetchGrievances(pagination)}
      />
      <ICTable<IGrievance>
        isLoading={isLoading}
        totalFlex="grid grid-cols-13"
        fields={getGrievancesFields(customActionView, t)}
        data={grievancesList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchGrievances({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchGrievances({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchGrievances({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
      {showForm && grievanceToEdit && (
        <GrievancesFormView
          handleClose={handleCloseForm}
          grievanceToEdit={grievanceToEdit}
          readOnly={readOnlyForm}
        />
      )}
    </div>
  );
};
export default GrievancesView;
