import { serviceMaker } from "..";
import { IActiveInactive, IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

interface ISystemHelpPayload {
  topic: string;
  display_sequence: number | undefined;
  help_description: string;
  reference_links: [];
  tags: string[];
  is_active?: string;
  help_video_url: string;
  help_category_id?: number;
  target_audiance: string;
}

export const GET_ALL_SYSTEM_HELP = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_system_help.url +
      `?query=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&sd=${search.deleteAll}&currentPage=${
        pagination.currentPage
      }&perPageRows=${pagination.perPageRows}&sortBy=${
        pagination.sortBy
      }&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_system_help.method
  );
};

export const POST_SYSTEM_HELP = (payload: ISystemHelpPayload) => {
  return serviceMaker(
    API_ENDPOINTS.create_system_help.url,
    API_ENDPOINTS.create_system_help.method,
    payload
  );
};
export const UPDATE_SYSTEM_HELP = (payload: ISystemHelpPayload, id: number) => {
  return serviceMaker(
    API_ENDPOINTS.update_system_help.url + `/${id}`,
    API_ENDPOINTS.update_system_help.method,
    payload
  );
};

export const DELETE_SYSTEM_HELP = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.delete_system_help.url + `/${id}`,
    API_ENDPOINTS.delete_system_help.method
  );
};

export const ACTIVE_INACTIVE_SYSTEM_HELP = async (
  id: number,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_inactive_system_help.url + `/${id}`,
    API_ENDPOINTS.active_inactive_system_help.method,
    activeInactiveValue
  );
};

export const GET_SYSTEM_HELP_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_system_help.url + `/${id}`,
    API_ENDPOINTS.get_all_system_help.method
  );
};
