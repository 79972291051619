import { decryptData } from "./../../utils/AppFunctions";
import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import {
  GET_ALL_REVIEW_LOGS,
  GET_TRAINER_APPLICATION_BY_ID,
  UPDATE_TRAINER_APPLICATION_STATUS,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
  TRAINER_APPLICATION_LABEL,
  TRAINER_APPLICATION_SECTION_REVIEW_LABEL,
  TRAINER_APPLICATION_SECTION_REVIEW_STATUS,
  TRAINER_APPLICATION_STATUS_OPTIONS,
} from "../../utils/AppConstants";
import {
  TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS,
  TRAINER_APPLICATION_STATUS,
} from "../../utils/AppEnumerations";
import { SweetAlertError, SweetAlertSuccess } from "../../utils/AppFunctions";

export interface ISectionReviewStatus {
  section: number;
  status: number;
  reason?: string;
}

export interface ITrainerApplicationValues {
  status: number;
  skill_set: number[];
  reason?: string;
  section_review_status?: ISectionReviewStatus[];
}
interface ISkillSet {
  id: number;
  title: string;
  isApprove: string;
}
interface ICertificatesUrl {
  certificate_image_url: string;
  category_name: string;
}
export interface ITrainerApplicationForm {
  id: number;
  submitted_date: Date;
  application_content: {
    skill_set: ISkillSet[];
    preferred_location: number;
    address: {
      address_line_1?: string;
      address_line_2?: string;
      address_line_3?: string;
      city: string;
      state_id: number;
      postcode: string;
    };
    gender: number;
    dob: string;
    is_marketing_partner: boolean;
    has_own_session_space: boolean;
    has_space_for_rent: boolean;
    identity_document_id: number;
    identity_document_image_url: string;
    address_document_id: number;
    address_document_image_url: string;
    certificates: ICertificatesUrl[];
    keycode_document_url: string;
    gender_label: string;
    preferred_location_label: string[];
  };
  application_status: number;
  applicant_name: string;
  email: string;
  phone: string;
  keycode: string;
  application_id: string;
}
export interface IReviewLog {
  review_date: string;
  review_status: number;
  review_comment: string;
  section_review_status: ISectionReviewStatus[];
  review_status_label: string;
  reviewed_by: string;
}
interface ITrainerDropDownOption {
  value: TRAINER_APPLICATION_STATUS;
  label: string;
}
export const trainerApplicationDropDownOptions = (
  trainerApplicationFormInitialValues: ITrainerApplicationForm | undefined,
  skillSetValue: number[],
  sectionReviewStatus: ISectionReviewStatus[]
): ITrainerDropDownOption[] => {
  const allDocumentApproved =
    sectionReviewStatus.length > 2 &&
    sectionReviewStatus.every(
      (item) =>
        item.status === TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Approved
    );
  const anyDocumentRejected = sectionReviewStatus.some(
    (item) =>
      item.status === TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Rejected
  );
  const { application_status, application_content } =
    trainerApplicationFormInitialValues || {};
  const skillSetMatch =
    skillSetValue.length === application_content?.skill_set.length;
  const skillSetLength = skillSetValue.length > 0;

  const addApprovedOption = (
    options: ITrainerDropDownOption[]
  ): ITrainerDropDownOption[] => {
    if (allDocumentApproved) {
      options.push({
        value: TRAINER_APPLICATION_STATUS.ScheduledInterview,
        label:
          TRAINER_APPLICATION_LABEL[
            TRAINER_APPLICATION_STATUS.ScheduledInterview
          ],
      });
    }
    if (skillSetMatch && allDocumentApproved) {
      options.push({
        value: TRAINER_APPLICATION_STATUS.Approved,
        label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Approved],
      });
    }
    if (
      skillSetMatch &&
      application_status === TRAINER_APPLICATION_STATUS.PartialApproved
    ) {
      options.push({
        value: TRAINER_APPLICATION_STATUS.Approved,
        label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Approved],
      });
    }
    if (
      skillSetMatch &&
      application_status === TRAINER_APPLICATION_STATUS.ScheduledInterview
    ) {
      options.push({
        value: TRAINER_APPLICATION_STATUS.Approved,
        label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Approved],
      });
    }
    return options;
  };

  const addPartialApproveOption = (
    options: ITrainerDropDownOption[]
  ): ITrainerDropDownOption[] => {
    if (
      application_status === TRAINER_APPLICATION_STATUS.ReviewInProgress
        ? allDocumentApproved && skillSetLength && !skillSetMatch
        : skillSetLength && !skillSetMatch
    ) {
      options.push({
        value: TRAINER_APPLICATION_STATUS.PartialApproved,
        label:
          TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.PartialApproved],
      });
    }
    return options;
  };

  const addChangeRequestedOption = (
    options: ITrainerDropDownOption[]
  ): ITrainerDropDownOption[] => {
    if (anyDocumentRejected) {
      options.push({
        value: TRAINER_APPLICATION_STATUS.ChangeRequested,
        label:
          TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.ChangeRequested],
      });
    }
    return options;
  };

  const modifyOptions = (
    options: ITrainerDropDownOption[]
  ): ITrainerDropDownOption[] => {
    return addChangeRequestedOption(
      addPartialApproveOption(addApprovedOption(options))
    );
  };

  switch (application_status) {
    case TRAINER_APPLICATION_STATUS.Hold:
      return modifyOptions([...TRAINER_APPLICATION_STATUS_OPTIONS.Locked]);
    case TRAINER_APPLICATION_STATUS.ScheduledInterview:
      return modifyOptions([
        ...TRAINER_APPLICATION_STATUS_OPTIONS.ScheduleInterview,
      ]);
    case TRAINER_APPLICATION_STATUS.PartialApproved:
      return modifyOptions([]);
    case TRAINER_APPLICATION_STATUS.ReviewInProgress:
      return skillSetLength && !skillSetMatch
        ? modifyOptions([
            ...TRAINER_APPLICATION_STATUS_OPTIONS.ReviewInProgress,
          ])
        : skillSetMatch
        ? modifyOptions([
            ...TRAINER_APPLICATION_STATUS_OPTIONS.ReviewInProgressAllApprove,
          ])
        : modifyOptions([
            ...TRAINER_APPLICATION_STATUS_OPTIONS.ReviewInProgress,
          ]);

    default:
      return modifyOptions([
        ...TRAINER_APPLICATION_STATUS_OPTIONS.ReviewInProgress,
      ]);
  }
};

export const handleTotalCount = (arr: ISectionReviewStatus[]) => {
  let totalCount = 0;

  if (arr !== null && arr.length > 0) {
    arr.forEach((val) => {
      if (val.status === TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Rejected) {
        totalCount++;
      }
    });
    return totalCount;
  }
};

export const sectionFieldValues = (
  arr: ISectionReviewStatus[],
  setVal: any,
  t: TUseTranslationTfn
) => {
  let str = "";
  for (const review of arr.sort((a, b) => a.section - b.section)) {
    str +=
      TRAINER_APPLICATION_SECTION_REVIEW_LABEL[
        review.section as keyof typeof TRAINER_APPLICATION_SECTION_REVIEW_LABEL
      ] +
      ": " +
      TRAINER_APPLICATION_SECTION_REVIEW_STATUS[
        review.status as keyof typeof TRAINER_APPLICATION_SECTION_REVIEW_STATUS
      ] +
      "\n";
    if (
      review.status === TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Rejected
    ) {
      str += t("rejection_reason") + ":" + review.reason + "\n\n";
    }
  }
  setVal(str);
};

export const fetchTrainerApplicationById = async (
  id: number,
  setTrainerApplicationFormInitialValues: TReactSetState<
    ITrainerApplicationForm | undefined
  >,
  setSkillSetValue: TReactSetState<number[]>,
  handleClose: (status: boolean) => void
) => {
  try {
    if (id) {
      const trainerApplicationById = await GET_TRAINER_APPLICATION_BY_ID(id);
      if (
        trainerApplicationById &&
        trainerApplicationById.code === DEFAULT_STATUS_CODE_SUCCESS
      ) {
        const applicationDetail = JSON.parse(
          decryptData(trainerApplicationById.data)
        );
        const filteredApproveSkills =
          applicationDetail.application_content.skill_set
            .filter((skill: ISkillSet) => skill.isApprove === "1")
            .map((skill: ISkillSet) => skill.id);
        setSkillSetValue(filteredApproveSkills);
        setTrainerApplicationFormInitialValues(
          JSON.parse(decryptData(trainerApplicationById.data))
        );
      } else {
        handleClose(false);
        SweetAlertError(
          trainerApplicationById.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
        );
      }
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(
      e?.trainerApplicationById?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const fetchReviewLogs = async (
  id: number,
  setReviewLogs: TReactSetState<IReviewLog[]>
) => {
  try {
    if (id) {
      const reviewLogs = await GET_ALL_REVIEW_LOGS(id);
      if (reviewLogs && reviewLogs.code === DEFAULT_STATUS_CODE_SUCCESS) {
        setReviewLogs(reviewLogs.data);
      } else {
        SweetAlertError(reviewLogs.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (e: any) {
    SweetAlertError(e?.reviewLogs?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleSubmitTrainerApplication = async (
  values: ITrainerApplicationValues,
  id: number | undefined,
  handleClose: (status: boolean) => void
) => {
  try {
    let resultTrainerApplication;
    if (id) {
      resultTrainerApplication = await UPDATE_TRAINER_APPLICATION_STATUS(
        id,
        values
      );
      if (
        resultTrainerApplication &&
        resultTrainerApplication.code === DEFAULT_STATUS_CODE_SUCCESS
      ) {
        SweetAlertSuccess(resultTrainerApplication.message);
        return handleClose(true);
      } else {
        SweetAlertError(
          resultTrainerApplication?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
        );
      }
    }
  } catch (e: any) {
    SweetAlertError(
      e?.resultTrainerApplication?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
