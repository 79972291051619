import { serviceMaker } from "..";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

export const LOG_ERROR = (payload: any) =>
  serviceMaker(
    API_ENDPOINTS.add_error_handling_token.url,
    API_ENDPOINTS.add_error_handling_token.method,
    payload
  );

export const LOG_ERROR_PT = (payload: any) =>
  serviceMaker(
    API_ENDPOINTS.add_error_handling_public_token.url,
    API_ENDPOINTS.add_error_handling_public_token.method,
    payload
  );

interface ICategoryHelpPayload {
  entity: string;
  data_value: string;
  display_value: string;
  sort?: string;
  order?: string;
}

export interface INotification {
  notfication_for: number | null;
  notification_body: string;
  image_url: string;
}

export const CATEGORY_HELP_DDL = (payload: ICategoryHelpPayload) => {
  return serviceMaker(
    API_ENDPOINTS.global_drop_down.url,
    API_ENDPOINTS.global_drop_down.method,
    payload
  );
};

export const SEND_NOTIFICATION = (payload: INotification) => {
  return serviceMaker(
    API_ENDPOINTS.send_notification.url,
    API_ENDPOINTS.send_notification.method,
    payload
  );
};

export interface IDDLFilter {
  field: string;
  value: string | number;
  case_sensitive?: boolean;
  multi_value?: boolean;
  condition: string;
}

export const GLOBAL_DROP_DOWN = (payload: {
  entity: string;
  data_value: string;
  display_value: string;
  filters?: IDDLFilter[];
}) =>
  serviceMaker(
    API_ENDPOINTS.global_drop_down.url,
    API_ENDPOINTS.global_drop_down.method,
    payload
  );

export const GET_PRESIGNED_URL = (url: string) =>
  serviceMaker(
    API_ENDPOINTS.get_presigned_url.url + `/${url}`,
    API_ENDPOINTS.get_presigned_url.method
  );

export const DELETE_PRESIGNED_URL = (url: string) =>
  serviceMaker(
    API_ENDPOINTS.delete_presigned_url.url + `/${url}`,
    API_ENDPOINTS.delete_presigned_url.method
  );

export const UPLOAD_IMAGE = (data: FormData) =>
  serviceMaker(
    API_ENDPOINTS.upload_image.url,
    API_ENDPOINTS.upload_image.method,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
