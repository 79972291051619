import { serviceMaker } from "..";
import { IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

interface IGrievanceConversationPayload {
  grievance_id: number;
  comment: string | null;
  file_path: string | null;
}
export interface IGrievancePayLoad {
  id: number;
  complaint_date: string;
  complaint_title: string;
  complaint_detail: string;
  grievance_status: string;
}
export const GET_ALL_GRIEVANCES = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_grievances.url +
      `?query=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&currentPage=${pagination.currentPage}&perPageRows=${
        pagination.perPageRows
      }&sortBy=${pagination.sortBy}&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_grievances.method
  );
};
export const GET_GRIEVANCE_CONVERSATION_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_grievances.url + `/${id}`,
    API_ENDPOINTS.get_all_grievances.method
  );
};

export const ADD_GRIEVANCE_CONVERSATION = (
  payload: IGrievanceConversationPayload
) => {
  return serviceMaker(
    API_ENDPOINTS.add_grievance_conversation.url,
    API_ENDPOINTS.add_grievance_conversation.method,
    payload
  );
};
export const UPDATE_GRIEVANCE_CONVERSATION = (
  payload: IGrievanceConversationPayload,
  id: number
) => {
  return serviceMaker(
    API_ENDPOINTS.update_grievance_conversation.url + `/${id}`,
    API_ENDPOINTS.update_grievance_conversation.method,
    payload
  );
};

export const UPDATE_GRIEVANCE_STATUS = (
  payload: { grievance_status: string },
  id: number
) => {
  return serviceMaker(
    API_ENDPOINTS.update_grievance_status.url + `/${id}`,
    API_ENDPOINTS.update_grievance_status.method,
    payload
  );
};

export const MARK_READ_GRIEVANCE = (grievanceId: number) =>
  serviceMaker(
    API_ENDPOINTS.mark_read_grievance.url + "/" + grievanceId,
    API_ENDPOINTS.mark_read_grievance.method,
    { is_seen: "1" }
  );
