import React, { useState } from "react";
import { timeIcon } from "../assets/icons/SvgIconList";
import { TOnChangeInput, TTimeFormat } from "../data/AppType";
import { getLocalDate } from "../utils/AppFunctions";
export interface ICTimePickerProps {
  value: string;
  onChangeTime?: (event: TOnChangeInput) => void;
  placeholder?: string;
  displayFormat?: TTimeFormat;
  min?: string;
  max?: string;
  errorMessage?: string;
  label?: string;
  containerClassName?: string;
  required?: boolean;
  disabled?: boolean;
}
const ICTimePicker: React.FC<ICTimePickerProps> = (props) => {
  const {
    containerClassName,
    value,
    placeholder,
    onChangeTime,
    min,
    max,
    errorMessage,
    label,
    required,
    disabled,
  } = props;
  const [selectedTime, setSelectedTime] = useState<string>(value || "");
  const [isFocused, setIsFocused] = useState(false);
  const handleTimeChange = (event: TOnChangeInput) => {
    setSelectedTime(event.target.value);
    if (onChangeTime) {
      onChangeTime(event);
    }
  };
  function getCurrentTime() {
    const now = getLocalDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const formattedHours = hours < 10 ? "0" + hours : hours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const currentTime = `${formattedHours}:${formattedMinutes}`;
    return currentTime;
  }
  const handleFocusTimePicker = () => {
    setIsFocused(true);
  };
  const handleBlurTimePicker = () => {
    setIsFocused(false);
  };
  return (
    <div className="pt-0 pb-0">
      <div
        className={`ic-date-input-container relative  ${
          errorMessage ? "error-border" : ""
        }${containerClassName}`}
      >
        {/* Removed as not working with safari */}
        {/* <label
          className={`absolute flex items-center  ${
            isFocused || selectedTime || value
              ? "floating-timePicker top-0  px-1 typo-placeholder-floating  "
              : " pl-1 typo-floating-label "
          } `}
        >
          {label + (required ? "*" : "")}
        </label> */}
        <label
          className={`absolute flex items-center floating-timePicker top-0 px-1 typo-placeholder-floating`}
        >
          {label + (required ? "*" : "")}
        </label>
        {/* Removed as not working with safari */}
        {/* <div className="flex justify-between items-center w-full h-6 ">
          <div>
            {value ? (
              value
            ) : placeholder ? (
              <span className="text-skin-time-picker ">{""}</span>
            ) : selectedTime ? (
              selectedTime
            ) : (
              getCurrentTime()
            )}
          </div>
          <div className="text-input-trailing">{timeIcon}</div>
        </div> */}
        {/* {!disabled && ( */}
        <input
          // Removed as not working with safari
          // className="ic-date-input"
          className="w-full border-0 outline-none pr-2 bg-transparent"
          type="time"
          disabled={disabled}
          max={max}
          min={min}
          onChange={handleTimeChange}
          onFocus={handleFocusTimePicker}
          onBlur={handleBlurTimePicker}
          value={selectedTime || selectedTime}
        />
        {/* )} */}
      </div>
      {errorMessage && <div className="typo-error">{errorMessage}</div>}
    </div>
  );
};
export default ICTimePicker;
