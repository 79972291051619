import { IDDL } from "../../data/AppInterface";
import { TReactSetState } from "../../data/AppType";
import { CATEGORY_HELP_DDL } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import { toastError } from "../../utils/AppFunctions";

export const fetchCategoryHelpDropDown = async (
  setCategoryHelpData: TReactSetState<IDDL[]>
) => {
  try {
    const categoryHelpValue = {
      entity: "help_category",
      data_value: "id",
      display_value: "category_title",
      sort: "display_sequence",
      order: "ASC",
    };
    const categoryHelpDdl = await CATEGORY_HELP_DDL(categoryHelpValue);

    if (
      categoryHelpDdl &&
      categoryHelpDdl.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      setCategoryHelpData(categoryHelpDdl.data);
    } else {
      toastError(categoryHelpDdl.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (error: any) {
    toastError(
      error?.categoryHelpDdl?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
