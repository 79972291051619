import { folderIcon } from "../../assets/icons/SvgIconList";
import {
  ITabelFieldViewProps,
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { ISearch } from "../../data/AppInterface";
import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import {
  GET_LIBRARY_DIRECTORY,
  GET_LIBRARY_DIRECTORY_SEARCH,
} from "../../services/Endpoints";
import {
  ACTIVE_INACTIVE_FILE,
  ACTIVE_INACTIVE_FOLDER,
  DELETE_FILE_LIBRARY,
  DELETE_FOLDER_LIBRARY,
} from "../../services/library/LibraryService";
import {
  DEFAULT_STATUS_CODE_CONSENT,
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import { BIT_VALUE, LIBRARY_FILE_TYPE } from "../../utils/AppEnumerations";
import {
  SweetAlertError,
  SweetAlertSuccess,
  toastError,
  toastSuccess,
} from "../../utils/AppFunctions";
import Swal from "sweetalert2";

export interface ILibrary {
  object_id: number;
  parent_directory_id?: number;
  file_type?: string;
  title: string;
  description?: string;
  is_premium?: string;
  is_active?: string;
  is_course_specific?: string;
  duration?: number;
  content_tags?: [];
  status?: string;
  price?: string;
  discounted_price?: string;
  file_path?: string;
  is_public?: string;
  created_date?: Date;
  modified_date?: Date;
  created_by?: string;
  cover_image_path?: string;
  deleted?: string;
}

export function getLibraryFields<T>(
  handelFormat: (item: ITabelFieldViewProps<T>) => JSX.Element | string,
  handleStatusChange: (item: T) => void,
  actionComponent: (item: T) => JSX.Element,
  checkBoxComponent: (item: T) => JSX.Element,
  isRender: boolean | undefined,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    ...(isRender
      ? [
          {
            name: t("select"),
            key: "select",
            sort: false,
            type: TABLE_FIELD_TYPE.custom,
            align: TABLE_FIELD_ALIGN.center,
            flex: "col-span-1",
            component: checkBoxComponent,
          },
        ]
      : []),
    {
      name: t("title"),
      key: "title",
      sort: true,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
      format: handelFormat,
    },
    {
      name: t("library_status"),
      key: "status",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("price"),
      key: "price",
      sort: false,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-1",
      format: ({ field, data }: ITabelFieldViewProps<T>) =>
        data["is_premium"] === "1" ? data["price"] : "-",
    },
    {
      name: t("discounted_price"),
      key: "discounted_price",
      sort: true,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-1",
      format: ({ field, data }: ITabelFieldViewProps<T>) =>
        data["is_premium"] === "1" ? data["discounted_price"] : "-",
    },
    {
      name: t("duration"),
      key: "duration",
      sort: true,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
      format: ({ field, data }: ITabelFieldViewProps<T>) =>
        data["duration"] ? data["duration"] + " Hours" : "-",
    },

    ...(!isRender
      ? [
          {
            name: t("library_active"),
            key: "is_active",
            sort: false,
            type: TABLE_FIELD_TYPE.switch,
            align: TABLE_FIELD_ALIGN.center,
            checkedValue: "1",
            handleCheck: handleStatusChange,
            flex: "col-span-1",
          },
        ]
      : []),
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },

    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    ...(!isRender
      ? [
          {
            name: t("action"),
            key: "action",
            sort: false,
            type: TABLE_FIELD_TYPE.custom,
            align: TABLE_FIELD_ALIGN.center,
            flex: "col-span-1",
            component: actionComponent,
          },
        ]
      : []),
  ];
}
export const fetchLibraryApiCall = async (
  id: number,
  setLibraryList: TReactSetState<ILibrary[]>,
  courseSpecific: boolean,
  isRender: boolean | undefined,
  search: ISearch
) => {
  try {
    const libraryLists = await GET_LIBRARY_DIRECTORY(
      id,
      courseSpecific,
      isRender,
      search
    );
    if (libraryLists && libraryLists.code === DEFAULT_STATUS_CODE_SUCCESS) {
      if (libraryLists.data) {
        setLibraryList(libraryLists.data);
      }
    } else {
      toastError(libraryLists.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.libraryLists?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const SearchLibrary = async (
  search: ISearch,
  setLibrarySearchList: TReactSetState<ILibrary[]>
) => {
  try {
    const librarySearch = await GET_LIBRARY_DIRECTORY_SEARCH(search);
    if (librarySearch && librarySearch.code === DEFAULT_STATUS_CODE_SUCCESS) {
      if (librarySearch.data) {
        setLibrarySearchList(librarySearch.data);
      }
    } else {
      toastError(librarySearch.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.librarySearch?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleActiveInactiveFolder = async (
  id: number,
  values: string,
  fetchLibrary: () => void,
  t: TUseTranslationTfn,
  subscriptionConsent: string
) => {
  try {
    const folderActiveInactive = await ACTIVE_INACTIVE_FOLDER(id, {
      is_active: values,
      subscription_consent: subscriptionConsent,
    });
    if (
      folderActiveInactive &&
      folderActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      fetchLibrary();
      toastSuccess(folderActiveInactive.message);
      return true;
    } else if (
      folderActiveInactive &&
      folderActiveInactive.code === DEFAULT_STATUS_CODE_CONSENT &&
      folderActiveInactive.data.data === "HasSubscription"
    ) {
      handleConsentActiveInactive(
        id,
        folderActiveInactive.message,
        t,
        values,
        fetchLibrary,
        LIBRARY_FILE_TYPE.Directory
      );
    } else {
      toastError(
        folderActiveInactive.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
      return false;
    }
  } catch (e: any) {
    toastError(
      e?.folderActiveInactive?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};

export const handleDeleteLibrary = async (
  id: number,
  subscription_consent: string,
  t: TUseTranslationTfn,
  fetchLibrary: () => void
) => {
  try {
    const libraryDeleted = await DELETE_FOLDER_LIBRARY(
      id,
      subscription_consent
    );
    if (libraryDeleted && libraryDeleted.code === DEFAULT_STATUS_CODE_SUCCESS) {
      SweetAlertSuccess(libraryDeleted.message);
      fetchLibrary();
      return true;
    } else if (
      libraryDeleted &&
      libraryDeleted.code === DEFAULT_STATUS_CODE_CONSENT &&
      libraryDeleted.data.data === "HasSubscription"
    ) {
      await handleConsentDelete(
        id,
        libraryDeleted.message,
        t,
        fetchLibrary,
        LIBRARY_FILE_TYPE.Directory
      );
      return false;
    } else {
      SweetAlertError(libraryDeleted.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    return false;
  } catch (e: any) {
    SweetAlertError(
      e?.libraryDeleted?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleDeleteLibraryFile = async (
  id: number,
  subscription_consent: string,
  t: TUseTranslationTfn,
  fetchLibrary: () => void
) => {
  try {
    const libraryDeleteFile = await DELETE_FILE_LIBRARY(
      id,
      subscription_consent
    );
    if (
      libraryDeleteFile &&
      libraryDeleteFile.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(libraryDeleteFile.message);
      fetchLibrary();
      return true;
    } else if (
      libraryDeleteFile &&
      libraryDeleteFile.code === DEFAULT_STATUS_CODE_CONSENT &&
      libraryDeleteFile.data.data === "HasSubscription"
    ) {
      await handleConsentDelete(id, libraryDeleteFile.message, t, fetchLibrary);
      return false;
    } else {
      SweetAlertError(
        libraryDeleteFile.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
    return false;
  } catch (e: any) {
    SweetAlertError(
      e?.libraryDeleteFile?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleActiveInactiveFile = async (
  id: number,
  values: string,
  fetchLibrary: () => void,
  t: TUseTranslationTfn,
  subscriptionConsent: string
) => {
  try {
    const fileActiveInactive = await ACTIVE_INACTIVE_FILE(id, {
      is_active: values,
      subscription_consent: subscriptionConsent,
    });
    if (
      fileActiveInactive &&
      fileActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      fetchLibrary();
      toastSuccess(fileActiveInactive.message);
      return true;
    } else if (
      fileActiveInactive &&
      fileActiveInactive.code === DEFAULT_STATUS_CODE_CONSENT &&
      fileActiveInactive.data.data === "HasSubscription"
    ) {
      handleConsentActiveInactive(
        id,
        fileActiveInactive.message,
        t,
        values,
        fetchLibrary
      );
    } else {
      toastError(fileActiveInactive.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      return false;
    }
  } catch (e: any) {
    toastError(
      e?.fileActiveInactive?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};

export const handleConsentDelete = (
  id: number,
  message: string,
  t: TUseTranslationTfn,
  fetchLibrary: () => void,
  itemType?: string
) => {
  Swal.fire({
    title: t("are_you_sure"),
    text: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: t("confirm"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (itemType && itemType === LIBRARY_FILE_TYPE.Directory) {
        handleDeleteLibrary(id, BIT_VALUE.One, t, fetchLibrary);
      } else {
        handleDeleteLibraryFile(id, BIT_VALUE.One, t, fetchLibrary);
      }
    }
  });
};

export const handleConsentActiveInactive = (
  id: number,
  message: string,
  t: TUseTranslationTfn,
  values: string,
  fetchLibrary: () => void,
  itemType?: string
) => {
  Swal.fire({
    title: t("are_you_sure"),
    text: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: t("confirm"),
  }).then(async (result) => {
    if (result.isConfirmed) {
      if (itemType && itemType === LIBRARY_FILE_TYPE.Directory) {
        await handleActiveInactiveFolder(
          id,
          values,
          fetchLibrary,
          t,
          BIT_VALUE.One
        );
      } else {
        await handleActiveInactiveFile(
          id,
          values,
          fetchLibrary,
          t,
          BIT_VALUE.One
        );
      }
    }
  });
};
