import {
  SweetAlertError,
  SweetAlertSuccess,
  getLocalDate,
  prepareMessageFromParams,
  toastError,
} from "../../../../../utils/AppFunctions";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../../../utils/AppConstants";
import {
  TReactSetState,
  TUseTranslationTfn,
} from "../../../../../data/AppType";
import * as Yup from "yup";
import { NavigateFunction } from "react-router-dom";
import { IAddService, IDDL } from "../../../../../data/AppInterface";
import {
  ADD_BASIC_INFO_SERVICE,
  GET_SERVICE_BY_ID,
  GET_SERVICE_SUB_CATEGORY_BY_ID_BASIC,
  UPDATE_BASIC_INFO_SERVICE,
} from "../../../../../services/Endpoints";
import { IServiceSubCategory } from "../../../product-service-subcategory/ProductServiceSubCategoryController";
import { routeAdmin, routeMaster } from "../../../../../routes/RouteHome";
import {
  BIT_VALUE,
  PRODUCT_SERVICE_TAB_NAVIGATION,
} from "../../../../../utils/AppEnumerations";
import { MAX_SERVICE_DAYS } from "../../../../../config/AppConfig";
import { GLOBAL_DROP_DOWN } from "../../../../../services/generic/GenericServices";

export const getBasicInfoInitialValues = () => {
  return {
    service_title: "",
    service_desc: "",
    service_category_id: undefined,
    service_sub_category_id: undefined,
    service_for_gender: undefined,
    service_age_min_criteria: undefined,
    service_age_max_criteria: undefined,
    service_tags: ["", ""],
    feedback_tags: ["", ""],
    is_active: BIT_VALUE.One,
    service_cost: undefined,
    service_discounted_price: undefined,
    service_duration: undefined,
    cancellation_cutoff_time_hours: undefined,
    service_days: [],
    is_offline: BIT_VALUE.Zero,
    service_media: undefined,
    is_published: BIT_VALUE.Zero,
    session_duration: undefined,
    publish_date: undefined,
    is_gender_specific: BIT_VALUE.Zero,
    is_peer_to_peer: BIT_VALUE.Zero,
  };
};

export const basicInfoServiceValidationSchema = (
  t: TUseTranslationTfn,
  isPublished: string
) => {
  return Yup.object().shape({
    service_title: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("service_title")],
        ])
      )
      .max(
        30,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("service title")],
          ["max", "30"],
        ])
      ),
    service_desc: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("service_desc")],
        ])
      )
      .max(
        1000,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("service_desc")],
          ["max", "1000"],
        ])
      )
      .min(
        50,
        prepareMessageFromParams(t("error_message_min_length"), [
          ["fieldName", t("service_desc")],
          ["min", "50"],
        ])
      ),
    service_for_gender: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("service_for_gender")],
      ])
    ),

    service_duration: Yup.number()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("service_duration")],
        ])
      )
      .positive(
        prepareMessageFromParams(t("error_message_negative"), [
          ["fieldName", t("service_duration")],
        ])
      )
      .max(
        Number(MAX_SERVICE_DAYS),
        prepareMessageFromParams(t("error_message_less_than"), [
          ["fieldName", t("service_duration")],
          ["fieldNameLess", t("service_duration")],
          ["fieldNameGrater", MAX_SERVICE_DAYS],
        ])
      ),
    // for future update
    // .when("interval_unit_type_id", {
    //   is: 1,
    //   then: (schema) =>
    //     schema.max(
    //       Number(MAX_SERVICE_DAYS),
    //       prepareMessageFromParams(t("error_message_less_than"), [
    //         ["fieldNameLess", t("service_duration")],
    //         ["fieldNameGrater", MAX_SERVICE_DAYS],
    //       ])
    //     ),
    //   otherwise: (schema) =>
    //     schema.when("interval_unit_type_id", {
    //       is: 4,
    //       then: (schema) =>
    //         schema.max(
    //           Number(MAX_SERVICE_HOURS),
    //           prepareMessageFromParams(t("error_message_less_than"), [
    //             ["fieldNameLess", t("service_duration")],
    //             ["fieldNameGrater", MAX_SERVICE_HOURS],
    //           ])
    //         ),
    //       // for future use
    //       // otherwise: schema => schema.when("interval_unit_type_id", {
    //       //   is: 3,
    //       //   then: schema => schema.max(
    //       //     4,
    //       //     prepareMessageFromParams(t("error_message_less_than"), [
    //       //       ["fieldNameLess", t("session_duration")],
    //       //       ["fieldNameGrater", "4"],
    //       //     ])
    //       //   )
    //       // })
    //     }),
    // }),

    session_duration: Yup.number()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("session_duration")],
        ])
      )
      .positive(
        prepareMessageFromParams(t("error_message_negative"), [
          ["fieldName", t("session_duration")],
        ])
      )
      .max(
        480,
        prepareMessageFromParams(t("error_message_less_than"), [
          ["fieldNameLess", t("session_duration")],
          ["fieldNameGrater", "480"],
        ])
      ),

    service_days: Yup.array()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("service_days")],
        ])
      )
      .of(Yup.string())

      .max(
        8,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("service_days")],
          ["max", "8"],
        ])
      ),

    service_category_id: Yup.number().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("service_category_id")],
      ])
    ),
    service_sub_category_id: Yup.number().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("service_sub_category_id")],
      ])
    ),

    service_age_max_criteria: Yup.number()
      .integer(
        prepareMessageFromParams(t("error_message_integer"), [
          ["fieldName", t("service_age_max_criteria")],
        ])
      )

      .moreThan(
        Yup.ref("service_age_min_criteria"),
        prepareMessageFromParams(t("error_message_greater_than"), [
          ["fieldNameLess", t("service_age_min_criteria")],
          ["fieldNameGrater", t("service_age_max_criteria")],
        ])
      )
      .max(
        100,
        prepareMessageFromParams(t("error_message_less_than"), [
          ["fieldNameLess", t("service_age_max_criteria")],
          ["fieldNameGrater", "100"],
        ])
      ),

    service_age_min_criteria: Yup.number()
      .integer(
        prepareMessageFromParams(t("error_message_integer"), [
          ["fieldName", t("service_age_min_criteria")],
        ])
      )
      .positive(
        prepareMessageFromParams(t("error_message_negative"), [
          ["fieldName", t("service_age_min_criteria")],
        ])
      )
      .max(
        100,
        prepareMessageFromParams(t("error_message_less_than"), [
          ["fieldNameLess", t("service_age_min_criteria")],
          ["fieldNameGrater", "100"],
        ])
      ),

    service_cost: Yup.number()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("service_cost")],
        ])
      )
      .min(
        0,
        prepareMessageFromParams(t("error_message_negative"), [
          ["fieldName", t("service_cost")],
        ])
      ),
    service_discounted_price: Yup.number()
      .lessThan(
        Yup.ref("service_cost"),
        prepareMessageFromParams(t("error_message_less_than"), [
          ["fieldNameLess", t("service_discounted_price")],
          ["fieldNameGrater", t("service_cost")],
        ])
      )
      .min(
        0,
        prepareMessageFromParams(t("error_message_negative"), [
          ["fieldName", t("service_discounted_price")],
        ])
      ),
    publish_date: Yup.string().when([], {
      is: () => isPublished === "1",
      then: () =>
        Yup.string().required(
          prepareMessageFromParams(t("error_message_required"), [
            ["fieldName", t("publish_date")],
          ])
        ),
    }),
    service_tags: Yup.array().of(
      Yup.string().max(
        100,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("service_tags")],
          ["max", "100"],
        ])
      )
    ),
    feedback_tags: Yup.array().of(
      Yup.string().max(
        100,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("service_feedback_tags")],
          ["max", "100"],
        ])
      )
    ),
    cancellation_cutoff_time_hours: Yup.number().positive(
      prepareMessageFromParams(t("error_message_negative"), [
        ["fieldName", t("cancellation_cutoff_time_hours")],
      ])
    ),
  });
};

export const getBasicInfoServiceById = async (
  id: number,
  copyService: boolean,
  navigate: NavigateFunction
) => {
  try {
    const result = await GET_SERVICE_BY_ID(id);
    if (result && result.code === DEFAULT_STATUS_CODE_SUCCESS) {
      return {
        id: copyService ? undefined : result.data.id,
        service_title: result.data.service_title || "",
        service_desc: result.data.service_desc || "",
        service_category_id: result.data.service_category_id || undefined,
        service_sub_category_id:
          result.data.service_sub_category_id || undefined,
        service_for_gender: result.data.service_for_gender || "",
        service_age_min_criteria:
          result.data.service_age_min_criteria || undefined,
        service_age_max_criteria:
          result.data.service_age_max_criteria || undefined,
        service_tags: result.data.service_tags || ["", ""],
        feedback_tags: result.data.feedback_tags || ["", ""],
        service_cost: result.data.service_cost
          ? Number(result.data.service_cost)
          : undefined,
        service_discounted_price:
          result.data.service_discounted_price !== null
            ? Number(result.data.service_discounted_price)
            : undefined,
        service_duration: result.data.service_duration || undefined,
        cancellation_cutoff_time_hours:
          result.data.cancellation_cutoff_time_hours || undefined,
        service_days: result.data.service_days || [],
        service_media: copyService ? undefined : result.data.app_media,
        is_active: result.data.is_active || BIT_VALUE.Zero,
        is_offline: result.data.is_offline || BIT_VALUE.Zero,
        is_published: result.data.is_published || BIT_VALUE.Zero,
        session_duration: result.data.session_duration || undefined,
        is_peer_to_peer: result.data.is_peer_to_peer || BIT_VALUE.Zero,
        is_gender_specific: result.data.is_gender_specific || BIT_VALUE.Zero,
        has_incomplete_session: copyService
          ? BIT_VALUE.Zero
          : result.data.has_incomplete_session || BIT_VALUE.Zero,
        publish_date: copyService
          ? undefined
          : result.data.publish_date
          ? getLocalDate(result.data.publish_date)
          : undefined,
      };
    } else {
      navigate("/" + routeAdmin.admin + "/" + routeMaster.serviceTable);
      toastError(result.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (error: any) {
    navigate("/" + routeAdmin.admin + "/" + routeMaster.serviceTable);
    toastError(error.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const fetchServiceSubCategoryDdl = async (
  setApiOptionsServiceCategory: TReactSetState<IDDL[]>,
  categoryId: number,
  subCategoryId?: number
) => {
  try {
    const payload = {
      entity: "service_sub_category",
      data_value: "id",
      display_value: "sub_category_title",
      required_ids: subCategoryId ? [subCategoryId] : [],
      filters: [
        {
          field: "service_category_id",
          value: categoryId,
          condition: "eq",
        },
      ],
    };
    const serviceCategoryDDLList = await GLOBAL_DROP_DOWN(payload);
    if (
      serviceCategoryDDLList &&
      serviceCategoryDDLList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (serviceCategoryDDLList.data) {
        setApiOptionsServiceCategory(serviceCategoryDDLList.data);
      } else {
        setApiOptionsServiceCategory([]);
      }
    } else {
      toastError(
        serviceCategoryDDLList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    toastError(
      e?.serviceCategoryDDLList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
export const handleSubmitBasicInfoService = async (
  values: IAddService,
  navigate: NavigateFunction,
  setCurrentIndex: TReactSetState<number>,
  buttonController: boolean,
  t: TUseTranslationTfn
) => {
  try {
    let resultService;
    if (values.id) {
      const resultService = await UPDATE_BASIC_INFO_SERVICE(values, values.id);
      if (resultService && resultService.code === DEFAULT_STATUS_CODE_SUCCESS) {
        if (buttonController === true) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          navigate("/" + routeAdmin.admin + "/" + routeMaster.serviceTable);
        }
        SweetAlertSuccess(resultService.message);
      } else {
        SweetAlertError(resultService.message);
      }
    } else {
      resultService = await ADD_BASIC_INFO_SERVICE(values);
      if (resultService && resultService.code === DEFAULT_STATUS_CODE_SUCCESS) {
        if (buttonController === true) {
          navigate("/" + routeAdmin.admin + "/" + routeMaster.addService, {
            state: {
              productServiceId: resultService.data.dataValues.id,
              productServiceTabNavigationState:
                PRODUCT_SERVICE_TAB_NAVIGATION.AdditionalInfo,
            },
          });
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          navigate("/" + routeAdmin.admin + "/" + routeMaster.serviceTable);
        }
        SweetAlertSuccess(resultService.message);
      } else {
        SweetAlertError(resultService.message);
      }
    }
  } catch (e: any) {
    SweetAlertError(
      e?.resultService?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
