import { ILibraryFilePayload } from "../../data/AppInterface";
import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import * as Yup from "yup";
import {
  SweetAlertError,
  SweetAlertSuccess,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  CREATE_FILE,
  GET_FILE_BY_ID,
  UPDATE_FILE_LIBRARY,
} from "../../services/Endpoints";
import { CHECK_LDC } from "../../services/library/LibraryService";

export interface ILibraryAddFileForm
  extends Omit<
    ILibraryFilePayload,
    "id" | "created_by" | "created_date" | "modified_by" | "modified_date"
  > {
  id: number | undefined;
}

export const libraryAddFileValidationSchema = (
  t: TUseTranslationTfn,
  isPremium?: string
) =>
  Yup.object().shape({
    title: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("title")],
        ])
      )
      .max(
        30,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("title")],
          ["max", "30"],
        ])
      ),
    description: Yup.string().max(
      4000,
      prepareMessageFromParams(t("error_message_max_length"), [
        ["fieldName", t("description")],
        ["max", "4000"],
      ])
    ),

    ...(isPremium === undefined
      ? {
          content_price: Yup.number().when("is_premium", {
            is: "1",
            then: () =>
              Yup.number()
                .required(
                  prepareMessageFromParams(t("error_message_required"), [
                    ["fieldName", t("price")],
                  ])
                )
                .min(
                  1,
                  prepareMessageFromParams(t("error_message_negative"), [
                    ["fieldName", t("price")],
                  ])
                )
                .max(
                  9999999,
                  prepareMessageFromParams(t("error_message_less_than"), [
                    ["fieldNameLess", t("price")],
                    ["fieldNameGrater", "9999999"],
                  ])
                ),
          }),
        }
      : {}),

    content_discounted_price: Yup.number().when("is_premium", {
      is: "1",
      then: () =>
        Yup.number()
          .max(
            Yup.ref("content_price"),
            prepareMessageFromParams(t("error_message_less_than"), [
              ["fieldNameLess", t("discounted_price")],
              ["fieldNameGrater", t("price")],
            ])
          )
          .positive(
            prepareMessageFromParams(t("error_message_negative"), [
              ["fieldName", t("discounted_price")],
            ])
          )
          .min(
            0,
            prepareMessageFromParams(t("error_message_negative"), [
              ["fieldName", t("discounted_price")],
            ])
          ),
    }),
  });

export const getLibraryAddFileInitialValues = (
  addFileToEdit: ILibraryFilePayload | undefined
): ILibraryAddFileForm => {
  return {
    id: addFileToEdit?.id,
    title: addFileToEdit?.title || "",
    description: addFileToEdit?.description || "",
    status: addFileToEdit?.status || 1,
    duration: addFileToEdit!?.duration,
    is_public: addFileToEdit?.is_public || "0",
    is_premium: addFileToEdit?.is_premium || "0",
    file_type: addFileToEdit?.file_type || 0,
    content_tags: addFileToEdit?.content_tags || [],
    content_price:
      addFileToEdit?.content_price !== null
        ? addFileToEdit?.content_price
        : undefined,
    is_course_specific: addFileToEdit?.is_course_specific || "0",
    file_path: addFileToEdit?.file_path || "",
    content_discounted_price:
      addFileToEdit?.content_discounted_price !== null
        ? addFileToEdit?.content_discounted_price
        : undefined,
    is_active: addFileToEdit?.is_active || "1",
  };
};

export const fetchLibraryAddFileById = async (
  id: number,
  setLibraryAddFileList: TReactSetState<ILibraryAddFileForm | undefined>,
  handleClose: (status: boolean) => void,
  setTags: TReactSetState<string[]>
) => {
  try {
    const libraryAddFileId = await GET_FILE_BY_ID(id);
    if (
      libraryAddFileId &&
      libraryAddFileId.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      setLibraryAddFileList({
        id: libraryAddFileId.data.id,
        title: libraryAddFileId.data.title,
        description: libraryAddFileId.data.description,
        status: libraryAddFileId.data.status,
        duration: libraryAddFileId.data.duration,
        is_public: libraryAddFileId.data.is_public,
        is_premium: libraryAddFileId.data.is_premium,
        is_course_specific: libraryAddFileId.data.is_course_specific,
        content_price:
          libraryAddFileId.data.content_price !== null
            ? libraryAddFileId.data.content_price
            : undefined,
        content_discounted_price:
          libraryAddFileId.data.content_discounted_price !== null
            ? libraryAddFileId.data.content_discounted_price
            : undefined,
        is_active: libraryAddFileId.data.is_active,
        file_path: libraryAddFileId.data.file_path || "",
        file_type: libraryAddFileId.data.file_type,
        content_tags: libraryAddFileId.data.content_tags,
      });
      setTags(libraryAddFileId.data.content_tags);
    } else {
      handleClose(false);
      SweetAlertError(
        libraryAddFileId.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(
      e?.libraryAddFileId?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleSubmitLibraryAddFile = async (
  values: ILibraryAddFileForm,
  id: number | undefined,
  handleClose: (status: boolean) => void,
  libraryDirectory: number,
  t: TUseTranslationTfn
) => {
  try {
    let resultLibraryAddFile;
    if (!id) {
      resultLibraryAddFile = await CREATE_FILE({
        ...values,
        directory_id: libraryDirectory,
      });
    } else {
      resultLibraryAddFile = await UPDATE_FILE_LIBRARY(
        { ...values, directory_id: libraryDirectory },
        id
      );
    }
    if (
      resultLibraryAddFile &&
      resultLibraryAddFile.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (!id) {
        const checkId = await CHECK_LDC({
          library_content_id: resultLibraryAddFile.data.id,
          library_directory_id: libraryDirectory,
        });
        if (checkId && checkId.code === DEFAULT_STATUS_CODE_SUCCESS) {
        }
      }
      id
        ? SweetAlertSuccess(
            prepareMessageFromParams(t("update_data_successfully"), [
              ["updatedName", t("file")],
            ])
          )
        : SweetAlertSuccess(
            prepareMessageFromParams(t("add_data_successfully"), [
              ["addName", t("file")],
            ])
          );
      return handleClose(true);
    } else {
      SweetAlertError(
        resultLibraryAddFile?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    SweetAlertError(
      e?.resultLibraryAddFile?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
