import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { GET_PRESIGNED_URL } from "../../services/generic/GenericServices";
import { encryptData } from "../../utils/AppFunctions";
import { DEFAULT_STATUS_CODE_SUCCESS } from "../../utils/AppConstants";
import { APP_IMAGE_URL } from "../../config/AppConfig";

const ICImageView = ({
  url = "",
  className,
  isPrivate,
}: {
  url: string;
  className?: string;
  isPrivate?: boolean;
}) => {
  const { t } = UseTranslationHook();
  const [imageUrl, setImageUrl] = useState<string | undefined>("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (url && isPrivate) {
          const resImage = await GET_PRESIGNED_URL(encryptData(url));
          if (resImage && resImage?.code === DEFAULT_STATUS_CODE_SUCCESS) {
            setImageUrl(resImage.data);
          }
        } else {
          setImageUrl(APP_IMAGE_URL + url);
        }
      } catch {
        setImageUrl(require("../../assets/image/noImage.png"));
      }
    };

    fetchImage();

    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [url]);

  return (
    <img
      className={className}
      src={imageUrl || require("../../assets/image/noImage.png")}
      alt={t("poster_image")}
      onError={() => setImageUrl(require("../../assets/image/noImage.png"))}
    />
  );
};
export default ICImageView;
