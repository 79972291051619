import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminLayout from "../components/layouts/admin/AdminLayout";
import FaqsView from "../screens/faqs/FaqsView";
import HighlightsView from "../screens/highlights/HighlightsView";
import BookingCancellationChargesView from "../screens/booking-cancellation-charges/BookingCancellationView";
import LibraryView from "../screens/library/LibraryView";
import ServiceSubCategory from "../screens/product-services/product-service-subcategory/ProductServiceSubCategory";
import ServiceCategory from "../screens/product-services/product-service-category/ProductServiceCategory";
import AddProductService from "../screens/product-services/add-product-service/AddProductService";
import ProductServicesTable from "../screens/product-services/product-service-table/ProductServicesTable";
import EventsView from "../screens/events/EventsView";
import BlogCategoryView from "../screens/blog-category/BlogCategoryView";
import SystemSettings from "../components/system-settings/SystemSettings";
import BlogView from "../screens/blog/BlogView";
import ContentManagement from "../screens/content-management/ContentManagement";
import SubscriptionPackagesView from "../screens/subscription-package/SubscriptionPackagesView";
import UserListView from "../screens/user-list/UserListView";
import TrainerApplicationView from "../screens/trainer-application/TrainerApplicationView";
import SystemConfigurations from "../screens/system-configuration/SystemConfigurations";
import GrievancesView from "../screens/grievances/GrievancesView";
import SystemHelpView from "../screens/system-help/SystemHelpView";
import BookingsDetailsView from "../screens/bookings-details/BookingsDetailsView";
import PaymentTransactionView from "../screens/payment-transaction/PaymentTransactionView";
import SessionsView from "../screens/sessions/SessionsView";
import Notification from "../screens/notification/notification";
import InquiryView from "../screens/inquiry/InquiryView";
import DashboardView from "../screens/dashboard/DashboardView";
import ResetPassword from "../screens/auth/reset-password/ResetPassword";
import NewsletterSubscriptionView from "../screens/newsletter-subscription/NewsletterSubscriptionView";
import PaymentDue from "../screens/payment-due/PaymentDue";
import PaymentDueSummary from "../screens/payment-due/PaymentDueSummary";
import PaymentHistory from "../screens/payment-history/PaymentHistory";

const RouterHome = () => {
  return (
    <Routes>
      <Route path={routeAdmin.admin} element={<AdminLayout />}>
        <Route
          path={routeMaster.serviceTable}
          element={<ProductServicesTable />}
        />
        <Route path={routeMaster.addService} element={<AddProductService />} />
        <Route
          path={routeMaster.serviceSubCategory}
          element={<ServiceSubCategory />}
        />
        <Route
          path={routeMaster.serviceCategory}
          element={<ServiceCategory />}
        />
        <Route path={routeMaster.faqs} element={<FaqsView />} />
        <Route path={routeMaster.highlights} element={<HighlightsView />} />
        <Route
          path={routeMaster.bookingCancellationChargesView}
          element={<BookingCancellationChargesView />}
        />
        <Route path={routeMaster.library} element={<LibraryView />} />
        <Route path={routeMaster.events} element={<EventsView />} />
        <Route path={routeMaster.blogCategory} element={<BlogCategoryView />} />
        <Route
          path={routeMaster.systemConfiguration}
          element={<SystemConfigurations />}
        />
        <Route path={routeMaster.blog} element={<BlogView />} />
        <Route
          path={routeMaster.subscriptionPackages}
          element={<SubscriptionPackagesView />}
        />
        <Route
          path={routeMaster.contentManagement}
          element={<ContentManagement />}
        />
        <Route path={routeMaster.userList} element={<UserListView />} />
        <Route
          path={routeMaster.trainerApplication}
          element={<TrainerApplicationView />}
        />
        <Route path={routeMaster.grievances} element={<GrievancesView />} />
        <Route path={routeMaster.systemHelp} element={<SystemHelpView />} />
        <Route
          path={routeMaster.bookingsDetails}
          element={<BookingsDetailsView />}
        />
        <Route
          path={routeMaster.paymentTransaction}
          element={<PaymentTransactionView />}
        />
        <Route path={routeMaster.sessions} element={<SessionsView />} />
        <Route path={routeMaster.notification} element={<Notification />} />
        <Route path={routeMaster.inquiry} element={<InquiryView />} />
        <Route path={routeMaster.dashboard} element={<DashboardView />} />
        <Route path={routeMaster.paymentHistory} element={<PaymentHistory />} />
        <Route path={routeMaster.paymentDue} element={<PaymentDue />} />
        <Route
          path={routeMaster.paymentSummary}
          element={<PaymentDueSummary />}
        />
        <Route
          path={routeMaster.newsletterSubscription}
          element={<NewsletterSubscriptionView />}
        />
      </Route>
      <Route
        path={routeResetPassword.resetPassword}
        element={<ResetPassword />}
      ></Route>
    </Routes>
  );
};

export default RouterHome;

export const routeMaster = {
  dataList: "datalist",
  dashboard: "dashboard",
  serviceSubCategory: "offering-sub-category",
  serviceCategory: "offering-category",
  serviceTable: "offerings",
  addService: "add-offering",
  courseContent: "course-content",
  faqs: "faqs",
  highlights: "highlights",
  library: "library",
  bookingCancellationChargesView: "booking-cancellation-charges",
  events: "events",
  blogCategory: "blogCategory",
  systemConfiguration: "system-settings",
  blog: "blog",
  contentManagement: "content-management",
  subscriptionPackages: "subscription-packages",
  userList: "user-list",
  trainerApplication: "trainer-applications",
  grievances: "grievances",
  systemHelp: "system-help",
  bookingsDetails: "bookings-details",
  paymentTransaction: "payment-Transaction",
  paymentHistory: "payment-history",
  paymentDue: "payment-due",
  paymentSummary: "payment-due/:encryptId",
  sessions: "sessions",
  notification: "notification",
  inquiry: "inquiry",
  newsletterSubscription: "newsletter-Subscription",
};
export const routeResetPassword = {
  resetPassword: "reset-password",
};
export const routeAdmin = {
  admin: "admin",
};

export const routeHome = {
  home: "home",
};
