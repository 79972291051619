import React from "react";
import { FormikProps } from "formik";
import ICTextInput from "./ICTextInput";

interface ColorPickerProps {
  formikProps: FormikProps<any>;
  fieldName: string;
  text: string;
  maxLength?: number;
  displayName: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  formikProps,
  fieldName,
  text,
  maxLength,
  displayName,
}) => {
  const { values, setFieldValue } = formikProps;
  const selectedColor = values[fieldName];

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(fieldName, event.target.value);
  };

  return (
    <div className="flex border-2 mb-2 px-2 ">
      <div className="w-1/3 flex items-center">{displayName}</div>
      <div className="text-left py-2 w-full">
        <ICTextInput
          value={selectedColor}
          maxLength={maxLength}
          onChange={handleColorChange}
          className="w-full outline-none px-2"
        />
      </div>
      <div className="text-right w-1/4 pl-4">
        <input
          type="color"
          className="h-full cursor-pointer w-full"
          id={values[fieldName]}
          name={values[fieldName]}
          value={selectedColor}
          onChange={handleColorChange}
        />
      </div>
    </div>
  );
};

export default ColorPicker;
