export enum SERVICE_METHODS {
  GET = "GET",
  GET_CONFIG = "GET_CONFIG",
  POST = "POST",
  POST_CONFIG = "POST_CONFIG",
  PUT = "PUT",
  PUT_CONFIG = "PUT_CONFIG",
  DELETE = "DELETE",
  DELETE_CONFIG = "DELETE_CONFIG",
  REQUEST = "REQUEST",
}

export enum LOCAL_STORAGE_KEYS {
  AccessToken = "AccessToken",
  UserInfo = "UserInfo",
  RefreshToken = "RefreshToken",
  ProfileName = "ProfileName",
  ProfileUrl = "ProfileUrl",
}

export enum DATE_FORMAT {
  "DD-Mon-YYYY" = 1, // 01-Mar-2023
  "DD-MM-YYYY" = 2, // 01-03-2023
  "YYYY-MM-DD" = 3, // 2023-01-03
  "MM-DD-YYYY" = 4,
}
export enum TIME_FORMAT {
  "HH:mm:ss" = "HH:mm:ss", // 24-hour format with seconds
  "HH:mm" = "HH:mm", // 24-hour format without seconds
  "hh:mm:ss a" = "hh:mm:ss a", // 12-hour format with seconds and AM/PM
  "hh:mm a" = "hh:mm a", // 12-hour format without seconds and AM/PM
}

export enum THEMES {
  Light = "Light",
  Dark = "Dark",
  Cold = "Cold",
  Winter = "Winter",
}

export enum FORGOT_PASSWORD_FLOWSTATUS {
  EmailId = 1,
  OTP = 2,
  SetPassword = 3,
}
export enum LOGIN_PASSWORD_FLOWSTATUS {
  username = 1,
  OTP = 2,
  password = 3,
}

export enum TIME_FORMAT {
  "hh:mm XM" = 1,
  "hh:mm" = 2,
}

export enum GENDER_VALUES {
  Male = "M",
  Female = "F",
  Both = "B",
  Unknown = "U",
}
export enum USER_GENDER_VALUES {
  Male = 1,
  Female = 2,
  Diverse = 3,
  Unknown = 4,
}
export enum GENDER_LABEL {
  Male = "Male",
  Female = "Female",
  Both = "Both",
  Diverse = "Diverse",
  Unknown = "Unknown",
}
export enum TRAINER_APPLICATION_DOCUMENT_SECTION {
  IdentityVerification = 1,
  AddressVerification = 2,
  KeycodeVerification = 3,
}
export enum TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS {
  Approved = 1,
  Rejected = 2,
}
export enum TRAINER_APPLICATION_STATUS {
  Draft = 1,
  SubmittedForReview = 2,
  ReviewInProgress = 3,
  ChangeRequested = 4,
  ScheduledInterview = 5,
  Hold = 6,
  Rejected = 7,
  Approved = 8,
  Archive = 9,
  Locked = 10,
  PartialApproved = 11,
}

export enum BOOKING_STATUS {
  AwaitingConfirmation = 1,
  BookingConfirmed = 2,
  Cancelled = 3,
}

export enum BOOKING_DATE_TYPE {
  ScheduleDate = 1,
  BookingDate = 2,
}
export enum TARGET_AUDIENCE_VALUES {
  Trainer = "T",
  User = "U",
  Public = "P",
  User_And_Trainer = "UT",
}

export enum TARGET_AUDIENCE_LABEL {
  Trainer = "Trainer",
  User = "User",
  Public = "Public",
  User_And_Trainer = "User & Trainer",
}

export enum TARGET_AUDIENCE_HELP_VALUES {
  Trainer = "T",
  User = "U",
  All = "A",
}
export enum TARGET_AUDIENCE_HELP {
  Trainer = "Trainer",
  User = "User",
  All = "All",
}

export enum LIBRARY_STATUS_VALUE {
  "Draft" = 1,
  "Published" = 2,
  "Discontinued" = 3,
}
export enum LIBRARY_STATUS_LABEL {
  "Draft" = "Draft",
  "Published" = "Published",
  "Discontinued" = "Discontinued",
}

export enum PRODUCT_SERVICE_TAB_NAVIGATION {
  BasicInfo = 0,
  AdditionalInfo = 1,
  CourseContent = 2,
  TimeSlots = 3,
  CancellationCharges = 4,
  FaqsTab = 5,
}
export enum TRAINER_APPLICATION_COLLATION_NAVIGATION {
  ApplicationInformation = 1,
  PreferredLocation = 2,
  SkillSet = 3,
  Certificates = 4,
  KeyCodeVerification = 5,
  Others = 6,
}
export enum TRAINER_APPLICATION_IMAGE_NAVIGATION {
  ApplicantNameImage = 1,
  AddressImage = 2,
  KeyCodeImage = 3,
  CertificatesImage = 4,
}

export const enum CONFIGURATION_TABS {
  GENERAL = 1,
  TRAINER = 2,
  USER = 3,
}

export const enum CONTROL_TYPE {
  TextInput = 1,
  NumberInput = 2,
  Switch = 3,
  PasswordInput = 4,
  TextArea = 5,
  Editor = 6,
  ColorPiker = 7,
}

export enum TRAINER_APPLICATION_ACTION_BUTTONS {
  IdentityButtonApprove = "button1",
  IdentityButtonReject = "button2",
  AddressButtonApprove = "button3",
  AddressButtonReject = "button4",
  KeycodeButtonApprove = "button5",
  KeycodeButtonReject = "button6",
}

export enum SYSTEM_CONFIGURATION_TABS {
  SystemSettings = 1,
  ContentManagement = 2,
  EmailTemplate = 3,
  SmsTemplate = 4,
}

export enum LIBRARY_CONTENT_TYPES {
  Unknown = "UNKNOWN",
  Directory = "DIRECTORY",
}

export enum GRIEVANCE_STATUS {
  Pending = 1,
  InReview = 2,
  Close = 3,
  Hold = 4,
  Invalid = 5,
}

export enum NOTIFICATION_SEND {
  All = 1,
  user = 2,
  trainer = 3,
}

export enum GRIEVANCE_STATUS_VALUE {
  Pending = "Pending",
  InReview = "Review In Progress",
  Hold = "Hold",
  Close = "Closed",
  Invalid = "Invalid",
}

export enum USER_STATIC_ROLE {
  SuperAdmin = 1,
  Customer = 2,
  Trainer = 3,
}

export enum FILE_UPLOAD_RETURN_TYPE {
  Pdf = "p",
  Image = "i",
  Video = "v",
}

export enum FILE_UPLOAD {
  Pdf = "application/pdf",
  Png = "image/png",
  Jpeg = "image/jpeg",
  Video = "video/mp4",
}

export enum FILE_UPLOAD_TYPE {
  Pdf = "pdf",
  Png = "png",
  Jpeg = "jpeg",
  Video = "mp4",
  Jpg = "jpg",
}

// payment transaction
export enum PAYMENT_ENTITY_TYPE_ENUM {
  Booking = "booking",
  LibraryContent = "library_content",
  Subscription = "subscription",
  event = "event",
  LibraryDirectory = "library_directory",
}

export enum PAYMENT_TRANSACTION_TYPE {
  Credit = "C",
  Debit = "D",
}

// library enum

export enum LIBRARY_FILE_TYPE {
  Directory = "DIRECTORY",
  Image = "IMAGE",
  Pdf = "PDF",
  Video = "VIDEO",
  Audio = "AUDIO",
}

export enum IS_PAID_EVENT {
  Yes = "1",
  No = "0",
}

// Payment transaction status
export enum PAYMENT_TRANSACTION_STATUS_ENUM {
  Initiated = 1,
  Captured = 2,
  Refunded = 3,
  Failed = 4,
}

export enum REPORT_ID {
  TotalRevenue = 1,
  MonthlyServiceRevenue = 2,
  MonthlySubscriptionRevenue = 3,
  TotalSession = 4,
  VisitorsImpression = 5,
  TopServices = 6,
  ServiceCategoryWiseSession = 7,
  TrainerRating = 8,
  CityWiseBookingVsRevenue = 9,
  MonthlyUserEngagement = 10,
  CustomerSatisfaction = 11,
  TopTrainer = 12,
}

export enum IMAGE_TYPE {
  Highlight = "1",
  Services = "2",
  Identity = "3",
  Post = "4",
  Events = "5",
  Blogs = "6",
  Profile = "7",
  Certificates = "8",
  Session = "9",
  ServiceCategory = "10",
  SystemHelp = "11",
  LibraryContent = "12",
  LibraryDirectory = "13",
  Notification = "14",
  Grievances = "15",
}

export enum IS_PREMIUM {
  Yes = "1",
  No = "0",
}

export enum PAYOUT_PAYMENT_STATUS {
  "queued" = "queued",
  "pending" = "pending",
  "rejected" = "rejected",
  "processing" = "processing",
  "processed" = "processed",
  "cancelled" = "cancelled",
  "reversed" = "reversed",
  "failed" = "failed",
}

export enum SYSTEM_CONFIGURATION_CONFIG_KEY {
  AdminVersion = "admin_app_version",
}

export enum BIT_VALUE {
  One = "1",
  Zero = "0",
}
