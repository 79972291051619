import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import {
  GET_SYSTEM_HELP_BY_ID,
  POST_SYSTEM_HELP,
  UPDATE_SYSTEM_HELP,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import { ISystemHelp } from "./SystemHelpController";
import * as Yup from "yup";

export interface ISystemHelpForm
  extends Omit<
    ISystemHelp,
    | "id"
    | "display_sequence"
    | "created_by"
    | "created_date"
    | "modified_by"
    | "modified_date"
  > {
  id: number | undefined;
  display_sequence: number | undefined;
  help_category_id: number | undefined;
}

export const systemHelpValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    topic: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("topic")],
        ])
      )
      .max(
        75,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("topic")],
          ["max", "75"],
        ])
      ),
    help_description: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("help_description")],
      ])
    ),
    tags: Yup.array().of(
      Yup.string()
        .required(
          prepareMessageFromParams(t("error_message_required"), [
            ["fieldName", t("tags")],
          ])
        )
        .max(
          50,
          prepareMessageFromParams(t("error_message_max_length"), [
            ["fieldName", t("tags")],
            ["max", "50"],
          ])
        )
    ),
    display_sequence: Yup.number()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("display_sequence")],
        ])
      )
      .min(
        1,
        prepareMessageFromParams(t("error_message_min_length"), [
          ["fieldName", t("display_sequence")],
          ["min", "1"],
        ])
      )
      .max(
        9999.99,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("display_sequence")],
          ["max", "9999.99"],
        ])
      ),
    help_category_id: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("help_category")],
      ])
    ),
  });
export const getSystemHelpInitialValues = (
  systemHelpToEdit: ISystemHelp | undefined
): ISystemHelpForm => {
  return {
    id: systemHelpToEdit?.id,
    topic: systemHelpToEdit?.topic || "",
    help_description: systemHelpToEdit?.help_description || "",
    help_category_id: systemHelpToEdit?.help_category_id,
    tags: systemHelpToEdit?.tags || [],
    display_sequence: systemHelpToEdit?.display_sequence,
    reference_links: systemHelpToEdit?.reference_links || [],
    help_video_url: systemHelpToEdit?.help_video_url || "",
    target_audiance: systemHelpToEdit?.target_audiance || "A",
    is_active: systemHelpToEdit?.is_active || "0",
  };
};

export const fetchSystemHelpById = async (
  id: number,
  setSystemHelpsList: TReactSetState<ISystemHelpForm | undefined>,
  handleClose: (status: boolean) => void,
  setTags: TReactSetState<string[]>
) => {
  try {
    const systemHelpId = await GET_SYSTEM_HELP_BY_ID(id);
    if (systemHelpId && systemHelpId.code === DEFAULT_STATUS_CODE_SUCCESS) {
      setSystemHelpsList({
        id: systemHelpId.data.id,
        topic: systemHelpId.data.topic,
        help_description: systemHelpId.data.help_description,
        help_category_id: systemHelpId.data.help_category.id,
        reference_links: systemHelpId.data.reference_links,
        tags: systemHelpId.data.tags,
        display_sequence: systemHelpId.data.display_sequence,
        help_video_url: systemHelpId.data.help_video_url,
        target_audiance: systemHelpId.data.target_audiance,
        is_active: systemHelpId.data.is_active,
      });
      setTags(systemHelpId.data.tags);
    } else {
      handleClose(false);
      SweetAlertError(systemHelpId.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(e?.systemHelpId?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleSubmitSystemHelp = async (
  values: ISystemHelpForm,
  id: number | undefined,
  handleClose: (status: boolean) => void,
  t: TUseTranslationTfn
) => {
  try {
    let resultSystemHelp;
    if (!id) {
      resultSystemHelp = await POST_SYSTEM_HELP(values);
    } else {
      resultSystemHelp = await UPDATE_SYSTEM_HELP(values, id);
    }
    if (
      resultSystemHelp &&
      resultSystemHelp.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      id
        ? SweetAlertSuccess(
            prepareMessageFromParams(t("update_data_successfully"), [
              ["updatedName", t("system_help")],
            ])
          )
        : SweetAlertSuccess(
            prepareMessageFromParams(t("add_data_successfully"), [
              ["addName", t("system_help")],
            ])
          );
      return handleClose(true);
    } else {
      SweetAlertError(
        resultSystemHelp?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    SweetAlertError(
      e?.resultSystemHelp?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
