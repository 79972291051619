import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import { GET_PAYMENT_DUE_SUMMARY } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
} from "../../utils/AppFunctions";

export interface IPaymentDueSummary {
  trainer_id: number;
  trainer_name: string;
  total_amount: string;
}

export interface IPaymentDueSummaryList {
  booking_date: string;
  booking_number: string;
  customer: string;
  service_title: string;
  session_date: string;
  service_cost: string;
  revenue_split_percentage: string;
  total_amount: string;
}

export function getPaymentDueSummaryFields<T>(
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("booking_date"),
      key: "booking_date",
      sort: false,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("booking_number"),
      key: "booking_number",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-3",
    },
    {
      name: t("customer"),
      key: "customer",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-4",
    },
    {
      name: t("service_title"),
      key: "service_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-5",
    },

    {
      name: t("session_date"),
      key: "session_date",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
    },
    {
      name: t("service_cost"),
      key: "service_cost",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-1",
    },
    {
      name: t("revenue_split_percentage"),
      key: "revenue_split_percentage",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("total_amount"),
      key: "total_amount",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-2",
    },
  ];
}

export const fetchPaymentDueSummaryApiCall = async (
  setPaymentDueSummaryHeader: TReactSetState<IPaymentDueSummary | null>,
  setPaymentDueSummaryList: TReactSetState<IPaymentDueSummaryList[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  trainerId: number
) => {
  try {
    const paymentSummaryListResponse = await GET_PAYMENT_DUE_SUMMARY(
      pagination,
      trainerId
    );
    if (
      paymentSummaryListResponse &&
      paymentSummaryListResponse.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (
        paymentSummaryListResponse.data &&
        paymentSummaryListResponse.data.item.length > 0
      ) {
        setPaymentDueSummaryHeader(
          paymentSummaryListResponse.data.header_details
        );
        setPaymentDueSummaryList(paymentSummaryListResponse.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(
            paymentSummaryListResponse.data.pagination
          ),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const paymentSummaryListResponse = await GET_PAYMENT_DUE_SUMMARY(
          resetPagination,
          trainerId
        );
        if (
          paymentSummaryListResponse &&
          paymentSummaryListResponse.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setPaymentDueSummaryList(paymentSummaryListResponse.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              paymentSummaryListResponse.data.pagination
            ),
          });
        } else {
          setPaymentDueSummaryList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(
        paymentSummaryListResponse.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    toastError(
      e?.paymentSummaryListResponse?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
