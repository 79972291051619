// import { TIsActive } from "../../../data/AppType";

import { serviceMaker } from "../..";
import {
  IActiveInactive,
  IPagination,
  ISearch,
} from "../../../data/AppInterface";
import { API_ENDPOINTS } from "../../../utils/ApiEndPoint";

interface IServiceSubCategoryPayload {
  category_title?: string;
  sub_category_title: string;
  is_active: string;
  deleted?: string;
  service_category_id?: number;
}

export const GET_SERVICE_SUBCATEGORY = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_service_subcategory.url +
      `?query=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&sd=${search.deleteAll}&currentPage=${
        pagination.currentPage
      }&perPageRows=${pagination.perPageRows}&sortBy=${
        pagination.sortBy
      }&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_service_subcategory.method
  );
};

export const POST_SERVICE_SUB_CATEGORY = (
  payload: IServiceSubCategoryPayload
) => {
  return serviceMaker(
    API_ENDPOINTS.add_service_sub_category.url,
    API_ENDPOINTS.add_service_sub_category.method,
    payload
  );
};

export const GET_SERVICE_SUB_CATEGORY_BY_ID = (id: number | undefined) => {
  return serviceMaker(
    API_ENDPOINTS.get_service_sub_category_by_id.url + `/${id}`,
    API_ENDPOINTS.get_service_sub_category_by_id.method
  );
};

export const UPDATE_SERVICE_SUB_CATEGORY = (
  payload: IServiceSubCategoryPayload,
  id: number
) => {
  return serviceMaker(
    API_ENDPOINTS.update_service_sub_category.url + `/${id}`,
    API_ENDPOINTS.update_service_sub_category.method,
    payload
  );
};

export const DELETE_SERVICE_SUB_CATEGORY = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.delete_service_sub_category.url + `/${id}`,
    API_ENDPOINTS.delete_service_sub_category.method
  );
};

export const ACTIVE_INACTIVE_SERVICE_SUB_CATEGORY = async (
  id: number,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_deactivate_service_sub_category.url + `/${id}`,
    API_ENDPOINTS.active_deactivate_service_sub_category.method,
    activeInactiveValue
  );
};
