import { serviceMaker } from "..";
import { IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

export const GET_ALL_BOOKINGS_DETAILS = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_bookings_details.url +
      `?search=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&${
        search.advanceSearch?.booking_status
          ? `status=${search.advanceSearch?.booking_status}`
          : ""
      }&${
        search.advanceSearch?.date_type
          ? `dateType=${search.advanceSearch?.date_type}`
          : ""
      }&fromDate=${search.advanceSearch?.from_date}&toDate=${
        search.advanceSearch?.to_date
      }&currentPage=${pagination.currentPage}&perPageRows=${
        pagination.perPageRows
      }&sortBy=${pagination.sortBy}&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_bookings_details.method
  );
};

export const GET_BOOKINGS_DETAILS_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_bookings_details_by_id.url + `/${id}`,
    API_ENDPOINTS.get_bookings_details_by_id.method
  );
};
