import React, { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  getLocalDate,
  hasAnyModification,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import { ErrorMessage, Form, Formik } from "formik";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import ICFormikTextInput from "../../components/formik-input/ICFormikTextInput";
import ICFormikDropDown from "../../components/formik-input/ICFormikDropDown";
import ICFormikTextArea from "../../components/formik-input/ICFormikTextIArea";
import ICFormikDatePicker from "../../components/formik-input/ICFormikDatePicker";
import {
  DATE_FORMAT,
  LIBRARY_CONTENT_TYPES,
} from "../../utils/AppEnumerations";
import ICFormikSwitch from "../../components/formik-input/ICFormikSwitch";
import ICButton from "../../core-component/ICButton";
import ICModal from "../../core-component/ICModal";
import {
  ISubscriptionPackageForm,
  fetchSubscriptionById,
  getSubscriptionInitialValues,
  handleSubmitSubscription,
  removeDuplicatesAndExclude,
  subscriptionValidationSchema,
} from "./SubscriptionPackageFormController";
import { TARGET_AUDIENCE_OPTION } from "../../utils/AppConstants";
import { ISubscriptionPackage } from "./SubscriptionPackagesViewController";
import LibrarySelectionView from "../library/LibrarySelectionView";
import { IItem } from "../../data/AppInterface";

interface ISubscriptionPackageFormProps {
  handleClose: (status: boolean) => void;
  subscriptionToEdit?: ISubscriptionPackage;
  readOnly: boolean;
  copyPackage: boolean;
}
const SubscriptionPackageForm: React.FC<ISubscriptionPackageFormProps> = ({
  handleClose,
  subscriptionToEdit,
  readOnly,
  copyPackage,
}) => {
  const { t } = UseTranslationHook();
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [subscriptionFormInitialValues, setSubscriptionFormInitialValues] =
    useState<ISubscriptionPackageForm>();
  const [selectedItems, setSelectedItems] = useState<IItem[]>([]);
  const libraryContent: number[] = [];
  const libraryDirectory: number[] = [];

  const checkFormModification = (values: ISubscriptionPackageForm) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      subscriptionFormInitialValues &&
      hasAnyModification(values, subscriptionFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };

  useEffect(() => {
    if (subscriptionToEdit?.id) {
      fetchSubscriptionById(
        subscriptionToEdit.id,
        setSubscriptionFormInitialValues,
        setSelectedItems,
        handleClose,
        copyPackage
      );
    } else {
      setSubscriptionFormInitialValues(getSubscriptionInitialValues());
    }
  }, []);

  return (
    <div>
      {subscriptionFormInitialValues && (
        <Formik
          initialValues={subscriptionFormInitialValues}
          validationSchema={() => subscriptionValidationSchema(t)}
          enableReinitialize
          onSubmit={(values, {}) => {
            if (selectedItems.length === 0) return;
            else {
              selectedItems.forEach((item) => {
                if (item.type === LIBRARY_CONTENT_TYPES.Directory) {
                  libraryDirectory.push(item.id);
                } else {
                  libraryContent.push(item.id);
                }
              });
              handleSubmitSubscription(
                {
                  ...values,
                  library_directory: values.library_directory
                    ? removeDuplicatesAndExclude(
                        libraryDirectory,
                        values.library_directory
                      )
                    : libraryDirectory,
                  library_content: values.library_content
                    ? removeDuplicatesAndExclude(
                        libraryContent,
                        values.library_content
                      )
                    : libraryContent,
                },
                subscriptionFormInitialValues?.id,
                handleClose,
                t
              );
            }
          }}
        >
          {(formikPorps) => {
            const { handleSubmit, values, submitCount } = formikPorps;

            return (
              <FormRightModalWrapper
                containerClassName={"!w-2/5"}
                onClose={() => checkFormModification(values)}
                title={t("subscription_packages")}
              >
                <Form
                  onSubmit={handleSubmit}
                  className="flex flex-col justify-between h-full"
                >
                  <div className="grid grid-cols-2 pt-2.5 gap-3">
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"package_title"}
                      placeholder={t("package_title")}
                      disabled={readOnly}
                      autoFocus
                      required
                    />
                    <ICFormikDropDown
                      {...formikPorps}
                      name={"target_audience"}
                      placeholder={t("target_audience")}
                      options={TARGET_AUDIENCE_OPTION}
                      disabled={readOnly}
                    />
                    <ICFormikTextArea
                      {...formikPorps}
                      containerClassName={"col-span-2"}
                      name={"package_description"}
                      placeholder={t("package_description")}
                      disabled={readOnly}
                    />

                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"package_price"}
                      placeholder={t("package_price")}
                      type="number"
                      disabled={readOnly}
                      required
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"package_discounted_price"}
                      placeholder={t("package_discounted_price")}
                      type="number"
                      disabled={readOnly}
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"expire_in_days"}
                      placeholder={t("expire_in_days")}
                      disabled={readOnly}
                      type="number"
                    />
                    <ICFormikDatePicker
                      {...formikPorps}
                      fieldName={"publish_date"}
                      placeholder={t("publish_date")}
                      dateProps={{
                        displayFormat: DATE_FORMAT["DD-Mon-YYYY"],
                        min: values.id ? values.publish_date : getLocalDate(),
                      }}
                      disabled={readOnly}
                      required
                    />
                    <div className="col-span-2">
                      <LibrarySelectionView
                        readOnly={readOnly}
                        courseSpecific={false}
                        sectionTitle="library_selection"
                        selectedItem={selectedItems}
                        setSelectedItem={setSelectedItems}
                      />
                      {submitCount > 0 && selectedItems.length < 1 && (
                        <div className="typo-error">
                          {t("library_selection_error_message_required")}
                        </div>
                      )}
                    </div>
                    <div className="checkbox-wrapper ml-1 col-span-2">
                      <label>
                        <input
                          type="checkbox"
                          name="allow_extension"
                          checked={formikPorps.values.allow_extension === "1"}
                          onChange={(e) => {
                            formikPorps.handleChange(e);
                            formikPorps.setFieldValue(
                              "allow_extension",
                              e.target.checked ? "1" : "0"
                            );
                          }}
                          disabled={readOnly}
                        />
                        <span className="ml-3">{t("allow_extension")}</span>
                      </label>
                    </div>
                    {values.allow_extension === "1" && (
                      <>
                        <ICFormikTextInput
                          {...formikPorps}
                          fieldName={"extension_price"}
                          placeholder={t("extension_price")}
                          disabled={readOnly}
                          type="number"
                          required
                        />
                        <ICFormikTextInput
                          {...formikPorps}
                          fieldName={"extension_expire_in_days"}
                          placeholder={t("extension_expire_in_days")}
                          disabled={readOnly}
                          type="number"
                          required
                        />
                      </>
                    )}

                    {values.id && (
                      <ICFormikSwitch
                        {...formikPorps}
                        containerClassName={
                          "flex items-center justify-start space-x-3 mt-4"
                        }
                        name={"is_active"}
                        placeholder={t("is_active")}
                        checkedValue={"1"}
                        unCheckedValue={"0"}
                        disabled={readOnly}
                        required
                      />
                    )}
                  </div>
                  {!readOnly && (
                    <ICButton className={"w-full"}>{t("submit")}</ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}
      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};

export default SubscriptionPackageForm;
