import React, { useState } from "react";
import { IPagination } from "../../data/AppInterface";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { prepareMessageFromParams } from "../../utils/AppFunctions";
import { PER_PAGE_ROW_LIST } from "../../config/AppConfig";
import { listIcon } from "../../assets/icons/SvgIconList";
import { TOnChangeSelect } from "../../data/AppType";

interface IPaginationFooter {
  pagination: IPagination;
  handleChangePerPageRows?: (perPageRows: number) => void;
  handleChangePage?: (page: number) => void;
}

const PaginationFooter = ({
  pagination,
  handleChangePerPageRows,
  handleChangePage,
}: IPaginationFooter) => {
  const { t } = UseTranslationHook();
  const pageClass =
    "border-skin-table border hover:bg-skin-table-hover typo-table-value bg-skin-table-surface flex items-center justify-center px-3 h-8";
  const activePageClass = "!bg-skin-table-active-page";
  const disableBtnClass = "!bg-skin-disabled hover:bg-skin-disabled";

  const onChangePerPageRows = (event: TOnChangeSelect) => {
    if (handleChangePerPageRows) {
      pagination.perPageRows = Number(event.target.value);
      handleChangePerPageRows(Number(event.target.value));
    }
  };
  const onChangePage = (page: number) => {
    if (handleChangePage) {
      pagination.currentPage = page;
      handleChangePage(page);
    }
  };
  const getPaginationMessage = () => {
    const startIndex =
      pagination.totalItems > 0
        ? ((pagination.currentPage - 1) * pagination.perPageRows + 1).toString()
        : "0";

    const pageEndIndex = pagination.currentPage * pagination.perPageRows;
    const endIndex =
      pagination.totalItems > 0
        ? (pageEndIndex < pagination.totalItems
            ? pageEndIndex
            : pagination.totalItems
          ).toString()
        : "0";
    return prepareMessageFromParams(t("pagination_showing"), [
      ["startIndex", startIndex],
      ["endIndex", endIndex],
      ["totalEntries", pagination.totalItems.toString()],
    ]);
  };

  const paginationBtnView = (page: number, key?: string) => {
    return (
      <button onClick={() => onChangePage(page)}>
        <span
          className={`${pageClass} ${
            pagination.currentPage === page ? activePageClass : ""
          }`}
        >
          {page}
        </span>
      </button>
    );
  };

  const dotBtnView = (
    <button>
      <span className={`${pageClass}`}>...</span>
    </button>
  );

  const prevBtnView = (
    <button
      onClick={() =>
        pagination.currentPage !== 1
          ? onChangePage(pagination.currentPage - 1)
          : {}
      }
    >
      <span
        className={`${pageClass} ${
          pagination.currentPage !== 1 ? "" : disableBtnClass
        } rounded-l`}
      >
        {t("previous")}
      </span>
    </button>
  );

  const nextBtnView = (
    <button
      onClick={() =>
        pagination.currentPage !== pagination.totalPages
          ? onChangePage(pagination.currentPage + 1)
          : {}
      }
    >
      <span
        className={`${pageClass}  ${
          pagination.currentPage !== pagination.totalPages
            ? ""
            : disableBtnClass
        } rounded-r`}
      >
        {t("next")}
      </span>
    </button>
  );

  const getPageToShow = () => {
    return (
      <>
        {prevBtnView}
        {pagination.totalPages < 8 ? (
          <>
            {Array.from(
              { length: pagination.totalPages },
              (_, index) => index
            ).map((page, index) => (
              <span key={index.toString()}>{paginationBtnView(page + 1)}</span>
            ))}
          </>
        ) : pagination.currentPage === 1 ? (
          <>
            {paginationBtnView(1)}
            {paginationBtnView(2)}
            {paginationBtnView(3)}
            {paginationBtnView(4)}
            {paginationBtnView(5)}
            {dotBtnView}
            {paginationBtnView(pagination.totalPages)}
          </>
        ) : pagination.currentPage === pagination.totalPages ? (
          <>
            {paginationBtnView(1)}
            {dotBtnView}
            {paginationBtnView(pagination.totalPages - 4)}
            {paginationBtnView(pagination.totalPages - 3)}
            {paginationBtnView(pagination.totalPages - 2)}
            {paginationBtnView(pagination.totalPages - 1)}
            {paginationBtnView(pagination.totalPages)}
          </>
        ) : (
          <>
            {paginationBtnView(1)}
            {pagination.currentPage > 3 && dotBtnView}
            {pagination.currentPage > pagination.totalPages - 3 &&
              paginationBtnView(pagination.totalPages - 4)}
            {pagination.currentPage > pagination.totalPages - 2 &&
              paginationBtnView(pagination.totalPages - 3)}
            {pagination.currentPage > 2 &&
              paginationBtnView(pagination.currentPage - 1)}
            {paginationBtnView(pagination.currentPage)}
            {pagination.currentPage < pagination.totalPages - 1 &&
              paginationBtnView(pagination.currentPage + 1)}
            {pagination.currentPage < 4 &&
              paginationBtnView(pagination.currentPage + 2)}
            {pagination.currentPage < 3 &&
              paginationBtnView(pagination.currentPage + 3)}
            {pagination.currentPage < 2 &&
              paginationBtnView(pagination.currentPage + 4)}
            {pagination.currentPage < pagination.totalPages - 2 && dotBtnView}
            {paginationBtnView(pagination.totalPages)}
          </>
        )}
        {nextBtnView}
      </>
    );
  };

  return (
    <div className="w-full bg-skin-table-base py-1 px-2 rounded-b-sm block md:flex justify-between items-center">
      <div className="flex items-center">
        {listIcon}
        <p className="typo-table-value">{getPaginationMessage()}</p>
      </div>
      <div className="block md:flex items-center">
        <div className="flex items-center mr-4">
          <label className="block typo-table-value pe-2">
            {t("row_per_page")}
          </label>
          <select
            className="px-1 py-1 border border-skin-table rounded shadow-sm focus:outline-none "
            value={pagination.perPageRows}
            onChange={onChangePerPageRows}
          >
            {PER_PAGE_ROW_LIST.map((perPageRow) => (
              <option key={perPageRow} value={perPageRow}>
                {perPageRow}
              </option>
            ))}
          </select>
        </div>
        <div className="inline-flex text-sm">{getPageToShow()}</div>
      </div>
    </div>
  );
};

export default PaginationFooter;
