import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  IInquiry,
  INITIAL_INQUIRY,
  fetchInquiryApiCall,
  getInquiryFields,
  handleCompletedInquiry,
} from "./InquiryController";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import usePagination from "../../hooks/UsePaginationHook";
import { IAdvanceSearch, IPagination } from "../../data/AppInterface";
import { ORDER_BY } from "../../utils/AppConstants";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import ICTable from "../../core-component/ICTable";
import ICButton from "../../core-component/ICButton";
import {
  approveIcon,
  eyeIcon,
  filterClearIcon,
} from "../../assets/icons/SvgIconList";
import ICDatePicker from "../../core-component/ICDatePicker";
import { getLocalDate } from "../../utils/AppFunctions";
import InquiryDetails from "./InquiryDetails";
import ICSweetAlertModal from "../../core-component/ICSweetAlertModal";

const InquiryView = () => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [inquiryList, setInquiryList] = useState<IInquiry[]>([]);
  const [inquiryDetailsToEdit, setInquiryDetailsToEdit] = useState<
    IInquiry | undefined
  >();
  const { pagination, setPagination } = usePagination({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });
  const [advanceSearch, setAdvanceSearch] =
    useState<IAdvanceSearch>(INITIAL_INQUIRY);

  useEffect(() => {
    fetchInquiry(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, advanceSearch]);

  const fetchInquiry = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchInquiryApiCall(
        setInquiryList,
        setPagination,
        payloadPagination,
        { enterpriseSearch, advanceSearch }
      );
      setIsLoading(false);
    }, 500);
  };
  const handleCloseForm = (status: boolean) => {
    setShowForm(false);
    if (status) fetchInquiry(pagination);
  };
  const handleCompletedInquiryCfm = async (id: number) => {
    const data = await handleCompletedInquiry(id);
    if (data) {
      fetchInquiry(pagination);
    }
  };
  const customActionView = (item: IInquiry) => (
    <div className="flex item-center justify-center ml-2">
      {item.addressed_by !== null ? (
        <>
          <div
            className="mr-2 action-view"
            title={t("view_tooltip_icon")}
            onClick={() => {
              setShowForm(true);
              setInquiryDetailsToEdit(item);
            }}
          >
            {eyeIcon}
          </div>
        </>
      ) : (
        <>
          <div
            className="mr-2 action-view"
            title={t("view_tooltip_icon")}
            onClick={() => {
              setShowForm(true);
              setInquiryDetailsToEdit(item);
            }}
          >
            {eyeIcon}
          </div>
          <div className="mr-2 action-view" title={t("completed_tooltip_icon")}>
            <ICSweetAlertModal
              title={t("inquiry_modal_title")}
              text={t("inquiry_modal_text_massage")}
              onConfirm={handleCompletedInquiryCfm}
              itemId={item.id}
              icon={approveIcon}
              confirmButtonText={t("inquiry_modal_button_text")}
            />
          </div>
        </>
      )}
    </div>
  );
  const filterView = (
    <div className="w-full grid grid-cols-3 gap-4 mt-3 bg-skin-background">
      <ICDatePicker
        useValue
        label={t("from_date")}
        placeholder={t("from_date")}
        value={
          advanceSearch.from_date !== ""
            ? getLocalDate(advanceSearch.from_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            from_date: event.target.value,
          }))
        }
      />
      <ICDatePicker
        useValue
        label={t("to_date")}
        placeholder={t("to_date")}
        value={
          advanceSearch.to_date !== ""
            ? getLocalDate(advanceSearch.to_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            to_date: event.target.value,
          }))
        }
      />
      <div className="checkbox-wrapper m-2">
        <label className="flex items-center">
          <input
            type="checkbox"
            className="h-4 w-4 mr-2 rounded-md"
            checked={advanceSearch.show_historical}
            onChange={(event) =>
              setAdvanceSearch((prev) => ({
                ...prev,
                show_historical: event.target.checked,
              }))
            }
          />
          <span className="text-lg">{t("show_historical")}</span>
        </label>
      </div>
      <ICButton
        onClick={() => setAdvanceSearch(INITIAL_INQUIRY)}
        className="flex"
      >
        <div className="w-5 h-5">{filterClearIcon}</div>&nbsp;{t("clear")}
      </ICButton>
    </div>
  );
  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        filterNode={filterView}
        handleSearchChange={(text) => {
          setEnterpriseSearch(text);
        }}
        searchText={enterpriseSearch}
        refreshTable={() => fetchInquiry(pagination)}
      />
      <ICTable<IInquiry>
        isLoading={isLoading}
        totalFlex="grid grid-cols-13"
        fields={getInquiryFields(customActionView, t)}
        data={inquiryList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchInquiry({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchInquiry({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchInquiry({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
      {showForm && (
        <InquiryDetails
          handleClose={handleCloseForm}
          inquiryDetailsToEdit={inquiryDetailsToEdit}
        />
      )}
    </div>
  );
};
export default InquiryView;
