import { useEffect, useState } from "react";
import { IEvents } from "./EventsViewController";
import {
  IEventsForm,
  eventsValidationSchema,
  fetchEventById,
  getEventsInitialValues,
  handleSubmitEvent,
} from "./EventFormController";
import {
  SweetAlertError,
  getLocalDate,
  hasAnyModification,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import ICModal from "../../core-component/ICModal";
import ICButton from "../../core-component/ICButton";
import { Form, Formik } from "formik";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import ICFormikDropDown from "../../components/formik-input/ICFormikDropDown";
import ICFormikTextInput from "../../components/formik-input/ICFormikTextInput";
import ICFormikTimePicker from "../../components/formik-input/ICFormikTimePicker";
import ICFormikDatePicker from "../../components/formik-input/ICFormikDatePicker";
import {
  DATE_FORMAT,
  IMAGE_TYPE,
  FILE_UPLOAD,
  FILE_UPLOAD_RETURN_TYPE,
  IS_PAID_EVENT,
  TIME_FORMAT,
} from "../../utils/AppEnumerations";
import ICFormikTextArea from "../../components/formik-input/ICFormikTextIArea";
import ICFileUpload from "../../core-component/ICFileUpload";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import ICFormikSwitch from "../../components/formik-input/ICFormikSwitch";
import { fetchUploadImageService } from "../global/image-service/ImageServices";
import { IMediaItemImage } from "../../data/AppInterface";
import {
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
  TARGET_AUDIENCE_OPTION,
} from "../../utils/AppConstants";
interface IEventsFormProps {
  handleClose: (status: boolean) => void;
  eventsToEdit?: IEvents;
  readOnly?: boolean;
}

const EventsForm: React.FC<IEventsFormProps> = ({
  handleClose,
  eventsToEdit,
  readOnly,
}) => {
  const { t } = UseTranslationHook();
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [eventsFormInitialValues, setEventsFormInitialValues] =
    useState<IEventsForm>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [eventImage, setEventImage] = useState<IMediaItemImage[]>([]);
  const [loading, setLoading] = useState(false);
  const checkFormModification = (values: IEventsForm) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      eventsFormInitialValues &&
      hasAnyModification(values, eventsFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };
  useEffect(() => {
    if (eventsToEdit?.id) {
      fetchEventById(eventsToEdit.id, setEventsFormInitialValues, handleClose);
    } else {
      setEventsFormInitialValues(getEventsInitialValues());
    }
  }, []);

  useEffect(() => {
    if (
      eventsFormInitialValues?.events_media &&
      eventsFormInitialValues.events_media.length > 0
    ) {
      const transformedData = eventsFormInitialValues.events_media.map(
        (item) => ({
          ...item,
        })
      );
      setEventImage(transformedData);
    } else {
      setEventImage([]);
    }
  }, [eventsFormInitialValues]);
  function checkFileType(fileType: string): string | null {
    if (fileType) {
      if (fileType === FILE_UPLOAD.Png || fileType === FILE_UPLOAD.Jpeg) {
        return FILE_UPLOAD_RETURN_TYPE.Image;
      } else if (fileType === FILE_UPLOAD.Video) {
        return FILE_UPLOAD_RETURN_TYPE.Video;
      }
    }
    return null;
  }
  const handleSubmitEventsWithFileUpload = async (values: IEventsForm) => {
    if (eventImage.length + selectedFiles.length === 0) return;
    try {
      setLoading(true);
      const updatedEventMedia = eventImage ? [...eventImage] : [];
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const fileType = checkFileType(file.type);
        const formData = new FormData();
        formData.append(`myImageFile`, file);
        formData.append(`type`, IMAGE_TYPE.Events);
        values.id && formData.append(`typeId`, values.id.toString());
        const mediaUrl = await fetchUploadImageService(formData);
        if (!mediaUrl) {
          return null;
        }
        const newMediaItem = {
          media_type: fileType !== null ? fileType : "",
          media_url: mediaUrl,
          media_title: selectedFiles[i].name,
        };
        updatedEventMedia.push(newMediaItem);
      }

      await handleSubmitEvent(
        {
          ...values,
          events_media: updatedEventMedia,
          registration_required:
            values.is_paid_event === IS_PAID_EVENT.Yes
              ? IS_PAID_EVENT.No
              : values.registration_required,
          registration_fee:
            values.is_paid_event === IS_PAID_EVENT.Yes
              ? values.registration_fee
              : null,
        },

        eventsToEdit?.id,
        handleClose
      );
    } catch (e) {
      SweetAlertError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      {eventsFormInitialValues && (
        <Formik
          initialValues={eventsFormInitialValues}
          validationSchema={() => eventsValidationSchema(t)}
          enableReinitialize
          onSubmit={(values) => {
            handleSubmitEventsWithFileUpload(values);
          }}
        >
          {(formikPorps) => {
            const { handleSubmit, values, submitCount } = formikPorps;
            return (
              <FormRightModalWrapper
                containerClassName={"!w-1/2"}
                onClose={() => checkFormModification(values)}
                title={t("events")}
              >
                <Form onSubmit={handleSubmit} className="flex flex-col h-full">
                  <div className="flex-1 table-scroll grid grid-cols-2 gap-3 p-2">
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"title"}
                      placeholder={t("title")}
                      disabled={readOnly}
                      autoFocus
                      required
                    />
                    <ICFormikDropDown
                      {...formikPorps}
                      name={"target_audience"}
                      placeholder={t("target_audience")}
                      options={TARGET_AUDIENCE_OPTION}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikTextArea
                      {...formikPorps}
                      containerClassName={"col-span-2"}
                      name={"description"}
                      placeholder={t("description")}
                      disabled={readOnly}
                    />

                    <ICFormikDatePicker
                      {...formikPorps}
                      fieldName={"event_start_date"}
                      placeholder={t("event_start_date")}
                      dateProps={{
                        displayFormat: DATE_FORMAT["DD-Mon-YYYY"],
                        min: values.id
                          ? values.event_start_date
                          : getLocalDate(),
                        max: values.event_end_date,
                      }}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikDatePicker
                      {...formikPorps}
                      fieldName={"event_end_date"}
                      placeholder={t("event_end_date")}
                      dateProps={{
                        displayFormat: DATE_FORMAT["DD-Mon-YYYY"],
                        min: values.id ? values.event_end_date : getLocalDate(),
                      }}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikTimePicker
                      {...formikPorps}
                      fieldName={"event_start_time"}
                      placeholder={t("event_start_time")}
                      timeProps={{
                        displayFormat: TIME_FORMAT["hh:mm"],
                      }}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikTimePicker
                      {...formikPorps}
                      fieldName={"event_end_time"}
                      placeholder={t("event_end_time")}
                      timeProps={{
                        displayFormat: TIME_FORMAT["hh:mm"],
                      }}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikTextArea
                      {...formikPorps}
                      containerClassName={"col-span-2"}
                      name={"venue"}
                      placeholder={t("venue")}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"venue_lat"}
                      placeholder={t("venue_lat")}
                      disabled={readOnly}
                      type="number"
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"venue_long"}
                      placeholder={t("venue_long")}
                      disabled={readOnly}
                      type="number"
                    />
                    <ICFormikDatePicker
                      {...formikPorps}
                      fieldName={"permission_letter_submit_date"}
                      placeholder={t("permission_letter_submit_date")}
                      dateProps={{
                        displayFormat: DATE_FORMAT["DD-Mon-YYYY"],
                        min: values.id
                          ? values.permission_letter_submit_date
                          : getLocalDate(),
                        max: values.permission_approval_date,
                      }}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikDatePicker
                      {...formikPorps}
                      fieldName={"permission_approval_date"}
                      placeholder={t("permission_approval_date")}
                      dateProps={{
                        displayFormat: DATE_FORMAT["DD-Mon-YYYY"],
                        min: values.id
                          ? values.permission_approval_date
                          : getLocalDate(),
                      }}
                      disabled={readOnly}
                      required
                    />

                    <div className="checkbox-wrapper ml-1 mb-4">
                      <label>
                        <input
                          type="checkbox"
                          name="is_paid_event"
                          checked={formikPorps.values.is_paid_event === "1"}
                          onChange={(e) => {
                            formikPorps.handleChange(e);
                            formikPorps.setFieldValue(
                              "is_paid_event",
                              e.target.checked ? "1" : "0"
                            );
                          }}
                          disabled={readOnly}
                        />
                        <span className="ml-3">{t("is_paid_event")}</span>
                      </label>
                    </div>
                    {values.is_paid_event === "0" && (
                      <div className="checkbox-wrapper ml-1 mb-4">
                        <label>
                          <input
                            type="checkbox"
                            name="registration_required"
                            checked={
                              formikPorps.values.registration_required === "1"
                            }
                            onChange={(e) => {
                              formikPorps.handleChange(e);
                              formikPorps.setFieldValue(
                                "registration_required",
                                e.target.checked ? "1" : "0"
                              );
                            }}
                            disabled={readOnly}
                          />
                          <span className="ml-3">
                            {t("registration_required")}
                          </span>
                        </label>
                      </div>
                    )}

                    {values.is_paid_event === "1" && (
                      <ICFormikTextInput
                        {...formikPorps}
                        type="number"
                        fieldName={"registration_fee"}
                        placeholder={t("registration_fee")}
                        disabled={readOnly}
                        required
                      />
                    )}
                    <ICFormikTextInput
                      {...formikPorps}
                      containerClassName="col-span-2"
                      fieldName={"meeting_link"}
                      placeholder={t("meeting_link")}
                      disabled={readOnly}
                    />
                    <div className="col-span-2">
                      <ICFileUpload
                        title={t("upload")}
                        maxFiles={5}
                        maxFileSizeMB={5000}
                        acceptedFileTypes={["jpg", "png", "jpeg", "mp4"]}
                        onFilesSelected={setSelectedFiles}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        data={eventImage}
                        setData={setEventImage}
                        disabled={readOnly}
                      />
                      {submitCount > 0 &&
                        eventImage.length + selectedFiles.length === 0 && (
                          <div className="typo-error mb-1">
                            {prepareMessageFromParams(
                              t("error_message_required"),
                              [["fieldName", t("upload_images")]]
                            )}
                          </div>
                        )}
                    </div>
                    {values.id && (
                      <ICFormikSwitch
                        {...formikPorps}
                        containerClassName={
                          "flex items-center justify-start space-x-3 mt-4"
                        }
                        name={"is_active"}
                        placeholder={t("is_active")}
                        checkedValue={"1"}
                        unCheckedValue={"0"}
                        disabled={readOnly}
                        required
                      />
                    )}
                  </div>
                  {!readOnly && (
                    <ICButton
                      disabled={loading ? true : false}
                      loading={loading}
                      className={"w-full"}
                    >
                      {t("submit")}
                    </ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}
      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};

export default EventsForm;
