import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IMediaItemImage, IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import {
  ACTIVE_INACTIVE_EVENT,
  DELETE_EVENT,
  GET_ALL_EVENTS,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
  toastSuccess,
} from "../../utils/AppFunctions";

export interface IEvents {
  id: number;
  title: string;
  description: string;
  event_start_date?: Date;
  event_end_date?: Date;
  event_start_time?: string;
  event_end_time?: string;
  target_audience: string;
  is_paid_event: string;
  registration_fee?: number | null;
  venue: string;
  venue_lat: string;
  venue_long: string;
  permission_letter_submit_date?: Date;
  permission_approval_date?: Date;
  is_active: string;
  deleted?: string;
  events_media: IMediaItemImage[];
  registration_required: string | null;
  meeting_link: string | null;
}

export function getEventsFields<T>(
  handleStatusChange: (item: T) => void,
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("title"),
      key: "title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("event_start_date"),
      key: "event_start_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("event_start_time"),
      key: "event_start_time",
      sort: true,
      type: TABLE_FIELD_TYPE.time,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("event_end_date"),
      key: "event_end_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },

    {
      name: t("event_end_time"),
      key: "event_end_time",
      sort: true,
      type: TABLE_FIELD_TYPE.time,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("status"),
      key: "is_active",
      sort: false,
      type: TABLE_FIELD_TYPE.switch,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      handleCheck: handleStatusChange,
      flex: "col-span-1",
    },
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("venue"),
      key: "venue",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      limit: 18,
      title: "venue",
    },

    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}

export const fetchEventApiCall = async (
  setEventsList: TReactSetState<IEvents[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const eventsList = await GET_ALL_EVENTS(pagination, search);
    if (eventsList && eventsList.code === DEFAULT_STATUS_CODE_SUCCESS) {
      if (eventsList.data && eventsList.data.item.length > 0) {
        setEventsList(eventsList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(eventsList.data.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetEventsList = await GET_ALL_EVENTS(resetPagination, search);
        if (
          resetEventsList &&
          resetEventsList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setEventsList(resetEventsList.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(resetEventsList.data.pagination),
          });
        } else {
          setEventsList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(eventsList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.eventsList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleActiveInactiveEvent = async (id: number, values: string) => {
  try {
    const eventActiveInactive = await ACTIVE_INACTIVE_EVENT(id, {
      is_active: values,
    });
    if (
      eventActiveInactive &&
      eventActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      toastSuccess(eventActiveInactive.message);
      return true;
    }
    return false;
  } catch (e: any) {
    toastError(
      e?.eventActiveInactive?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};

export const handleDeleteEvent = async (id: number, t: TUseTranslationTfn) => {
  try {
    const eventDelete = await DELETE_EVENT(id);
    if (eventDelete && eventDelete.code === DEFAULT_STATUS_CODE_SUCCESS) {
      SweetAlertSuccess(eventDelete.message);
      return true;
    } else {
      SweetAlertError(eventDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    return false;
  } catch (e: any) {
    SweetAlertError(e?.eventDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    return false;
  }
};
