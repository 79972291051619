import NumberSpinnerAnimation from "../../../components/NumberSpinnerAnimation";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import { CURRENCY_SIGN } from "../../../utils/AppConstants";
export interface IReportTileView {
  title: string;
  total: number;
  className?: string;
  growth?: number;
  currency_sign?: boolean;
}
const ReportTileView = ({
  title,
  total,
  className,
  growth,
  currency_sign = false,
}: IReportTileView) => {
  const { t } = UseTranslationHook();

  return (
    <div
      className={`rounded-2xl flex flex-col h-40 sm:h-48 p-4 ${
        className ? className : ""
      }`}
    >
      <h4
        className={`${
          className ? className : ""
        } h-8 sm:h-12 typo-title-medium`}
      >
        {title}
      </h4>
      <h1 className="mx-auto typo-header-large">
        {currency_sign && CURRENCY_SIGN}
        <NumberSpinnerAnimation number={total} />
      </h1>
      {growth && (
        <p
          className={`typo-title-small
          ${
            Number(growth) === 0
              ? ""
              : growth < 0
              ? "!text-red-500"
              : "!text-green-950"
          }  mt-auto mx-auto`}
        >
          {Number(growth) === 0 ? "" : growth > 0 ? "+" : "-"}{" "}
          {<NumberSpinnerAnimation number={Math.abs(growth)} />}%{" "}
          {t("from_previous_month")}
        </p>
      )}
    </div>
  );
};
export default ReportTileView;
