// import moment from 'moment-timezone';
// moment

import { DATE_FORMAT, TIME_FORMAT } from "./AppEnumerations";
import { TDateFormat, TReactSetState } from "../data/AppType";
import CryptoJS from "crypto-js";
import { localStorageUtils } from "./LocalStorageUtil";
import { FormikErrors, FormikTouched } from "formik";
import {
  IItemPagination,
  ILibraryItemData,
  ILibraryItems,
  IPaginationApi,
} from "../data/AppInterface";
import ICSweetAlert from "../core-component/ICSweetAlert";
import { CURRENCY_SIGN, MONTH_NAMES } from "./AppConstants";
import ICToastMessage from "../core-component/ICToastMessage";
import { CRYPTO_CIPHER_IV, CRYPTO_CIPHER_KEY } from "../config/AppConfig";

// import { US_CURRENCY } from "./AppConstants";

// Region Date And Time

export const convertUTCToTime = (
  utcTime: Date,
  offset: number,
  format?: TDateFormat
) => {
  const newTime = new Date(utcTime.getTime() + offset * 60 * 1000);
  if (format) {
    return dateFormat(newTime);
  }
  return newTime;
};
export const getLocalDate = (objPar?: string | number | Date) => {
  if (objPar) {
    return new Date(objPar);
  } else {
    return new Date();
  }
};

export const responseDateStringToDate = (
  dateString: string | undefined
): Date => {
  if (dateString) {
    const [day, month, year] = dateString.split("/");
    const newDate = getLocalDate(`${year}/${month}/${day}`);
    return newDate;
  } else {
    return getLocalDate();
  }
};

export const dateFormat = (
  date: Date,
  format: TDateFormat = DATE_FORMAT["DD-Mon-YYYY"]
) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  switch (format) {
    case DATE_FORMAT["DD-MM-YYYY"]:
      return `${day}-${month}-${year}`;
    case DATE_FORMAT["MM-DD-YYYY"]:
      return `${month}-${day}-${year}`;
    case DATE_FORMAT["DD-Mon-YYYY"]:
      return `${day}-${MONTH_NAMES[date.getMonth()]}-${year}`;
    case DATE_FORMAT["YYYY-MM-DD"]:
      return `${year}-${month}-${day}`;
  }
};

// time format
export const timeFormat = (date: Date): string => {
  const hours = date.getHours();
  const formattedHours = String(hours % 12 || 12).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const amPm = hours >= 12 ? "PM" : "AM";

  return `${formattedHours}:${minutes} ${amPm}`;
};
export const getLocalTime = (timeString?: string): Date | undefined => {
  if (timeString) {
    const [timePart] = timeString.split("+");

    const date = new Date(`2000-01-01T${timePart}`);

    return date;
  } else {
    return undefined;
  }
};

// Region Currency

export default function formatMoneyWithExchange(
  amount: number,
  targetCurrency?: string
): string {
  const formatter = new Intl.NumberFormat("USD", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(amount);
}

// end currency

//region phone format

export function formatPhoneNumber(
  phoneNumber: string,
  countryCode: string = "US"
): string {
  switch (countryCode) {
    case "US":
      // Format US phone number as (XXX) XXX-XXXX
      return phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3");
    case "IN":
      // Format India phone number as +91 XXXXX-XXXXX
      return phoneNumber.replace(/^(\d{5})(\d{5})$/, "+91 $1-$2");
    case "CA":
      // Format Canada phone number as XXX-XXX-XXXX
      return phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, "$1-$2-$3");
    default:
      return phoneNumber; // Return unformatted number if country code is not recognized
  }
}

// region end phone format

// Region   Tost Message

const toastSuccess = (
  message: string,
  duration?: number,
  position?: string
) => {
  ICToastMessage("success", message, duration, position);
};

const toastError = (message: string, duration?: number, position?: string) => {
  ICToastMessage("error", message, duration, position);
};

const toastWarning = (
  message: string,
  duration?: number,
  position?: string
) => {
  ICToastMessage("warning", message, duration, position);
};

const toastInfo = (message: string, duration?: number, position?: string) => {
  ICToastMessage("info", message, duration, position);
};

export { toastSuccess, toastError, toastWarning, toastInfo };

// Region End TostMessage

// Region ClearSession

export const clearSession = () => {
  localStorageUtils.removeAll();
};
// Region End ClearSession

export const prepareMessageFromParams = (
  message: string,
  params: [string, string][]
) => {
  let resultMessage = message;
  for (const [key, value] of params) {
    resultMessage = resultMessage.replaceAll("<<" + key + ">>", value);
  }
  return resultMessage;
};

export const getFormikErrorMessage = <T extends Object>(
  errors: FormikErrors<T>,
  touched: FormikTouched<T>,
  name: keyof T
) => {
  let errorMessage;
  if (touched[name] && errors[name]) {
    errorMessage = errors[name];
  }
  if (!errorMessage) {
    return "";
  }
  if (typeof errorMessage === "string") {
    return errorMessage;
  }
  return "";
};

export const hasAnyModification = <T extends Object>(
  object: T,
  objectToCompare: T
) => {
  let hasChanges = false;
  let key: keyof T;
  for (key in object) {
    if (object[key] !== objectToCompare[key]) {
      hasChanges = true;
      break;
    }
  }
  return hasChanges;
};

export const SweetAlertSuccess = (message: string) => {
  const type = "success";
  ICSweetAlert({ type, message });
};
export const SweetAlertError = (message: string) => {
  const type = "error";
  ICSweetAlert({ type, message });
};
export const SweetAlertInfo = (message: string) => {
  const type = "info";
  ICSweetAlert({ type, message });
};

//generate pagination object from Api
export const generatePaginationFromApiRes = (
  objPagination: IItemPagination
) => {
  const pagination: IPaginationApi = {
    totalPages: objPagination.totalPages,
    totalItems: objPagination.totalItems,
    perPageRows: objPagination.perPageRows,
    currentPage: objPagination.currentPage,
  };
  return pagination;
};

export const resetPaginationWithPpr = (perPageRows: number) => {
  return {
    totalPages: 0,
    totalItems: 0,
    perPageRows: perPageRows,
    currentPage: 1,
  };
};

export const dateFormate = (item: string | Date): string => {
  const date = new Date(item);

  const month = MONTH_NAMES[date.getMonth()];
  let dt = date.getDate();
  const year = date.getFullYear();
  if (dt < 10) {
    dt = parseInt("0" + dt); // Parse it as an integer
  }
  return `${dt} ${month} ${year}`;
};

export const getTextContentOnly = (htmlText: string) => {
  const regex = /(<([^>]+)>)/gi;
  const result = htmlText ? htmlText.replace(regex, "") : "";
  return result;
};

export const formatTime = (timeString: string) => {
  // Parse the time string
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Create a Date object and set the hours, minutes, and seconds
  const time = getLocalDate();
  time.setHours(hours);
  time.setMinutes(minutes);
  time.setSeconds(seconds);

  // Format the time as "HH:MM AM/PM"
  const formattedTime = time.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "numeric",
    hour12: true,
    hourCycle: "h23",
  });

  return formattedTime;
};

export const combinedLibraryItems = (
  library_items: ILibraryItemData[],
  setSelectedItems: TReactSetState<ILibraryItems[]>
) => {
  const combinedItemsArray: ILibraryItems[] = [];

  library_items.forEach((item) => {
    item.library_content &&
      item.library_content.forEach((content) => {
        combinedItemsArray.push({ ...content, type: "CONTENT" });
      });

    item.library_directory &&
      item.library_directory.forEach((directory) => {
        combinedItemsArray.push({ ...directory, type: "DIRECTORY" });
      });
  });

  setSelectedItems(combinedItemsArray);
};

// add Limit functionality
export const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  } else {
    const truncatedLength = maxLength - 3;
    return text.substring(0, truncatedLength) + "...";
  }
};

export const encryptData = (text: string) => {
  const encryptedText = CryptoJS.AES.encrypt(text, CRYPTO_CIPHER_KEY, {
    iv: CryptoJS.enc.Hex.parse(CRYPTO_CIPHER_IV),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  }).toString();
  return CryptoJS.enc.Hex.stringify(CryptoJS.enc.Utf8.parse(encryptedText));
};

export const decryptData = (text: string) => {
  const descryptedText = CryptoJS.AES.decrypt(
    CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Hex.parse(text)),
    CRYPTO_CIPHER_KEY,
    {
      iv: CryptoJS.enc.Hex.parse(CRYPTO_CIPHER_IV),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  ).toString(CryptoJS.enc.Utf8);
  return descryptedText;
};

// mobile number encryption
export const maskMobileNumber = (number: string): string => {
  if (number.length < 5) return number; // Ensure number has at least 10 digits
  return number.slice(0, -4).replace(/\d/g, "*") + number.slice(-4);
};

// email encryption

export const maskEmail = (email: string): string => {
  if (typeof email !== "string" || !email.includes("@")) {
    console.error("Invalid email format");
    return "";
  }

  const [localPart, domainPart] = email.split("@");

  if (!localPart || !domainPart) {
    console.error("Invalid email format after split");
    return email;
  }

  const maskedLocalPart =
    localPart.length > 2
      ? localPart[0] +
        localPart.slice(1, -1).replace(/./g, "*") +
        localPart.slice(-1)
      : localPart.replace(/./g, "*");

  const domainParts = domainPart.split(".");
  if (domainParts.length < 2) {
    console.error("Domain part format is invalid");
    return `${maskedLocalPart}@${domainPart.replace(/./g, "*")}`;
  }

  const [domain, ...tldParts] = domainParts;
  const maskedDomain = domain.replace(/./g, "*");
  const tld = tldParts.join(".");

  return `${maskedLocalPart}@${maskedDomain}.${tld}`;
};

export const formatToCurrency = (number: number) => {
  const numberStr = number.toString().split(".");
  const integerPart = numberStr[0];
  const decimalPart = numberStr.length > 1 ? "." + numberStr[1] : "";

  let lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  let formattedNumber =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    lastThreeDigits +
    decimalPart;

  return formattedNumber;
};

export const addCurrencySign = (value: number | string) => {
  return CURRENCY_SIGN + value;
};

// strength Password

export const calculatePasswordStrength = (password: string) => {
  let score = 0;
  if (password.length >= 8) score += 1;
  if (password.length >= 12) score += 1;
  if (/[A-Z]/.test(password)) score += 1;
  if (/[a-z]/.test(password)) score += 1;
  if (/[0-9]/.test(password)) score += 1;
  if (/[^A-Za-z0-9]/.test(password)) score += 1;

  let strength = "";
  let color = "";
  switch (score) {
    case 1:
    case 2:
      strength = "Very Weak";
      color = "red";
      break;
    case 3:
      strength = "Weak";
      color = "orange";
      break;
    case 4:
      strength = "Moderate";
      color = "#023020";
      break;
    case 5:
      strength = "Strong";
      color = "blue";
      break;
    case 6:
      strength = "Very Strong";
      color = "green";
      break;
    default:
      strength = "Very Weak";
      color = "red";
  }
  return { strength, color };
};
