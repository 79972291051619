import React from "react";
import { IItem } from "../data/AppInterface";
import { TOnChangeInput } from "../data/AppType";
import { LIBRARY_CONTENT_TYPES } from "../utils/AppEnumerations";

interface ICheckboxProps {
  id: number;
  type?: string; // Adding type to props
  title: string; // Adding type to props
  selectedItems: IItem[];
  setSelectedItems?: (item: IItem[]) => void;
}

const ICCheckBox: React.FC<ICheckboxProps> = ({
  id,
  type,
  title,
  selectedItems,
  setSelectedItems,
}) => {
  const handleChange = (event: TOnChangeInput) => {
    const isChecked = event.target.checked;
    if (isChecked && setSelectedItems) {
      // Pushing an object containing id and type into the selectedItems array
      if (type) {
        setSelectedItems([...selectedItems, { id, type, title }]);
      }
    } else if (setSelectedItems) {
      // Filtering out the item with matching id and type from selectedItems
      setSelectedItems(
        selectedItems.filter((item) => !(item.id === id && item.type === type))
      );
    }
  };

  return (
    <input
      type="checkbox"
      checked={selectedItems.some((item) => item.id === id)} // Checking if the item with id and type exists in selectedItems
      onChange={handleChange}
    />
  );
};

export default ICCheckBox;
