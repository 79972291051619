import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import {
  ACTIVE_INACTIVE__BOOKING_CANCELLATION,
  DELETE_BOOKING_CANCELLATION,
  GET_ALL_BOOKING_CANCELLATION,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
  toastSuccess,
} from "../../utils/AppFunctions";

export interface IBookingCancellationCharges {
  id: number;
  service_id: number | null;
  service_title: string;
  cancellation_cutoff_hours: number | null;
  cancellation_refund_percentage: number | null;
  is_active: string;
  deleted?: string;
  created_date?: Date;
  modified_date?: Date;
}

export function getBookingCancellationFields<T>(
  handleStatusChange: (item: T) => void,
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("service_title"),
      key: "service_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("cancellation_cutoff_hours"),
      key: "cancellation_cutoff_hours",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-3",
    },
    {
      name: t("cancellation_refund_percentage"),
      key: "cancellation_refund_percentage",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-3",
    },

    {
      name: t("status"),
      key: "is_active",
      sort: false,
      type: TABLE_FIELD_TYPE.switch,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      handleCheck: handleStatusChange,
      flex: "col-span-1",
    },
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}

export const fetchBookingCancellationApiCall = async (
  setFaqsList: TReactSetState<IBookingCancellationCharges[]>,
  setPagination: TSetPaginationFn,
  serviceId: number | undefined,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const bookingCancellationList = await GET_ALL_BOOKING_CANCELLATION(
      pagination,
      search,
      serviceId
    );
    if (
      bookingCancellationList &&
      bookingCancellationList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (bookingCancellationList.data) {
        setFaqsList(bookingCancellationList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(
            bookingCancellationList.data.pagination
          ),
        });
      } else {
        setFaqsList([]);
        setPagination({
          ...pagination,
          ...resetPaginationWithPpr(pagination.perPageRows),
        });
      }
    } else {
      toastError(
        bookingCancellationList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    toastError(
      e?.bookingCancellationList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleDeleteBookingCancellation = async (
  id: number,
  t: TUseTranslationTfn
) => {
  try {
    const bookingCancellationDelete = await DELETE_BOOKING_CANCELLATION(id);
    if (
      bookingCancellationDelete &&
      bookingCancellationDelete.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(bookingCancellationDelete.message);
      return true;
    } else {
      SweetAlertError(
        bookingCancellationDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
    return false;
  } catch (e: any) {
    SweetAlertError(
      e?.bookingCancellationDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};

export const handleActiveInactiveBookingCancellation = async (
  id: number,
  is_active: string
) => {
  try {
    const bookingCancellationChargeActiveInactive =
      await ACTIVE_INACTIVE__BOOKING_CANCELLATION(id, {
        is_active: is_active,
      });
    if (
      bookingCancellationChargeActiveInactive &&
      bookingCancellationChargeActiveInactive.code ===
        DEFAULT_STATUS_CODE_SUCCESS
    ) {
      toastSuccess(bookingCancellationChargeActiveInactive.message);
      return true;
    }
    return false;
  } catch (e: any) {
    toastError(
      e?.bookingCancellationChargeActiveInactive?.message ||
        MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};
