import {
  ITabelFieldViewProps,
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import { GET_ALL_BOOKINGS_DETAILS } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
} from "../../utils/AppFunctions";

export interface IBookingsDetails {
  id: number;
  service_title: string;
  user_name: string;
  trainer_name: string;
  booking_status_label: string;
  booking_status?: string;
  service_cost: number;
  service_discounted_price: number;
  booking_date: Date;
  schedule_time: string;
  schedule_start_date: Date;
}

export function getBookingsDetailsFields<T>(
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("booking_user_name"),
      key: "user_name",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("trainer_name"),
      key: "trainer_name",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("service_title"),
      key: "service_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("booking_date"),
      key: "booking_date",
      sort: false,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("schedule_date"),
      key: "schedule_start_date",
      sort: false,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },

    {
      name: t("schedule_time"),
      key: "schedule_time",
      sort: false,
      type: TABLE_FIELD_TYPE.time,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("booking_status"),
      key: "booking_status_label",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("cost"),
      key: "service_discounted_price",
      sort: false,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-1",
      format: ({ field, data }: ITabelFieldViewProps<T>) =>
        data["service_discounted_price"] !== null
          ? data["service_discounted_price"]
          : data["service_cost"],
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}
export const INITIAL_BOOKINGS_DETAILS = {
  to_date: "",
  from_date: "",
};
export const fetchBookingDetailsApiCall = async (
  setBookingDetailsList: TReactSetState<IBookingsDetails[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const bookingDetailsList = await GET_ALL_BOOKINGS_DETAILS(
      pagination,
      search
    );
    if (
      bookingDetailsList &&
      bookingDetailsList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (bookingDetailsList.data && bookingDetailsList.data.item.length > 0) {
        setBookingDetailsList(bookingDetailsList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(bookingDetailsList.data.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetBookingDetailsList = await GET_ALL_BOOKINGS_DETAILS(
          resetPagination,
          search
        );
        if (
          resetBookingDetailsList &&
          resetBookingDetailsList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setBookingDetailsList(resetBookingDetailsList.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              resetBookingDetailsList.data.pagination
            ),
          });
        } else {
          setBookingDetailsList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(bookingDetailsList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(
      e?.bookingDetailsList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
