import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import {
  ADD_EVENT,
  GET_EVENT_BY_ID,
  UPDATE_EVENT,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  getLocalDate,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import { IEvents } from "./EventsViewController";
import * as Yup from "yup";

export interface IEventsForm
  extends Omit<
    IEvents,
    "id" | "created_by" | "created_date" | "modified_by" | "modified_date"
  > {
  id?: number | undefined;
}

export const eventsValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    title: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("title")],
        ])
      )
      .max(
        100,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("title")],
          ["max", "100"],
        ])
      ),
    description: Yup.string().max(
      1000,
      prepareMessageFromParams(t("error_message_max_length"), [
        ["fieldName", t("description")],
        ["max", "1000"],
      ])
    ),
    event_start_date: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("event_start_date")],
      ])
    ),
    event_end_date: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("event_end_date")],
      ])
    ),
    event_start_time: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("event_start_time")],
      ])
    ),

    event_end_time: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("event_end_time")],
      ])
    ),
    target_audience: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("target_audience")],
      ])
    ),
    venue: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("venue")],
        ])
      )
      .max(
        500,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("venue")],
          ["max", "500"],
        ])
      )
      .min(
        3,
        prepareMessageFromParams(t("error_message_min_length"), [
          ["fieldName", t("venue")],
          ["min", "3"],
        ])
      ),
    permission_letter_submit_date: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("permission_letter_submit_date")],
      ])
    ),
    permission_approval_date: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("permission_approval_date")],
      ])
    ),
    registration_fee: Yup.number()
      .nullable()
      .when("is_paid_event", {
        is: "1",
        then: () =>
          Yup.number()
            .required(
              prepareMessageFromParams(t("error_message_required"), [
                ["fieldName", t("registration_fee")],
              ])
            )
            .min(
              1,
              prepareMessageFromParams(t("error_message_negative"), [
                ["fieldName", t("registration_fee")],
              ])
            )
            .max(
              9999999,
              prepareMessageFromParams(t("error_message_less_than"), [
                ["fieldNameLess", t("registration_fee")],
                ["fieldNameGrater", "9999999"],
              ])
            ),
      }),
  });

export const getEventsInitialValues = (): IEventsForm => {
  return {
    title: "",
    description: "",
    event_start_date: undefined,
    event_end_date: undefined,
    event_start_time: "",
    event_end_time: "",
    target_audience: "",
    is_paid_event: "0",
    registration_fee: undefined,
    venue: "",
    venue_lat: "",
    venue_long: "",
    permission_letter_submit_date: undefined,
    permission_approval_date: undefined,
    is_active: "1",
    deleted: "",
    events_media: [],
    registration_required: "1",
    meeting_link: null,
  };
};

export const fetchEventById = async (
  id: number,
  setEventsFormInitialValues: TReactSetState<IEventsForm | undefined>,
  handleClose: (status: boolean) => void
) => {
  try {
    const eventById = await GET_EVENT_BY_ID(id);
    if (eventById && eventById.code === DEFAULT_STATUS_CODE_SUCCESS) {
      setEventsFormInitialValues({
        id: eventById.data.id,
        title: eventById.data.title || "",
        description: eventById.data.description || "",
        event_start_date:
          getLocalDate(eventById.data.event_start_date) || undefined,
        event_end_date:
          getLocalDate(eventById.data.event_end_date) || undefined,
        event_start_time: eventById.data.event_start_time.substring(0, 5) || "",
        event_end_time: eventById.data.event_end_time.substring(0, 5) || "",
        target_audience: eventById.data.target_audience.trim() || "",
        is_paid_event: eventById.data.is_paid_event || "0",
        registration_fee: eventById.data.registration_fee || null,
        venue: eventById.data.venue || "",
        venue_lat: eventById.data.venue_lat || "",
        venue_long: eventById.data.venue_long || "",
        permission_letter_submit_date: getLocalDate(
          eventById.data.permission_letter_submit_date
        ),
        permission_approval_date: getLocalDate(
          eventById.data.permission_approval_date
        ),
        is_active: eventById.data.is_active || "0",
        events_media: eventById.data.app_media || [],
        registration_required: eventById.data.registration_required,
        meeting_link: eventById.data.meeting_link || null,
      });
    } else {
      handleClose(false);
      SweetAlertError(eventById.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(e?.eventById?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleSubmitEvent = async (
  values: IEventsForm,
  id: number | undefined,
  handleClose: (status: boolean) => void
) => {
  try {
    let resultEvent;
    if (!id) {
      resultEvent = await ADD_EVENT(values);
      if (resultEvent.code === DEFAULT_STATUS_CODE_SUCCESS) {
        SweetAlertSuccess(resultEvent.message);
        return handleClose(true);
      } else {
        SweetAlertError(resultEvent.message);
      }
    } else {
      resultEvent = await UPDATE_EVENT(values, id);
      if (resultEvent.code === DEFAULT_STATUS_CODE_SUCCESS) {
        SweetAlertSuccess(resultEvent.message);
        return handleClose(true);
      } else {
        SweetAlertError(resultEvent.message);
      }
    }
  } catch (e: any) {
    SweetAlertError(e?.resultFaqs?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
