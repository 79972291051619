import React, { useState } from "react";

import UseTranslationHook from "../../../hooks/UseTranslationHook";
import SidebarMenu from "../../../components/page-layouts/SidebarMenu";

const HomeView = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { t } = UseTranslationHook();

  const containerClassName = `${sidebarOpen ? "" : "closed-sidebar "}`;
  const rotationStyle = {
    transform: sidebarOpen ? "rotate(90deg)" : "rotate(-90deg)",
    transition: "transform 0.5s ease", // Add a transition for a smooth rotation effect
  };

  return (
    <div className="overflow-x-auto min-w-64 scrollbar-remove">
      <div className="full-height-minus-header flex justify-center font-sans ">
        <div
          className={`py-4 relative w-full border-e-2  border-skin-home bg-skin-home `}
        >
          <div className="px-5">
            <div className="flex items-center"></div>
            <div className={`${containerClassName} open-sidebar`}>
              <SidebarMenu />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeView;
