import { NavigateFunction } from "react-router-dom";
import {
  decryptData,
  toastError,
  toastSuccess,
} from "../../../utils/AppFunctions";
import {
  TOnChangeInput,
  TReactSetState,
  TUseTranslationTfn,
} from "../../../data/AppType";
import { localStorageUtils } from "../../../utils/LocalStorageUtil";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../utils/AppConstants";
import { VERIFY_OTP } from "../../../services/Endpoints";
import { logApplicationError } from "../../../core-component/error-boundary/ErrorBoundaryController";

export interface IOTPVerifyViewProps {
  handleSubmit: (status: boolean) => void;
}
export interface otpForm {
  username: string;
  otp: string;
}

export const initialValuesOtp = {
  username: "",
  otp: "",
};

export const handleInputChange = (
  event: TOnChangeInput,
  index: number,
  inputRefs: React.RefObject<HTMLInputElement>[],
  setError: TReactSetState<string>,
  handleSubmit: (status: boolean) => void
) => {
  const input = event.target;
  const value = input.value;
  if (value.length >= 1) {
    if (index < inputRefs.length - 1) {
      inputRefs[index + 1].current?.focus();
    }
  }
  const otpValues = inputRefs.map((inputRef) => inputRef.current?.value || "");
  if (otpValues.join("").length === 6) {
    setError("");
    if (otpValues.join("") == "123456") {
      handleSubmit(true);
      toastSuccess("Successfully opt");
    } else {
      toastError("Invalid otp!");
    }
  }
};
export const handleKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>,
  index: number,
  inputRefs: React.RefObject<HTMLInputElement>[]
) => {
  if (event.key === "Backspace" && !event.currentTarget.value && index > 0) {
    inputRefs[index - 1].current?.focus();
  }
};

export const startTimer = (
  setCount: TReactSetState<number>,
  setIntervalNumber: TReactSetState<NodeJS.Timeout | undefined>
) => {
  const interval = setInterval(() => {
    setCount((prevCount) => prevCount - 1);
  }, 1000);
  setIntervalNumber(interval);
};
export const restCountDown = (
  setIsButtonVisible: TReactSetState<boolean>,
  setCount: TReactSetState<number>,
  setIntervalNumber: TReactSetState<NodeJS.Timeout | undefined>
) => {
  setIsButtonVisible(false);
  const intervals = setInterval(() => {
    setCount((prevCount) => prevCount - 1);
  }, 1000);
  setIntervalNumber(intervals);
};
export const handleVerifyController = (
  otpValues: string[],
  setError: TReactSetState<string>,
  navigate: NavigateFunction,
  handleSubmit: (status: boolean) => void
) => {
  if (otpValues.join("").length === 6) {
    setError("");
  } else if (otpValues.join("").length === 0) {
    setError("otp is required!");
  } else if (otpValues.join("").length < 6) {
    setError("input does not have exactly 6 digits");
  }
  if (otpValues.join("") == "123456") {
    toastSuccess("Successfully opt");
    handleSubmit(true);
  } else {
    toastError("Invalid otp!");
  }
};

export const submitOtp = async (
  values: otpForm,
  navigation: NavigateFunction,
  t: TUseTranslationTfn
) => {
  try {
    const { username, otp } = values;
    const payload = {
      username: username,
      otp: otp,
    };

    const otpNumber = await VERIFY_OTP(payload);
    if (otpNumber.code === DEFAULT_STATUS_CODE_SUCCESS) {
      const otpNumberData = JSON.parse(decryptData(otpNumber.data));

      localStorageUtils.setAccessToken(otpNumberData.token);
      localStorageUtils.setRefreshToken(otpNumberData.refresh_token);
      localStorageUtils.setProfileName(
        otpNumberData?.user_profile?.profile_name
          ? otpNumberData?.user_profile?.profile_name
          : ""
      );
      localStorageUtils.setProfileUrl(
        otpNumberData?.user_profile?.profile_url
          ? otpNumberData?.user_profile?.profile_url
          : ""
      );

      navigation(`/admin/dashboard`);
      toastSuccess(t("otp_verified_successfully"));
    } else {
      toastError(otpNumber.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    logApplicationError(e);
    toastError(e?.otpNumber.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
