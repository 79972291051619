import * as Yup from "yup";
import {
  TReactSetState,
  TUseTranslationTfn,
} from "../../../../../data/AppType";
import {
  SweetAlertError,
  SweetAlertSuccess,
  combinedLibraryItems,
  prepareMessageFromParams,
} from "../../../../../utils/AppFunctions";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../../../utils/AppConstants";
import {
  GET_COURSE_CONTENT_BY_ID,
  POST_COURSE_CONTENT,
  UPDATE_COURSE_CONTENT,
} from "../../../../../services/Endpoints";
import { IItem } from "../../../../../data/AppInterface";

export interface ILibraryItem {
  id: number;
  title: string;
}

export interface ICourseContentForm {
  id?: number;
  content_title: string;
  content_desc: string;
  content_image_url: string;
  content_delivery_time: number | null;
  service_id: number;
  library_content: number[];
  library_directory: number[];
  library_items: ILibraryItem[];
  is_active: string;
}

export const getCourseContentInitialValues = (
  service_id: number
): ICourseContentForm => {
  return {
    content_title: "",
    content_desc: "",
    content_image_url: "",
    content_delivery_time: null,
    service_id: service_id,
    library_content: [],
    library_directory: [],
    library_items: [],
    is_active: "1",
  };
};

export const courseContentValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    content_title: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("content_title")],
        ])
      )
      .max(
        40,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("content_title")],
          ["max", "40"],
        ])
      ),
    content_desc: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("content_desc")],
        ])
      )
      .max(
        1000,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("content_desc")],
          ["max", "1000"],
        ])
      ),
    content_delivery_time: Yup.number()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("content_delivery_time")],
        ])
      )
      .max(
        999,
        prepareMessageFromParams(t("error_message_less_than"), [
          ["fieldNameLess", t("content_delivery_time")],
          ["fieldNameGrater", "999"],
        ])
      )
      .integer(
        prepareMessageFromParams(t("error_message_integer"), [
          ["fieldName", t("content_delivery_time")],
        ])
      )
      .positive(
        prepareMessageFromParams(t("error_message_negative"), [
          ["fieldName", t("content_delivery_time")],
        ])
      ),
  });

export const handleSubmitCourseContent = async (
  values: ICourseContentForm,
  id: number | undefined,
  handleClose: (status: boolean) => void,
  t: TUseTranslationTfn
) => {
  try {
    let resultCourseContent;
    if (!id) {
      resultCourseContent = await POST_COURSE_CONTENT(values);
      if (resultCourseContent.code === DEFAULT_STATUS_CODE_SUCCESS) {
        SweetAlertSuccess(resultCourseContent.message);
        return handleClose(true);
      } else {
        SweetAlertError(resultCourseContent.message);
      }
    } else if (id) {
      resultCourseContent = await UPDATE_COURSE_CONTENT(values, id);
      if (resultCourseContent.code === DEFAULT_STATUS_CODE_SUCCESS) {
        SweetAlertSuccess(resultCourseContent.message);
        return handleClose(true);
      } else {
        SweetAlertError(resultCourseContent.message);
      }
    }
  } catch (e: any) {
    SweetAlertError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const fetchCourseContentById = async (
  id: number | undefined,
  setCourseContentFormInitialValues: TReactSetState<
    ICourseContentForm | undefined
  >,
  setSelectedItems: TReactSetState<IItem[]>,
  handleClose: (status: boolean) => void
) => {
  try {
    if (id) {
      const getCourseContentById = await GET_COURSE_CONTENT_BY_ID(id);
      if (
        getCourseContentById &&
        getCourseContentById.code === DEFAULT_STATUS_CODE_SUCCESS
      ) {
        setCourseContentFormInitialValues(getCourseContentById.data);
        combinedLibraryItems(
          getCourseContentById.data.library_items,
          setSelectedItems
        );
      } else {
        handleClose(false);
        SweetAlertError(
          getCourseContentById.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
        );
      }
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(
      e?.getCourseContentById?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
