import React, { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  ChartOptions,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import {
  IVisitorImpressionReport,
  getReportById,
} from "../DashboardController";
import { REPORT_ID } from "../../../utils/AppEnumerations";
import { monthShortNames } from "../../../utils/AppConstants";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import RectChartSkeleton from "../skelton-report/RectChartSkeleton";
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
);

const VisitorImpressionView = () => {
  const { t } = UseTranslationHook();
  const [visitorImpression, setVisitorImpression] = useState<
    IVisitorImpressionReport[]
  >([]);
  const [loading, setLoading] = useState(true);
  const fetchRevenueReport = async () => {
    const data = await getReportById(REPORT_ID.VisitorsImpression, setLoading);
    if (data) {
      setVisitorImpression(data.reverse());
    }
  };
  useEffect(() => {
    fetchRevenueReport();
  }, []);

  const options = useMemo((): ChartOptions<"line"> => {
    return {
      aspectRatio: window.innerWidth < 480 ? 1 / 0.7 : undefined,
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: t("visitor_impression"),
          color: "#000000",
          font: {
            family: "Segoe UI",
            size: 20,
            weight: "normal",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              family: "Segoe UI",
              size: 13,
            },
          },
        },
        y: {
          ticks: {
            font: {
              family: "Segoe UI",
              size: 10,
            },
          },
        },
      },
    };
  }, [t]);

  const data = useMemo(() => {
    return {
      labels: visitorImpression
        .slice(-6)
        .map((item) => monthShortNames[item.month - 1]),
      datasets: [
        {
          label: t("new_customer"),
          data: visitorImpression
            .slice(-6)
            .map((item) => item.total_new_customer),
          backgroundColor: "#0095FF",
          borderColor: "#0095FF",
        },
        {
          label: t("unique_customer"),
          data: visitorImpression
            .slice(-6)
            .map((item) => item.total_unique_customer),
          backgroundColor: "#FFBBAB",
          borderColor: "#FFBBAB",
        },
        {
          label: t("loyal_customer"),
          data: visitorImpression
            .slice(-6)
            .map((item) => item.total_loyal_customer),
          backgroundColor: "#00E096",
          borderColor: "#00E096",
        },
      ],
    };
  }, [visitorImpression, t]);

  return loading ? (
    <>
      <div className="xl:hidden">
        <RectChartSkeleton height={220} />
      </div>
      <div className="xl:block hidden">
        <RectChartSkeleton height={390} />
      </div>
    </>
  ) : (
    <div className="w-full h-full flex justify-center">
      <Line options={options} data={data} />
    </div>
  );
};

export default VisitorImpressionView;
