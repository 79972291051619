import { FormikProps } from "formik";
import { IDDL, IMediaItemImage } from "../../data/AppInterface";
import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import {
  CREATE_BLOG,
  GET_BLOG_BY_ID,
  UPDATE_BLOG,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  getLocalDate,
  prepareMessageFromParams,
  toastError,
} from "../../utils/AppFunctions";
import * as Yup from "yup";
import { IBlogPayload } from "../../services/blog/BlogService";
import { GLOBAL_DROP_DOWN } from "../../services/generic/GenericServices";
import { IOption } from "../../core-component/ICMultiSelectDropDown";
export interface IBlogsForm {
  id?: number;
  blog_title: string;
  blog_meta_title: string;
  blog_content: string;
  slug: string;
  published: string;
  publish_date?: Date;
  expiry_date?: Date;
  post_anonymously: string;
  cover_image: string;
  blog_categories: { id: number; category_title: string }[];
  hash_tags: string[];
  blogs_media: IMediaItemImage[];
  is_active: string;
}
interface IBlogCategory {
  id: number;
  category_title: string;
}

export interface ICategoryCheckboxListProps {
  categories: IBlogCategory[];
  formikProps: FormikProps<IBlogsForm>;
}

const transformObject = (obj: IDDL): IOption<number> => {
  return {
    label: obj.display_value,
    value: obj.data_value,
  };
};

export const removeSpecialCharacters = (input: string) => {
  return input.replace(/[^\w\s]/gi, "");
};

export const createSlug = (input: string) => {
  return input
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-|-$/g, "");
};

export const blogsValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    blog_title: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("blog_title")],
        ])
      )
      .max(
        50,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("blog_title")],
          ["max", "50"],
        ])
      ),

    blog_meta_title: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("blog_meta_title")],
        ])
      )
      .max(
        255,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("blog_meta_title")],
          ["max", "255"],
        ])
      ),

    blog_content: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("blog_content")],
      ])
    ),
    publish_date: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("publish_date")],
      ])
    ),
  });

export const getBlogsInitialValues = (): IBlogsForm => {
  return {
    blog_title: "",
    blog_meta_title: "",
    blog_content: "",
    slug: "",
    cover_image: "",
    published: "1",
    publish_date: getLocalDate(),
    expiry_date: undefined,
    blog_categories: [],
    hash_tags: [],
    blogs_media: [],
    post_anonymously: "0",
    is_active: "1",
  };
};

export const fetchBlogById = async (
  id: number | undefined,
  handleClose: (status: boolean) => void,
  setTags: TReactSetState<string[]>
) => {
  try {
    if (id) {
      const blogById = await GET_BLOG_BY_ID(id);
      if (blogById && blogById.code === DEFAULT_STATUS_CODE_SUCCESS) {
        setTags(blogById.data.blog_tags);
        return {
          id: blogById.data.id,
          blog_title: blogById.data.blog_title || "",
          blog_meta_title: blogById.data.blog_meta_title || "",
          blog_content: blogById.data.blog_content || "",
          cover_image: blogById.data.cover_image || "",
          slug: blogById.data.slug || "",
          published: blogById.data.published || "0",
          publish_date: getLocalDate(blogById.data.publish_date) || undefined,
          expiry_date: blogById.data.expiry_date
            ? getLocalDate(blogById.data.expiry_date)
            : undefined,
          blog_categories: blogById.data.blog_categories || [],
          hash_tags: blogById.data.blog_tags || [],
          blogs_media: blogById.data.app_media || [],
          post_anonymously: blogById.data.post_anonymously || "0",
          is_active: blogById.data.is_active || "1",
        };
      } else {
        handleClose(false);
        SweetAlertError(blogById.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(e?.blogById?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const fetchBlogsCategoryDdl = async (
  setBlogsCategoryList: TReactSetState<IOption<number>[]>,
  setSelectedBlogCategory?: TReactSetState<IOption<number>[]>,
  categoryIds?: number[]
) => {
  try {
    const payload = {
      entity: "blog_category",
      data_value: "id",
      display_value: "category_title",
      required_ids: categoryIds ? categoryIds : [],
    };

    const blogCategoryList = await GLOBAL_DROP_DOWN(payload);

    if (
      blogCategoryList &&
      blogCategoryList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      const selectedCategory: { data_value: number; display_value: string }[] =
        categoryIds &&
        blogCategoryList.data.filter(
          (category: { data_value: number; display_value: string }) =>
            categoryIds.includes(category.data_value)
        );
      setSelectedBlogCategory &&
        setSelectedBlogCategory(
          selectedCategory.map(({ data_value, display_value }) => ({
            value: data_value,
            label: display_value,
          }))
        );
      setBlogsCategoryList(blogCategoryList.data.map(transformObject));
    } else {
      toastError(blogCategoryList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (error: any) {
    toastError(
      error?.blogCategoryList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleSubmitBlog = async (
  values: IBlogPayload,
  id: number | undefined,
  handleClose: (status: boolean) => void,
  t: TUseTranslationTfn
) => {
  try {
    let resultBlogs;
    if (!id) {
      resultBlogs = await CREATE_BLOG(values);
      if (resultBlogs.code === DEFAULT_STATUS_CODE_SUCCESS) {
        SweetAlertSuccess(resultBlogs.message);
        return handleClose(true);
      }
    } else {
      resultBlogs = await UPDATE_BLOG(values, id);
    }
    if (resultBlogs && resultBlogs.code === DEFAULT_STATUS_CODE_SUCCESS) {
      id
        ? SweetAlertSuccess(
            prepareMessageFromParams(t("update_data_successfully"), [
              ["updatedName", t("blog")],
            ])
          )
        : SweetAlertSuccess(
            prepareMessageFromParams(t("add_data_successfully"), [
              ["addName", t("blog")],
            ])
          );
      return handleClose(true);
    } else {
      SweetAlertError(resultBlogs?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    SweetAlertError(e?.resultBlogs?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
