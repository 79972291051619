import React, { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  ChartOptions,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  ICityWiseBookingRevenueReport,
  getReportById,
} from "../DashboardController";
import { REPORT_ID } from "../../../utils/AppEnumerations";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import RectChartSkeleton from "../skelton-report/RectChartSkeleton";
import { addCurrencySign } from "../../../utils/AppFunctions";
import { CURRENCY_SIGN } from "../../../utils/AppConstants";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CityWiseBookingRevenueView = () => {
  const { t } = UseTranslationHook();
  const [cityReport, setCityReport] = useState<ICityWiseBookingRevenueReport[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const fetchCityReport = async () => {
    const data = await getReportById(
      REPORT_ID.CityWiseBookingVsRevenue,
      setLoading
    );
    if (data) {
      setCityReport(data.reverse());
    }
  };
  useEffect(() => {
    fetchCityReport();
  }, []);

  const options = useMemo((): ChartOptions<"bar"> => {
    return {
      aspectRatio: window.innerWidth < 480 ? 1 / 0.7 : undefined,
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: (item) => {
              return (
                item.dataset.label +
                ": " +
                (item.datasetIndex === 1
                  ? addCurrencySign(item.raw as string)
                  : item.raw)
              );
            },
          },
        },
        title: {
          display: true,
          text: t("city_wise_booking_vs_revenue"),
          color: "#000000",
          font: {
            family: "Segoe UI",
            size: 20,
            weight: "normal",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              family: "Segoe UI",
              size: 13,
            },
          },
        },
        y: {
          ticks: {
            font: {
              family: "Segoe UI",
              size: 10,
            },
          },
        },
        y1: {
          type: "linear" as const,
          display: true,
          position: "right" as const,
          grid: {
            drawOnChartArea: false,
          },
          ticks: {
            callback: (value) => addCurrencySign(value),
            font: {
              family: "Segoe UI",
              size: 10,
            },
          },
        },
      },
    };
  }, [t]);

  const data = useMemo(() => {
    return {
      labels: cityReport.slice(-6).map((item) => item.city),
      datasets: [
        {
          label: t("bookings"),
          data: cityReport.slice(-6).map((item) => item.total_booking),
          backgroundColor: "#0095FF",
          yAxisID: "y",
        },
        {
          label: t("revenue"),
          data: cityReport.slice(-6).map((item) => item.revenue),
          backgroundColor: "#00E096",
          yAxisID: "y1",
        },
      ],
    };
  }, [cityReport, t]);

  return loading ? (
    <>
      <div className="xl:hidden">
        <RectChartSkeleton height={220} />
      </div>
      <div className="xl:block hidden">
        <RectChartSkeleton height={390} />
      </div>
    </>
  ) : (
    <div className="w-full h-full flex justify-center">
      <Bar options={options} data={data} />
    </div>
  );
};

export default CityWiseBookingRevenueView;
