import { useEffect, useState } from "react";
import { IBookingView, fetchBookingDetailsById } from "./BookingVIewController";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { dateFormat, formatTime, getLocalDate } from "../../utils/AppFunctions";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import { IBookingsDetails } from "./BookingDetailsController";
import ICImageView from "../../core-component/ic-image/ICImageView";

interface IBokingViewFormProps {
  handleClose: (status: boolean) => void;
  bookingDetailsToEdit?: IBookingsDetails;
  readOnly: boolean;
}

const BookingView: React.FC<IBokingViewFormProps> = ({
  handleClose,
  bookingDetailsToEdit,
  readOnly,
}) => {
  const { t } = UseTranslationHook();
  const [bookingDetailsFormInitialValues, setBookingDetailsFormInitialValues] =
    useState<IBookingView>();

  useEffect(() => {
    if (bookingDetailsToEdit) {
      fetchBookingDetailsById(
        bookingDetailsToEdit.id,
        setBookingDetailsFormInitialValues,
        handleClose
      );
    }
  }, []);

  return (
    <FormRightModalWrapper
      containerClassName="w-full md:w-2/5 lg:w-2/5 xl:w-2/5"
      onClose={() => handleClose(false)}
      title={t("bookings_details")}
    >
      <div className="bg-skin-booking-view rounded-lg shadow-md p-6">
        <div className="flex justify-center items-center mb-6">
          <ICImageView
            className="w-48 h-48 object-contain rounded-lg border border-skin-booking-view"
            url={
              bookingDetailsFormInitialValues?.service.app_media
                ? bookingDetailsFormInitialValues?.service.app_media[0]
                    .media_url
                : require("../../assets/image/noImage.png").default
            }
          />
        </div>
        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <div className="typo-form-title">{t("service_title")}:</div>
          <div>{bookingDetailsFormInitialValues?.service.service_title}</div>
          <div className="typo-form-title">{t("booking_number")}:</div>
          <div>{bookingDetailsFormInitialValues?.booking_number}</div>
          <div className="typo-form-title">{t("booking_status")}:</div>
          <div>{bookingDetailsFormInitialValues?.booking_status_label}</div>
          <div className="typo-form-title">{t("booking_date")}:</div>
          <div>
            {dateFormat(
              getLocalDate(bookingDetailsFormInitialValues?.booking_date)
            )}
          </div>
          <div className="typo-form-title">{t("schedule_date")}:</div>
          <div>
            {dateFormat(
              getLocalDate(bookingDetailsFormInitialValues?.schedule_start_date)
            )}
          </div>
          <div className="typo-form-title">{t("schedule_time")}:</div>
          <div>
            {bookingDetailsFormInitialValues?.schedule_time &&
              formatTime(bookingDetailsFormInitialValues?.schedule_time)}
          </div>
          <div className="typo-form-title">{t("service_cost")}:</div>
          <div>{bookingDetailsFormInitialValues?.service_cost}</div>
          <div className="typo-form-title">
            {t("service_discounted_price")}:
          </div>
          <div>{bookingDetailsFormInitialValues?.service_discounted_price}</div>
          <div className="typo-form-title">{t("booking_user_name")}:</div>
          <div>{bookingDetailsFormInitialValues?.user.user_name}</div>
          <div className="typo-form-title">{t("trainer_name")}:</div>
          <div>
            {bookingDetailsFormInitialValues?.trainer
              ? bookingDetailsFormInitialValues?.trainer.trainer_name
              : " - "}
          </div>
          {bookingDetailsFormInitialValues?.booking_cancellation_date !==
            null && (
            <>
              <div className="typo-form-title">{t("cancellation_date")}:</div>
              <div>
                {dateFormat(
                  getLocalDate(
                    bookingDetailsFormInitialValues?.booking_cancellation_date
                  )
                )}
              </div>
            </>
          )}
          {bookingDetailsFormInitialValues?.cancellation_reason !== null && (
            <>
              <div className="typo-form-title">{t("cancellation_reason")}:</div>
              <div>{bookingDetailsFormInitialValues?.cancellation_reason}</div>
            </>
          )}
        </div>
      </div>
    </FormRightModalWrapper>
  );
};
export default BookingView;
