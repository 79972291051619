import { serviceMaker } from "..";
import { IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

export const GET_ALL_INQUIRY = (pagination: IPagination, search: ISearch) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_inquiry.url +
      `?search=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&${
        search.advanceSearch?.show_historical
          ? `sh=${search.advanceSearch?.show_historical}`
          : ""
      }&${
        search.advanceSearch?.from_date
          ? `fd=${search.advanceSearch?.from_date}`
          : ""
      }&${
        search.advanceSearch?.to_date
          ? `td=${search.advanceSearch?.to_date}`
          : ""
      }&currentPage=${pagination.currentPage}&perPageRows=${
        pagination.perPageRows
      }&sortBy=${pagination.sortBy}&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_inquiry.method
  );
};

export const GET_BY_ID_INQUIRY = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_inquiry.url + `/${id}`,
    API_ENDPOINTS.get_all_inquiry.method
  );
};

export const APPROVE_INQUIRY = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.approve_inquiry.url + `/${id}`,
    API_ENDPOINTS.approve_inquiry.method
  );
};
