import { serviceMaker } from "../..";
import {
  IActiveInactive,
  IPagination,
  ISearch,
} from "../../../data/AppInterface";
import { API_ENDPOINTS } from "../../../utils/ApiEndPoint";

interface IServiceCategoryPayload {
  category_title: string;
  category_desc: string;
  poster_image: string;
  is_active?: string;
  category_options?: [];
}

export const GET_SERVICE_CATEGORY = (
  pagination: IPagination,
  search: ISearch
) => {
  return serviceMaker(
    API_ENDPOINTS.get_service_category.url +
      `?query=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&sd=${search.deleteAll}&currentPage=${
        pagination.currentPage
      }&perPageRows=${pagination.perPageRows}&sortBy="service_category"."${
        pagination.sortBy
      }"&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_service_category.method
  );
};

export const POST_SERVICE_CATEGORY = (payload: IServiceCategoryPayload) => {
  return serviceMaker(
    API_ENDPOINTS.add_service_category.url,
    API_ENDPOINTS.add_service_category.method,
    payload
  );
};

export const GET_SERVICE_CATEGORY_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_service_category.url + `/${id}`,
    API_ENDPOINTS.get_service_category.method
  );
};

export const UPDATE_SERVICE_CATEGORY = (
  payload: IServiceCategoryPayload,
  id: number
) => {
  return serviceMaker(
    API_ENDPOINTS.update_service_category.url + `/${id}`,
    API_ENDPOINTS.update_service_category.method,
    payload
  );
};

export const DELETE_SERVICE_CATEGORY = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.delete_service_category.url + `/${id}`,
    API_ENDPOINTS.delete_service_category.method
  );
};

export const ACTIVE_INACTIVE_SERVICE_CATEGORY = async (
  id: number,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_deactivate_service_category.url + `/${id}`,
    API_ENDPOINTS.active_deactivate_service_category.method,
    activeInactiveValue
  );
};

export const FETCH_UPLOAD_IMAGE = async (payload: FormData) => {
  return serviceMaker(
    API_ENDPOINTS.fetch_upload_image.url,
    API_ENDPOINTS.fetch_upload_image.method,
    payload
  );
};
