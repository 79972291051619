import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { ILibrary } from "./LibraryViewController";
import {
  ILibraryAddFolderForm,
  fetchLibraryAddFolderById,
  getLibraryAddFolderInitialValues,
  handleSubmitLibraryAddFolder,
  libraryAddFolderValidationSchema,
} from "./LibraryAddFolderFormController";
import {
  SweetAlertError,
  hasAnyModification,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import ICModal from "../../core-component/ICModal";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import ICButton from "../../core-component/ICButton";
import { Form, Formik, FormikProps } from "formik";
import ICFormikTextInput from "../../components/formik-input/ICFormikTextInput";
import ICFormikTextArea from "../../components/formik-input/ICFormikTextIArea";
import ICFormikSwitch from "../../components/formik-input/ICFormikSwitch";
import ICFormikRadioBtn from "../../components/formik-input/ICFormikRadioBtn";
import ICFileUpload from "../../core-component/ICFileUpload";
import { fetchUploadImageService } from "../global/image-service/ImageServices";
import {
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
  RADIO_BUTTON_LIBRARY_STATUS,
} from "../../utils/AppConstants";
import {
  BIT_VALUE,
  IMAGE_TYPE,
  IS_PREMIUM,
  LIBRARY_STATUS_VALUE,
} from "../../utils/AppEnumerations";

interface ILibraryAddFolderFormProps {
  handleClose: (status: boolean) => void;
  addFolderToEdit?: ILibrary;
  libraryDirectory: number;
  readOnly?: boolean;
  isPremium?: string;
  CS?: string;
}

const LibraryAddFolderForm: React.FC<ILibraryAddFolderFormProps> = ({
  handleClose,
  addFolderToEdit,
  libraryDirectory,
  readOnly,
  isPremium,
  CS,
}) => {
  const { t } = UseTranslationHook();
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [
    libraryAddFolderFormInitialValues,
    setLibraryAddFolderFormInitialValues,
  ] = useState<ILibraryAddFolderForm>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [libraryAddFolderImage, setLibraryAddFolderImage] = useState<string[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const checkFormModification = (values: ILibraryAddFolderForm) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      libraryAddFolderFormInitialValues &&
      hasAnyModification(values, libraryAddFolderFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };
  useEffect(() => {
    if (addFolderToEdit) {
      fetchLibraryAddFolderById(
        addFolderToEdit.object_id,
        setLibraryAddFolderFormInitialValues,
        handleClose
      );
    } else {
      setLibraryAddFolderFormInitialValues(
        getLibraryAddFolderInitialValues(addFolderToEdit, libraryDirectory)
      );
    }
  }, []);

  useEffect(() => {
    setLibraryAddFolderImage(
      libraryAddFolderFormInitialValues?.cover_image_path
        ? [libraryAddFolderFormInitialValues.cover_image_path]
        : []
    );
  }, [libraryAddFolderFormInitialValues]);

  const handleSubmitLibraryFolderWithUpload = async (
    values: ILibraryAddFolderForm
  ) => {
    if (
      values.is_premium === BIT_VALUE.Zero &&
      values.is_public === BIT_VALUE.Zero
    ) {
      return;
    } else {
      try {
        setLoading(true);
        const coverImageMedia = libraryAddFolderImage
          ? libraryAddFolderImage.map((url) => ({
              media_url: url,
            }))
          : [];
        for (let i = 0; i < selectedFiles.length; i++) {
          const formData = new FormData();
          formData.append(`myImageFile`, selectedFiles[i]);
          formData.append(`type`, IMAGE_TYPE.LibraryDirectory);
          values.id && formData.append(`typeId`, values.id.toString());
          formData.append(
            "isPremium",
            values.is_premium == IS_PREMIUM.No ? "true" : "false"
          );
          const mediaUrl = await fetchUploadImageService(formData);
          const newMediaItem: any = {
            media_url: mediaUrl,
          };
          coverImageMedia.push(newMediaItem);
        }

        await handleSubmitLibraryAddFolder(
          {
            ...values,
            cover_image_path: coverImageMedia[0]?.media_url || "",
          },
          addFolderToEdit?.object_id,
          handleClose,
          libraryDirectory,

          t
        );
      } catch (e) {
        SweetAlertError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOnchangePermission = (
    permissionType: string,
    formikPorps: FormikProps<ILibraryAddFolderForm>
  ) => {
    if (permissionType === BIT_VALUE.One) {
      formikPorps.setFieldValue("is_premium", BIT_VALUE.One);
      formikPorps.setFieldValue("is_public", BIT_VALUE.Zero);
    } else {
      formikPorps.setFieldValue("is_premium", BIT_VALUE.Zero);
      formikPorps.setFieldValue("is_public", BIT_VALUE.One);
    }
  };

  return (
    <div className="transition">
      {libraryAddFolderFormInitialValues && (
        <Formik
          initialValues={libraryAddFolderFormInitialValues}
          validationSchema={() =>
            libraryAddFolderValidationSchema(t, isPremium)
          }
          enableReinitialize
          onSubmit={(values) =>
            handleSubmitLibraryFolderWithUpload({
              ...values,
              is_course_specific: CS ? CS : values.is_course_specific,
              is_premium:
                isPremium && isPremium === BIT_VALUE.One
                  ? BIT_VALUE.One
                  : values.is_premium,
              is_public:
                isPremium && isPremium === BIT_VALUE.Zero
                  ? BIT_VALUE.One
                  : values.is_public,
            })
          }
        >
          {(formikPorps) => {
            const { handleSubmit, values, submitCount } = formikPorps;
            return (
              <FormRightModalWrapper
                containerClassName="!w-2/5"
                onClose={() => checkFormModification(values)}
                title={t("add_folder")}
              >
                <Form onSubmit={handleSubmit} className="flex flex-col h-full">
                  <div className="flex-1 overflow-y-auto table-scroll pt-2.5">
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"directory_name"}
                      placeholder={t("folder_Name")}
                      disabled={readOnly}
                      autoFocus
                      required
                    />
                    <ICFormikTextArea
                      {...formikPorps}
                      name={"description"}
                      placeholder={t("description")}
                      disabled={readOnly}
                      required
                    />
                    <ICFormikRadioBtn
                      {...formikPorps}
                      name={"status"}
                      placeholder={t("status")}
                      radioGroup={RADIO_BUTTON_LIBRARY_STATUS.radioGroup}
                      disabled={
                        readOnly ||
                        libraryAddFolderFormInitialValues?.status ===
                          LIBRARY_STATUS_VALUE.Discontinued
                          ? true
                          : false
                      }
                      required
                    />
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"duration"}
                      placeholder={t("duration_Hours")}
                      disabled={readOnly}
                      type="number"
                    />
                    <div className="flex-1">
                      <div className="checkbox-wrapper">
                        <label>
                          <input
                            type="checkbox"
                            name="is_course_specific"
                            checked={
                              formikPorps.values.is_course_specific ===
                                BIT_VALUE.One || CS === BIT_VALUE.One
                            }
                            onChange={(e) => {
                              formikPorps.handleChange(e);
                              formikPorps.setFieldValue(
                                "is_course_specific",
                                e.target.checked
                                  ? BIT_VALUE.One
                                  : BIT_VALUE.Zero || CS === BIT_VALUE.One
                              );
                            }}
                            disabled={
                              readOnly ||
                              CS !== undefined ||
                              values.id !== undefined
                            }
                          />
                          <span className="ml-3">
                            {t("is_course_specific")}
                          </span>
                        </label>
                      </div>
                      <div className="flex space-x-5 items-end my-3">
                        <div className="font-semibold ">{t("permission")}*</div>
                        <div className="flex items-end">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              className="h-[15px] w-[15px]"
                              name="is_public"
                              checked={
                                formikPorps.values.is_public ===
                                  BIT_VALUE.One ||
                                (isPremium && isPremium === BIT_VALUE.Zero
                                  ? true
                                  : false)
                              }
                              onChange={(e) => {
                                handleOnchangePermission(
                                  BIT_VALUE.Zero,
                                  formikPorps
                                );
                              }}
                              disabled={
                                readOnly ||
                                (isPremium === BIT_VALUE.Zero ||
                                isPremium === BIT_VALUE.One
                                  ? true
                                  : false) ||
                                values.id !== undefined
                              }
                            />
                            <span className="ml-1">{t("public")}</span>
                          </label>
                        </div>
                        <div className="flex items-end">
                          <label className="flex items-center">
                            <input
                              className="h-[15px] w-[15px]"
                              type="radio"
                              name="is_premium"
                              checked={
                                formikPorps.values.is_premium ===
                                  BIT_VALUE.One ||
                                (isPremium && isPremium === BIT_VALUE.One
                                  ? true
                                  : false)
                              }
                              onChange={() => {
                                handleOnchangePermission(
                                  BIT_VALUE.One,
                                  formikPorps
                                );
                              }}
                              disabled={
                                readOnly ||
                                (isPremium === BIT_VALUE.Zero ||
                                isPremium === BIT_VALUE.One
                                  ? true
                                  : false) ||
                                values.id !== undefined
                              }
                            />
                            <span className="ml-1">{t("private")}</span>
                          </label>
                        </div>
                      </div>
                      {submitCount > 0 &&
                        isPremium === undefined &&
                        values.is_premium === BIT_VALUE.Zero &&
                        values.is_public === BIT_VALUE.Zero && (
                          <div className="typo-error">
                            {prepareMessageFromParams(
                              t("error_message_required"),
                              [["fieldName", t("permission")]]
                            )}
                          </div>
                        )}
                      {values.id === undefined && (
                        <div className="text-yellow-500 mb-5">
                          {t("library_warning_note")}
                        </div>
                      )}
                    </div>

                    {values.is_premium === BIT_VALUE.One &&
                      isPremium === undefined && (
                        <ICFormikTextInput
                          {...formikPorps}
                          fieldName={"course_price"}
                          placeholder={t("price")}
                          disabled={readOnly}
                          type="number"
                        />
                      )}
                    {values.is_premium === BIT_VALUE.One &&
                      isPremium === undefined && (
                        <ICFormikTextInput
                          {...formikPorps}
                          fieldName={"course_discounted_price"}
                          placeholder={t("discounted_price")}
                          disabled={readOnly}
                          type="number"
                        />
                      )}
                    {values.id && (
                      <ICFormikSwitch
                        {...formikPorps}
                        containerClassName={
                          "flex items-center justify-between mt-4"
                        }
                        name={"is_active"}
                        placeholder={t("library_active")}
                        checkedValue={"1"}
                        unCheckedValue={"0"}
                        disabled={readOnly}
                        required
                      />
                    )}
                    <div className="mt-5">
                      <ICFileUpload
                        title="Cover Image"
                        maxFiles={1}
                        maxFileSizeMB={5000}
                        acceptedFileTypes={["jpg", "png", "jpeg"]}
                        onFilesSelected={setSelectedFiles}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        data={libraryAddFolderImage}
                        setData={setLibraryAddFolderImage}
                        disabled={readOnly}
                      />
                    </div>
                  </div>
                  {!readOnly && (
                    <ICButton
                      disabled={loading ? true : false}
                      loading={loading}
                      className={"w-full"}
                    >
                      {t("submit")}
                    </ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}
      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};

export default LibraryAddFolderForm;
