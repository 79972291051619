import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import { GET_ALL_SESSIONS } from "../../services/Endpoints";

import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
} from "../../utils/AppFunctions";

export interface ISessions {
  id: number;
  session_start_date: Date;
  session_end_date: Date;
  session_start_time: string;
  session_end_time: string;
  join_url: string;
  created_date: Date;
  trainer_name: string;
  service_title: string;
  total_bookings: number;
}

export function getSessionsFields<T>(t: TUseTranslationTfn): ITableFields<T>[] {
  return [
    {
      name: t("trainer_name"),
      key: "trainer_name",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("service_title"),
      key: "service_title",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("session_start_date"),
      key: "session_start_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("session_end_date"),
      key: "session_end_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("session_start_time"),
      key: "session_start_time",
      sort: false,
      type: TABLE_FIELD_TYPE.time,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("total_bookings"),
      key: "total_bookings",
      sort: false,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
  ];
}
export const INITIAL_SESSIONS = {
  to_date: "",
  from_date: "",
  service_title: "",
  trainer_name: "",
};

export const fetchSessionsApiCall = async (
  setSessionsList: TReactSetState<ISessions[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const sessionsList = await GET_ALL_SESSIONS(pagination, search);
    if (sessionsList && sessionsList.code === DEFAULT_STATUS_CODE_SUCCESS) {
      if (sessionsList.data && sessionsList.data.item.length > 0) {
        setSessionsList(sessionsList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(sessionsList.data.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetSessionsList = await GET_ALL_SESSIONS(
          resetPagination,
          search
        );
        if (
          resetSessionsList &&
          resetSessionsList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setSessionsList(resetSessionsList.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(resetSessionsList.data.pagination),
          });
        } else {
          setSessionsList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(sessionsList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.sessionsList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
