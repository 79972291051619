import { FormikProps } from "formik";
// import IDFDatePicker, { IDFDatePickerProps } from '../../core-component/IDFDatePicker';
import { getFormikErrorMessage, getLocalDate } from "../../utils/AppFunctions";
import ICDatePicker, {
  ICDatePickerProps,
} from "../../core-component/ICDatePicker";
interface ICustomFormikDatePicker<T> extends FormikProps<T> {
  fieldName: keyof T;
  placeholder: string;
  containerClassName?: string;
  dateProps?: Omit<ICDatePickerProps, "value">;
  required?: boolean;
  disabled?: boolean;
}
const ICFormikDatePicker = <T extends Object>(
  props: ICustomFormikDatePicker<T>
) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
    fieldName,
    placeholder,
    containerClassName,
    dateProps,
    required,
    disabled,
  } = props;
  const fieldNameStr = props.fieldName as string;
  return (
    <div className={`mb-6 ${containerClassName || ""}`}>
      <ICDatePicker
        {...dateProps}
        placeholder={placeholder}
        onChangeDate={(event) => {
          setFieldTouched(fieldNameStr, true);
          setFieldValue(
            fieldNameStr,
            event.target.value ? getLocalDate(event.target.value) : undefined
          );
        }}
        value={values[fieldName] as Date}
        label={placeholder}
        errorMessage={
          touched[fieldName] && errors[fieldName]
            ? getFormikErrorMessage<T>(errors, touched, fieldName)
            : undefined
        }
        required={required}
        disabled={disabled}
      />
    </div>
  );
};
export default ICFormikDatePicker;
