import React, { useEffect, useState } from "react";
import { IHighlights } from "./HighlightsViewController";
import {
  fetchHighlightsById,
  getHighlightsInitialValues,
  handleSubmitHighlights,
  highlightsValidationSchema,
} from "./HighlightsFormController";
import { Form, Formik } from "formik";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import ICFormikTextInput from "../../components/formik-input/ICFormikTextInput";
import ICButton from "../../core-component/ICButton";
import ICModal from "../../core-component/ICModal";
import ICFormikSwitch from "../../components/formik-input/ICFormikSwitch";
import {
  SweetAlertError,
  getLocalDate,
  hasAnyModification,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import ICFormikDatePicker from "../../components/formik-input/ICFormikDatePicker";
import { DATE_FORMAT, IMAGE_TYPE } from "../../utils/AppEnumerations";
import ICFileUpload from "../../core-component/ICFileUpload";
import { fetchUploadImageService } from "../global/image-service/ImageServices";
import { IMediaItemImage } from "../../data/AppInterface";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { MESSAGE_UNKNOWN_ERROR_OCCURRED } from "../../utils/AppConstants";

interface IHighlightsFormProps {
  handleClose: (status: boolean) => void;
  highlightsToEdit?: IHighlights;
  readOnly?: boolean;
}
const HighlightsForm: React.FC<IHighlightsFormProps> = ({
  handleClose,
  highlightsToEdit,
  readOnly,
}) => {
  const { t } = UseTranslationHook();
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [highlightsFormInitialValues, setHighlightsFormInitialValues] =
    useState<IHighlights>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [imageData, setImageData] = useState<IMediaItemImage[]>([]);
  const [loading, setLoading] = useState(false);
  const checkFormModification = (values: IHighlights) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      highlightsFormInitialValues &&
      hasAnyModification(values, highlightsFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };

  useEffect(() => {
    if (highlightsToEdit) {
      fetchHighlightsById(
        highlightsToEdit.id,
        setHighlightsFormInitialValues,
        handleClose
      );
    } else {
      setHighlightsFormInitialValues(getHighlightsInitialValues());
    }
  }, []);

  useEffect(() => {
    if (
      highlightsFormInitialValues?.app_media &&
      highlightsFormInitialValues.app_media.length > 0
    ) {
      const transformedData = highlightsFormInitialValues.app_media.map(
        (item) => ({
          ...item,
        })
      );
      setImageData(transformedData);
    } else {
      setImageData([]);
    }
  }, [highlightsFormInitialValues]);

  const handleSubmitHighlightsWithFileUpload = async (values: IHighlights) => {
    try {
      setLoading(true);
      if (imageData?.length > 0 || selectedFiles?.length > 0) {
        const updatedServiceMedia = imageData ? imageData : [];

        for (let i = 0; i < selectedFiles.length; i++) {
          const formData = new FormData();
          formData.append(`myImageFile`, selectedFiles[i]);
          formData.append(`type`, IMAGE_TYPE.Highlight);
          values.id && formData.append(`typeId`, values.id.toString());

          const mediaUrl = await fetchUploadImageService(formData);
          if (!mediaUrl) {
            return null;
          }
          const newMediaItem = {
            media_type: selectedFiles[i].type === "video/mp4" ? "v" : "i",
            media_url: mediaUrl,
            media_title: selectedFiles[i].name,
          };

          updatedServiceMedia.push(newMediaItem);
        }

        await handleSubmitHighlights(
          { ...values, highlight_media: updatedServiceMedia },
          highlightsToEdit?.id,
          handleClose,
          t
        );
      } else {
        return;
      }
    } catch (e) {
      SweetAlertError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {highlightsFormInitialValues && (
        <Formik
          initialValues={highlightsFormInitialValues}
          validationSchema={() => highlightsValidationSchema(t)}
          enableReinitialize
          onSubmit={(values) => handleSubmitHighlightsWithFileUpload(values)}
        >
          {(formikPorps) => {
            const { handleSubmit, values, submitCount } = formikPorps;
            return (
              <FormRightModalWrapper
                containerClassName="!w-2/5"
                onClose={() => checkFormModification(values)}
                title={t("highlights")}
              >
                <Form onSubmit={handleSubmit} className="flex flex-col h-full">
                  <div className="flex-1 overflow-y-auto table-scroll overflow-x-hidden p-2">
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"highlight_text"}
                      placeholder={t("highlight_text")}
                      disabled={readOnly}
                      required
                      tabIndex={0}
                      autoFocus
                    />
                    <ICFormikDatePicker
                      {...formikPorps}
                      fieldName={"end_date"}
                      placeholder={t("end_date")}
                      dateProps={{
                        displayFormat: DATE_FORMAT["DD-Mon-YYYY"],
                        min:
                          values.id && values.end_date
                            ? values.end_date
                            : getLocalDate(),
                      }}
                      required
                      disabled={readOnly}
                    />

                    <ICFileUpload
                      title={t("upload_images")}
                      maxFiles={5}
                      maxFileSizeMB={5000}
                      acceptedFileTypes={["jpg", "png", "jpeg", "mp4"]}
                      onFilesSelected={setSelectedFiles}
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      data={imageData}
                      setData={setImageData}
                      disabled={readOnly}
                    />
                    {submitCount > 0 && selectedFiles.length === 0 && (
                      <div className="typo-error">
                        {prepareMessageFromParams(t("error_message_required"), [
                          ["fieldName", t("highlight_media")],
                        ])}
                      </div>
                    )}
                    {values && (
                      <ICFormikSwitch
                        {...formikPorps}
                        containerClassName={
                          "flex items-center justify-between mt-4"
                        }
                        name={"is_active"}
                        placeholder={t("is_active")}
                        checkedValue={"1"}
                        unCheckedValue={"0"}
                        disabled={readOnly}
                        required
                      />
                    )}
                  </div>
                  {!readOnly && (
                    <ICButton
                      disabled={loading ? true : false}
                      loading={loading}
                      className={"w-full"}
                    >
                      {t("submit")}
                    </ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}
      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};

export default HighlightsForm;
