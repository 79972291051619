import React, { useState, useEffect } from "react";
import UseTranslationHook from "../hooks/UseTranslationHook";
interface ITimerProps {
  initialTime: string; // Format: "mm:ss"
  maxResend: number;
  onResend: () => void;
}
const Timer: React.FC<ITimerProps> = ({
  initialTime,
  maxResend: maxResend,
  onResend,
}) => {
  // Utility function to parse time in "mm:ss" format
  const { t } = UseTranslationHook();
  const parseTime = (timeString: string): number => {
    const [minutes, seconds] = timeString.split(":").map(Number);
    return minutes * 60 + seconds;
  };
  // Utility function to format time as "mm:ss"
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };
  const [time, setTime] = useState(parseTime(initialTime));
  const [resendCount, setResendCount] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const handleResendClick = () => {
    if (resendCount < maxResend && time === 0) {
      onResend();
      setResendCount((prevCount) => prevCount + 1);
      setTime(parseTime(initialTime));
    }
  };
  return (
    <div>
      <div
        className={`text-center mt-2 otp-timer  ${
          resendCount === maxResend ? "hidden" : ""
        }`}
      >
        {formatTime(time)}
      </div>

      <div
        className={`text-center mt-2 otp-timer  ${
          resendCount === maxResend ? "hidden" : ""
        } ${time === 0 ? "cursor" : "cursor-not-allowed"}`}
        onClick={handleResendClick}
      >
        {t("resend_otp")}
      </div>
    </div>
  );
};
export default Timer;
