import React, { useEffect, useState } from "react";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import ICTable from "../../core-component/ICTable";
import usePagination from "../../hooks/UsePaginationHook";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import { IAdvanceSearch, IPagination } from "../../data/AppInterface";
import { ORDER_BY, PAYMENT_HISTORY_STATUS } from "../../utils/AppConstants";
import {
  eyeIcon,
  filterClearIcon,
  infoIcon,
} from "../../assets/icons/SvgIconList";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  fetchPaymentHistoryApiCall,
  getPaymentHistoryFields,
  INITIAL_PAYMENT_HISTORY,
  IPaymentHistory,
} from "./PaymentHistoryController";
import PaymentHistoryDetail from "./PaymentHistoryDetails";
import Swal from "sweetalert2";
import ICDropDown from "../../core-component/ICDropDown";
import ICDatePicker from "../../core-component/ICDatePicker";
import { getLocalDate } from "../../utils/AppFunctions";
import ICButton from "../../core-component/ICButton";

const PaymentHistory = () => {
  const { t } = UseTranslationHook();
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [paymentHistoryList, setPaymentHistoryList] = useState<
    IPaymentHistory[]
  >([]);
  const [advanceSearch, setAdvanceSearch] = useState<IAdvanceSearch>(
    INITIAL_PAYMENT_HISTORY
  );
  const [paymentHistoryToView, setPaymentHistoryToView] = useState<
    number | null
  >(null);
  const { pagination, setPagination } = usePagination({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });
  let timer: NodeJS.Timeout;
  useEffect(() => {
    fetchPaymentHistory(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, advanceSearch]);

  const fetchPaymentHistory = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchPaymentHistoryApiCall(
        setPaymentHistoryList,
        setPagination,
        payloadPagination,
        {
          enterpriseSearch,
          advanceSearch,
        }
      );
      setIsLoading(false);
    }, 500);
  };

  const handleClickInfo = (failureReason: string) => {
    Swal.fire({
      title: t("payment_failed"),
      icon: "info",
      html: failureReason,
    });
  };

  const customActionView = (item: IPaymentHistory) => {
    return (
      <div className="flex item-center justify-center ml-2">
        <div
          className="mr-2 action-view flex items-center"
          title={t("view_tooltip_icon")}
          onClick={() => {
            setShowForm(true);
            setPaymentHistoryToView(item.id);
          }}
        >
          {eyeIcon}
        </div>
        {item.failure_reason && (
          <div
            className="mr-2 action-view h-5 w-5 place-content-center"
            title={t("payment_fail_info")}
            onClick={() =>
              handleClickInfo(item.failure_reason ? item.failure_reason : "")
            }
          >
            {infoIcon}
          </div>
        )}
      </div>
    );
  };

  const filterView = (
    <div className="w-full grid grid-cols-4 gap-4 mt-2 bg-skin-background">
      <ICDropDown
        placeholder={t("payment_status")}
        options={PAYMENT_HISTORY_STATUS}
        onSelect={(option) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            paymentStatus: option.value,
          }))
        }
        selected={advanceSearch.paymentStatus}
        required
      />

      <ICDatePicker
        useValue
        label={t("from_date")}
        placeholder={t("from_date")}
        value={
          advanceSearch.from_date !== ""
            ? getLocalDate(advanceSearch.from_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            from_date: event.target.value,
          }))
        }
      />
      <ICDatePicker
        useValue
        label={t("to_date")}
        placeholder={t("to_date")}
        min={getLocalDate(advanceSearch.from_date)}
        value={
          advanceSearch.to_date !== ""
            ? getLocalDate(advanceSearch.to_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            to_date: event.target.value,
          }))
        }
      />

      <ICButton
        onClick={() => setAdvanceSearch(INITIAL_PAYMENT_HISTORY)}
        className="flex"
      >
        <div className="w-5 h-5">{filterClearIcon}</div>&nbsp;{t("clear")}
      </ICButton>
    </div>
  );

  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        filterNode={filterView}
        handleSearchChange={(text) => {
          setEnterpriseSearch(text);
        }}
        searchText={enterpriseSearch}
        refreshTable={() => fetchPaymentHistory(pagination)}
      />
      <ICTable<IPaymentHistory>
        isLoading={isLoading}
        totalFlex="grid grid-cols-23"
        fields={getPaymentHistoryFields(customActionView, t)}
        data={paymentHistoryList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchPaymentHistory({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchPaymentHistory({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchPaymentHistory({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
      {showForm && paymentHistoryToView && (
        <PaymentHistoryDetail
          setShowForm={setShowForm}
          paymentDetailId={paymentHistoryToView}
        />
      )}
    </div>
  );
};

export default PaymentHistory;
