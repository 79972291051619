// SweetAlert.tsx
import React from "react";
import Swal from "sweetalert2";

interface ISweetAlertProps {
  type: "success" | "error" | "warning" | "info"; // Add more types if needed
  title: string;
  text: string;
  fieldTitle: string;
  footer?: string;
}

const ICSweetAlertInfo: React.FC<ISweetAlertProps> = ({
  type,
  title,
  text,
  fieldTitle,
  footer,
}) => {
  const showAlert = () => {
    Swal.fire({
      icon: type,
      title: title,
      text: text,
      footer: footer,
    });
  };

  return <button onClick={showAlert}>{fieldTitle}</button>;
};

export default ICSweetAlertInfo;
