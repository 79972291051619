import { serviceMaker } from "../../..";
import { IPayLoadTimeSlot } from "../../../../screens/product-services/add-product-service/pages/time-slot/TimeSlotViewController";
import { API_ENDPOINTS } from "../../../../utils/ApiEndPoint";

export const POST_TIME_SLOT = (payload: IPayLoadTimeSlot) => {
  return serviceMaker(
    API_ENDPOINTS.post_time_slot.url,
    API_ENDPOINTS.post_time_slot.method,
    payload
  );
};

export const GET_TIME_SLOT_BY_SERVICE_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_time_slot_by_id.url + `/${id}`,
    API_ENDPOINTS.get_time_slot_by_id.method
  );
};
