import { useEffect, useState } from "react";
import { PER_PAGE_ROWS } from "../../../../../config/AppConfig";
import usePagination from "../../../../../hooks/UsePaginationHook";
import { IPagination } from "../../../../../data/AppInterface";
import {
  addIcon,
  editIcon,
  eyeIcon,
} from "../../../../../assets/icons/SvgIconList";
import ICTable from "../../../../../core-component/ICTable";
import { ORDER_BY } from "../../../../../utils/AppConstants";
import {
  ICourseContent,
  fetchCourseContentApiCall,
  getCourseContentFields,
  handleActiveInactiveCourseContent,
  handleDeleteCourseContent,
} from "./CourseContentViewController";
import CourseContentFormView from "./CourseContentFormView";
import UseTranslationHook from "../../../../../hooks/UseTranslationHook";
import ICButton from "../../../../../core-component/ICButton";
import { TReactSetState } from "../../../../../data/AppType";
import { useLocation, useNavigate } from "react-router-dom";
import ICSweetAlertModal from "../../../../../core-component/ICSweetAlertModal";
import { routeAdmin, routeMaster } from "../../../../../routes/RouteHome";
import ICImageView from "../../../../../core-component/ic-image/ICImageView";

interface ICourseContentProps {
  setCurrentIndex: TReactSetState<number>;
}

const CourseContentView: React.FC<ICourseContentProps> = ({
  setCurrentIndex,
}) => {
  const { t } = UseTranslationHook();
  const location = useLocation();
  let timer: NodeJS.Timeout;
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState(false);
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [courseContentList, setCourseContentList] = useState<ICourseContent[]>(
    []
  );
  const [courseContentToEdit, setCourseContentToEdit] =
    useState<ICourseContent>();
  const navigate = useNavigate();
  const { pagination, setPagination } = usePagination({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });
  const serviceId: number = location?.state?.productServiceId;
  const productReadonly: boolean = location?.state?.readOnly;

  useEffect(() => {
    fetchCourseContent(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch]);

  const fetchCourseContent = async (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchCourseContentApiCall(
        setCourseContentList,
        setPagination,
        payloadPagination,
        serviceId,
        {
          enterpriseSearch,
        }
      );
      setIsLoading(false);
    }, 500);
  };
  const handleCloseForm = (status: boolean) => {
    setShowForm(false);
    setReadOnly(false);
    if (courseContentToEdit) {
      setCourseContentToEdit(undefined);
    } else {
      setEnterpriseSearch("");
    }
    if (status) {
      fetchCourseContent(pagination);
    }
  };
  const handleDeleteCfm = async (id: number) => {
    const data = await handleDeleteCourseContent(id, t);
    if (data) {
      fetchCourseContent(pagination);
    }
  };

  const handleCourseContentStatus = async (values: ICourseContent) => {
    var isStatusChange = await handleActiveInactiveCourseContent(
      values.id,
      values.is_active === "1" ? "0" : "1"
    );
    if (isStatusChange) {
      const updatedCourseContent = courseContentList.map((courseContent) => {
        if (courseContent.id === values.id) {
          return {
            ...courseContent,
            is_active: values.is_active === "1" ? "0" : "1",
          };
        }
        return courseContent;
      });

      setCourseContentList(updatedCourseContent);
    }
  };

  const customActionViewCourseContent = (item: ICourseContent) => {
    return (
      <div
        className={`flex justify-${
          productReadonly ? "center" : "start"
        } pl-2.5`}
      >
        <div
          className="mr-2 action-view"
          title={t("view_tooltip_icon")}
          onClick={() => {
            setShowForm(true);
            setCourseContentToEdit(item);
            setReadOnly(true);
          }}
        >
          {eyeIcon}
        </div>

        {!productReadonly && (
          <>
            <div
              className="mr-2 action-view"
              title={t("edit_tooltip_icon")}
              onClick={() => {
                setShowForm(true);
                setCourseContentToEdit(item);
              }}
            >
              {editIcon}
            </div>
            <div className="mr-2 action-view" title={t("delete_tooltip_icon")}>
              <ICSweetAlertModal onConfirm={handleDeleteCfm} itemId={item.id} />
            </div>
          </>
        )}
      </div>
    );
  };

  const handleImageFormatCourseContent = (item: ICourseContent) => {
    return (
      <div className="flex items-center">
        <div className="flex lg:justify-center justify-end">
          <ICImageView
            url={`${
              item.content_image_url.length > 0
                ? item.content_image_url
                : require("../../../../../assets/image/noImage.png")
            }`}
            className="w-6 h-6 rounded-full"
          />
        </div>
        <div className="ml-1">{item.content_title}</div>
      </div>
    );
  };

  const handleNextButton = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };
  const handleSaveButton = () => {
    navigate("/" + routeAdmin.admin + "/" + routeMaster.serviceTable);
  };
  return (
    <div className="w-full ">
      {!productReadonly && (
        <div className="flex justify-end m-3">
          <ICButton type="button" onClick={() => setShowForm(true)}>
            <div className="flex gap-1 items-center">
              {addIcon}
              {t("add")}
            </div>
          </ICButton>
        </div>
      )}

      <div className="">
        <ICTable<ICourseContent>
          isLoading={isLoading}
          totalFlex="grid grid-cols-12"
          fields={getCourseContentFields(
            customActionViewCourseContent,
            handleImageFormatCourseContent,
            handleCourseContentStatus,
            t
          )}
          data={courseContentList}
          pagination={pagination}
          handleChangePerPageRows={(perPageRow) =>
            fetchCourseContent({
              ...pagination,
              perPageRows: perPageRow,
              currentPage: 1,
            })
          }
          handleChangePage={(page) =>
            fetchCourseContent({ ...pagination, currentPage: page })
          }
          handleSort={(field) =>
            fetchCourseContent({
              ...pagination,
              sortBy: field.key,
              orderBy:
                pagination.orderBy === ORDER_BY.desc
                  ? ORDER_BY.asc
                  : ORDER_BY.desc,
            })
          }
        />
        {showForm && (
          <CourseContentFormView
            handleClose={handleCloseForm}
            courseContentToEdit={courseContentToEdit}
            readOnly={readOnly}
          />
        )}
      </div>
      <div className="flex justify-end mr-3">
        <div className=" grid grid-cols-2 space-x-4 mt-5 ">
          {!productReadonly && (
            <>
              <div onClick={handleSaveButton}>
                <ICButton className={"w-full  "}>{t("save_button")}</ICButton>
              </div>

              <div onClick={handleNextButton}>
                <ICButton className={"w-full"}>{t("next")}</ICButton>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseContentView;
