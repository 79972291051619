import * as Yup from "yup";
import {
  SweetAlertError,
  SweetAlertSuccess,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import { SEND_NOTIFICATION } from "../../services/Endpoints";
import { INotification } from "../../services/generic/GenericServices";
import { fetchUploadImageService } from "../global/image-service/ImageServices";
import { IMAGE_TYPE } from "../../utils/AppEnumerations";

export const notificationValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    notfication_for: Yup.number().required(t("notfication_for")),
    notification_title: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("notification_title")],
      ])
    ),
    notification_body: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("notification_body")],
      ])
    ),
  });

export const initialValuesNotification = {
  notfication_for: null,
  notification_title: "",
  notification_body: "",
  image_url: "",
};

export const handleSendNotifications = async (
  values: INotification,
  t: TUseTranslationTfn,
  setLoading: TReactSetState<boolean>,
  selectedFiles?: File[]
) => {
  try {
    let imageUrl;
    setLoading(true);
    if (selectedFiles && selectedFiles.length > 0) {
      const formData = new FormData();
      formData.append("myImageFile", selectedFiles[0]);
      formData.append(`type`, IMAGE_TYPE.Notification);

      imageUrl = await fetchUploadImageService(formData);

      if (!imageUrl) {
        SweetAlertError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
        return;
      }
    }
    const resultNotification = await SEND_NOTIFICATION({
      ...values,
      image_url: imageUrl,
    });

    if (
      resultNotification &&
      resultNotification.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(t("notification_send_successfully"));
    } else {
      SweetAlertError(
        resultNotification.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    SweetAlertError(
      e?.data.responce?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  } finally {
    setLoading(false);
  }
};
