import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import {
  GET_ALL_TRAINER_APPLICATIONS,
  UPDATE_TRAINER_APPLICATION_STATUS,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  decryptData,
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
  toastSuccess,
} from "../../utils/AppFunctions";

export interface IUnlockTrainerApplication {
  status: number;
  reason?: string;
}

export interface IDropdownOptions {
  label: string;
  value: number;
}

export interface ITrainerApplication {
  id: number;
  submitted_date: Date;
  application_content: {
    preferred_location: number;
    gender: number;
  };
  application_status: number;
  created_date: Date;
  created_by: string;
  modified_date: Date;
  modified_by: string;
  applicant_name: string;
  gender_label: string;
  preferred_location_label: string[];
  application_status_label: string;
  application_id: string;
  is_user_deleted: string;
}

export function getTrainerApplicationFields<T>(
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("applicant_name"),
      key: "applicant_name",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
    },
    {
      name: t("application_id"),
      key: "application_id",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("gender"),
      key: "gender_label",
      sort: false,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },

    {
      name: t("preferred_location"),
      key: "preferred_location_label",
      sort: false,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-4",
      format: (item) => {
        return item.data["preferred_location_label"].join(", ");
      },
    },
    {
      name: t("submitted_date"),
      key: "submitted_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
    },
    {
      name: t("application_status"),
      key: "application_status_label",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
    },

    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-3",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
      component: actionComponent,
    },
  ];
}

export const INITIAL_TRAINER_APPLICATION_ADVANCE_SEARCH = {
  submitted_from_date: "",
  submitted_to_date: "",
};

export const fetchTrainerApplicationsApiCall = async (
  setTrainerApplicationList: TReactSetState<ITrainerApplication[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const trainerApplicationsList = await GET_ALL_TRAINER_APPLICATIONS(
      pagination,
      search
    );
    if (
      trainerApplicationsList &&
      trainerApplicationsList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      const trainerApplicationsListData = JSON.parse(
        decryptData(trainerApplicationsList.data)
      );
      if (
        trainerApplicationsListData &&
        trainerApplicationsListData.item.length > 0
      ) {
        setTrainerApplicationList(trainerApplicationsListData.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(
            trainerApplicationsListData.pagination
          ),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetTrainerApplicationList = await GET_ALL_TRAINER_APPLICATIONS(
          resetPagination,
          search
        );
        if (
          resetTrainerApplicationList &&
          resetTrainerApplicationList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          const resetTrainerApplicationListData = JSON.parse(
            decryptData(resetTrainerApplicationList.data)
          );
          setTrainerApplicationList(resetTrainerApplicationListData.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              resetTrainerApplicationListData.pagination
            ),
          });
        } else {
          setTrainerApplicationList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(
        trainerApplicationsList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    toastError(
      e?.trainerApplicationsList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleUpdateTrainerApplicationStatus = async (
  values: IUnlockTrainerApplication,
  id: number | undefined,
  handleClose?: (status: boolean) => void
) => {
  try {
    let resultTrainerApplication;
    if (id) {
      resultTrainerApplication = await UPDATE_TRAINER_APPLICATION_STATUS(
        id,
        values
      );
      if (
        resultTrainerApplication &&
        resultTrainerApplication.code === DEFAULT_STATUS_CODE_SUCCESS
      ) {
        if (handleClose) {
          toastSuccess(resultTrainerApplication.message);
          handleClose(true);
        }
      } else {
        toastError(
          resultTrainerApplication?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
        );
      }
    }
  } catch (e: any) {
    toastError(
      e?.resultTrainerApplication?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
