import React, { ChangeEvent, useEffect, useState } from "react";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  arrowDownIcon,
  arrowUpIcon,
  checkIcon,
  eyeIcon,
  rejectIcon,
  visibilityEyeIcon,
} from "../../assets/icons/SvgIconList";
import ICButton from "../../core-component/ICButton";
import ICDropDown from "../../core-component/ICDropDown";
import { TRAINER_APPLICATION_LABEL } from "../../utils/AppConstants";
import {
  ISectionReviewStatus,
  ITrainerApplicationForm,
  fetchTrainerApplicationById,
  handleSubmitTrainerApplication,
  sectionFieldValues,
  trainerApplicationDropDownOptions,
} from "./TrainerApplicationFormController";
import { ITrainerApplication } from "./TrainerApplicationController";
import {
  TRAINER_APPLICATION_COLLATION_NAVIGATION,
  TRAINER_APPLICATION_IMAGE_NAVIGATION,
  TRAINER_APPLICATION_DOCUMENT_SECTION,
  TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS,
  TRAINER_APPLICATION_STATUS,
  TRAINER_APPLICATION_ACTION_BUTTONS,
} from "../../utils/AppEnumerations";
import ModalComponent from "../../core-component/ICModalComponent";
import ICTextArea from "../../core-component/ICTextArea";
import ICImageView from "../../core-component/ic-image/ICImageView";
import {
  SweetAlertError,
  dateFormat,
  getLocalDate,
  maskEmail,
  maskMobileNumber,
  prepareMessageFromParams,
  responseDateStringToDate,
} from "../../utils/AppFunctions";
import TrainerApplicationTimeLineView from "./TrainerApplicationTimeLineView";

interface ITrainerApplicationFormProps {
  handleClose: (status: boolean) => void;
  trainerApplicationToEdit?: ITrainerApplication;
  readOnly: boolean;
}

const initialOpenAccordions = [
  TRAINER_APPLICATION_COLLATION_NAVIGATION.ApplicationInformation,
  TRAINER_APPLICATION_COLLATION_NAVIGATION.PreferredLocation,
  TRAINER_APPLICATION_COLLATION_NAVIGATION.SkillSet,
  TRAINER_APPLICATION_COLLATION_NAVIGATION.Certificates,
  TRAINER_APPLICATION_COLLATION_NAVIGATION.KeyCodeVerification,
  TRAINER_APPLICATION_COLLATION_NAVIGATION.Others,
];

const TrainerApplicationFormView: React.FC<ITrainerApplicationFormProps> = ({
  handleClose,
  trainerApplicationToEdit,
  readOnly,
}) => {
  const { t } = UseTranslationHook();
  const [showModal, setShowModal] = useState<{
    modal: boolean;
    value: string;
  }>({ modal: false, value: "" });
  const [textareaValue, setTextareaValue] = useState("");
  const [notesTextareaValue, setNotesTextareaValue] = useState("");
  const [openAccordions, setOpenAccordions] = useState<number[]>(
    initialOpenAccordions
  );
  const [skillSetValue, setSkillSetValue] = useState<number[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<number>(0);
  const [sectionReviewStatus, setSectionReviewStatus] = useState<
    ISectionReviewStatus[]
  >([]);
  const [
    trainerApplicationFormInitialValues,
    setTrainerApplicationFormInitialValues,
  ] = useState<ITrainerApplicationForm>();
  const [applicantNameImage, setApplicantNameImage] = useState<boolean | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [addressImage, setAddressImage] = useState<boolean | null>(null);
  const [keyCodeImage, setKeyCodeImage] = useState<boolean | null>(null);
  const [showZoom, setShowZoom] = useState<number>(0);
  const [notesErrorMessage, setNotesErrorMessage] = useState<string>();
  const [showEmail, setShowEmail] = useState(false);
  const [showMobileNumber, setShowMobileNumber] = useState(false);
  const toggleEmailVisibility = () => {
    setShowEmail(!showEmail);
  };
  const toggleMobileNumberVisibility = () => {
    setShowMobileNumber(!showMobileNumber);
  };
  useEffect(() => {
    sectionFieldValues(sectionReviewStatus, setNotesTextareaValue, t);
  }, [sectionReviewStatus]);

  const toggleAccordion = (index: number) => {
    if (openAccordions.includes(index)) {
      setOpenAccordions(openAccordions.filter((i) => i !== index));
    } else {
      setOpenAccordions([...openAccordions, index]);
    }
  };
  useEffect(() => {
    if (trainerApplicationToEdit?.id) {
      fetchTrainerApplicationById(
        trainerApplicationToEdit.id,
        setTrainerApplicationFormInitialValues,
        setSkillSetValue,
        handleClose
      );
    }
  }, []);

  const handleSelectDropDown = (option: { label: string; value: number }) => {
    setSelectedStatus(option.value);
  };

  const handleRejectIdentityImage = () => {
    setShowModal({
      modal: true,
      value: TRAINER_APPLICATION_ACTION_BUTTONS.IdentityButtonReject,
    });
  };

  const handleRejectAddressImage = () => {
    setShowModal({
      modal: true,
      value: TRAINER_APPLICATION_ACTION_BUTTONS.AddressButtonReject,
    });
  };
  const handleKeyCodeImage = () => {
    setShowModal({
      modal: true,
      value: TRAINER_APPLICATION_ACTION_BUTTONS.KeycodeButtonReject,
    });
  };

  const handleCloseModal = () => {
    setTextareaValue("");
    setShowModal({ modal: false, value: "" });
  };

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(event.target.value);
  };
  const handleModalValue = () => {
    setTextareaValue("");
    setShowModal({ modal: false, value: "" });
    handleGetTextFieldValue(showModal.value);
  };

  const updateSectionStatus = (
    section: number,
    status: number,
    reason?: string
  ) => {
    const index = sectionReviewStatus.findIndex(
      (item) => item.section === section
    );
    if (index !== -1) {
      setSectionReviewStatus((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = {
          ...updatedState[index],
          status,
          reason:
            status === TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Rejected
              ? reason
              : undefined,
        };
        return updatedState;
      });
    } else {
      setSectionReviewStatus((prevState) => [
        ...prevState,
        {
          section,
          status,
          reason:
            status === TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Rejected
              ? reason
              : undefined,
        },
      ]);
    }
  };

  const handleGetTextFieldValue = (button: string) => {
    switch (button) {
      case TRAINER_APPLICATION_ACTION_BUTTONS.IdentityButtonApprove:
        setApplicantNameImage(true);
        updateSectionStatus(
          TRAINER_APPLICATION_DOCUMENT_SECTION.IdentityVerification,
          TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Approved
        );
        break;
      case TRAINER_APPLICATION_ACTION_BUTTONS.IdentityButtonReject:
        updateSectionStatus(
          TRAINER_APPLICATION_DOCUMENT_SECTION.IdentityVerification,
          TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Rejected,
          textareaValue
        );
        setApplicantNameImage(false);
        break;
      case TRAINER_APPLICATION_ACTION_BUTTONS.AddressButtonApprove:
        setAddressImage(true);
        updateSectionStatus(
          TRAINER_APPLICATION_DOCUMENT_SECTION.AddressVerification,
          TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Approved
        );
        break;
      case TRAINER_APPLICATION_ACTION_BUTTONS.AddressButtonReject:
        updateSectionStatus(
          TRAINER_APPLICATION_DOCUMENT_SECTION.AddressVerification,
          TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Rejected,
          textareaValue
        );
        setAddressImage(false);
        break;
      case TRAINER_APPLICATION_ACTION_BUTTONS.KeycodeButtonApprove:
        setKeyCodeImage(true);
        updateSectionStatus(
          TRAINER_APPLICATION_DOCUMENT_SECTION.KeycodeVerification,
          TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Approved
        );
        break;
      case TRAINER_APPLICATION_ACTION_BUTTONS.KeycodeButtonReject:
        updateSectionStatus(
          TRAINER_APPLICATION_DOCUMENT_SECTION.KeycodeVerification,
          TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Rejected,
          textareaValue
        );
        setKeyCodeImage(false);
        break;
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    const checkRejectStatus = sectionReviewStatus.some(
      (item) =>
        item.status === TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Rejected
    );
    const allDocumentApproved =
      sectionReviewStatus.length > 2 &&
      sectionReviewStatus.every(
        (item) =>
          item.status === TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Approved
      );
    let hasError = false;
    if (notesTextareaValue.length > 999) {
      setNotesErrorMessage(
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("notes")],
          ["max", "999"],
        ])
      );
      hasError = true;
    }
    if (selectedStatus < TRAINER_APPLICATION_STATUS.Draft) {
      SweetAlertError(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("application_status")],
        ])
      );
      hasError = true;
    }
    if (
      selectedStatus !== TRAINER_APPLICATION_STATUS.ReviewInProgress &&
      notesTextareaValue.length === 0
    ) {
      SweetAlertError(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("notes")],
        ])
      );
      hasError = true;
    }
    if (
      selectedStatus === TRAINER_APPLICATION_STATUS.ChangeRequested &&
      !checkRejectStatus
    ) {
      SweetAlertError(t("one_file_rejected"));
      hasError = true;
    }
    if (
      trainerApplicationFormInitialValues?.application_status ===
        TRAINER_APPLICATION_STATUS.ReviewInProgress &&
      (selectedStatus === TRAINER_APPLICATION_STATUS.ScheduledInterview ||
        selectedStatus === TRAINER_APPLICATION_STATUS.PartialApproved ||
        selectedStatus === TRAINER_APPLICATION_STATUS.Approved) &&
      sectionReviewStatus.length < 3 &&
      !allDocumentApproved
    ) {
      SweetAlertError(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("all_doc_approvals")],
        ])
      );
      hasError = true;
    }
    if (
      trainerApplicationFormInitialValues?.application_status ===
        TRAINER_APPLICATION_STATUS.PartialApproved &&
      (selectedStatus === TRAINER_APPLICATION_STATUS.ScheduledInterview ||
        selectedStatus === TRAINER_APPLICATION_STATUS.Approved) &&
      skillSetValue.length <
        trainerApplicationFormInitialValues.application_content.skill_set.length
    ) {
      SweetAlertError(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("error_messages_approve_required")],
        ])
      );
      hasError = true;
    }
    if (
      trainerApplicationFormInitialValues?.application_status ===
        TRAINER_APPLICATION_STATUS.ScheduledInterview &&
      selectedStatus === TRAINER_APPLICATION_STATUS.PartialApproved &&
      skillSetValue.length < 1
    ) {
      SweetAlertError(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("error_messages_approve_required")],
        ])
      );
      hasError = true;
    }
    if (
      trainerApplicationFormInitialValues?.application_status ===
        TRAINER_APPLICATION_STATUS.PartialApproved &&
      selectedStatus === TRAINER_APPLICATION_STATUS.PartialApproved &&
      skillSetValue.length < 1
    ) {
      SweetAlertError(t("error_messages_partial_approve_required"));
      hasError = true;
    }

    if (!hasError) {
      let values;
      if (selectedStatus === TRAINER_APPLICATION_STATUS.ChangeRequested) {
        values = {
          status: selectedStatus,
          skill_set: skillSetValue,
          reason: notesTextareaValue,
          section_review_status: sectionReviewStatus,
        };
      } else if (
        selectedStatus === TRAINER_APPLICATION_STATUS.ReviewInProgress
      ) {
        values = {
          status: selectedStatus,
          skill_set: skillSetValue,
        };
      } else {
        values = {
          status: selectedStatus,
          reason: notesTextareaValue,
          skill_set: skillSetValue,
        };
      }
      await handleSubmitTrainerApplication(
        values,
        trainerApplicationToEdit?.id,
        handleClose
      );
    }
    setLoading(false);
  };

  const toggleZoom = (val: number) => {
    setShowZoom(showZoom === val ? 0 : val);
  };

  const handleSkillChange = (id: number) => {
    setSkillSetValue((prevSkillSet) => {
      if (prevSkillSet.includes(id)) {
        return prevSkillSet.filter((skillId) => skillId !== id);
      } else {
        return [...prevSkillSet, id];
      }
    });
  };

  return (
    <FormRightModalWrapper
      containerClassName={"w-full"}
      onClose={() => handleClose(false)}
      title={t("trainer_application")}
      subTitle={`${trainerApplicationFormInitialValues?.applicant_name} - ${trainerApplicationFormInitialValues?.application_id}`}
    >
      <div className="grid grid-cols-1 xl:grid-cols-12 xl:gap-4">
        <div className="col-span-8">
          <div className="flex justify-between p-3 rounded-lg mb-3 bg-skin-sidebar-menu-tab">
            <div className="flex space-x-1">
              <div className="typo-form-title">
                {t("application_submit_date")}
              </div>
              {trainerApplicationFormInitialValues?.submitted_date && (
                <div>
                  {dateFormat(
                    getLocalDate(
                      trainerApplicationFormInitialValues?.submitted_date
                    )
                  )}
                </div>
              )}
            </div>
            <div className="flex space-x-1">
              <div className="typo-form-title">
                {t("current_application_status")}
              </div>
              <div>
                {
                  TRAINER_APPLICATION_LABEL[
                    trainerApplicationFormInitialValues?.application_status as keyof typeof TRAINER_APPLICATION_LABEL
                  ]
                }
              </div>
            </div>
          </div>
          <div className="space-y-3">
            <div className="shadow-md ">
              <h2>
                <button
                  onClick={() =>
                    toggleAccordion(
                      TRAINER_APPLICATION_COLLATION_NAVIGATION.ApplicationInformation
                    )
                  }
                  type="button"
                  className={` flex items-center justify-between w-full p-5 font-medium trainer-form-accordion-border  hover:bg-skin-sidebar-menu-tab ${
                    openAccordions.includes(
                      TRAINER_APPLICATION_COLLATION_NAVIGATION.ApplicationInformation
                    )
                      ? "bg-skin-sidebar-menu-tab"
                      : ""
                  }  rounded-t-lg`}
                >
                  <span className="typo-form-title">
                    {t("applicant_information")}
                  </span>
                  {openAccordions.includes(
                    TRAINER_APPLICATION_COLLATION_NAVIGATION.ApplicationInformation
                  )
                    ? arrowUpIcon
                    : arrowDownIcon}
                </button>
              </h2>
              <div
                className={`${
                  openAccordions.includes(
                    TRAINER_APPLICATION_COLLATION_NAVIGATION.ApplicationInformation
                  )
                    ? "block"
                    : "hidden"
                }`}
              >
                <div className="p-5 border-t-0 trainer-form-accordion-border">
                  <>
                    <div className="grid grid-cols-1 lg:grid-cols-18">
                      <div className="space-y-3 lg:col-span-8">
                        <div className="flex space-x-1">
                          <div className="typo-form-title">
                            {t("applicant_name")}:
                          </div>
                          <div>
                            {
                              trainerApplicationFormInitialValues?.applicant_name
                            }
                          </div>
                        </div>
                        <div className="flex space-x-1">
                          <div className="typo-form-title">
                            {t("date_of_birth")}:
                          </div>
                          <div>
                            {dateFormat(
                              responseDateStringToDate(
                                trainerApplicationFormInitialValues
                                  ?.application_content.dob
                              )
                            )}
                          </div>
                        </div>
                        <div className="flex space-x-1">
                          <div className="typo-form-title">{t("gender")}:</div>
                          <div>
                            {
                              trainerApplicationFormInitialValues
                                ?.application_content.gender_label
                            }
                          </div>
                        </div>
                        <div className="flex space-x-1">
                          <div className="typo-form-title">
                            {t("contact_number")}:
                          </div>
                          {trainerApplicationFormInitialValues?.phone !==
                          null ? (
                            <>
                              {showMobileNumber ? (
                                <div className="mr-2">
                                  {trainerApplicationFormInitialValues?.phone}
                                </div>
                              ) : (
                                <div className="mr-2">
                                  {maskMobileNumber(
                                    trainerApplicationFormInitialValues?.phone ??
                                      ""
                                  )}
                                </div>
                              )}
                              {showMobileNumber ? (
                                <div
                                  className="cursor-pointer w-5 h-5"
                                  onClick={toggleMobileNumberVisibility}
                                >
                                  {eyeIcon}
                                </div>
                              ) : (
                                <div
                                  className="cursor-pointer w-5 h-5"
                                  onClick={toggleMobileNumberVisibility}
                                >
                                  {visibilityEyeIcon}
                                </div>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex space-x-1">
                          <div className="typo-form-title">{t("email")}:</div>
                          {trainerApplicationFormInitialValues?.email !==
                          null ? (
                            <>
                              {showEmail ? (
                                <div>
                                  {trainerApplicationFormInitialValues?.email}
                                </div>
                              ) : (
                                <div>
                                  {maskEmail(
                                    trainerApplicationFormInitialValues?.email ??
                                      ""
                                  )}
                                </div>
                              )}
                              {showEmail ? (
                                <div
                                  className="cursor-pointer w-5 h-5"
                                  onClick={toggleEmailVisibility}
                                >
                                  {eyeIcon}
                                </div>
                              ) : (
                                <div
                                  className="cursor-pointer w-5 h-5"
                                  onClick={toggleEmailVisibility}
                                >
                                  {visibilityEyeIcon}
                                </div>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex space-x-1">
                          <div className="typo-form-title">{t("address")}:</div>
                          <div>
                            <div>
                              {
                                trainerApplicationFormInitialValues
                                  ?.application_content?.address?.address_line_1
                              }
                            </div>
                            <div>
                              {
                                trainerApplicationFormInitialValues
                                  ?.application_content.address?.address_line_2
                              }
                            </div>
                            {trainerApplicationFormInitialValues
                              ?.application_content.address?.address_line_3 && (
                              <div>
                                {
                                  trainerApplicationFormInitialValues
                                    ?.application_content.address
                                    ?.address_line_3
                                }
                              </div>
                            )}
                            <div className="">
                              {
                                trainerApplicationFormInitialValues
                                  ?.application_content.address?.city
                              }
                              {
                                trainerApplicationFormInitialValues
                                  ?.application_content.address?.state_id
                              }
                              {
                                trainerApplicationFormInitialValues
                                  ?.application_content.address?.postcode
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-between lg:col-span-10">
                        <div className="flex justify-between items-center">
                          <div
                            onClick={() => {
                              !readOnly &&
                                toggleZoom(
                                  TRAINER_APPLICATION_IMAGE_NAVIGATION.ApplicantNameImage
                                );
                            }}
                            className="img-list"
                          >
                            <ICImageView
                              isPrivate
                              url={
                                trainerApplicationFormInitialValues
                                  ?.application_content
                                  .address_document_image_url
                                  ? trainerApplicationFormInitialValues
                                      ?.application_content
                                      .identity_document_image_url
                                  : ""
                              }
                              className={
                                showZoom ===
                                TRAINER_APPLICATION_IMAGE_NAVIGATION.ApplicantNameImage
                                  ? "img-zoom"
                                  : "img-list"
                              }
                            />
                            {showZoom ===
                            TRAINER_APPLICATION_IMAGE_NAVIGATION.ApplicantNameImage ? (
                              <div className="img-div"></div>
                            ) : (
                              ""
                            )}
                          </div>
                          {applicantNameImage !== null && (
                            <div className=" inset-0 flex justify-center items-center">
                              {applicantNameImage ? (
                                <div className="h-14 w-14 approve-fill-color">
                                  {checkIcon}
                                </div>
                              ) : (
                                <div className="h-14 w-14 reject-fill-color">
                                  {rejectIcon}
                                </div>
                              )}
                            </div>
                          )}

                          {!readOnly &&
                            trainerApplicationFormInitialValues?.application_status ===
                              TRAINER_APPLICATION_STATUS.ReviewInProgress && (
                              <div className="flex space-x-3">
                                <ICButton
                                  className="approve-button-color"
                                  onClick={() =>
                                    handleGetTextFieldValue(
                                      TRAINER_APPLICATION_ACTION_BUTTONS.IdentityButtonApprove
                                    )
                                  }
                                >
                                  {t("approve")}
                                </ICButton>

                                <ICButton
                                  className="reject-button-color"
                                  onClick={handleRejectIdentityImage}
                                >
                                  {t("reject")}
                                </ICButton>
                              </div>
                            )}
                        </div>
                        <div className="flex justify-between items-center">
                          <div
                            onClick={() => {
                              !readOnly &&
                                toggleZoom(
                                  TRAINER_APPLICATION_IMAGE_NAVIGATION.AddressImage
                                );
                            }}
                            className="img-list"
                          >
                            <ICImageView
                              isPrivate
                              url={
                                trainerApplicationFormInitialValues
                                  ?.application_content
                                  .address_document_image_url
                                  ? trainerApplicationFormInitialValues
                                      ?.application_content
                                      .address_document_image_url
                                  : ""
                              }
                              className={
                                showZoom ===
                                TRAINER_APPLICATION_IMAGE_NAVIGATION.AddressImage
                                  ? "img-zoom"
                                  : "img-list"
                              }
                            />
                            {showZoom ===
                            TRAINER_APPLICATION_IMAGE_NAVIGATION.AddressImage ? (
                              <div className="img-div"></div>
                            ) : (
                              ""
                            )}
                          </div>
                          {addressImage !== null && (
                            <div className=" inset-0 flex justify-center items-center">
                              {addressImage ? (
                                <div className="h-14 w-14 approve-fill-color">
                                  {checkIcon}
                                </div>
                              ) : (
                                <div className="h-14 w-14 reject-fill-color">
                                  {rejectIcon}
                                </div>
                              )}
                            </div>
                          )}
                          {!readOnly &&
                            trainerApplicationFormInitialValues?.application_status ===
                              TRAINER_APPLICATION_STATUS.ReviewInProgress && (
                              <div className="flex space-x-3">
                                <ICButton
                                  className="approve-button-color"
                                  onClick={() =>
                                    handleGetTextFieldValue(
                                      TRAINER_APPLICATION_ACTION_BUTTONS.AddressButtonApprove
                                    )
                                  }
                                >
                                  {t("approve")}
                                </ICButton>
                                <ICButton
                                  className="reject-button-color"
                                  onClick={handleRejectAddressImage}
                                >
                                  {t("reject")}
                                </ICButton>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div className="shadow-md">
              <h2>
                <button
                  onClick={() =>
                    toggleAccordion(
                      TRAINER_APPLICATION_COLLATION_NAVIGATION.PreferredLocation
                    )
                  }
                  type="button"
                  className={`flex items-center justify-between w-full p-5 font-medium trainer-form-accordion-border  hover:bg-skin-sidebar-menu-tab ${
                    openAccordions.includes(
                      TRAINER_APPLICATION_COLLATION_NAVIGATION.PreferredLocation
                    )
                      ? "bg-skin-sidebar-menu-tab"
                      : ""
                  }  rounded-t-lg`}
                >
                  <span className="typo-form-title">
                    {t("preferred_location")}
                  </span>
                  {openAccordions.includes(
                    TRAINER_APPLICATION_COLLATION_NAVIGATION.PreferredLocation
                  )
                    ? arrowUpIcon
                    : arrowDownIcon}
                </button>
              </h2>
              <div
                className={`${
                  openAccordions.includes(
                    TRAINER_APPLICATION_COLLATION_NAVIGATION.PreferredLocation
                  )
                    ? "block"
                    : "hidden"
                }`}
              >
                <div className="p-5  border-t-0 trainer-form-accordion-border">
                  <div className="inline-flex w-full flex-wrap gap-4">
                    {trainerApplicationFormInitialValues?.application_content
                      .preferred_location_label &&
                      trainerApplicationFormInitialValues?.application_content.preferred_location_label.map(
                        (location, index) => (
                          <>
                            <div
                              className="bg-skin-sidebar-menu-tab p-2 rounded-lg"
                              key={index}
                            >
                              {location}
                            </div>
                          </>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-md">
              <h2>
                <button
                  onClick={() =>
                    toggleAccordion(
                      TRAINER_APPLICATION_COLLATION_NAVIGATION.SkillSet
                    )
                  }
                  type="button"
                  className={`flex items-center justify-between w-full p-5 font-medium  trainer-form-accordion-border  hover:bg-skin-sidebar-menu-tab ${
                    openAccordions.includes(
                      TRAINER_APPLICATION_COLLATION_NAVIGATION.SkillSet
                    )
                      ? "bg-skin-sidebar-menu-tab"
                      : ""
                  }  rounded-t-lg`}
                >
                  <span className="typo-form-title"> {t("skill_set")}</span>
                  {openAccordions.includes(
                    TRAINER_APPLICATION_COLLATION_NAVIGATION.SkillSet
                  )
                    ? arrowUpIcon
                    : arrowDownIcon}
                </button>
              </h2>
              <div
                className={`${
                  openAccordions.includes(
                    TRAINER_APPLICATION_COLLATION_NAVIGATION.SkillSet
                  )
                    ? "block"
                    : "hidden"
                }`}
              >
                <div className="p-5 border-t-0 trainer-form-accordion-border">
                  <div className="w-full ">
                    {trainerApplicationFormInitialValues?.application_content
                      .skill_set &&
                      trainerApplicationFormInitialValues?.application_content.skill_set.map(
                        (skillSet, index) => (
                          <div className="flex justify-between mb-3 items-center">
                            <div
                              className="bg-skin-sidebar-menu-tab  p-2 rounded-lg"
                              key={index}
                            >
                              {skillSet.title}
                            </div>
                            <label className="relative inline-flex cursor-pointer">
                              <input
                                disabled={readOnly}
                                type="checkbox"
                                name={skillSet.title}
                                className="sr-only peer"
                                onChange={() => handleSkillChange(skillSet.id)}
                                checked={
                                  skillSetValue.includes(skillSet.id)
                                    ? true
                                    : false
                                }
                              />
                              <div className="w-9 h-5 bg-skin-switch-ict peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-skin-ict dark:peer-focus:ring-skin-dark-focus rounded-full peer dark:bg-skin-dark-ict peer-checked:after:translate-x-full peer-checked:after:border-skin-ict-after after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-skin-ict after:border-skin-ict-after after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-skin-ict-dark peer-checked:bg-skin-checked-ict"></div>
                            </label>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-md">
              <h2>
                <button
                  onClick={() =>
                    toggleAccordion(
                      TRAINER_APPLICATION_COLLATION_NAVIGATION.Certificates
                    )
                  }
                  type="button"
                  className={`flex items-center justify-between w-full p-5 font-medium trainer-form-accordion-border hover:bg-skin-sidebar-menu-tab ${
                    openAccordions.includes(
                      TRAINER_APPLICATION_COLLATION_NAVIGATION.Certificates
                    )
                      ? "bg-skin-sidebar-menu-tab"
                      : ""
                  }  rounded-t-lg`}
                >
                  <span className="typo-form-title">{t("certificates")}</span>
                  {openAccordions.includes(
                    TRAINER_APPLICATION_COLLATION_NAVIGATION.Certificates
                  )
                    ? arrowUpIcon
                    : arrowDownIcon}
                </button>
              </h2>
              <div
                className={`${
                  openAccordions.includes(
                    TRAINER_APPLICATION_COLLATION_NAVIGATION.Certificates
                  )
                    ? "block"
                    : "hidden"
                }`}
              >
                <div className="p-5 border-t-0 trainer-form-accordion-border">
                  <div className="grid grid-cols-3 space-y-6 content-center">
                    {trainerApplicationFormInitialValues?.application_content
                      .certificates &&
                      trainerApplicationFormInitialValues?.application_content.certificates.map(
                        (val) => (
                          <div className="flex justify-between items-center">
                            <div
                              className="grid place-items-center img-list"
                              onClick={() => {
                                !readOnly &&
                                  toggleZoom(
                                    TRAINER_APPLICATION_IMAGE_NAVIGATION.CertificatesImage
                                  );
                              }}
                            >
                              <div className="flex flex-col items-center">
                                <ICImageView
                                  isPrivate
                                  url={val.certificate_image_url}
                                  className={`${
                                    showZoom ===
                                    TRAINER_APPLICATION_IMAGE_NAVIGATION.CertificatesImage
                                      ? "img-zoom"
                                      : "img-list"
                                  }`}
                                />
                                {showZoom ===
                                TRAINER_APPLICATION_IMAGE_NAVIGATION.CertificatesImage ? (
                                  <div className="img-div"></div>
                                ) : (
                                  ""
                                )}
                                <div>{val?.category_name}</div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-md">
              <h2>
                <button
                  onClick={() =>
                    toggleAccordion(
                      TRAINER_APPLICATION_COLLATION_NAVIGATION.KeyCodeVerification
                    )
                  }
                  type="button"
                  className={`flex items-center justify-between w-full p-5 font-medium trainer-form-accordion-border hover:bg-skin-sidebar-menu-tab ${
                    openAccordions.includes(
                      TRAINER_APPLICATION_COLLATION_NAVIGATION.KeyCodeVerification
                    )
                      ? "bg-skin-sidebar-menu-tab"
                      : ""
                  }  rounded-t-lg`}
                >
                  <div className="typo-form-title">
                    {t("key_code_verification")}
                  </div>
                  {openAccordions.includes(
                    TRAINER_APPLICATION_COLLATION_NAVIGATION.KeyCodeVerification
                  )
                    ? arrowUpIcon
                    : arrowDownIcon}
                </button>
              </h2>
              <div
                className={`${
                  openAccordions.includes(
                    TRAINER_APPLICATION_COLLATION_NAVIGATION.KeyCodeVerification
                  )
                    ? "block"
                    : "hidden"
                }`}
              >
                <div className="grid grid-col-1 lg:grid-cols-12 p-2">
                  <div className="p-2 w-full col-span-5 grid place-content-stretch ">
                    <div className="typo-form-title">
                      {t("generated_key_code")}
                    </div>
                    <div className="key-code">
                      {trainerApplicationFormInitialValues?.keycode}
                    </div>
                  </div>
                  <div className="flex-1 lg:flex justify-between item-end lg:items-center col-span-7">
                    <div
                      onClick={() => {
                        !readOnly &&
                          toggleZoom(
                            TRAINER_APPLICATION_IMAGE_NAVIGATION.KeyCodeImage
                          );
                      }}
                      className="img-list"
                    >
                      <ICImageView
                        isPrivate
                        url={
                          trainerApplicationFormInitialValues
                            ?.application_content.keycode_document_url
                            ? trainerApplicationFormInitialValues
                                ?.application_content.keycode_document_url
                            : ""
                        }
                        className={
                          showZoom ===
                          TRAINER_APPLICATION_IMAGE_NAVIGATION.KeyCodeImage
                            ? "img-zoom"
                            : "img-list"
                        }
                      />
                      {showZoom ===
                      TRAINER_APPLICATION_IMAGE_NAVIGATION.KeyCodeImage ? (
                        <div className="img-div"></div>
                      ) : (
                        ""
                      )}
                    </div>
                    {keyCodeImage !== null && (
                      <div className="flex justify-center items-center">
                        {keyCodeImage ? (
                          <div className="h-14 w-14 approve-fill-color">
                            {checkIcon}
                          </div>
                        ) : (
                          <div className="h-14 w-14 reject-fill-color">
                            {rejectIcon}
                          </div>
                        )}
                      </div>
                    )}
                    {!readOnly &&
                      trainerApplicationFormInitialValues?.application_status ===
                        TRAINER_APPLICATION_STATUS.ReviewInProgress && (
                        <div className="flex space-x-2 mr-4">
                          <ICButton
                            className="approve-button-color"
                            onClick={() =>
                              handleGetTextFieldValue(
                                TRAINER_APPLICATION_ACTION_BUTTONS.KeycodeButtonApprove
                              )
                            }
                          >
                            {t("approve")}
                          </ICButton>
                          <ICButton
                            className="reject-button-color"
                            onClick={handleKeyCodeImage}
                          >
                            {t("reject")}
                          </ICButton>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-md">
              <h2>
                <button
                  onClick={() =>
                    toggleAccordion(
                      TRAINER_APPLICATION_COLLATION_NAVIGATION.Others
                    )
                  }
                  type="button"
                  className={`flex items-center justify-between w-full p-5 font-medium trainerFormAccordionBorder0 hover:bg-skin-sidebar-menu-tab ${
                    openAccordions.includes(
                      TRAINER_APPLICATION_COLLATION_NAVIGATION.Others
                    )
                      ? "bg-skin-sidebar-menu-tab"
                      : ""
                  }  rounded-t-lg`}
                >
                  <span className="typo-form-title">{t("others")}</span>
                  {openAccordions.includes(
                    TRAINER_APPLICATION_COLLATION_NAVIGATION.Others
                  )
                    ? arrowUpIcon
                    : arrowDownIcon}
                </button>
              </h2>
              <div
                className={`${
                  openAccordions.includes(
                    TRAINER_APPLICATION_COLLATION_NAVIGATION.Others
                  )
                    ? "block"
                    : "hidden"
                }`}
              >
                <div className="space-y-2 p-4">
                  <div className="flex space-x-1">
                    <div className="typo-form-title">
                      {t("marketing_partner")}:
                    </div>
                    <div>
                      {trainerApplicationFormInitialValues?.application_content
                        .is_marketing_partner
                        ? t("yes")
                        : t("no")}
                    </div>
                  </div>
                  <div className="flex space-x-1">
                    <div className="typo-form-title">{t("has_own_space")}:</div>
                    <div className="">
                      {trainerApplicationFormInitialValues?.application_content
                        .has_own_session_space
                        ? t("yes")
                        : t("no")}
                    </div>
                  </div>
                  <div className="flex space-x-1">
                    <div className="typo-form-title">
                      {t("available_for_rent")}:
                    </div>
                    <div>
                      {trainerApplicationFormInitialValues?.application_content
                        .has_space_for_rent
                        ? t("yes")
                        : t("no")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!readOnly && (
              <div className=" p-1 mt-3 space-y-2">
                <div className=" flex space-x-2">
                  <div className="typo-form-title content-center">
                    {t("application_status")}
                  </div>
                  <div className="w-1/4">
                    <ICDropDown
                      options={trainerApplicationDropDownOptions(
                        trainerApplicationFormInitialValues,
                        skillSetValue,
                        sectionReviewStatus
                      )}
                      onSelect={handleSelectDropDown}
                      selected={selectedStatus}
                      disabled={readOnly}
                    />
                  </div>
                </div>
                <div>
                  <ICTextArea
                    rows={10}
                    label={t("notes")}
                    containerClassName={"p-1 mt-5"}
                    value={notesTextareaValue}
                    onChange={(event) => {
                      setNotesTextareaValue(event.target.value);
                      setNotesErrorMessage("");
                    }}
                    disabled={readOnly}
                    errorMessage={
                      notesTextareaValue.length < 1 ||
                      notesTextareaValue.length > 999
                        ? notesErrorMessage
                        : ""
                    }
                  />
                </div>
                {!readOnly && (
                  <div className="flex justify-start">
                    <ICButton
                      className="min-w-48"
                      children={t("submit")}
                      loading={loading}
                      onClick={handleSubmit}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="mt-3.5 xl:mt-0 col-span-4 h-[1199px] overflow-y-scroll w-full border rounded-lg trainer-form-accordion-border shadow-md">
          <TrainerApplicationTimeLineView
            handleClose={handleClose}
            trainerApplicationToEdit={trainerApplicationToEdit}
          />
        </div>
      </div>

      {showModal.modal && (
        <ModalComponent
          title={t("why_reject")}
          content={
            <>
              <div className="my-3 custom-text-area-container">
                <textarea
                  className="h-52 w-full custom-text-area typo-input-value"
                  value={textareaValue}
                  onChange={handleTextareaChange}
                />
              </div>
              <ICButton
                onClick={handleModalValue}
                className={`${
                  textareaValue.length < 1
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                disabled={textareaValue.length < 1}
              >
                {t("save")}
              </ICButton>
            </>
          }
          open={showModal.modal}
          onClose={handleCloseModal}
        />
      )}
    </FormRightModalWrapper>
  );
};

export default TrainerApplicationFormView;
