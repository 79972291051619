import { BrowserRouter as Router } from "react-router-dom";
import RouterHome from "./RouteHome";
import PublicRouter from "./RoutePublic";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <RouterHome />
      <PublicRouter />
    </Router>
  );
};

export default AppRouter;
