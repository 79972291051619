import { serviceMaker } from "..";
import { IActiveInactive, IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

interface IFaqsPayload {
  faq_type: string;
  question: string;
  answer: string;
  reference_links?: [];
  tags?: [];
  is_active?: string;
  is_common_for_service?: string;
}

interface ServiceFaqs {
  question_id: number;
}

export interface ServiceFaqsData {
  service_id: number;
  service_faqs: ServiceFaqs[];
}

export const GET_ALL_FAQS = (pagination: IPagination, search: ISearch) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_faqs.url +
      `?query=${encodeURIComponent(
        search.enterpriseSearch ? search.enterpriseSearch : ""
      )}&sd=${search.deleteAll}&currentPage=${
        pagination.currentPage
      }&perPageRows=${pagination.perPageRows}&sortBy="faqs"."${
        pagination.sortBy
      }"&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_faqs.method
  );
};

export const POST_FAQS = (payload: IFaqsPayload) => {
  return serviceMaker(
    API_ENDPOINTS.add_faqs.url,
    API_ENDPOINTS.add_faqs.method,
    payload
  );
};

export const UPDATE_FAQS = (payload: IFaqsPayload, id: number) => {
  return serviceMaker(
    API_ENDPOINTS.update_faqs.url + `/${id}`,
    API_ENDPOINTS.update_faqs.method,
    payload
  );
};

export const DELETE_FAQS = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.delete_faqs.url + `/${id}`,
    API_ENDPOINTS.delete_faqs.method
  );
};

export const ACTIVE_INACTIVE_FAQS = async (
  id: number,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_faqs.url + `/${id}`,
    API_ENDPOINTS.active_faqs.method,
    activeInactiveValue
  );
};

export const GET_FAQS_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_faqs.url + `/${id}`,
    API_ENDPOINTS.get_all_faqs.method
  );
};

export const GET_FAQS_BY_SERVICE_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_service_faqs.url + `/${id}?uin=faqs`,
    API_ENDPOINTS.get_service_faqs.method
  );
};

export const ADD_SERVICE_FAQS = (payload: ServiceFaqsData) => {
  return serviceMaker(
    API_ENDPOINTS.add_service_faqs.url,
    API_ENDPOINTS.add_service_faqs.method,
    payload
  );
};

export const GET_ALL_SERVICE_FAQS = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_service_faqs.url + `/${id}`,
    API_ENDPOINTS.get_service_faqs.method
  );
};

export const DELETE_SERVICE_FAQS = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.delete_service_faqs.url + `/${id}`,
    API_ENDPOINTS.delete_service_faqs.method
  );
};
