import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, IMediaItemImage, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import {
  ACTIVE_INACTIVE_HIGHLIGHTS,
  DELETE_HIGHLIGHTS,
  GET_ALL_HIGHLIGHTS,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  generatePaginationFromApiRes,
  prepareMessageFromParams,
  resetPaginationWithPpr,
  toastError,
  toastSuccess,
} from "../../utils/AppFunctions";

export interface IHighlights {
  id: number;
  highlight_text: string;
  cancellation_charge_percentage: number | null;
  end_date: Date | null;
  is_active?: string;
  deleted?: string;
  app_media?: [IMediaItemImage];
  highlight_media?: IMediaItemImage[];
}

export function getHighlightsFields<T>(
  actionComponent: (item: T) => JSX.Element,
  customComponentImgView: (item: T) => JSX.Element,
  handleStatusChange: (item: T) => void,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("highlight_text"),
      key: "highlight_text",
      sort: true,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
      component: customComponentImgView,
    },
    {
      name: t("end_date"),
      key: "end_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("status"),
      key: "is_active",
      sort: false,
      type: TABLE_FIELD_TYPE.switch,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      handleCheck: handleStatusChange,
      flex: "col-span-1",
    },
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
      component: actionComponent,
    },
  ];
}

export const fetchHighlightsApiCall = async (
  setHighlightsList: TReactSetState<IHighlights[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const highlightsList = await GET_ALL_HIGHLIGHTS(pagination, search);
    if (highlightsList && highlightsList.code === DEFAULT_STATUS_CODE_SUCCESS) {
      if (highlightsList.data && highlightsList.data.item.length > 0) {
        setHighlightsList(highlightsList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(highlightsList.data.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetHighlightsList = await GET_ALL_HIGHLIGHTS(
          resetPagination,
          search
        );
        if (
          resetHighlightsList &&
          resetHighlightsList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setHighlightsList(resetHighlightsList.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              resetHighlightsList.data.pagination
            ),
          });
        } else {
          setHighlightsList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(highlightsList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.highlightsList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleDeleteHighlights = async (
  id: number,
  t: TUseTranslationTfn
) => {
  try {
    const highlightsDelete = await DELETE_HIGHLIGHTS(id);
    if (
      highlightsDelete &&
      highlightsDelete.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(highlightsDelete.message);
      return true;
    } else {
      SweetAlertError(
        highlightsDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
    return false;
  } catch (e: any) {
    SweetAlertError(e?.faqsDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    return false;
  }
};

export const handleActiveInactiveHighlights = async (
  id: number | undefined,
  values: string
) => {
  try {
    const highlightsActiveInactive = await ACTIVE_INACTIVE_HIGHLIGHTS(id, {
      is_active: values,
    });
    if (
      highlightsActiveInactive &&
      highlightsActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      toastSuccess(highlightsActiveInactive.message);
      return true;
    }
    return false;
  } catch (e: any) {
    toastError(
      e?.highlightsActiveInactive?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};
