import { useFormik } from "formik";
import * as Yup from "yup";

import {
  calculatePasswordStrength,
  toastError,
  toastSuccess,
} from "../../../utils/AppFunctions";
import { NavigateFunction } from "react-router-dom";
import { CHANGE_PASSWORD } from "../../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../utils/AppConstants";
import { TUseTranslationTfn } from "../../../data/AppType";

export interface IChangePassword {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

export const validationSchemaConfirm = (t: TUseTranslationTfn) =>
  Yup.object({
    old_password: Yup.string().required(
      t("change_password_validation_massage")
    ),
    new_password: Yup.string()
      .required(t("change_password_validation_massage_1"))
      .min(8, t("change_password_validation_massage_2"))
      .matches(/[A-Z]/, t("change_password_validation_massage_3"))
      .matches(/[a-z]/, t("change_password_validation_massage_4"))
      .matches(/[0-9]/, t("change_password_validation_massage_5"))
      .matches(/[^A-Za-z0-9]/, t("change_password_validation_massage_6"))
      .test(
        t("change_password_validation_massage_7"),
        t("change_password_validation_massage_8"),
        (value) => {
          if (!value) return false;
          const { strength } = calculatePasswordStrength(value);
          return strength !== "Very Weak" && strength !== "Weak";
        }
      ),
    confirm_password: Yup.string()
      .oneOf(
        [Yup.ref("new_password")],
        t("change_password_validation_massage_9")
      )
      .required(t("change_password_validation_massage_10")),
  });

export const initialValuesConfirmPassword = {
  old_password: "",
  new_password: "",
  confirm_password: "",
};

export const createNewPassword = async (
  values: IChangePassword,
  navigation: NavigateFunction
) => {
  try {
    const { old_password, new_password, confirm_password } = values;
    const payload = {
      old_password: old_password,
      new_password: new_password,
      confirm_password: confirm_password,
    };
    let changePassword;
    changePassword = await CHANGE_PASSWORD(payload);
    if (changePassword && changePassword.code === DEFAULT_STATUS_CODE_SUCCESS) {
      toastSuccess(changePassword?.message);
      navigation("/");
    }
  } catch (e: any) {
    toastError(e?.changePassword.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
