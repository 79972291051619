import { TReactSetState } from "../../data/AppType";
import {
  GET_ALL_SYSTEM_CONFIGURATION,
  GET_SYSTEM_CONFIGURATION_BY_KEY,
  UPDATE_ALL_SYSTEM_CONFIGURATION,
} from "../../services/Endpoints";
import {
  IConfigPayload,
  IConfigValuePayload,
} from "../../services/system-configurations/SystemConfigurationsService";

import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import { SweetAlertError, SweetAlertSuccess } from "../../utils/AppFunctions";

export const fetchSystemSettings = async (
  setSystemSettings: TReactSetState<IConfigPayload[]>,
  menuId: number
) => {
  try {
    const systemSettings = await GET_ALL_SYSTEM_CONFIGURATION(menuId);

    if (systemSettings && systemSettings.code === DEFAULT_STATUS_CODE_SUCCESS) {
      setSystemSettings(systemSettings.data);
    }
  } catch (e: any) {
    SweetAlertError(
      e?.systemSettings?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleSubmitSystemConfiguration = async (
  values: IConfigValuePayload[]
) => {
  try {
    const resultBlogCategory = await UPDATE_ALL_SYSTEM_CONFIGURATION(values);

    if (
      resultBlogCategory &&
      resultBlogCategory.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(resultBlogCategory.message);
    } else {
      SweetAlertError(
        resultBlogCategory?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    SweetAlertError(
      e?.resultBlogCategory?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const fetchSystemSettingsByConfigKey = async (
  setSystemSettings: TReactSetState<IConfigPayload | undefined>,
  configKey: string
) => {
  try {
    const systemSettings = await GET_SYSTEM_CONFIGURATION_BY_KEY(configKey);

    if (systemSettings && systemSettings.code === DEFAULT_STATUS_CODE_SUCCESS) {
      setSystemSettings(systemSettings.data);
    } else {
      SweetAlertError(systemSettings.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    SweetAlertError(
      e?.systemSettings?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
