import { serviceMaker } from "../../..";
import { IAddService } from "../../../../data/AppInterface";
import { API_ENDPOINTS } from "../../../../utils/ApiEndPoint";

export const GET_SERVICE_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_services.url + `/${id}`,
    API_ENDPOINTS.get_all_services.method
  );
};

export const GET_SERVICE_SUB_CATEGORY_BY_ID_BASIC = (
  id: number | undefined
) => {
  return serviceMaker(
    API_ENDPOINTS.get_service_subcategory_by_id.url + `/${id}`,
    API_ENDPOINTS.get_service_subcategory_by_id.method
  );
};

export const ADD_BASIC_INFO_SERVICE = (payload: IAddService) => {
  return serviceMaker(
    API_ENDPOINTS.add_basic_info_service.url,
    API_ENDPOINTS.add_basic_info_service.method,
    payload
  );
};
export const UPDATE_BASIC_INFO_SERVICE = (
  payload: any,
  id: number | undefined
) => {
  return serviceMaker(
    API_ENDPOINTS.update_basic_info_service.url + `/${id}`,
    API_ENDPOINTS.update_basic_info_service.method,
    payload
  );
};

export const GET_INTERVAL_UNITS = () => {
  return serviceMaker(
    API_ENDPOINTS.get_interval_units.url,
    API_ENDPOINTS.get_interval_units.method
  );
};
