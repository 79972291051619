import React, { useState } from "react";
import { Link } from "react-router-dom";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  addIcon,
  filterIcon,
  refreshIcon,
  searchIcon,
} from "../../assets/icons/SvgIconList";

interface ILibraryHeder {
  handleOnClickAdd?: () => void;
  handleOnClickAddFile?: () => void;
  handleSearchChange?: (text: string) => void;
  handleDeleteCheckBox?: (check: boolean) => void;
  filterCheckBox?: boolean;
  searchText?: string;
  filterNode?: React.ReactNode;
  refreshTable?: () => void;
}

const LibraryHeder = ({
  handleOnClickAdd,
  handleOnClickAddFile,
  handleSearchChange,
  handleDeleteCheckBox,
  filterCheckBox,
  searchText,
  filterNode,
  refreshTable,
}: ILibraryHeder) => {
  const { t } = UseTranslationHook();
  const [showFilter, setShowFilter] = useState(false);

  return (
    <div>
      <div className="min-w-max w-full  bg-skin-advance-search-background-color flex py-2 px-6 ps-3 rounded-t-sm">
        <div className="text-skin-advance-search-text-label-color  text-lg font-semibold me-auto flex items-center">
          {t("library")}
        </div>
        {handleDeleteCheckBox && (
          <div className="checkbox-wrapper m-2">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="is_premium"
                className="h-4 w-4 mr-2 rounded-md"
                checked={filterCheckBox}
                onChange={(e) => handleDeleteCheckBox(e.target.checked)}
              />
              <span className="text-lg">{t("show_deleted")}</span>
            </label>
          </div>
        )}
        {refreshTable && (
          <div className="w-8 cursor-pointer m-1" onClick={refreshTable}>
            {refreshIcon}
          </div>
        )}
        {handleSearchChange && (
          <div>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              {t("search")}
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <div className="w-4 h-4 text-skin-search-icon-color">
                  {searchIcon}
                </div>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-auto p-2 pl-10  text-sm  rounded"
                placeholder="Search Data"
                onChange={(event) => handleSearchChange(event.target.value)}
                value={searchText}
                required
              />
            </div>
          </div>
        )}

        {filterNode && (
          <button
            type="button"
            onClick={() => setShowFilter((prev) => !prev)}
            className=" flex items-center px-3 py-2 text-xs font-medium text-center border bg-skin-advance-search-btn-color rounded focus:outline-none mx-3"
          >
            <div className="text-skin-search-icon-color">{filterIcon}</div>
            &nbsp;{t("filter")}
          </button>
        )}

        <div className="flex">
          <div>
            {handleOnClickAdd && (
              <button
                type="button"
                onClick={handleOnClickAdd}
                className=" flex ml-2 items-center px-3 py-2 h-9 text-xs font-medium text-center text-skin-advance-search-text-color border rounded  focus:outline-none bg-skin-primary-btn"
              >
                {addIcon}
                &nbsp;
                {t("add_folder")}
              </button>
            )}
          </div>
          <div>
            {handleOnClickAddFile && (
              <button
                type="button"
                onClick={handleOnClickAddFile}
                className="flex ml-2 items-center px-3 py-2 h-9 text-xs font-medium text-center text-skin-advance-search-text-color border rounded  focus:outline-none bg-skin-primary-btn"
              >
                {addIcon}
                &nbsp;
                {t("add_file")}
              </button>
            )}
          </div>
        </div>
      </div>
      {showFilter && filterNode && (
        <div className="min-w-max w-full flex py-2 px-6 ps-3 rounded-t-sm">
          {filterNode}
        </div>
      )}
    </div>
  );
};

export default LibraryHeder;
