import usePagination from "../../hooks/UsePaginationHook";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import { useEffect, useState } from "react";
import {
  INITIAL_NEWSLETTERSUBSCRIPTION,
  INewsletterSubscription,
  fetchNewsletterSubscriptionApiCall,
  getNewsletterSubscriptionFields,
} from "./NewsletterSubscriptionController";
import { IAdvanceSearch, IPagination } from "../../data/AppInterface";
import { ORDER_BY } from "../../utils/AppConstants";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import ICTable from "../../core-component/ICTable";
import { decryptData, getLocalDate, maskEmail } from "../../utils/AppFunctions";
import {
  eyeIcon,
  filterClearIcon,
  visibilityEyeIcon,
} from "../../assets/icons/SvgIconList";
import ICButton from "../../core-component/ICButton";
import ICDatePicker from "../../core-component/ICDatePicker";

const NewsletterSubscriptionView = () => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [isLoading, setIsLoading] = useState(true);
  const [newsletterSubscriptionList, setNewsletterSubscriptionList] = useState<
    INewsletterSubscription[]
  >([]);
  const [visibleEmail, setVisibleEmail] = useState<{
    [key: number]: boolean;
  }>({});
  const [showAllNewsletterSubscription, setShowAllNewsletterSubscription] =
    useState(false);

  const showAllEmail = () => {
    setShowAllNewsletterSubscription((prevState) => !prevState);
  };

  const { pagination, setPagination } = usePagination({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });
  const [advanceSearch, setAdvanceSearch] = useState<IAdvanceSearch>(
    INITIAL_NEWSLETTERSUBSCRIPTION
  );
  useEffect(() => {
    fetchNewsletterSubscription(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [advanceSearch]);

  const fetchNewsletterSubscription = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchNewsletterSubscriptionApiCall(
        setNewsletterSubscriptionList,
        setPagination,
        payloadPagination,
        {
          advanceSearch,
        }
      );
      setIsLoading(false);
    }, 500);
  };
  const toggleMobileNumberVisibility = (id: number) => {
    setVisibleEmail((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const customComponentUserNameView = (item: INewsletterSubscription) => {
    return (
      <div className="grid grid-cols-6 ">
        {showAllNewsletterSubscription === true ? (
          <>
            <div className="col-span-2">
              {visibleEmail[item.id] ? (
                <div>{maskEmail(decryptData(item.email ?? ""))}</div>
              ) : (
                <>
                  <div>{decryptData(item.email)}</div>
                </>
              )}
            </div>
            {decryptData(item.email) ? (
              <div
                className="cursor-pointer w-5 h-5 col-span-4"
                onClick={() => toggleMobileNumberVisibility(item.id)}
              >
                {visibleEmail[item.id] ? visibilityEyeIcon : eyeIcon}
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <div className="col-span-2">
              {visibleEmail[item.id] ? (
                <div>{decryptData(item.email)}</div>
              ) : (
                <>
                  <div>{maskEmail(decryptData(item.email ?? ""))}</div>
                </>
              )}
            </div>
            {decryptData(item.email) ? (
              <div
                className="cursor-pointer w-5 h-5 col-span-4"
                onClick={() => toggleMobileNumberVisibility(item.id)}
              >
                {visibleEmail[item.id] ? eyeIcon : visibilityEyeIcon}
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    );
  };
  const filterView = (
    <div className="w-full grid grid-cols-3 gap-4 mt-3 bg-skin-background">
      <ICDatePicker
        useValue
        label={t("from_date")}
        placeholder={t("from_date")}
        value={
          advanceSearch.from_date !== ""
            ? getLocalDate(advanceSearch.from_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            from_date: event.target.value,
          }))
        }
      />
      <ICDatePicker
        useValue
        label={t("to_date")}
        min={getLocalDate(advanceSearch.from_date)}
        placeholder={t("to_date")}
        value={
          advanceSearch.to_date !== ""
            ? getLocalDate(advanceSearch.to_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            to_date: event.target.value,
          }))
        }
      />

      <ICButton
        onClick={() => setAdvanceSearch(INITIAL_NEWSLETTERSUBSCRIPTION)}
        className="flex"
      >
        <div className="w-5 h-5">{filterClearIcon}</div>&nbsp;{t("clear")}
      </ICButton>
    </div>
  );

  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        filterNode={filterView}
        refreshTable={() => fetchNewsletterSubscription(pagination)}
        handleCheckBox={showAllEmail}
      />
      <ICTable<INewsletterSubscription>
        isLoading={isLoading}
        totalFlex="grid grid-cols-12"
        fields={getNewsletterSubscriptionFields(customComponentUserNameView, t)}
        data={newsletterSubscriptionList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchNewsletterSubscription({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchNewsletterSubscription({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchNewsletterSubscription({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
    </div>
  );
};
export default NewsletterSubscriptionView;
