import { useEffect, useState } from "react";
import { ITopTrainerReport, getReportById } from "../DashboardController";
import { REPORT_ID } from "../../../utils/AppEnumerations";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import RectChartSkeleton from "../skelton-report/RectChartSkeleton";

const TopTrainerView = () => {
  const { t } = UseTranslationHook();
  const [topTrainer, setTopTrainer] = useState<ITopTrainerReport[]>([]);
  const [loading, setLoading] = useState(true);
  const fetchTopTrainer = async () => {
    const data = await getReportById(REPORT_ID.TopTrainer, setLoading);
    if (data) {
      setTopTrainer(data);
    }
  };
  useEffect(() => {
    fetchTopTrainer();
  }, []);

  return loading ? (
    <RectChartSkeleton height={390} />
  ) : (
    <div>
      <p className="typo-title-large font-medium">{t("top_trainer")}</p>
      <div>
        <div>
          <div className="border-b font-medium dark:border-neutral-500">
            <div className="flex items-center py-4">
              <div className="typo-body-large">#</div>
              <div className="typo-body-large pl-4 flex-1">{t("name")}</div>
              <div className="typo-body-large">{t("sessions")}</div>
            </div>
          </div>
          <div>
            {topTrainer.map((item, index) => (
              <div className="flex items-center py-4">
                <div className="typo-body-large">{index + 1}</div>
                <div className="typo-body-large pl-4 flex-1 break-all">
                  {item.trainer_name}
                </div>
                <div className="typo-body-large w-14 text-center">
                  {item.total_session}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopTrainerView;
