import { IItem } from "../../data/AppInterface";
import { TReactSetState, TUseTranslationTfn } from "../../data/AppType";
import {
  CREATE_SUBSCRIPTION,
  GET_SUBSCRIPTION_BY_ID,
  UPDATE_SUBSCRIPTION,
} from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_CONSENT,
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import { BIT_VALUE } from "../../utils/AppEnumerations";
import {
  SweetAlertError,
  SweetAlertSuccess,
  combinedLibraryItems,
  getLocalDate,
  prepareMessageFromParams,
} from "../../utils/AppFunctions";
import { ISubscriptionPackage } from "./SubscriptionPackagesViewController";
import Swal from "sweetalert2";
import * as Yup from "yup";

export interface ISubscriptionPackageForm
  extends Omit<
    ISubscriptionPackage,
    "id" | "created_by" | "created_date" | "modified_by" | "modified_date"
  > {
  id?: number | undefined;
}

export const removeDuplicatesAndExclude = (
  itemsArray: number[],
  excludeValues: number[]
) => {
  return Array.from(
    new Set(itemsArray.filter((item) => !excludeValues.includes(item)))
  );
};

export const subscriptionValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    package_title: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("package_title")],
        ])
      )
      .max(
        100,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("package_title")],
          ["max", "100"],
        ])
      ),
    package_description: Yup.string().max(
      1000,
      prepareMessageFromParams(t("error_message_max_length"), [
        ["fieldName", t("package_description")],
        ["max", "1000"],
      ])
    ),

    target_audience: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("target_audience")],
      ])
    ),
    package_price: Yup.number()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("package_price")],
        ])
      )
      .min(
        1,
        prepareMessageFromParams(t("error_message_negative"), [
          ["fieldName", t("package_price")],
        ])
      )
      .max(
        999999,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("package_price")],
          ["max", "6"],
        ])
      ),
    package_discounted_price: Yup.number()
      .max(
        999999,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("package_discounted_price")],
          ["max", "6"],
        ])
      )
      .min(
        0,
        prepareMessageFromParams(t("error_message_negative"), [
          ["fieldName", t("package_discounted_price")],
        ])
      )
      .lessThan(
        Yup.ref("package_price"),
        prepareMessageFromParams(t("error_message_less_than"), [
          ["fieldNameLess", t("package_discounted_price")],
          ["fieldNameGrater", t("package_price")],
        ])
      ),

    expire_in_days: Yup.number()
      .nullable()
      .min(
        1,
        prepareMessageFromParams(t("error_message_negative"), [
          ["fieldName", t("expire_in_days")],
        ])
      )
      .max(
        7,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("expire_in_days")],
          ["max", "7"],
        ])
      ),

    publish_date: Yup.date().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("publish_date")],
      ])
    ),
    allow_extension: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("allow_extension")],
      ])
    ),
    extension_price: Yup.number().when("allow_extension", {
      is: "1",
      then: () =>
        Yup.number()
          .required(
            prepareMessageFromParams(t("error_message_required"), [
              ["fieldName", t("extension_price")],
            ])
          )
          .min(
            1,
            prepareMessageFromParams(t("error_message_negative"), [
              ["fieldName", t("extension_price")],
            ])
          ),
    }),
    extension_expire_in_days: Yup.string().when("allow_extension", {
      is: "1",
      then: () =>
        Yup.string().required(
          prepareMessageFromParams(t("error_message_required"), [
            ["fieldName", t("extension_expire_in_days")],
          ])
        ),
    }),
  });

export const getSubscriptionInitialValues = (): ISubscriptionPackageForm => {
  return {
    package_title: "",
    package_description: "",
    target_audience: "UT",
    package_price: undefined,
    package_discounted_price: undefined,
    expire_in_days: null,
    allow_extension: "0",
    extension_price: undefined,
    extension_expire_in_days: undefined,
    publish_date: undefined,
    is_active: "1",
  };
};

export const fetchSubscriptionById = async (
  id: number,
  setSubscriptionFormInitialValues: TReactSetState<
    ISubscriptionPackageForm | undefined
  >,
  setSelectedItems: TReactSetState<IItem[]>,
  handleClose: (status: boolean) => void,
  copyPackage: boolean
) => {
  try {
    const subscriptionById = await GET_SUBSCRIPTION_BY_ID(id);
    if (
      subscriptionById &&
      subscriptionById.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      setSubscriptionFormInitialValues({
        id: !copyPackage ? subscriptionById.data.id : undefined,
        package_title: subscriptionById.data.package_title || "",
        package_description: subscriptionById.data.package_description || "",
        target_audience: subscriptionById.data.target_audience
          ? subscriptionById.data.target_audience.trim()
          : "UT",
        package_price: subscriptionById.data.package_price || undefined,
        package_discounted_price:
          subscriptionById.data.package_discounted_price || undefined,
        expire_in_days: subscriptionById.data.expire_in_days || undefined,
        allow_extension: subscriptionById.data.allow_extension || "",
        extension_price: subscriptionById.data.extension_price || undefined,
        extension_expire_in_days:
          subscriptionById.data.extension_expire_in_days || undefined,
        publish_date: copyPackage
          ? undefined
          : subscriptionById.data.publish_date
          ? getLocalDate(subscriptionById.data.publish_date)
          : getLocalDate(),
        is_active: subscriptionById.data.is_active || "1",
      });
      subscriptionById.data.library_items &&
        combinedLibraryItems(
          subscriptionById.data.library_items,
          setSelectedItems
        );
    } else {
      handleClose(false);
      SweetAlertError(
        subscriptionById.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(
      e?.subscriptionById?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleSubmitSubscription = async (
  values: ISubscriptionPackageForm,
  id: number | undefined,
  handleClose: (status: boolean) => void,
  t: TUseTranslationTfn
) => {
  try {
    let subscriptionByIdResult;
    if (!id) {
      subscriptionByIdResult = await CREATE_SUBSCRIPTION(values);
      if (subscriptionByIdResult.code === DEFAULT_STATUS_CODE_SUCCESS) {
        SweetAlertSuccess(subscriptionByIdResult.message);
        return handleClose(true);
      } else {
        SweetAlertError(subscriptionByIdResult.message);
        return {
          ...values,
          library_content: undefined,
          library_directory: undefined,
        };
      }
    } else {
      subscriptionByIdResult = await UPDATE_SUBSCRIPTION(values, id);
      if (subscriptionByIdResult.code === DEFAULT_STATUS_CODE_SUCCESS) {
        SweetAlertSuccess(subscriptionByIdResult.message);
        return handleClose(true);
      } else if (
        subscriptionByIdResult &&
        subscriptionByIdResult.code === DEFAULT_STATUS_CODE_CONSENT &&
        subscriptionByIdResult.data.data === "HasSubscription"
      ) {
        Swal.fire({
          title: t("are_you_sure"),
          text: subscriptionByIdResult.message,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: t("confirm"),
        }).then((result) => {
          if (result.isConfirmed) {
            UPDATE_SUBSCRIPTION(
              { ...values, subscription_consent: BIT_VALUE.One },
              id
            );
            return handleClose(true);
          }
        });
      } else {
        SweetAlertError(subscriptionByIdResult.message);
      }
    }
  } catch (e: any) {
    SweetAlertError(e?.resultFaqs?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
