import { useEffect, useState } from "react";
import {
  IServiceSubCategory,
  fetchServiceSubCategoryApiCall,
  getAdmitTypeFields,
  handleActiveInactiveServiceSubCategory,
  handleDeleteServiceSubCategory,
} from "./ProductServiceSubCategoryController";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import {
  editIcon,
  eyeIcon,
  restoreIcon,
} from "../../../assets/icons/SvgIconList";
import { ORDER_BY } from "../../../utils/AppConstants";
import AdvanceSearch from "../../../components/page-layouts/AdvanceSearch";
import ICTable from "../../../core-component/ICTable";
import usePagination from "../../../hooks/UsePaginationHook";
import { PER_PAGE_ROWS } from "../../../config/AppConfig";
import ServiceSubCategoryForm from "./ProductServiceSubCategoryForm";
import { IPagination } from "../../../data/AppInterface";
import ICSweetAlertModal from "../../../core-component/ICSweetAlertModal";

const ProductServiceSubCategory = () => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [isLoading, setIsLoading] = useState(true);
  const [serviceSubCategoryList, setServiceSubCategoryList] = useState<
    IServiceSubCategory[]
  >([]);
  const [serviceSubCategoryToEdit, setServiceSubCategoryToEdit] =
    useState<IServiceSubCategory>();
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [readOnlyForm, setReadOnlyForm] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const { pagination, setPagination } = usePagination({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "sub_category_title",
      orderBy: "ASC",
    },
  });
  useEffect(() => {
    fetchServiceSubCategory(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, deleteAll]);

  const fetchServiceSubCategory = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchServiceSubCategoryApiCall(
        setServiceSubCategoryList,
        setPagination,
        payloadPagination,
        { enterpriseSearch, deleteAll }
      );
      setIsLoading(false);
    }, 500);
  };
  const handleServiceSubCategoryStatus = async (
    values: IServiceSubCategory
  ) => {
    var isStatusChange = await handleActiveInactiveServiceSubCategory(
      values.id,
      values.is_active === "1" ? "0" : "1"
    );
    if (isStatusChange) {
      const updatedServiceSubCategoryList = serviceSubCategoryList.map(
        (serviceSubCategory) => {
          if (serviceSubCategory.id === values.id) {
            return {
              ...serviceSubCategory,
              is_active: values.is_active === "1" ? "0" : "1",
            };
          }
          return serviceSubCategory;
        }
      );

      setServiceSubCategoryList(updatedServiceSubCategoryList);
    }
  };
  const handleCloseForm = (status: boolean) => {
    setShowForm(false);
    setReadOnlyForm(false);
    if (serviceSubCategoryToEdit) {
      setServiceSubCategoryToEdit(undefined);
    } else {
      setEnterpriseSearch("");
    }
    if (status) {
      fetchServiceSubCategory(pagination);
    }
  };

  const customActionView = (item: IServiceSubCategory) => {
    return (
      <div>
        {item.deleted === "1" ? (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              title={t("restore")}
              onClick={() => handleDeleteCfm(item.id)}
            >
              {restoreIcon}
            </div>
          </div>
        ) : (
          <div className="flex item-center justify-center ml-2">
            <div
              className="mr-2 action-view"
              onClick={() => {
                setShowForm(true);
                setServiceSubCategoryToEdit(item);
                setReadOnlyForm(true);
              }}
            >
              {eyeIcon}
            </div>
            <div
              className="mr-2 action-view "
              title={t("edit_tooltip_icon")}
              onClick={() => {
                setShowForm(true);
                setServiceSubCategoryToEdit(item);
              }}
            >
              {editIcon}
            </div>
            <div className="action-view " title={t("delete_tooltip_icon")}>
              <ICSweetAlertModal onConfirm={handleDeleteCfm} itemId={item.id} />
            </div>
          </div>
        )}
      </div>
    );
  };
  const handleDeleteCfm = async (id: number) => {
    const data = await handleDeleteServiceSubCategory(id, t);
    if (data) {
      fetchServiceSubCategory(pagination);
    }
  };
  const allServiceSubCategoryList = serviceSubCategoryList.map((val) => ({
    ...val,
    IsDeleted: val.deleted === "1" ? true : false,
  }));
  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        handleOnClickAdd={() => setShowForm(true)}
        handleSearchChange={(text) => {
          setEnterpriseSearch(text);
        }}
        searchText={enterpriseSearch}
        handleDeleteCheckBox={(ans) => {
          setDeleteAll(ans);
        }}
        filterCheckBox={deleteAll}
        refreshTable={() => fetchServiceSubCategory(pagination)}
      />
      <ICTable<IServiceSubCategory>
        isLoading={isLoading}
        totalFlex="grid grid-cols-12"
        fields={getAdmitTypeFields(
          handleServiceSubCategoryStatus,
          customActionView,
          t
        )}
        data={allServiceSubCategoryList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchServiceSubCategory({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchServiceSubCategory({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchServiceSubCategory({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
      {showForm && (
        <ServiceSubCategoryForm
          handleClose={handleCloseForm}
          serviceSubCategoryToEdit={serviceSubCategoryToEdit}
          readOnly={readOnlyForm}
          showForm={showForm}
        />
      )}
    </div>
  );
};
export default ProductServiceSubCategory;
