import { IDDL } from "../../../data/AppInterface";
import { TReactSetState, TUseTranslationTfn } from "../../../data/AppType";
import {
  GET_SERVICE_SUB_CATEGORY_BY_ID,
  POST_SERVICE_SUB_CATEGORY,
  UPDATE_SERVICE_SUB_CATEGORY,
} from "../../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  prepareMessageFromParams,
} from "../../../utils/AppFunctions";

import { IServiceSubCategory } from "./ProductServiceSubCategoryController";
import * as Yup from "yup";

export interface IServiceSubCategoryForm
  extends Omit<
    IServiceSubCategory,
    "id" | "created_by" | "created_date" | "modified_by" | "modified_date"
  > {
  id: number | undefined;
}

export const serviceCategorySubValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    sub_category_title: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("category_sub_title")],
        ])
      )
      .min(
        3,
        prepareMessageFromParams(t("error_message_min_length"), [
          ["fieldName", t("category_sub_title")],
          ["min", "3"],
        ])
      )
      .max(
        30,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("category_sub_title")],
          ["max", "30"],
        ])
      ),
    service_category_id: Yup.string().required(
      prepareMessageFromParams(t("error_message_required"), [
        ["fieldName", t("category_title")],
      ])
    ),
  });

export const getServiceSubCategoryInitialValues = (
  serviceSubCategoryToEdit: IServiceSubCategory | undefined
): IServiceSubCategoryForm => {
  return {
    id: serviceSubCategoryToEdit?.id,
    sub_category_title: serviceSubCategoryToEdit?.sub_category_title || "",
    service_category_id: serviceSubCategoryToEdit?.service_category_id,
    is_active: serviceSubCategoryToEdit?.is_active || "0",
  };
};

export const getServiceSubCategoryInitialValueDdl = (
  serviceSubCategoryFormInitialValues: IServiceSubCategoryForm,
  serviceCategoryList: IDDL[]
) => {
  const findCategory = serviceCategoryList.find(
    (item) =>
      item.data_value ===
      serviceSubCategoryFormInitialValues.service_category_id
  );

  return {
    ...serviceSubCategoryFormInitialValues,
    service_category_id: findCategory
      ? serviceSubCategoryFormInitialValues.service_category_id
      : undefined,
  };
};

export const fetchServiceSubCategoryById = async (
  id: number | undefined,
  handleClose: (status: boolean) => void
) => {
  try {
    const serviceSubCategoryId = await GET_SERVICE_SUB_CATEGORY_BY_ID(id);
    if (
      serviceSubCategoryId &&
      serviceSubCategoryId.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      return {
        id: serviceSubCategoryId.data.id,
        sub_category_title: serviceSubCategoryId.data.sub_category_title,
        service_category_id: serviceSubCategoryId.data.service_category_id,
        is_active: serviceSubCategoryId.data.is_active,
      };
    } else {
      handleClose(false);
      SweetAlertError(
        serviceSubCategoryId.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(
      e?.serviceSubCategoryId?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleSubmitServiceSubCategory = async (
  values: IServiceSubCategoryForm,
  id: number | undefined,
  handleClose: (status: boolean) => void,
  t: TUseTranslationTfn
) => {
  try {
    let resultServiceSubCategory;
    if (!id) {
      resultServiceSubCategory = await POST_SERVICE_SUB_CATEGORY(values);
    } else {
      resultServiceSubCategory = await UPDATE_SERVICE_SUB_CATEGORY(values, id);
    }
    if (
      resultServiceSubCategory &&
      resultServiceSubCategory.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      id
        ? SweetAlertSuccess(
            prepareMessageFromParams(t("update_data_successfully"), [
              ["updatedName", t("service_category_sub")],
            ])
          )
        : SweetAlertSuccess(
            prepareMessageFromParams(t("add_data_successfully"), [
              ["addName", t("service_category_sub")],
            ])
          );
      return handleClose(true);
    } else {
      SweetAlertError(
        resultServiceSubCategory?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    SweetAlertError(
      e?.resultServiceSubCategory?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
