import {
  ITabelFieldViewProps,
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../../core-component/ICTable";
import { IDDL, IPagination, ISearch } from "../../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../../data/AppType";
import {
  ACTIVE_INACTIVE_SERVICE_CATEGORY,
  DELETE_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORY,
  // GET_SERVICE_CATEGORY_DDL,
} from "../../../services/Endpoints";
import { GLOBAL_DROP_DOWN } from "../../../services/generic/GenericServices";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
  toastSuccess,
  truncateText,
} from "../../../utils/AppFunctions";

export interface IServiceCategory {
  id: number;
  category_title: string;
  category_desc: string;
  poster_image: string;
  is_active: string;
  category_options: [];
  deleted?: string;
}

export function getServiceCategoryFields<T>(
  handelFormat: (item: ITabelFieldViewProps<T>) => JSX.Element | string,
  handleStatusChange: (item: T) => void,
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("category_title"),
      key: "category_title",
      sort: true,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
      format: handelFormat,
    },
    {
      name: t("category_desc"),
      key: "category_desc",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
      limit: 50,
      title: "category_desc",
    },
    {
      name: t("category_options"),
      key: "category_options",
      sort: false,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
      format: ({ field, data }: ITabelFieldViewProps<T>) =>
        data[field.key].join(" , ")
          ? data[field.key]
              .map((ss: string) => truncateText(ss, 10))
              .join(" , ")
          : "",
      title: "category_options",
    },
    {
      name: t("status"),
      key: "is_active",
      sort: false,
      type: TABLE_FIELD_TYPE.switch,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      handleCheck: handleStatusChange,
      flex: "col-span-1",
    },
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },

    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}

export const fetchServiceCategoryApiCall = async (
  setServiceCategoryList: TReactSetState<IServiceCategory[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const serviceCategoryList = await GET_SERVICE_CATEGORY(pagination, search);
    if (
      serviceCategoryList &&
      serviceCategoryList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (
        serviceCategoryList.data &&
        serviceCategoryList.data.item.length > 0
      ) {
        setServiceCategoryList(serviceCategoryList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(serviceCategoryList.data.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetServiceCategoryList = await GET_SERVICE_CATEGORY(
          resetPagination,
          search
        );
        if (
          resetServiceCategoryList &&
          resetServiceCategoryList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setServiceCategoryList(resetServiceCategoryList.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              resetServiceCategoryList.data.pagination
            ),
          });
        } else {
          setServiceCategoryList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(serviceCategoryList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(
      e?.serviceCategoryList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleDeleteServiceCategory = async (
  id: number,
  t: TUseTranslationTfn
) => {
  try {
    const serviceCategoryDelete = await DELETE_SERVICE_CATEGORY(id);
    if (
      serviceCategoryDelete &&
      serviceCategoryDelete.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      SweetAlertSuccess(serviceCategoryDelete.message);
      return true;
    } else {
      SweetAlertError(
        serviceCategoryDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
    return false;
  } catch (e: any) {
    SweetAlertError(
      e?.serviceCategoryDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};

export const handleActiveInactiveServiceCategory = async (
  id: number,
  values: string
) => {
  try {
    const serviceCategoryActiveInactive =
      await ACTIVE_INACTIVE_SERVICE_CATEGORY(id, { is_active: values });
    if (
      serviceCategoryActiveInactive &&
      serviceCategoryActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      toastSuccess(serviceCategoryActiveInactive.message);
      return true;
    }
    return false;
  } catch (e: any) {
    toastError(
      e?.serviceCategoryActiveInactive?.message ||
        MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};

export const fetchServiceCategoryDdl = async (
  setApiOptionsServiceCategory: TReactSetState<IDDL[]>,
  categoryId?: number
) => {
  try {
    const payload = {
      entity: "service_category",
      data_value: "id",
      display_value: "category_title",
      required_ids: categoryId ? [categoryId] : [],
    };
    const serviceCategoryDDLList = await GLOBAL_DROP_DOWN(payload);
    if (
      serviceCategoryDDLList &&
      serviceCategoryDDLList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (serviceCategoryDDLList.data) {
        setApiOptionsServiceCategory(serviceCategoryDDLList.data);
      } else {
        setApiOptionsServiceCategory([]);
      }
    } else {
      toastError(
        serviceCategoryDDLList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    toastError(
      e?.serviceCategoryDDLList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
