import { NavigateFunction } from "react-router-dom";
import {
  TReactSetState,
  TUseTranslationTfn,
} from "../../../../../data/AppType";
import {
  GET_TIME_SLOT_BY_SERVICE_ID,
  POST_TIME_SLOT,
} from "../../../../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_LOCAL_UNKNOWN_ERROR_OCCURRED,
} from "../../../../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
} from "../../../../../utils/AppFunctions";
import { routeAdmin, routeMaster } from "../../../../../routes/RouteHome";

export const timeSlotOptions = [
  { label: "Manually ", value: "M" },
  { label: "Auto", value: "A" },
];
export interface IPayLoadTimeSlot {
  service_id: number;
  slot: string[];
  is_active: string;
  has_incomplete_session?: string;
}

export interface ITimeSlotInitialValues {
  generate_time_slot: string;
  total_slot: string;
  start_at: string;
  time_slot: string;
  start_at1: string;
  start_at2: string;
}
export const timeSlotInitialValuesFromController = (
  settimeSlotInitialValues: TReactSetState<ITimeSlotInitialValues | undefined>
) => {
  settimeSlotInitialValues({
    generate_time_slot: "M",
    total_slot: "",
    start_at: "",
    time_slot: "",
    start_at2: "",
    start_at1: "",
  });
};

export const handleSubmitTimeSlot = async (
  payLoad: IPayLoadTimeSlot,
  id: number,
  navigation: NavigateFunction,
  setCurrentIndex: TReactSetState<number>,
  buttonController: boolean,
  t: TUseTranslationTfn
) => {
  try {
    if (id) {
      let addTimeSlot;
      addTimeSlot = await POST_TIME_SLOT(payLoad);
      if (addTimeSlot.code === DEFAULT_STATUS_CODE_SUCCESS) {
        if (buttonController === true) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          navigation("/" + routeAdmin.admin + "/" + routeMaster.serviceTable);
        }
        SweetAlertSuccess(addTimeSlot.message);
      } else {
        SweetAlertError(addTimeSlot.message);
      }
    }
  } catch (error) {
    SweetAlertError(MESSAGE_LOCAL_UNKNOWN_ERROR_OCCURRED);
  }
};

export const fetchTimeSlot = async (
  id: number,
  setSlots: TReactSetState<string[]>,
  setIncompleteSession: TReactSetState<string>
) => {
  let timeSlot;
  timeSlot = await GET_TIME_SLOT_BY_SERVICE_ID(id);
  if (timeSlot.code === DEFAULT_STATUS_CODE_SUCCESS) {
    setSlots(timeSlot.data.slot);
    setIncompleteSession(timeSlot.data.has_incomplete_session);
  } else {
    setSlots([]);
  }
};
