import React from "react";
import Modal, { ModalProps } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ICButton from "./ICButton";
import UseTranslationHook from "../hooks/UseTranslationHook";

interface IModalProps extends ModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  customClass?: string;
  content: React.ReactNode;
}

const ModalComponent: React.FC<IModalProps> = ({
  open,
  onClose,
  title,
  customClass,
  content,
}) => {
  const { t } = UseTranslationHook();
  const customStyles: ModalProps["styles"] = {
    modal: {
      maxWidth: "100%",
      width: "95%",
      padding: 20,
    },
    closeButton: {
      top: "8px",
      right: "8px",
    },
    modalContainer: {
      padding: "20px",
      maxHeight: "95%",
      overflowY: "auto",
    },
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={customClass ? customStyles : {}}
      closeOnOverlayClick={false}
    >
      <div className="max-h-[500px] flex flex-col">
        <h2 className="font-bold text-xl">{title}</h2>
        <div className="overflow-scroll flex-1">{content}</div>
        <div className="flex justify-end"></div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
