import {
  ITabelFieldViewProps,
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TTFaqsService,
  TUseTranslationTfn,
} from "../../data/AppType";
import { DELETE_FAQS, GET_ALL_FAQS } from "../../services/Endpoints";
import { ACTIVE_INACTIVE_FAQS } from "../../services/faqs/FaqsServices";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  SweetAlertError,
  SweetAlertSuccess,
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
  toastSuccess,
  truncateText,
} from "../../utils/AppFunctions";

export interface IFaqs {
  id: number;
  faq_type: string;
  question: string;
  answer: string;
  reference_links: [];
  tags: [];
  is_active?: string;
  is_common_for_service: TTFaqsService;
  deleted?: string;
}

export function getFaqsFields<T>(
  handleStatusChange: (item: T) => void,
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("faq_type"),
      key: "faq_type",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("question"),
      key: "question",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      limit: 25,
      title: "question",
    },
    {
      name: t("answer"),
      key: "answer",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-2",
      limit: 25,
      title: "answer",
    },
    {
      name: t("is_common_for_service"),
      key: "is_common_for_service",
      sort: false,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      flex: "col-span-2",
      format: ({ field, data }: ITabelFieldViewProps<T>) =>
        data[field.key] === "1" ? "Yes" : "No",
    },
    {
      name: t("tags"),
      key: "tags",
      sort: false,
      type: TABLE_FIELD_TYPE.format,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
      format: ({ field, data }: ITabelFieldViewProps<T>) =>
        data[field.key].join(" , ")
          ? data[field.key]
              .map((ss: string) => truncateText(ss, 10))
              .join(" , ")
          : "",
    },
    {
      name: t("status"),
      key: "is_active",
      sort: false,
      type: TABLE_FIELD_TYPE.switch,
      align: TABLE_FIELD_ALIGN.center,
      checkedValue: "1",
      handleCheck: handleStatusChange,
      flex: "col-span-1",
    },
    {
      name: t("created_by"),
      key: "created_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },

    {
      name: t("created_date"),
      key: "created_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("modified_by"),
      key: "modified_by",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-1",
    },
    {
      name: t("modified_date"),
      key: "modified_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}

export const fetchFaqsApiCall = async (
  setFaqsList: TReactSetState<IFaqs[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const faqsList = await GET_ALL_FAQS(pagination, search);
    if (faqsList && faqsList.code === DEFAULT_STATUS_CODE_SUCCESS) {
      if (faqsList.data && faqsList.data.item.length > 0) {
        setFaqsList(faqsList.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(faqsList.data.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetFaqsList = await GET_ALL_FAQS(resetPagination, search);
        if (
          resetFaqsList &&
          resetFaqsList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setFaqsList(resetFaqsList.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(resetFaqsList.data.pagination),
          });
        } else {
          setFaqsList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(faqsList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    toastError(e?.faqsList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const handleDeleteFaqs = async (id: number, t: TUseTranslationTfn) => {
  try {
    const faqsDelete = await DELETE_FAQS(id);
    if (faqsDelete && faqsDelete.code === DEFAULT_STATUS_CODE_SUCCESS) {
      SweetAlertSuccess(faqsDelete.message);
      return true;
    } else {
      SweetAlertError(faqsDelete.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    return false;
  } catch (e: any) {
    SweetAlertError(e?.faqsDelete?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    return false;
  }
};

export const handleActiveInactiveFaqs = async (id: number, values: string) => {
  try {
    const faqsActiveInactive = await ACTIVE_INACTIVE_FAQS(id, {
      is_active: values,
    });
    if (
      faqsActiveInactive &&
      faqsActiveInactive.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      toastSuccess(faqsActiveInactive.message);
      return true;
    }
    return false;
  } catch (e: any) {
    toastError(
      e?.faqsActiveInactive?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
    return false;
  }
};
