import React, { useEffect, useState } from "react";
import { IServiceCategory } from "./ProductServiceCategoryController";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import {
  IServiceCategoryForm,
  fetchServiceCategoryById,
  getServiceCategoryInitialValues,
  handleSubmitServiceCategory,
  serviceCategoryValidationSchema,
} from "./ProductServiceCategoryFormController";
import {
  SweetAlertError,
  hasAnyModification,
  prepareMessageFromParams,
} from "../../../utils/AppFunctions";
import ICModal from "../../../core-component/ICModal";
import { Form, Formik, FieldArray, FormikProvider, ErrorMessage } from "formik";
import FormRightModalWrapper from "../../../components/FormRightModalWrapper";
import ICFormikTextInput from "../../../components/formik-input/ICFormikTextInput";
import ICFormikSwitch from "../../../components/formik-input/ICFormikSwitch";
import ICButton from "../../../core-component/ICButton";
import ICFileUpload from "../../../core-component/ICFileUpload";
import { deleteIcon } from "../../../assets/icons/SvgIconList";
import ICFormikTextArea from "../../../components/formik-input/ICFormikTextIArea";
import { fetchUploadImageService } from "../../global/image-service/ImageServices";
import ICTextInput from "../../../core-component/ICTextInput";
import { IMAGE_TYPE } from "../../../utils/AppEnumerations";
import { MESSAGE_UNKNOWN_ERROR_OCCURRED } from "../../../utils/AppConstants";

interface IServiceCategoryFormProps {
  handleClose: (status: boolean) => void;
  serviceCategoryToEdit?: IServiceCategory;
  readOnly?: boolean;
  showForm?: boolean;
}

const ProductServiceCategoryForm: React.FC<IServiceCategoryFormProps> = ({
  handleClose,
  serviceCategoryToEdit,
  readOnly,
}) => {
  const { t } = UseTranslationHook();
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [
    serviceCategoryFormInitialValues,
    setServiceCategoryFormInitialValues,
  ] = useState<IServiceCategoryForm>();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [serviceCategoryImage, setServiceCategoryImage] = useState<string[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const checkFormModification = (values: IServiceCategoryForm) => {
    if (readOnly) {
      handleClose(false);
    } else if (
      serviceCategoryFormInitialValues &&
      hasAnyModification(values, serviceCategoryFormInitialValues)
    ) {
      setShowDiscardWarning(true);
    } else {
      handleClose(false);
    }
  };

  useEffect(() => {
    if (serviceCategoryToEdit) {
      fetchServiceCategoryById(
        serviceCategoryToEdit.id,
        setServiceCategoryFormInitialValues,
        handleClose
      );
    } else {
      setServiceCategoryFormInitialValues(
        getServiceCategoryInitialValues(serviceCategoryToEdit)
      );
    }
  }, []);

  useEffect(() => {
    setServiceCategoryImage(
      serviceCategoryFormInitialValues?.poster_image
        ? [serviceCategoryFormInitialValues.poster_image]
        : []
    );
  }, [serviceCategoryFormInitialValues]);

  const handleSubmitServiceCategoryWithFileUpload = async (values: any) => {
    try {
      setLoading(true);
      const updatedServiceMedia = serviceCategoryImage
        ? serviceCategoryImage.map((url) => ({
            media_url: url,
          }))
        : [];

      for (let i = 0; i < selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append(`myImageFile`, selectedFiles[i]);
        formData.append(`type`, IMAGE_TYPE.ServiceCategory);
        values.id && formData.append(`typeId`, values.id.toString());
        const mediaUrl = await fetchUploadImageService(formData);
        if (!mediaUrl) {
          return null;
        }
        const newMediaItem = {
          media_url: mediaUrl,
        };
        updatedServiceMedia.push(newMediaItem);
      }
      await handleSubmitServiceCategory(
        { ...values, poster_image: updatedServiceMedia[0]?.media_url || "" },
        serviceCategoryToEdit?.id,
        handleClose,
        t
      );
    } catch (e) {
      SweetAlertError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {serviceCategoryFormInitialValues && (
        <Formik
          initialValues={serviceCategoryFormInitialValues}
          validationSchema={() => serviceCategoryValidationSchema(t)}
          enableReinitialize
          onSubmit={(values) =>
            handleSubmitServiceCategoryWithFileUpload(values)
          }
        >
          {(formikPorps) => {
            const { handleSubmit, values, submitCount } = formikPorps;
            return (
              <FormRightModalWrapper
                onClose={() => checkFormModification(values)}
                title={t("category")}
              >
                <Form onSubmit={handleSubmit} className="flex flex-col h-full">
                  <div className="flex-1 overflow-y-auto table-scroll pt-2.5">
                    <ICFormikTextInput
                      {...formikPorps}
                      fieldName={"category_title"}
                      placeholder={t("category_title")}
                      disabled={readOnly}
                      required
                      autoFocus
                    />
                    <ICFormikTextArea
                      {...formikPorps}
                      name={"category_desc"}
                      placeholder={t("category_desc")}
                      disabled={readOnly}
                      required
                    />

                    <FormikProvider value={formikPorps}>
                      <div className="flex gap-4">
                        <FieldArray
                          name="category_options"
                          render={(arrayHelpers) => (
                            <div className="w-full">
                              <p className="mb-2">{t("category_options")}</p>
                              {formikPorps.values.category_options.map(
                                (item, index) => (
                                  <div key={index} className="mb-6">
                                    <div className="flex gap-4">
                                      <ICTextInput
                                        label={t("category_option")}
                                        name={`category_options[${index}]`}
                                        value={
                                          formikPorps.values.category_options[
                                            index
                                          ]
                                        }
                                        onChange={formikPorps.handleChange}
                                        disabled={readOnly}
                                        containerClassName="services-input mt-2"
                                      />
                                      {!readOnly && (
                                        <button
                                          disabled={
                                            formikPorps.values.category_options
                                              ?.length === 0
                                          }
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                          className="deleteButton action-view"
                                        >
                                          {deleteIcon}
                                        </button>
                                      )}
                                    </div>
                                    <ErrorMessage
                                      name={`category_options[${index}]`}
                                      component="div"
                                      className="typo-error"
                                    />
                                  </div>
                                )
                              )}
                              {!readOnly && (
                                <button
                                  className="custom-button typo-btn-primary bg-skin-primary mb-6 w-full"
                                  type="button"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  {t("add_category_options")}
                                </button>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </FormikProvider>

                    <ICFileUpload
                      title="Upload Image"
                      maxFiles={1}
                      maxFileSizeMB={5000}
                      acceptedFileTypes={["jpg", "png", "jpeg", "mp4"]}
                      onFilesSelected={setSelectedFiles}
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      data={serviceCategoryImage}
                      setData={setServiceCategoryImage}
                      disabled={readOnly}
                    />
                    {submitCount > 0 && selectedFiles.length === 0 && (
                      <div className="typo-error">
                        {prepareMessageFromParams(t("error_message_required"), [
                          ["fieldName", t("upload_image")],
                        ])}
                      </div>
                    )}

                    {values.id && (
                      <ICFormikSwitch
                        {...formikPorps}
                        containerClassName={
                          "flex items-center justify-between mt-4"
                        }
                        name={"is_active"}
                        placeholder={t("is_active")}
                        checkedValue={"1"}
                        unCheckedValue={"0"}
                        disabled={readOnly}
                        required
                      />
                    )}
                  </div>
                  {!readOnly && (
                    <ICButton
                      disabled={loading ? true : false}
                      loading={loading}
                      className={"w-full"}
                    >
                      {t("submit")}
                    </ICButton>
                  )}
                </Form>
              </FormRightModalWrapper>
            );
          }}
        </Formik>
      )}
      {showDiscardWarning && (
        <ICModal
          onClose={() => {
            setShowDiscardWarning(false);
          }}
          onConfirm={() => {
            setShowDiscardWarning(false);
            handleClose(false);
          }}
          isOpen
          message={t("discard_unsaved_changes")}
        />
      )}
    </div>
  );
};
export default ProductServiceCategoryForm;
