import {
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import { GET_ALL_PAYMENT_HISTORY } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
} from "../../utils/AppFunctions";

export interface IPaymentHistory {
  id: number;
  payment_date: string;
  amount_paid: string;
  failure_reason: null | string;
  payment_status: string;
  payment_mode: string;
  trainer_name: string;
  from_date: string;
  to_date: string;
  no_of_bookings: number;
  no_of_sessions: number;
  tds_amount: string;
  total_amount: string;
}
export const INITIAL_PAYMENT_HISTORY = {
  to_date: "",
  from_date: "",
  paymentStatus: "",
};
export function getPaymentHistoryFields<T>(
  actionComponent: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("trainer"),
      key: "trainer_name",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-3",
    },

    {
      name: t("total_amount"),
      key: "total_amount",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-2",
    },
    {
      name: t("tds_amount"),
      key: "tds_amount",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-2",
    },
    {
      name: t("amount_paid"),
      key: "amount_paid",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.right,
      flex: "col-span-2",
    },
    {
      name: t("payment_mode"),
      key: "payment_mode",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-3",
    },
    {
      name: t("payment_status"),
      key: "payment_status",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-3",
    },
    {
      name: t("no_of_bookings"),
      key: "no_of_bookings",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("no_of_sessions"),
      key: "no_of_sessions",
      sort: true,
      type: TABLE_FIELD_TYPE.text,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-2",
    },
    {
      name: t("payment_date"),
      key: "payment_date",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-3",
    },
    {
      name: t("action"),
      key: "action",
      sort: false,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-1",
      component: actionComponent,
    },
  ];
}

export const fetchPaymentHistoryApiCall = async (
  setPaymentHistoryList: TReactSetState<IPaymentHistory[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const paymentHistoryListResponse = await GET_ALL_PAYMENT_HISTORY(
      pagination,
      search
    );
    if (
      paymentHistoryListResponse &&
      paymentHistoryListResponse.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      if (
        paymentHistoryListResponse.data &&
        paymentHistoryListResponse.data.item.length > 0
      ) {
        setPaymentHistoryList(paymentHistoryListResponse.data.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(
            paymentHistoryListResponse.data.pagination
          ),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const paymentHistoryListResponse = await GET_ALL_PAYMENT_HISTORY(
          resetPagination,
          search
        );
        if (
          paymentHistoryListResponse &&
          paymentHistoryListResponse.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setPaymentHistoryList(paymentHistoryListResponse.data.item || []);
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              paymentHistoryListResponse.data.pagination
            ),
          });
        } else {
          setPaymentHistoryList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(
        paymentHistoryListResponse.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    toastError(e?.response?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
