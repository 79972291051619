import {
  ITabelFieldViewProps,
  ITableFields,
  TABLE_FIELD_ALIGN,
  TABLE_FIELD_TYPE,
} from "../../core-component/ICTable";
import { IPagination, ISearch } from "../../data/AppInterface";
import {
  TReactSetState,
  TSetPaginationFn,
  TUseTranslationTfn,
} from "../../data/AppType";
import { GET_ALL_NL_SUBSCRIPTION } from "../../services/Endpoints";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../utils/AppConstants";
import {
  generatePaginationFromApiRes,
  resetPaginationWithPpr,
  toastError,
} from "../../utils/AppFunctions";

export interface INewsletterSubscription {
  id: number;
  email: string;
  subscribed_at: Date;
}
export function getNewsletterSubscriptionFields<T>(
  customComponentUserNameView: (item: T) => JSX.Element,
  t: TUseTranslationTfn
): ITableFields<T>[] {
  return [
    {
      name: t("nl_email"),
      key: "email",
      sort: true,
      type: TABLE_FIELD_TYPE.custom,
      align: TABLE_FIELD_ALIGN.left,
      flex: "col-span-6",
      component: customComponentUserNameView,
    },
    {
      name: t("subscribed_at_date"),
      key: "subscribed_at",
      sort: true,
      type: TABLE_FIELD_TYPE.date,
      align: TABLE_FIELD_ALIGN.center,
      flex: "col-span-6",
    },
  ];
}
export const INITIAL_NEWSLETTERSUBSCRIPTION = {
  to_date: "",
  from_date: "",
};

export const fetchNewsletterSubscriptionApiCall = async (
  setNewsletterSubscriptionList: TReactSetState<INewsletterSubscription[]>,
  setPagination: TSetPaginationFn,
  pagination: IPagination,
  search: ISearch
) => {
  try {
    const newsletterSubscriptionList = await GET_ALL_NL_SUBSCRIPTION(
      pagination,
      search
    );
    if (
      newsletterSubscriptionList &&
      newsletterSubscriptionList.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      const inquiryListData = newsletterSubscriptionList.data;
      if (inquiryListData && inquiryListData.item.length > 0) {
        setNewsletterSubscriptionList(inquiryListData.item);
        setPagination({
          ...pagination,
          ...generatePaginationFromApiRes(inquiryListData.pagination),
        });
      } else {
        const resetPagination = { ...pagination, currentPage: 1 };
        const resetGrievancesList = await GET_ALL_NL_SUBSCRIPTION(
          resetPagination,
          search
        );
        const resetNewsletterSubscriptionData = newsletterSubscriptionList.data;

        if (
          resetGrievancesList &&
          resetGrievancesList.code === DEFAULT_STATUS_CODE_SUCCESS
        ) {
          setNewsletterSubscriptionList(
            resetNewsletterSubscriptionData.item || []
          );
          setPagination({
            ...resetPagination,
            ...generatePaginationFromApiRes(
              resetNewsletterSubscriptionData.pagination
            ),
          });
        } else {
          setNewsletterSubscriptionList([]);
          setPagination({
            ...resetPagination,
            ...resetPaginationWithPpr(pagination.perPageRows),
          });
        }
      }
    } else {
      toastError(
        newsletterSubscriptionList.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    toastError(
      e?.newsletterSubscriptionList?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
