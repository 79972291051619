import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import {
  INITIAL_PAYMENT_TRANSACTION,
  IPaymentTransaction,
  fetchPaymentTransactionApiCall,
  getPaymentTransactionFields,
} from "./PaymentTransactionController";
import UsePaginationHook from "../../hooks/UsePaginationHook";
import { PER_PAGE_ROWS } from "../../config/AppConfig";
import { IAdvanceSearch, IPagination } from "../../data/AppInterface";
import {
  ORDER_BY,
  PAYMENT_ENTITY_TYPE_STATUS,
  PAYMENT_TRANSACTION_TYPE_STATUS,
  PAYMENT_TRANSACTION_STATUS,
} from "../../utils/AppConstants";
import AdvanceSearch from "../../components/page-layouts/AdvanceSearch";
import ICTable from "../../core-component/ICTable";
import ICButton from "../../core-component/ICButton";
import { filterClearIcon } from "../../assets/icons/SvgIconList";
import ICDropDown from "../../core-component/ICDropDown";
import ICDatePicker from "../../core-component/ICDatePicker";
import { getLocalDate } from "../../utils/AppFunctions";

const PaymentTransactionView = () => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [isLoading, setIsLoading] = useState(true);
  const [enterpriseSearch, setEnterpriseSearch] = useState("");
  const [paymentTransactionList, setPaymentTransactionList] = useState<
    IPaymentTransaction[]
  >([]);
  const { pagination, setPagination } = UsePaginationHook({
    pagination: {
      perPageRows: Number(PER_PAGE_ROWS),
      currentPage: 1,
      sortBy: "id",
      orderBy: "ASC",
    },
  });

  const [advanceSearch, setAdvanceSearch] = useState<IAdvanceSearch>(
    INITIAL_PAYMENT_TRANSACTION
  );

  useEffect(() => {
    fetchPaymentTransaction(pagination);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [enterpriseSearch, advanceSearch]);

  const fetchPaymentTransaction = (payloadPagination: IPagination) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchPaymentTransactionApiCall(
        setPaymentTransactionList,
        setPagination,
        payloadPagination,
        {
          enterpriseSearch,
          advanceSearch,
        }
      );
      setIsLoading(false);
    }, 500);
  };

  const filterView = (
    <div className="w-full grid grid-cols-3 gap-4 mt-3 bg-skin-background">
      <ICDropDown
        placeholder={t("transaction_type")}
        options={PAYMENT_TRANSACTION_TYPE_STATUS}
        onSelect={(option) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            transaction_type: option.value as string,
          }))
        }
        selected={advanceSearch.transaction_type}
        required
      />
      <ICDropDown
        placeholder={t("entity_type")}
        options={PAYMENT_ENTITY_TYPE_STATUS}
        onSelect={(option) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            entity_type: option.value as string,
          }))
        }
        selected={advanceSearch.entity_type}
        required
      />
      <ICDropDown
        placeholder={t("transaction_status")}
        options={PAYMENT_TRANSACTION_STATUS}
        onSelect={(option) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            transaction_status: option.value,
          }))
        }
        selected={advanceSearch.transaction_status}
        required
      />

      <ICDatePicker
        useValue
        label={t("from_date")}
        placeholder={t("from_date")}
        value={
          advanceSearch.from_date !== ""
            ? getLocalDate(advanceSearch.from_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            from_date: event.target.value,
          }))
        }
      />
      <ICDatePicker
        useValue
        label={t("to_date")}
        placeholder={t("to_date")}
        min={getLocalDate(advanceSearch.from_date)}
        value={
          advanceSearch.to_date !== ""
            ? getLocalDate(advanceSearch.to_date)
            : undefined
        }
        onChangeDate={(event) =>
          setAdvanceSearch((prev) => ({
            ...prev,
            to_date: event.target.value,
          }))
        }
      />

      <ICButton
        onClick={() => setAdvanceSearch(INITIAL_PAYMENT_TRANSACTION)}
        className="flex"
      >
        <div className="w-5 h-5">{filterClearIcon}</div>&nbsp;{t("clear")}
      </ICButton>
    </div>
  );

  return (
    <div className="w-full overflow-auto scrollbar-remove">
      <AdvanceSearch
        filterNode={filterView}
        handleSearchChange={(text) => {
          setEnterpriseSearch(text);
        }}
        searchText={enterpriseSearch}
        refreshTable={() => fetchPaymentTransaction(pagination)}
      />
      <ICTable<IPaymentTransaction>
        isLoading={isLoading}
        totalFlex="grid grid-cols-16"
        fields={getPaymentTransactionFields(t)}
        data={paymentTransactionList}
        pagination={pagination}
        handleChangePerPageRows={(perPageRow) =>
          fetchPaymentTransaction({
            ...pagination,
            perPageRows: perPageRow,
            currentPage: 1,
          })
        }
        handleChangePage={(page) =>
          fetchPaymentTransaction({ ...pagination, currentPage: page })
        }
        handleSort={(field) =>
          fetchPaymentTransaction({
            ...pagination,
            sortBy: field.key,
            orderBy:
              pagination.orderBy === ORDER_BY.desc
                ? ORDER_BY.asc
                : ORDER_BY.desc,
          })
        }
      />
    </div>
  );
};

export default PaymentTransactionView;
