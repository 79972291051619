import { serviceMaker } from "..";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

export interface IConfigPayload {
  id: number;
  config_key: string;
  config_value: string | boolean;
  user_friendly_name: string;
  display_sequence: string;
  modified_date: string;
  control_type: number;
  group_title: string;
  group_display_sequence: string;
  config_tab: number;
  tab_id: number;
  tab_title: string;
  tab_sequence: number;
  max_length: number;
}

export interface IConfigValuePayload {
  id: number;
  config_value: string;
}

export const GET_ALL_SYSTEM_CONFIGURATION = (menuId: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_system_configuration.url + "/" + menuId,
    API_ENDPOINTS.get_system_configuration.method
  );
};

export const UPDATE_ALL_SYSTEM_CONFIGURATION = (
  payload: IConfigValuePayload[]
) => {
  return serviceMaker(
    API_ENDPOINTS.update_system_configuration.url,
    API_ENDPOINTS.update_system_configuration.method,
    payload
  );
};

export const GET_SYSTEM_CONFIGURATION_BY_KEY = (configkey: string) => {
  return serviceMaker(
    API_ENDPOINTS.get_system_version.url + "/" + configkey,
    API_ENDPOINTS.get_system_version.method
  );
};
