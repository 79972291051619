import { serviceMaker } from "..";
import { IActiveInactive, IPagination, ISearch } from "../../data/AppInterface";
import { API_ENDPOINTS } from "../../utils/ApiEndPoint";

interface IBookingCancellation {
  id?: number;
  service_title: string;
  service_id: number | null;
  cancellation_cutoff_hours: number | null;
  cancellation_refund_percentage: number | null;
  is_active: string;
  deleted?: string;
  created_date?: Date;
  modified_date?: Date;
}

export const GET_ALL_BOOKING_CANCELLATION = (
  pagination: IPagination,
  search: ISearch,
  serviceId: number | undefined
) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_booking_cancellation.url +
      `?${serviceId ? `sId=${serviceId}` : ""}&&sd=${
        search.deleteAll
      }&currentPage=${pagination.currentPage}&perPageRows=${
        pagination.perPageRows
      }&sortBy=${pagination.sortBy}&orderBy=${pagination.orderBy}`,
    API_ENDPOINTS.get_all_booking_cancellation.method
  );
};

export const POST_BOOKING_CANCELATION = (payload: IBookingCancellation) => {
  return serviceMaker(
    API_ENDPOINTS.post_booking_cancelation.url,
    API_ENDPOINTS.post_booking_cancelation.method,
    payload
  );
};

export const UPDATE_BOOKING_CANCELATION = (
  payload: IBookingCancellation,
  id: number
) => {
  return serviceMaker(
    API_ENDPOINTS.update_booking_cancelation.url + `/${id}`,
    API_ENDPOINTS.update_booking_cancelation.method,
    payload
  );
};

export const GET_ALL_SERVICE_TITLE = () => {
  return serviceMaker(
    API_ENDPOINTS.get_all_service_title.url,
    API_ENDPOINTS.get_all_service_title.method
  );
};
export const DELETE_BOOKING_CANCELLATION = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.delete_booking_cancellation.url + `/${id}`,
    API_ENDPOINTS.delete_booking_cancellation.method
  );
};

export const GET_BOOKING_CANCELLATION_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_booking_cancellation.url + `/${id}`,
    API_ENDPOINTS.get_all_booking_cancellation.method
  );
};

export const ACTIVE_INACTIVE__BOOKING_CANCELLATION = async (
  id: number,
  activeInactiveValue: IActiveInactive
) => {
  return serviceMaker(
    API_ENDPOINTS.active_inactive_booking_cancellation.url + `/${id}`,
    API_ENDPOINTS.active_inactive_booking_cancellation.method,
    activeInactiveValue
  );
};
