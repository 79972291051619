import React, { ReactNode, useCallback } from "react";
import { TOOL_TIP_POSITION } from "../utils/AppConstants";

interface ITooltipProps {
  text: string;
  children: ReactNode;
  position?: (typeof TOOL_TIP_POSITION)[keyof typeof TOOL_TIP_POSITION]; // New position prop
}

const ICTooltip: React.FC<ITooltipProps> = ({ text, children, position }) => {
  const getPositionClass = useCallback(() => {
    switch (position) {
      case TOOL_TIP_POSITION.right:
        return "right-tooltip";
      case TOOL_TIP_POSITION.bottom:
        return "bottom-tooltip";
      case TOOL_TIP_POSITION.left:
        return "left-tooltip";
      default:
        return "";
    }
  }, [position]);

  return (
    <div className={getPositionClass()}>
      <div className="tooltip-container">
        {children}
        <div className="tooltip-text">{text}</div>
      </div>
    </div>
  );
};

export default ICTooltip;
