import { serviceMaker } from "..";

import { API_ENDPOINTS } from "../../utils/ApiEndPoint";
import { encryptData } from "../../utils/AppFunctions";

export const LOG_IN = (payload: { username: string }) =>
  serviceMaker(API_ENDPOINTS.log_in.url, API_ENDPOINTS.log_in.method, {
    payload: encryptData(JSON.stringify(payload)),
  });

export const GET_SERVICE_CATEGORY_BY_ID = (id: number) => {
  return serviceMaker(
    API_ENDPOINTS.get_all_services.url + `/${id}`,
    API_ENDPOINTS.get_all_services.method
  );
};

export const VERIFY_OTP = (payload: { username: string; otp: string }) =>
  serviceMaker(API_ENDPOINTS.verify_otp.url, API_ENDPOINTS.verify_otp.method, {
    payload: encryptData(JSON.stringify(payload)),
  });

export const CHANGE_PASSWORD = (payload: {
  old_password: string;
  new_password: string;
  confirm_password: string;
}) =>
  serviceMaker(
    API_ENDPOINTS.change_password.url,
    API_ENDPOINTS.change_password.method,
    // payload
    {
      payload: encryptData(JSON.stringify(payload)),
    }
  );
