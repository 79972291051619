import { IServiceCategory } from "./ProductServiceCategoryController";
import { TReactSetState, TUseTranslationTfn } from "../../../data/AppType";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../utils/AppConstants";
import {
  GET_SERVICE_CATEGORY_BY_ID,
  POST_SERVICE_CATEGORY,
  UPDATE_SERVICE_CATEGORY,
} from "../../../services/Endpoints";
import {
  SweetAlertError,
  SweetAlertSuccess,
  prepareMessageFromParams,
} from "../../../utils/AppFunctions";
import * as Yup from "yup";

export interface IServiceCategoryForm
  extends Omit<
    IServiceCategory,
    "id" | "created_by" | "created_date" | "modified_by" | "modified_date"
  > {
  id: number | undefined;
}

export const serviceCategoryValidationSchema = (t: TUseTranslationTfn) =>
  Yup.object().shape({
    category_title: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("category_title")],
        ])
      )
      .min(
        3,
        prepareMessageFromParams(t("error_message_min_length"), [
          ["fieldName", t("category_title")],
          ["min", "3"],
        ])
      )
      .max(
        30,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("category_title")],
          ["max", "30"],
        ])
      ),
    category_desc: Yup.string()
      .required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("category_desc")],
        ])
      )
      .min(
        10,
        prepareMessageFromParams(t("error_message_min_length"), [
          ["fieldName", t("category_desc")],
          ["min", "10"],
        ])
      )
      .max(
        1000,
        prepareMessageFromParams(t("error_message_max_length"), [
          ["fieldName", t("category_desc")],
          ["max", "1000"],
        ])
      ),
    category_options: Yup.array().of(
      Yup.string().required(
        prepareMessageFromParams(t("error_message_required"), [
          ["fieldName", t("category_options")],
        ])
      )
    ),
  });

export const getServiceCategoryInitialValues = (
  serviceCategoryToEdit: IServiceCategory | undefined
): IServiceCategoryForm => {
  return {
    id: serviceCategoryToEdit?.id,
    category_title: serviceCategoryToEdit?.category_title || "",
    category_desc: serviceCategoryToEdit?.category_desc || "",
    poster_image: serviceCategoryToEdit?.poster_image || "",
    category_options: serviceCategoryToEdit?.category_options || [],
    is_active: serviceCategoryToEdit?.is_active || "0",
  };
};

export const fetchServiceCategoryById = async (
  id: number,
  setServiceCategoryList: TReactSetState<IServiceCategoryForm | undefined>,
  handleClose: (status: boolean) => void
) => {
  try {
    const serviceCategoryId = await GET_SERVICE_CATEGORY_BY_ID(id);
    if (
      serviceCategoryId &&
      serviceCategoryId.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      setServiceCategoryList({
        id: serviceCategoryId.data.id,
        category_title: serviceCategoryId.data.category_title,
        category_desc: serviceCategoryId.data.category_desc,
        poster_image: serviceCategoryId.data.poster_image || "",
        category_options: serviceCategoryId.data.category_options,
        is_active: serviceCategoryId.data.is_active,
      });
    } else {
      handleClose(false);
      SweetAlertError(
        serviceCategoryId.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    handleClose(false);
    SweetAlertError(
      e?.serviceCategoryId?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};

export const handleSubmitServiceCategory = async (
  values: IServiceCategoryForm,
  id: number | undefined,
  handleClose: (status: boolean) => void,
  t: TUseTranslationTfn
) => {
  try {
    let resultServiceCategory;
    if (!id) {
      resultServiceCategory = await POST_SERVICE_CATEGORY(values);
    } else {
      resultServiceCategory = await UPDATE_SERVICE_CATEGORY(values, id);
    }
    if (
      resultServiceCategory &&
      resultServiceCategory.code === DEFAULT_STATUS_CODE_SUCCESS
    ) {
      id
        ? SweetAlertSuccess(
            prepareMessageFromParams(t("update_data_successfully"), [
              ["updatedName", t("category")],
            ])
          )
        : SweetAlertSuccess(
            prepareMessageFromParams(t("add_data_successfully"), [
              ["addName", t("category")],
            ])
          );
      return handleClose(true);
    } else {
      SweetAlertError(
        resultServiceCategory.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
      );
    }
  } catch (e: any) {
    SweetAlertError(
      e?.resultServiceCategory?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    );
  }
};
