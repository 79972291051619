import { useEffect, useState } from "react";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { IInquiry } from "./InquiryController";
import {
  IInquiryDetails,
  fetchBookingDetailsById,
} from "./InquiryDetailsController";
import FormRightModalWrapper from "../../components/FormRightModalWrapper";
import {
  dateFormat,
  getLocalDate,
  maskEmail,
  maskMobileNumber,
} from "../../utils/AppFunctions";
import { eyeIcon, visibilityEyeIcon } from "../../assets/icons/SvgIconList";

interface IInquiryDetailsProps {
  handleClose: (status: boolean) => void;
  inquiryDetailsToEdit?: IInquiry;
}

const InquiryDetails: React.FC<IInquiryDetailsProps> = ({
  handleClose,
  inquiryDetailsToEdit,
}) => {
  const { t } = UseTranslationHook();
  const [inquiryDetailsFormInitialValues, setInquiryDetailsFormInitialValues] =
    useState<IInquiryDetails>();
  const [showEmail, setShowEmail] = useState(false);
  const [showMobileNumber, setShowMobileNumber] = useState(false);
  const toggleEmailVisibility = () => {
    setShowEmail(!showEmail);
  };
  const toggleMobileNumberVisibility = () => {
    setShowMobileNumber(!showMobileNumber);
  };
  useEffect(() => {
    if (inquiryDetailsToEdit) {
      fetchBookingDetailsById(
        inquiryDetailsToEdit.id,
        setInquiryDetailsFormInitialValues,
        handleClose
      );
    }
  }, []);
  return (
    <FormRightModalWrapper
      containerClassName="w-full md:w-2/5 lg:w-2/5 xl:w-2/5"
      onClose={() => handleClose(false)}
      title={t("inquiry")}
    >
      <div className="bg-skin-booking-view rounded-lg shadow-md p-6">
        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <div className="typo-form-title">{t("inquiry_subject")}:</div>
          <div>{inquiryDetailsFormInitialValues?.inquiry_subject}</div>
          <div className="typo-form-title">{t("first_name")}:</div>
          <div>{inquiryDetailsFormInitialValues?.first_name}</div>
          <div className="typo-form-title">{t("last_name")}:</div>
          <div>{inquiryDetailsFormInitialValues?.last_name}</div>
          <div className="typo-form-title">{t("contact_email_address")}:</div>
          <div className="flex justify-between">
            {inquiryDetailsFormInitialValues?.contact_email_address !== null ? (
              <>
                {showEmail ? (
                  <div className="mr-2">
                    {inquiryDetailsFormInitialValues?.contact_email_address ??
                      ""}
                  </div>
                ) : (
                  <div className="mr-2">
                    {maskEmail(
                      inquiryDetailsFormInitialValues?.contact_email_address ??
                        ""
                    )}
                  </div>
                )}
                {showEmail ? (
                  <div
                    className="cursor-pointer w-5 h-5"
                    onClick={toggleEmailVisibility}
                  >
                    {eyeIcon}
                  </div>
                ) : (
                  <div
                    className="cursor-pointer w-5 h-5"
                    onClick={toggleEmailVisibility}
                  >
                    {visibilityEyeIcon}
                  </div>
                )}
              </>
            ) : (
              "-"
            )}
          </div>

          <div className="typo-form-title">{t("contact_number")}:</div>
          <div className="flex justify-between">
            {inquiryDetailsFormInitialValues?.contact_number ? (
              <>
                {showMobileNumber ? (
                  <div className="mr-2">
                    {inquiryDetailsFormInitialValues?.contact_number ?? ""}
                  </div>
                ) : (
                  <div className="mr-2">
                    {maskMobileNumber(
                      inquiryDetailsFormInitialValues?.contact_number ?? ""
                    )}
                  </div>
                )}

                {showMobileNumber ? (
                  <div
                    className="cursor-pointer w-5 h-5"
                    onClick={toggleMobileNumberVisibility}
                  >
                    {eyeIcon}
                  </div>
                ) : (
                  <div
                    className="cursor-pointer w-5 h-5"
                    onClick={toggleMobileNumberVisibility}
                  >
                    {visibilityEyeIcon}
                  </div>
                )}
              </>
            ) : (
              "-"
            )}
          </div>
          <div className="typo-form-title">{t("inquiry_date")}:</div>
          <div>
            {dateFormat(
              getLocalDate(inquiryDetailsFormInitialValues?.inquiry_date)
            )}
          </div>
          {inquiryDetailsFormInitialValues?.addressed_date !== null && (
            <>
              <div className="typo-form-title">{t("addressed_date")}:</div>
              <div>
                {dateFormat(
                  getLocalDate(inquiryDetailsFormInitialValues?.addressed_date)
                )}
              </div>
            </>
          )}
          {inquiryDetailsFormInitialValues?.addressed_by !== null && (
            <>
              <div className="typo-form-title">{t("addressed_by")}:</div>
              <div>{inquiryDetailsFormInitialValues?.addressed_by}</div>
            </>
          )}

          <div className="typo-form-title">{t("message_content")}:</div>
          <div>{inquiryDetailsFormInitialValues?.message_content}</div>
        </div>
      </div>
    </FormRightModalWrapper>
  );
};
export default InquiryDetails;
